import {
  CardContent,
  CardFooter,
  CardLarge,
  CardSeparator,
  Form,
  FormGroup,
  FormLabel,
  Heading1,
  ISelectOption,
  Select,
  Size,
  Text,
  useTestId,
  ValidationErrorMessage
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredButton } from '../../Components/CenteredButton';
import { FormGroupWithMoreBottomMargin } from '../../Components/FormGroupWithMoreBottomMargin';
import { GoBackIcon } from '../../Components/GoBackIcon';
import { NONE_OF_THE_ABOVE, INVESTMENT_EXPERIENCE, EDUCATION_SUBJECT, WORK_EXPERIENCE } from '../../constants/sgp/knowledgeExperience';
import { INextPage, IPreviousPage } from '../../models/IPageNavigation';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { IStore } from '../../Store/Store';
import { CardHeaderWithText } from '../../Components/CardHeaderWithText';
import { ISgpKnowledgeExperience } from '../../models/sgp/profile';
import { saveKnowledgeExperience } from '../../Store/Actions';
import { useKnowledgeExperienceValidators } from '../../hooks/sgp/validators/useKnowledgeExperienceValidators';

interface IKnowledgeExperienceProps extends INextPage, Partial<IPreviousPage> {}
const sortFn: () => number = () => 1;

export const KnowledgeExperience: React.FC<IKnowledgeExperienceProps> = ({ onNext, onPrevious, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `sg.knowledgeExperience`);
  const knowledgeExperienceDetails = useSelector((store: IStore) => store.remote.sgp.profile.knowledgeExperience);

  const dispatch = useDispatch();
  const investmentExperienceOptions = createKeyValueTranslatedToOptionList(INVESTMENT_EXPERIENCE, t, sortFn);
  const educationOptions = createKeyValueTranslatedToOptionList(EDUCATION_SUBJECT, t, sortFn);
  const workExperienceOptions = createKeyValueTranslatedToOptionList(WORK_EXPERIENCE, t, sortFn);

  const { investmentExperienceValidator, educationValidator, workExperienceValidator } = useKnowledgeExperienceValidators(
    investmentExperienceOptions,
    educationOptions,
    workExperienceOptions,
    knowledgeExperienceDetails
  );

  const onConfirm = () => {
    const updatedKnowledgeExp: ISgpKnowledgeExperience = {
      investmentExperience: investmentExperienceValidator.value?.value,
      education: educationValidator.value?.value,
      workExperience: workExperienceValidator.value?.value
    };
    dispatch(saveKnowledgeExperience(updatedKnowledgeExp));

    onNext();
  };

  const showNextMsg =
    (investmentExperienceValidator.value && investmentExperienceValidator.value.value !== NONE_OF_THE_ABOVE) ||
    (educationValidator.value && educationValidator.value.value !== NONE_OF_THE_ABOVE) ||
    (workExperienceValidator.value && workExperienceValidator.value.value !== NONE_OF_THE_ABOVE);

  const showComfirmMsg =
    investmentExperienceValidator.value?.value === NONE_OF_THE_ABOVE &&
    educationValidator.value?.value === NONE_OF_THE_ABOVE &&
    workExperienceValidator.value?.value === NONE_OF_THE_ABOVE;

  const showWorkExperience = investmentExperienceValidator.value?.value === NONE_OF_THE_ABOVE && educationValidator.value?.value === NONE_OF_THE_ABOVE;

  return (
    <Form>
      <CardLarge>
        <CardHeaderWithText textWithSmallMargin>
          {onPrevious && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Knowledge and Experience')}</Heading1>
          <Text textAlign='center' size={Size.Large}>
            {t('To access all products offered by CMC Markets Singapore Invest we need to ask you some questions to confirm your eligibility')}
          </Text>
        </CardHeaderWithText>
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <FormLabel>{t('Investment Experience')}</FormLabel>
            <Text>{t('Please select a product that you have placed 6 or more trades in (within the last 3 years)')}</Text>
            <Select
              value={investmentExperienceValidator.value}
              options={investmentExperienceOptions}
              onChange={(option) => investmentExperienceValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Please select a product')}
              isSearchable
              isClearable
              invalid={investmentExperienceValidator.invalid}
              {...generateTestId(`investmentExperience.select`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={investmentExperienceValidator} />
          </FormGroup>
          {investmentExperienceValidator.value?.value === NONE_OF_THE_ABOVE && (
            <FormGroup>
              <FormLabel>{t('Education')}</FormLabel>
              <Text>{t('Please select a subject that you hold a diploma, professional or higher qualifications in')}</Text>
              <Select
                value={educationValidator.value}
                options={educationOptions}
                onChange={(option) => educationValidator.handleEvent(option as ISelectOption)}
                placeholder={t('Please select a subject')}
                isSearchable
                isClearable
                invalid={educationValidator.invalid}
                {...generateTestId(`education.select`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={educationValidator} />
            </FormGroup>
          )}
          {showWorkExperience && (
            <FormGroup>
              <FormLabel>{t('Work Experience')}</FormLabel>
              <Text>{t('Please select an industry that you have work experience in for 3 or more consecutive years (within the last 10 years)')}</Text>
              <Select
                value={workExperienceValidator.value}
                options={workExperienceOptions}
                onChange={(option) => workExperienceValidator.handleEvent(option as ISelectOption)}
                placeholder={t('Please select an industry')}
                isSearchable
                isClearable
                invalid={workExperienceValidator.invalid}
                {...generateTestId(`workExperience.select`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={workExperienceValidator} />
            </FormGroup>
          )}
        </CardContent>
        <CardSeparator />
        <CardFooter>
          {showNextMsg && (
            <FormGroupWithMoreBottomMargin>
              <Text textAlign='start' size={Size.Large}>
                {t(
                  `The Monetary Authority of Singapore’s “Notice on The Sale of Investment Products” requires CMC Markets Singapore Invest Pte. Ltd. to assess that you possess the relevant knowledge and experience to trade specified investment products. By clicking 'Next' you declare the information provided is true and correct.`
                )}
              </Text>
            </FormGroupWithMoreBottomMargin>
          )}
          {showComfirmMsg && (
            <FormGroupWithMoreBottomMargin>
              <Text textAlign='start' size={Size.Large}>
                {t(
                  `The Monetary Authority of Singapore’s “Notice on The Sale of Investment Products” requires CMC Markets Singapore Invest Pte. Ltd. to assess that you possess the relevant knowledge and experience to trade specified investment products. When you submit your application, we will send you an email with more information.`
                )}
              </Text>
            </FormGroupWithMoreBottomMargin>
          )}
          <CenteredButton
            label={t('Next')}
            onClick={onConfirm}
            validators={[investmentExperienceValidator, educationValidator, workExperienceValidator]}
            {...generateTestId(`next`)}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
