import { DayMonthYear1 } from '@cmctechnology/phoenix-stockbroking-api-client';
import { breakpoint, FormCol, FormRow, ISelectOption, Select, useTestId, useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { mapKeyValueTranslatedToOption } from '../common/mappings';
import { MONTHS, MONTHS_DISPLAY_WITH_NUMBERS } from '../constants/commonConstants';
import { filterByStartWith } from '../models/filterOption';
import { PositiveNumericFormControl } from './PositiveNumericFormControl';

const DAY_REGEX = /^(0?[1-9]|[12]\d|3[01])$/;
const YEAR_REGEX = /^\d{4}$/;
const DAY_MAX_LENGTH = 2;
const YEAR_MAX_LENGTH = 4;
const DATE_INDIVIDUAL_COMPONENT_WIDTH_PERCENTAGE = 30;

// will probably create a interface in web-design for IDayMonthYear (to remove dependency to api-client) when need to refactor this component by moving to web-design
export interface IDateProps {
  value: DayMonthYear1;
  onChange: (value: DayMonthYear1) => void;
  validated?: boolean;
  invalid?: boolean;
  hideDay?: boolean; // need to hide day filed for medicare card with Green color
  disabled?: boolean;
  showMonthAsNumber?: boolean; // It is better show month number for medicare to match the value with the card exactly
}

const MonthSelect = styled(Select)`
  @media (max-width: ${breakpoint.mobileSmall}) {
    .react-select__single-value {
      padding-left: 2rem;
    }
  }
  .react-select__placeholder {
    padding-left: 2rem;
  }
`;

export const Date: React.FC<IDateProps> = ({ value, invalid, onChange, validated, hideDay, showMonthAsNumber, disabled, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `date`);
  const yearInputRef = useRef<HTMLInputElement | null>(null);

  const months = showMonthAsNumber ? MONTHS_DISPLAY_WITH_NUMBERS : MONTHS;
  const monthsSelectOptions = Object.keys(months)
    .sort((a, b) => a.localeCompare(b) - 1)
    .map((x) => mapKeyValueTranslatedToOption(months, x, t));
  const dayValidator = useValidator<string>(value.day, Validators.required('').match(DAY_REGEX, ''), { validated });
  const monthValidator = useValidator<string>(value.month, Validators.required(''), { debounceMs: 30, validated });
  const yearValidator = useValidator<string>(value.year, Validators.required('').match(YEAR_REGEX, ''), { validated });

  useEffect(() => {
    if (yearValidator.validated) {
      onChange({ day: dayValidator.value, month: monthValidator.value, year: yearValidator.value });
    }
  }, [dayValidator.value, monthValidator.value, yearValidator.value, dayValidator.validated, monthValidator.validated, yearValidator.validated]);

  // will remove FormRow and FormCol by using flexbox  when need to refactor this component by moving to web-design
  return (
    <>
      <FormRow>
        {!hideDay && (
          <FormCol percentWidth={DATE_INDIVIDUAL_COMPONENT_WIDTH_PERCENTAGE}>
            <PositiveNumericFormControl
              textAlign='center'
              placeholder={t('DD')}
              value={dayValidator.value}
              onChange={(e) => {
                const day = e.target.value.slice(0, DAY_MAX_LENGTH);
                dayValidator.handleEvent(day);
              }}
              onBlur={(e) => {
                const day = e.target.value.slice(0, DAY_MAX_LENGTH);
                dayValidator.handleEvent(day);
              }}
              disabled={disabled}
              invalid={dayValidator.invalid || invalid}
              {...generateTestId(`day`)}
            />
          </FormCol>
        )}
        <FormCol percentWidth={DATE_INDIVIDUAL_COMPONENT_WIDTH_PERCENTAGE}>
          <MonthSelect
            value={monthsSelectOptions.find((x) => x.value === monthValidator.value)}
            options={monthsSelectOptions}
            onChange={async (option) => {
              const month = (option as ISelectOption)?.value || '';
              await monthValidator.handleEvent(month);
              if (!yearValidator.value) {
                yearInputRef.current?.focus();
              }
            }}
            onBlur={() => {
              monthValidator.validate();
            }}
            placeholder={t('Month')}
            isDisabled={disabled}
            invalid={monthValidator.invalid || invalid}
            filterOption={filterByStartWith}
            textAlign='center'
            {...generateTestId(`month`)}
          />
        </FormCol>
        <FormCol percentWidth={DATE_INDIVIDUAL_COMPONENT_WIDTH_PERCENTAGE}>
          <PositiveNumericFormControl
            textAlign='center'
            placeholder={t('YYYY')}
            value={yearValidator.value}
            onChange={(e) => {
              const year = e.target.value.slice(0, YEAR_MAX_LENGTH);
              yearValidator.handleEvent(year, !yearValidator.validated && e.target.value.length < YEAR_MAX_LENGTH);
            }}
            onBlur={(e) => {
              const year = e.target.value.slice(0, YEAR_MAX_LENGTH);
              yearValidator.handleEvent(year);
            }}
            disabled={disabled}
            invalid={yearValidator.invalid || invalid}
            {...generateTestId(`year`)}
            ref={yearInputRef}
          />
        </FormCol>
      </FormRow>
    </>
  );
};
