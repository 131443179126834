import { AustralianTaxInfo, OverseasTaxInfo2 } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../common/mappings';

import { COUNTRIES_EXCEPT_AUS } from '../constants/countryConstants';
import { FIELD_VALIDATION_MESSAGES } from '../constants/validationConstants';
import { AUS_TAX_EXEMPTION_REASONS, OVERSEAS_TAX_EXEMPTION_REASONS, UNABLE_TO_OBTAIN_TIN_REASON } from '../models/profile';
import { taxValidator } from '../validators/taxValidator';

export const useTaxValidators = (australianTaxInfo: AustralianTaxInfo, overseasTaxInfo: OverseasTaxInfo2) => {
  const { t } = useTranslation();
  // TFN should be validated upfront since it's an optional field and only validated once a text has been entered
  const tfnValidator = useValidator(australianTaxInfo.tfn, Validators.custom(taxValidator.isValidTfn, FIELD_VALIDATION_MESSAGES.Tfn.message(t)));

  const ausTaxExemptionReasonsSelectOptions = createKeyValueTranslatedToOptionList(AUS_TAX_EXEMPTION_REASONS, t);
  const ausTaxExemptionReasonValidator = useValidator(
    ausTaxExemptionReasonsSelectOptions.find((x) => x.value === australianTaxInfo.taxExemptionReason),
    Validators.custom((value) => {
      return !!value;
    }, t('Required'))
  );

  const countriesExceptAusSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_EXCEPT_AUS, t);
  const countryValidator = useValidator(
    countriesExceptAusSelectOptions.find((x) => x.value === overseasTaxInfo.country),
    Validators.required(t('Required'))
  );

  const tinValidator = useValidator(
    overseasTaxInfo.tin,
    Validators.custom((value) => !!value, t('Required'))
  );

  const overseasTaxExemptionReasonsSelectOptions = createKeyValueTranslatedToOptionList(OVERSEAS_TAX_EXEMPTION_REASONS, t);
  const overseasTaxExemptionReasonValidator = useValidator(
    overseasTaxExemptionReasonsSelectOptions.find((x) => x.value === overseasTaxInfo.taxExemptionReason),
    Validators.custom((value) => {
      return !!value;
    }, t('Required'))
  );

  const overseasTaxExemptionExplanationValidator = useValidator(
    overseasTaxInfo.taxExemptionExplanation,
    Validators.custom((value) => {
      return overseasTaxExemptionReasonValidator.value?.value !== UNABLE_TO_OBTAIN_TIN_REASON || !!value;
    }, t('Required'))
  );

  return {
    tfnValidator,
    ausTaxExemptionReasonValidator,
    countryValidator,
    tinValidator,
    overseasTaxExemptionReasonValidator,
    overseasTaxExemptionExplanationValidator
  };
};
