import React from 'react';
import { FormCol, FormRow, Label, Text, CardContent, Heading3, FormGroup, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { OCCUPATIONS } from '../models/profile';
import { ReviewEditButton } from '../Components/ReviewEditButton';

export interface IReviewProfilePersonalDetailsProps {
  occupation: string;
  onEdit: () => void;
}

export const ReviewProfileEmploymentDetails: React.FC<IReviewProfilePersonalDetailsProps> = ({ occupation, onEdit }) => {
  const { t } = useTranslation();

  return (
    <CardContent>
      <FormRow>
        <FormCol percentWidth={80}>
          <Heading3>{t('Employment')}</Heading3>
        </FormCol>
        <FormCol percentWidth={20}>
          <FormRow>
            <ReviewEditButton label={t('Edit')} onClick={onEdit} />
          </FormRow>
        </FormCol>
      </FormRow>
      <FormGroup>
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('Occupation')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            <Text size={Size.Medium}>{OCCUPATIONS[occupation]?.(t)}</Text>
          </FormCol>
        </FormRow>
      </FormGroup>
    </CardContent>
  );
};
