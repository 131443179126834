import { FormLabel, IconNames, IThemeProps, Size, Tooltip, Text, useTestId, CircularIcon } from '@cmctechnology/phoenix-stockbroking-web-design';
import { MutableRefObject, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconMarginLeftCss } from './IconCss';

const FormLabelWithOptionalBottomMargin = styled(FormLabel)<{ includeBottomMargin?: boolean }>`
  ${({ includeBottomMargin }) =>
    includeBottomMargin &&
    css`
      margin-bottom: 0.5rem;
    `}
  text-align: left;
`;

export const TooltipIcon = styled(CircularIcon)`
  ${IconMarginLeftCss}
`;

export const TooltipText = styled(Text)`
  background-color: ${(props: IThemeProps) => props.theme.colours.tooltip.background};
  padding: 1rem;
`;

export interface ITooltipLabelAndTextProps {
  label: string;
  text: string;
  parentRef: MutableRefObject<HTMLElement | null>;
  includeBottomMargin?: boolean;
}

export const TooltipLabelAndText: React.FC<ITooltipLabelAndTextProps> = ({ label, text, parentRef, includeBottomMargin, ...rest }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipIconRef = useRef<HTMLElement | null>(null);
  const { generateTestId } = useTestId(rest, `TooltipLabelAndText`);
  return (
    <>
      <FormLabelWithOptionalBottomMargin includeBottomMargin={includeBottomMargin}>
        {label}
        <TooltipIcon ref={tooltipIconRef} name={IconNames.Info} size={Size.Small} onClick={() => setTooltipVisible((x) => !x)} {...generateTestId(`icon`)} />
      </FormLabelWithOptionalBottomMargin>

      <Tooltip parentRef={parentRef} triggerRef={tooltipIconRef} visible={tooltipVisible} onClickOutside={() => setTooltipVisible(false)} {...generateTestId()}>
        <TooltipText>{text}</TooltipText>
      </Tooltip>
    </>
  );
};
