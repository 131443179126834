// Add validator & tests in separate PR
/* istanbul ignore file */
import React, { useRef } from 'react';
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  CardContent,
  CardLarge,
  CardSeparator,
  Heading1,
  Text,
  ValidationErrorMessage,
  Size,
  CardHeader,
  useTestId,
  ISelectOption,
  Select,
  CheckBox,
  ButtonLink,
  useModalState,
  useValidator,
  Validators
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useDispatch, useSelector } from 'react-redux';
import { DayMonthYear, DayMonthYear1 } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IStore } from '../../Store/Store';
import { ProfileCardFooter } from '../../Components/ProfileCardFooter';
import { INextPage } from '../../models/IPageNavigation';
import { AutoComplete } from '../../constants/autocompleteConstants';
import { Date } from '../../Components/Date';
import { IdentityDocumentType, IDENTITY_DOCUMENT_TYPES } from '../../models/sgp/identityDocumentType';
import { usePersonalDetailsValidators } from '../../hooks/sgp/validators/usePersonalDetailsValidators';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { SGP_PERSON_TITLES } from '../../models/sgp/titleConstants';
import { COUNTRIES_ALL, COUNTRIES_RESIDENCE, COUNTRY_CODE_SG } from '../../models/sgp/country';
import { FORMATTED_MONTH_FIXED_LENGTH } from '../../constants/personalDetails';
import { EMPTY_STRING } from '../../constants/commonConstants';
import { ISgpPersonalDetails } from '../../models/sgp/profile';
import { openExternalUrl, savePersonalDetails } from '../../Store/Actions';
import { SGP_GOV_CHECK_YOUR_WORK_PASS } from '../../constants/sgp/urlConstants';
import { NameExtractCheckModal } from './NameExtractCheckModal';
import { CONFIRM_DOB_VALIDATION_MESSAGES } from '../../constants/validationConstants';
import { isEqualDayMonthYear } from '../../utils/dateUtils';

const FormGroupWithLeftAlignedButtonLink = styled(FormGroup)`
  text-align: left;
  ${ButtonLink} {
    text-decoration: none;
  }
`;

const UppercaseInput = styled(FormControl)`
  text-transform: uppercase;
  ::placeholder {
    text-transform: none;
  }
`;

export interface IProfilePersonalDetailsProps extends INextPage {}

export const ProfilePersonalDetails: React.FC<IProfilePersonalDetailsProps> = ({ onNext, ...rest }) => {
  const [, setModalState] = useModalState();

  const personalDetails = useSelector((store: IStore) => store.remote.sgp.profile.personalDetails);
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `profile`);
  const dispatch = useDispatch();

  const refConfirmDob = useRef<DayMonthYear1>();
  const {
    titleValidator,
    firstNameValidator,
    middleNameValidator,
    lastNameValidator,
    aliasNameValidator,
    hanyuPinyinNameValidator,
    hanyuPinyinAliasNameValidator,
    dobValidator,
    countryOfResidenceValidator,
    nationalityValidator,
    identityDocumentTypeValidator,
    nationalRegistrationIdentityCardNumberValidator,
    foreignIdentificationNumberValidator,
    passExpiryDateValidator,
    clientEnteredNameValidator,
    fullNameValidator
  } = usePersonalDetailsValidators(personalDetails);
  const validateConfirmDob = () => {
    if (personalDetails.isDateOfBirthReadonly && personalDetails.dateOfBirth) return true;
    return isEqualDayMonthYear(dobValidator.value, refConfirmDob.current);
  };

  const confirmDobValidator = useValidator<DayMonthYear1>(
    { day: '', month: '', year: '' },
    Validators.custom(() => validateConfirmDob(), CONFIRM_DOB_VALIDATION_MESSAGES.NotMatch.message(t))
  );

  const haveHanyuPinyinName = personalDetails.isHaveHanyuPinyinNameReadOnly || personalDetails.haveHanyuPinyinName;
  const completeMyInfoProcess = useSelector((store: IStore) => store.persist!.sgp.completeMyInfoProcess);

  const titleSelectOptions = createKeyValueTranslatedToOptionList(SGP_PERSON_TITLES, t);
  const countryOfResidenceSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_RESIDENCE, t);
  const nationalitySelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t);
  const identityDocumentTypeSelectOptions = createKeyValueTranslatedToOptionList(IDENTITY_DOCUMENT_TYPES, t);

  const needShowIdentityDocumentType = countryOfResidenceValidator.value?.value === COUNTRY_CODE_SG || nationalityValidator.value?.value === COUNTRY_CODE_SG;
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const convertToDayMonthYear1 = (value: DayMonthYear): DayMonthYear1 => {
    const dayMonthYear1: DayMonthYear1 = {
      day: value?.day!,
      month: value?.month ? value?.month!.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0') : EMPTY_STRING,
      year: value?.year!
    };
    return dayMonthYear1;
  };

  const parseDateStringToDayMonthYear1 = (dateString: string): DayMonthYear1 => {
    const [year, month, day] = dateString.split('-');
    return {
      day,
      month: month?.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0') ?? EMPTY_STRING,
      year
    };
  };

  const savePersonalDetailsAndGoNext = () => {
    const updatedPersonalDetails: ISgpPersonalDetails = {
      title: titleValidator.value?.value,
      useClientEnteredName: clientEnteredNameValidator.value,
      clientEnteredFirstName: firstNameValidator.value,
      clientEnteredMiddleName: middleNameValidator.value,
      clientEnteredLastName: lastNameValidator.value,
      aliasName: aliasNameValidator.value,
      haveHanyuPinyinName,
      hanyuPinyinName: hanyuPinyinNameValidator.value,
      hanyuPinyinAliasName: hanyuPinyinAliasNameValidator.value,
      dateOfBirth: dobValidator.value,
      countryOfResidence: countryOfResidenceValidator.value?.value,
      nationality: nationalityValidator.value?.value,
      identityDocumentType: identityDocumentTypeValidator.value?.value,
      nric: nationalRegistrationIdentityCardNumberValidator.value,
      fin: foreignIdentificationNumberValidator.value,
      passExpiryDate: passExpiryDateValidator.value,
      idType: personalDetails.idType,
      idCountry: personalDetails.idCountry,
      idNumber: personalDetails.idNumber,
      idExpiry: personalDetails.idExpiry
    };

    dispatch(savePersonalDetails(updatedPersonalDetails));
    onNext();
  };

  const onNextClicked = async () => {
    const isClientEnteredNameValid = await fullNameValidator.validate();

    if (!isClientEnteredNameValid) {
      setModalState({
        dialog: <NameExtractCheckModal onProceed={savePersonalDetailsAndGoNext} />,
        open: true
      });
    } else {
      savePersonalDetailsAndGoNext();
    }
  };

  const onClientEnteredNameChange = (e: any) => {
    clientEnteredNameValidator.handleEvent(e.target.checked);

    if (!e.target.checked) {
      // reset custom names
      firstNameValidator.handleEvent(EMPTY_STRING, true);
      middleNameValidator.handleEvent(EMPTY_STRING, true);
      lastNameValidator.handleEvent(EMPTY_STRING, true);
    }
  };

  return (
    <Form>
      <CardLarge>
        <CardHeader>
          <Heading1>{t('Personal details')}</Heading1>
        </CardHeader>
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <FormLabel>{t('Full name')}</FormLabel>
            <FormControl type='text' name='fullName' value={personalDetails.fullName ?? EMPTY_STRING} disabled {...generateTestId(`fullName`)} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Title')}</FormLabel>
            <Select
              value={titleValidator.value}
              options={titleSelectOptions}
              onChange={(option) => titleValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Please select a title')}
              invalid={titleValidator.invalid}
              {...generateTestId(`title`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={titleValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('First name')}</FormLabel>
            <FormControl type='text' name='firstName' value={personalDetails.firstName ?? EMPTY_STRING} disabled {...generateTestId(`firstName`)} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Last name')}</FormLabel>
            <FormControl type='text' name='lastName' value={personalDetails.lastName ?? EMPTY_STRING} disabled {...generateTestId(`lastName`)} />
          </FormGroup>
          <FormGroup>
            <CheckBox
              label={t('My name does not appear correctly in separate fields above')}
              checked={clientEnteredNameValidator.value}
              onChange={onClientEnteredNameChange}
              {...generateTestId(`clientEnteredName`)}
            />
          </FormGroup>
          {clientEnteredNameValidator.value && (
            <>
              <Text>
                {t(
                  'Please enter your First, Middle and Last Name as it should appear. (This is how we will store your name, it must include all the names that have been extracted as listed in the Full Name field)'
                )}
              </Text>
              <FormGroup>
                <FormLabel>{t('First name')}</FormLabel>
                <UppercaseInput
                  type='text'
                  name='clientEnteredFirstName'
                  autoComplete={AutoComplete.FirstName}
                  placeholder={t('Enter your first name')}
                  value={firstNameValidator.value}
                  onChange={(e) => firstNameValidator.handleEvent(e.target.value, !firstNameValidator.validated)}
                  onBlur={(e) => firstNameValidator.handleEvent(e.target.value.trim())}
                  invalid={firstNameValidator.invalid}
                  {...generateTestId(`clientEnteredFirstName`)}
                />
                <ValidationErrorMessage size={Size.Medium} validator={firstNameValidator} />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('Middle name (optional)')}</FormLabel>
                <UppercaseInput
                  type='text'
                  name='clientEnteredMiddleName'
                  autoComplete={AutoComplete.MiddleName}
                  placeholder={t('Enter your middle name')}
                  value={middleNameValidator.value}
                  onChange={(e) => middleNameValidator.handleEvent(e.target.value, !middleNameValidator.validated)}
                  onBlur={(e) => middleNameValidator.handleEvent(e.target.value.trim())}
                  invalid={middleNameValidator.invalid}
                  {...generateTestId(`clientEnteredMiddleName`)}
                />
                <ValidationErrorMessage size={Size.Medium} validator={middleNameValidator} />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('Last name')}</FormLabel>
                <UppercaseInput
                  type='text'
                  name='clientEnteredLastName'
                  autoComplete={AutoComplete.LastName}
                  placeholder={t('Enter your last name')}
                  value={lastNameValidator.value}
                  onChange={(e) => lastNameValidator.handleEvent(e.target.value, !lastNameValidator.validated)}
                  onBlur={(e) => lastNameValidator.handleEvent(e.target.value.trim())}
                  invalid={lastNameValidator.invalid}
                  {...generateTestId(`clientEnteredLastName`)}
                />
                <ValidationErrorMessage size={Size.Medium} validator={lastNameValidator} />
              </FormGroup>
            </>
          )}
          {completeMyInfoProcess && personalDetails.isAliasNameReadonly && (
            <FormGroup>
              <FormLabel>{t('Alias name (optional)')}</FormLabel>
              <FormControl
                type='text'
                name='aliasName'
                placeholder={t('Enter your alias name')}
                value={aliasNameValidator.value}
                onChange={(e) => aliasNameValidator.handleEvent(e.target.value.trim(), !aliasNameValidator.validated)}
                onBlur={(e) => aliasNameValidator.handleEvent(e.target.value.trim())}
                invalid={aliasNameValidator.invalid}
                disabled={personalDetails.isAliasNameReadonly}
                {...generateTestId(`aliasName`)}
              />
            </FormGroup>
          )}
          {completeMyInfoProcess && haveHanyuPinyinName && (
            <>
              <FormGroup>
                <FormLabel>{t('Hanyu Pinyin name (optional)')}</FormLabel>
                <FormControl
                  type='text'
                  name='hanyuPinyinName'
                  placeholder={t('Enter your hanyu pinyin name')}
                  value={hanyuPinyinNameValidator.value}
                  onChange={(e) => hanyuPinyinNameValidator.handleEvent(e.target.value, !hanyuPinyinNameValidator.validated)}
                  onBlur={(e) => hanyuPinyinNameValidator.handleEvent(e.target.value.trim())}
                  invalid={hanyuPinyinNameValidator.invalid}
                  disabled={personalDetails.isHanyuPinyinNameReadonly}
                  {...generateTestId(`hanyuPinyinName`)}
                />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('Hanyu Pinyin alias name (optional)')}</FormLabel>
                <FormControl
                  type='text'
                  name='hanyuPinyinAliasName'
                  placeholder={t('Enter your hanyu pinyin alias name')}
                  value={hanyuPinyinAliasNameValidator.value}
                  onChange={(e) => hanyuPinyinAliasNameValidator.handleEvent(e.target.value, !hanyuPinyinAliasNameValidator.validated)}
                  onBlur={(e) => hanyuPinyinAliasNameValidator.handleEvent(e.target.value.trim())}
                  invalid={hanyuPinyinAliasNameValidator.invalid}
                  disabled={personalDetails.isHanyuPinyinAliasNameReadonly}
                  {...generateTestId(`hanyuPinyinAliasName`)}
                />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <FormLabel>{t('Date of birth:')}</FormLabel>
            <Date
              value={convertToDayMonthYear1(dobValidator.value)}
              onChange={(newDob) => {
                dobValidator.handleEvent(newDob);
              }}
              disabled={personalDetails.isDateOfBirthReadonly}
              invalid={dobValidator.invalid}
              validated={personalDetails.validated}
              {...generateTestId(`dob`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={dobValidator} />
          </FormGroup>
          {!personalDetails.isDateOfBirthReadonly && (
            <FormGroup>
              <FormLabel>{t('Confirm date of birth')}</FormLabel>
              <Date
                value={confirmDobValidator.value}
                onChange={(newDob) => {
                  refConfirmDob.current = newDob;
                }}
                invalid={confirmDobValidator.invalid}
                {...generateTestId(`confirm_dob`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={confirmDobValidator} />
            </FormGroup>
          )}
          <FormGroup>
            <FormLabel>{t('Country of Residence')}</FormLabel>
            <Select
              value={countryOfResidenceValidator.value}
              options={countryOfResidenceSelectOptions}
              onChange={(option) => countryOfResidenceValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Select country')}
              isSearchable
              isClearable
              invalid={countryOfResidenceValidator.invalid}
              isDisabled={personalDetails.isCountryOfResidenceReadonly}
            />
            <ValidationErrorMessage size={Size.Medium} validator={countryOfResidenceValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Nationality')}</FormLabel>
            <Select
              value={nationalityValidator.value}
              options={nationalitySelectOptions}
              onChange={(option) => nationalityValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Select country')}
              isSearchable
              isClearable
              invalid={nationalityValidator.invalid}
              isDisabled={personalDetails.isNationalityReadonly}
            />
            <ValidationErrorMessage size={Size.Medium} validator={nationalityValidator} />
          </FormGroup>
          {needShowIdentityDocumentType && (
            <FormGroup>
              <FormLabel>{t('National Identification Type')}</FormLabel>
              <Select
                value={identityDocumentTypeValidator.value}
                options={identityDocumentTypeSelectOptions}
                onChange={(option) => identityDocumentTypeValidator.handleEvent(option as ISelectOption)}
                placeholder={t('Please select a title')}
                invalid={identityDocumentTypeValidator.invalid}
                isDisabled={personalDetails.isIdentityDocumentTypeReadonly}
                {...generateTestId(`nationalIdentificationType`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={identityDocumentTypeValidator} />
            </FormGroup>
          )}
          {identityDocumentTypeValidator.value?.value === IdentityDocumentType.NationalRegistrationIdentityCard && needShowIdentityDocumentType && (
            <FormGroup>
              <FormLabel>{t('National Registration Identity Card (NRIC) number')}</FormLabel>
              <FormControl
                type='text'
                name='uinfin'
                placeholder={t('Enter your National Registration Identity Card (NRIC) number')}
                value={nationalRegistrationIdentityCardNumberValidator.value}
                onChange={(e) =>
                  nationalRegistrationIdentityCardNumberValidator.handleEvent(e.target.value.trim(), !nationalRegistrationIdentityCardNumberValidator.validated)
                }
                onBlur={(e) => nationalRegistrationIdentityCardNumberValidator.handleEvent(e.target.value.trim())}
                disabled={personalDetails.isNricReadonly}
                {...generateTestId(`uinfin`)}
              />
            </FormGroup>
          )}
          {identityDocumentTypeValidator.value?.value === IdentityDocumentType.ForeignIdentificationNumber && needShowIdentityDocumentType && (
            <>
              <FormGroup>
                <FormLabel>{t('Foreign Identification Number (FIN)')}</FormLabel>
                <FormControl
                  type='text'
                  name='foreignIdentificationNumber'
                  placeholder={t('Enter your Foreign Identification Number')}
                  value={foreignIdentificationNumberValidator.value}
                  onChange={(e) => foreignIdentificationNumberValidator.handleEvent(e.target.value.trim(), !foreignIdentificationNumberValidator.validated)}
                  onBlur={(e) => foreignIdentificationNumberValidator.handleEvent(e.target.value.trim())}
                  disabled={personalDetails.isFinReadonly}
                  {...generateTestId(`foreignIdentificationNumber`)}
                />
              </FormGroup>
              <FormGroupWithLeftAlignedButtonLink>
                <FormLabel>
                  {t('Pass expiry date:')}
                  <ButtonLink onClick={() => openExternalUrl(SGP_GOV_CHECK_YOUR_WORK_PASS)}>{t('(Check your FIN expiry date)')}</ButtonLink>
                </FormLabel>
                <Date
                  value={convertToDayMonthYear1(personalDetails.passExpiryDate!)}
                  onChange={(newExpiryDate) => {
                    passExpiryDateValidator.handleEvent(newExpiryDate);
                  }}
                  invalid={passExpiryDateValidator.invalid}
                  validated={personalDetails.validated}
                  disabled={personalDetails.isPassExpiryDateReadonly}
                />
                <ValidationErrorMessage size={Size.Medium} validator={passExpiryDateValidator} />
              </FormGroupWithLeftAlignedButtonLink>
            </>
          )}
          {personalDetails.idType && (
            <FormGroup>
              <FormLabel>{t('Identity Document Type')}</FormLabel>
              <UppercaseInput type='text' name='idType' value={personalDetails.idType} disabled {...generateTestId(`idType`)} />
            </FormGroup>
          )}
          {personalDetails.idNumber && (
            <FormGroup>
              <FormLabel>{t('Identity Document Number')}</FormLabel>
              <UppercaseInput type='text' name='idNumber' value={personalDetails.idNumber} disabled {...generateTestId(`idNumber`)} />
            </FormGroup>
          )}
          {personalDetails.idCountry && (
            <FormGroup>
              <FormLabel>{t('Identity Document Issuing Country')}</FormLabel>
              <UppercaseInput type='text' name='idCountry' value={personalDetails.idCountry} disabled {...generateTestId(`idCountry`)} />
            </FormGroup>
          )}
          {personalDetails.idExpiry && (
            <FormGroup>
              <FormLabel>{t('Identity Document Expiry Date')}</FormLabel>
              <Date value={parseDateStringToDayMonthYear1(personalDetails.idExpiry)} onChange={() => {}} disabled {...generateTestId(`idExpiry`)} />
            </FormGroup>
          )}
        </CardContent>
        <ProfileCardFooter
          nextButtonLabel={t('Next')}
          saveButtonLabel={t('Save')}
          onNext={onNextClicked}
          onCancel={onNext}
          validators={[
            firstNameValidator,
            middleNameValidator,
            lastNameValidator,
            titleValidator,
            dobValidator,
            countryOfResidenceValidator,
            nationalityValidator,
            confirmDobValidator
          ]}
        />
      </CardLarge>
    </Form>
  );
};
