import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  CardLarge,
  Heading1,
  CardHeader,
  CardSeparator,
  CardContent,
  FormGroup,
  FormLabel,
  FormControl,
  PasswordFormControl,
  Button,
  ButtonLink,
  CheckBox,
  breakpoint,
  IThemeProps,
  Validators,
  useValidator,
  ValidationErrorMessage,
  Size,
  Variant,
  Text,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useDispatch, useSelector } from 'react-redux';
import { UsersErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { selectIndividualAccountAndCashPayment, signIn } from '../../Store/Actions';
import { ApiRequest, ApiRequestStatus } from '../../models/apiRequestResult';
import { BUSINESS_REGION_TO_BRAND_MAPPINGS, emailRegex } from '../../constants/loginConstants';
import { IStore } from '../../Store/Store';
import { getSignInErrorMessage } from '../../models/usersErrorCodes/signInErrorCodes';
import { INextPage } from '../../models/IPageNavigation';
import { useBusinessRegion } from '../../hooks/useBusinessRegion';
import { BusinessRegion } from '../../configuration/configuration';

// shared by FundIntercept screen
export const CardWrapper = styled(CardLarge)`
  width: 28rem;
  max-width: 28rem;

  margin-left: 8rem;
  margin-right: 0;

  @media only screen and (max-width: ${breakpoint.monitor}) {
    margin-left: auto;
  }

  @media only screen and (max-width: ${breakpoint.mobile}) {
    margin-left: auto;
  }

  @media only screen and (max-width: ${breakpoint.mobileSmall}) {
    width: 100%;
    margin: 0 1.5rem;
  }
`;

const LoginCardHeader = styled(CardHeader)`
  padding: 0;
  width: 100%;
  height: 8.5rem;
  justify-content: center;

  ${Heading1} {
    margin-top: 0;
    margin-bottom: 0;
    color: ${(props: IThemeProps) => props.theme.colours.card.header.text};
  }
`;

const LoginCardContent = styled(CardContent)`
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 3rem 3rem;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 1rem 1.5rem 3rem;
  }
`;

const LoginButton = styled(Button)`
  width: 10.5rem;
`;

const LinkButton = styled(ButtonLink)`
  text-decoration: none;
`;

const ForgotPasswordFormGroup = styled(FormGroup)`
  text-align: left;
`;

const PasswordInput = styled(PasswordFormControl)`
  padding: 0.75rem;
`;

const UsernameInput = styled(FormControl)`
  padding: 0.75rem;
`;

export const ErrorText = styled(Text)`
  margin-bottom: 1.5rem;
`;

interface ILoginCardProps extends INextPage {
  onForgotPassword: () => void;
}

export const LoginCard: React.FC<ILoginCardProps> = ({ onNext, onForgotPassword, ...rest }): JSX.Element => {
  const signInStatus = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignIn].status);
  const signInErrorCode = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignIn].errorCode);
  const signInErrorCodeIfNotMatchBrand = UsersErrorCode.NotAuthorizedException;
  const brand = useSelector((store: IStore) => store.local.brand);
  const { businessRegion } = useBusinessRegion();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isAccountCached, setIsAccountCached] = useState(false);

  const emailValidator = useValidator<string>('', Validators.required(t('Please enter your email')).match(emailRegex, t('Please enter a valid email')));
  const passwordValidator = useValidator<string>('', Validators.required(t('Please enter your password')));
  const { generateTestId } = useTestId(rest, `onboarding.login`);

  const brandInSignInRequest = BUSINESS_REGION_TO_BRAND_MAPPINGS[businessRegion as BusinessRegion];

  // To be safer, just in case the brand is not returned for cmc brand since the brand feature is added after au onboarding go live
  const isMatchedBrand = brandInSignInRequest === brand || (!brand && businessRegion === BusinessRegion.AUS);

  const isLoading = signInStatus === ApiRequestStatus.InProgress;

  useEffect(() => {
    if (signInStatus === ApiRequestStatus.Success && isMatchedBrand) {
      onNext();
    }
  }, [signInStatus, onNext]);

  const onSubmit = () => {
    dispatch(signIn({ username: emailValidator.value, password: passwordValidator.value, brand: brandInSignInRequest }));
    dispatch(selectIndividualAccountAndCashPayment);
  };

  return (
    <CardWrapper>
      <LoginCardHeader>
        <Heading1>{t('Login')}</Heading1>
      </LoginCardHeader>
      <CardSeparator />
      <LoginCardContent>
        <FormGroup>
          <FormLabel>{t('Email (username)')}</FormLabel>
          <UsernameInput
            autoFocus
            type='email'
            placeholder={t('Enter your email address')}
            id='logonAccount'
            name='logonAccount'
            value={emailValidator.value}
            onChange={(e) => {
              emailValidator.handleEvent(e.target.value, !emailValidator.validated);
            }}
            onBlur={(e) => emailValidator.handleEvent(e.target.value)}
            invalid={emailValidator.invalid}
            {...generateTestId(`username`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={emailValidator} />
        </FormGroup>
        <FormGroup>
          <FormLabel>{t('Password')}</FormLabel>
          <PasswordInput
            placeholder={t('Enter your password')}
            id='logonPassword'
            name='logonPassword'
            value={passwordValidator.value}
            onChange={(e) => {
              passwordValidator.handleEvent(e.target.value);
            }}
            onBlur={(e) => passwordValidator.handleEvent(e.target.value)}
            invalid={passwordValidator.invalid}
            {...generateTestId(`password`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={passwordValidator} />
        </FormGroup>
        <ForgotPasswordFormGroup>
          <LinkButton onClick={onForgotPassword} type='button' {...generateTestId(`forgotPassword`)}>
            {t('Forgot your password?')}
          </LinkButton>
        </ForgotPasswordFormGroup>
        <FormGroup>
          {signInStatus === ApiRequestStatus.Failed && signInErrorCode && (
            <ErrorText variant={Variant.Error} textAlign='center' size={Size.Medium}>
              {getSignInErrorMessage(signInErrorCode, t)}
            </ErrorText>
          )}
          {signInStatus === ApiRequestStatus.Success && !isMatchedBrand && (
            <ErrorText variant={Variant.Error} textAlign='center' size={Size.Medium}>
              {getSignInErrorMessage(signInErrorCodeIfNotMatchBrand, t)}
            </ErrorText>
          )}
          <LoginButton
            label={t('Login')}
            type='submit'
            id='loginButton'
            onClick={onSubmit}
            {...generateTestId(`loginBtn`)}
            validators={[emailValidator, passwordValidator]}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </FormGroup>
        <FormGroup>
          <CheckBox
            label={t('Remember me')}
            id='logonRemember'
            name='logonRemember'
            checked={isAccountCached}
            onChange={() => setIsAccountCached(!isAccountCached)}
          />
        </FormGroup>
      </LoginCardContent>
    </CardWrapper>
  );
};
