import { ISelectOption, useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { ISgpKnowledgeExperience } from '../../../models/sgp/profile';
import { NONE_OF_THE_ABOVE } from '../../../constants/sgp/knowledgeExperience';

export const useKnowledgeExperienceValidators = (
  investmentExperienceOptions: ISelectOption[],
  educationOptions: ISelectOption[],
  workExperienceOptions: ISelectOption[],
  knowledgeExperienceDetails?: ISgpKnowledgeExperience
) => {
  const { t } = useTranslation();

  const investmentExperienceValidator = useValidator(
    investmentExperienceOptions.find((x) => x.value === knowledgeExperienceDetails?.investmentExperience),
    Validators.required(t('Investment Experience is required'), `investmentExperience`)
  );

  const educationValidator = useValidator(
    educationOptions.find((x) => x.value === knowledgeExperienceDetails?.education),
    Validators.custom(
      (value) => !(investmentExperienceValidator.value && investmentExperienceValidator.value?.value === NONE_OF_THE_ABOVE) || !!value,
      t('Education is required'),
      `Education`
    )
  );

  const workExperienceValidator = useValidator(
    workExperienceOptions.find((x) => x.value === knowledgeExperienceDetails?.workExperience),
    Validators.custom(
      (value) => !(educationValidator.value && educationValidator.value?.value === NONE_OF_THE_ABOVE) || !!value,
      t('Work Experience is required'),
      `workExperience`
    )
  );

  return {
    investmentExperienceValidator,
    educationValidator,
    workExperienceValidator
  };
};
