import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardLarge, FormGroup, FormRow, Heading1, IconNames, Size, Text, useTestId } from '@cmctechnology/phoenix-stockbroking-web-design';
import { ModalCardContent, ModalTopIcon } from './IdentificationFailModal';
import { InlineButton } from './InlineButton';

export interface IIdentificationTimeoutProps {
  onExitApp: () => void;
}

export const IdentificationTimeout: React.FC<IIdentificationTimeoutProps> = ({ onExitApp, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `identificationFail`);

  return (
    <CardLarge {...generateTestId()}>
      <ModalCardContent>
        <FormRow>
          <ModalTopIcon name={IconNames.HelpCircle} size={Size.Large} />
        </FormRow>
        <Heading1>{t('Sorry')}</Heading1>

        <Text size={Size.Large}>{t('Something went wrong verifying your ID.')}</Text>
        <FormGroup>
          <Text>
            {t(
              `We'll need you to come back later whilst we continue trying to verify your identity. We'll send you an email with details on how to return and continue this application.`
            )}
          </Text>
        </FormGroup>
        <FormGroup>
          <InlineButton label={t('Exit application')} onClick={onExitApp} {...generateTestId(`exitApp`)} />
        </FormGroup>
      </ModalCardContent>
    </CardLarge>
  );
};
