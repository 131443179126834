import { UsersErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { Translated } from '../translation';
import { BaseErrorCode, baseErrorMessages } from '../baseErrorCodes';
import { baseUserErrorCodeMappings } from './baseUserErrorCodeMappings';

enum ForgotPasswordErrorCode {
  InvalidEmail = `InvalidEmail`,
  InvalidCode = `InvalidCode`,
  LimitExceeded = 'LimitExceeded'
}

// Map backend error code [UsersErrorCode] to client error code[ForgotPasswordErrorCode]
const forgotPasswordErrorCodeMappings: Record<UsersErrorCode, BaseErrorCode | ForgotPasswordErrorCode> = {
  ...baseUserErrorCodeMappings,
  [UsersErrorCode.UserNotFoundException]: ForgotPasswordErrorCode.InvalidEmail,
  [UsersErrorCode.ExpiredCodeException]: ForgotPasswordErrorCode.InvalidCode,
  [UsersErrorCode.CodeMismatchException]: ForgotPasswordErrorCode.InvalidCode,
  [UsersErrorCode.LimitExceededException]: ForgotPasswordErrorCode.LimitExceeded
};

const forgotPasswordErrorMessages: Record<BaseErrorCode | ForgotPasswordErrorCode, Translated> = {
  ...baseErrorMessages,
  [ForgotPasswordErrorCode.InvalidEmail]: (t) => t(`Invalid email`),
  [ForgotPasswordErrorCode.InvalidCode]: (t) => t(`The code is invalid, or expired`),
  [ForgotPasswordErrorCode.LimitExceeded]: (t) => t(`You have exceeded the maximum number of retry attempts. Please try again in 15 minutes.`)
};

export const getForgotPasswordErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = errorCode as UsersErrorCode;

  return forgotPasswordErrorMessages[forgotPasswordErrorCodeMappings[errorCodeEnum]](t);
};
