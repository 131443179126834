import { DayMonthYear, OnboardingModel } from '@cmctechnology/phoenix-stockbroking-api-client';
import { EMPTY_STRING } from '../../constants/commonConstants';
import { isValidCountyOfResidence } from '../../models/sgp/country';
import { IdentityDocumentType } from '../../models/sgp/identityDocumentType';
import { ISgpLegalTerms, ISgpProfile, ISgpAddressDetails, ISgpFinancialBackground, ISgpPersonalDetails, ISgpTaxInformation } from '../../models/sgp/profile';
import { isForeignIdentificationNumber, isNationalRegistrationCardNumber } from '../../models/sgp/profileDetails';
import { hasValidValue } from '../../utils/stringUtils';

const hasDateValue = (date: DayMonthYear | undefined) => date && hasValidValue(date.day) && hasValidValue(date.month) && hasValidValue(date.year);

export const sgpIdentityVerificationConverter = {
  convertToUserIdentification: (onboardingModel: OnboardingModel) => {
    const foreignIdentificationNumber = isForeignIdentificationNumber(onboardingModel.user?.fin) ? IdentityDocumentType.ForeignIdentificationNumber : undefined;

    const personalDetails: ISgpPersonalDetails = {
      title: onboardingModel.user?.title,
      nric: onboardingModel.user?.nric,
      identityDocumentType: isNationalRegistrationCardNumber(onboardingModel.user?.nric)
        ? IdentityDocumentType.NationalRegistrationIdentityCard
        : foreignIdentificationNumber,

      fullName: onboardingModel.user?.fullName,
      firstName: onboardingModel.user?.firstName,
      middleName: onboardingModel.user?.middleName ?? EMPTY_STRING,
      lastName: onboardingModel.user?.lastName,
      clientEnteredFirstName: onboardingModel.user?.clientEnteredFirstName,
      clientEnteredMiddleName: onboardingModel.user?.clientEnteredMiddleName,
      clientEnteredLastName: onboardingModel.user?.clientEnteredLastName,
      useClientEnteredName: onboardingModel.user?.useClientEnteredName,
      aliasName: onboardingModel.user?.aliasName,
      hanyuPinyinName: onboardingModel.user?.hanyuPinyinName,
      hanyuPinyinAliasName: onboardingModel.user?.hanyuPinyinAliasName,
      dateOfBirth: onboardingModel.user?.dateOfBirth,
      countryOfResidence: onboardingModel.user?.countryOfResidence,
      nationality: onboardingModel.user?.nationality,
      fin: onboardingModel.user?.fin,
      passExpiryDate: onboardingModel.user?.passExpiryDate,
      isFirstNameReadonly: hasValidValue(onboardingModel.user?.firstName),
      isMiddleNameReadonly: hasValidValue(onboardingModel.user?.middleName),
      isLastNameReadonly: hasValidValue(onboardingModel.user?.lastName),
      isAliasNameReadonly: hasValidValue(onboardingModel.user?.aliasName),
      isHanyuPinyinNameReadonly: hasValidValue(onboardingModel.user?.hanyuPinyinName),
      isHanyuPinyinAliasNameReadonly: hasValidValue(onboardingModel.user?.hanyuPinyinAliasName),
      isDateOfBirthReadonly: hasDateValue(onboardingModel.user?.dateOfBirth),
      isCountryOfResidenceReadonly:
        hasValidValue(onboardingModel.user?.countryOfResidence) && isValidCountyOfResidence(onboardingModel.user?.countryOfResidence!),
      isNationalityReadonly: hasValidValue(onboardingModel.user?.nationality),
      isNricReadonly: hasValidValue(onboardingModel.user?.nric),
      isFinReadonly: hasValidValue(onboardingModel.user?.fin),
      isPassExpiryDateReadonly: hasDateValue(onboardingModel.user?.passExpiryDate),
      isIdentityDocumentTypeReadonly: hasValidValue(onboardingModel.user?.nric || onboardingModel.user?.fin),
      isHaveHanyuPinyinNameReadOnly: hasValidValue(onboardingModel.user?.hanyuPinyinName || onboardingModel.user?.hanyuPinyinAliasName),
      idType: onboardingModel.user?.idType,
      idNumber: onboardingModel.user?.idNumber,
      idCountry: onboardingModel.user?.idCountry,
      idExpiry: onboardingModel.user?.idExpiry
    };

    const addressDetails: ISgpAddressDetails = {
      line1: onboardingModel.registeredAddress?.line1,
      line2: onboardingModel.registeredAddress?.line2,
      line3: onboardingModel.registeredAddress?.line3,
      city: onboardingModel.registeredAddress?.city,
      postCode: onboardingModel.registeredAddress?.postCode,
      countryCode: onboardingModel.registeredAddress?.countryCode,
      // TODO: the following code will be not needed once move it to the back end to handle resume application case after done [SBAM-1956]
      isLine1Readonly: hasValidValue(onboardingModel.registeredAddress?.line1),
      isLine2Readonly: hasValidValue(onboardingModel.registeredAddress?.line2),
      isLine3Readonly: hasValidValue(onboardingModel.registeredAddress?.line3),
      isCityReadonly: hasValidValue(onboardingModel.registeredAddress?.city),
      isPostCodeReadonly: hasValidValue(onboardingModel.registeredAddress?.postCode),
      isCountryCodeReadonly:
        hasValidValue(onboardingModel.registeredAddress?.countryCode) && isValidCountyOfResidence(onboardingModel.registeredAddress?.countryCode!)
    };

    const taxInformation: ISgpTaxInformation = {
      ...onboardingModel.taxInformation
    };

    const financialBackground: ISgpFinancialBackground = {
      ...onboardingModel.financialBackground
    };

    const termsAndConditions: ISgpLegalTerms[] = onboardingModel.termsAndConditions?.map((c) => c as ISgpLegalTerms) ?? [];

    const profile: ISgpProfile = {
      personalDetails,
      addressDetails,
      taxInformation,
      financialBackground,
      termsAndConditions
    };

    return profile;
  }
};
