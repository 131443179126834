import React from 'react';
import { Route as ReactRouterRoute, RouteProps } from 'react-router-dom';
import { IPage } from '../models/page';
import { PageHead } from './PageHead';

export interface IRouteProps extends Omit<RouteProps, 'path'>, IPage {}

export const BaseRoute: React.FC<IRouteProps> = (props) => {
  const { path, children, ...rest } = props;

  return (
    <ReactRouterRoute
      {...rest}
      path={path}
      render={() => (
        <>
          <PageHead page={props} />
          {children}
        </>
      )}
    />
  );
};
