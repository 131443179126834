import { useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList, createOptionListFromNestedList } from '../../../common/mappings';
import { ISgpEmployment } from '../../../models/sgp/profile';
import { EMPLOYMENT_STATUS, INDUSTRY_NAME, OCCUPATION, SOURCE_OF_FUNDS } from '../../../constants/sgp/employment';
import { EMPTY_STRING } from '../../../constants/commonConstants';
import { isBusinessNameValid, isCompanyNameValid, isIndustryValid, isOccupationValid, isOtherDetailsValid, isSourceOfFunds } from './employmentConditions';

export const useEmploymentValidators = (employment?: ISgpEmployment) => {
  const { t } = useTranslation();

  const validatorConfig = {
    debounceMs: 0
  };

  const employmentStatusOptions = createKeyValueTranslatedToOptionList(EMPLOYMENT_STATUS, t);
  const employmentStatusValidator = useValidator(
    employmentStatusOptions.find((x) => x.value === employment?.employmentStatus),
    Validators.required(t('Employment Status is required'), `EmploymentStatus`),
    validatorConfig
  );

  const companyNameValidator = useValidator(
    employment?.companyName ?? EMPTY_STRING,
    Validators.custom((value) => isCompanyNameValid(value, employmentStatusValidator.value?.value), t('Company Name is required'), `CompanyName`),
    validatorConfig
  );

  const businessNameValidator = useValidator(
    employment?.businessName ?? EMPTY_STRING,
    Validators.custom((value) => isBusinessNameValid(value, employmentStatusValidator.value?.value), t('Business Name is required'), `BusinessName`),
    validatorConfig
  );

  const industryOptions = createKeyValueTranslatedToOptionList(INDUSTRY_NAME, t);
  const industryValidator = useValidator(
    industryOptions.find((x) => x.value === employment?.industry),
    Validators.custom((value) => isIndustryValid(value, employmentStatusValidator.value?.value), t('Industry is required'), `Industry`),
    validatorConfig
  );

  const occupationOptions = createOptionListFromNestedList(industryValidator.value?.value, OCCUPATION, t);
  const occupationValidator = useValidator(
    occupationOptions.find((x) => x.value === employment?.occupation),
    Validators.custom(
      (value) => isOccupationValid(occupationOptions, value, employmentStatusValidator.value?.value),
      t('Occupation is required'),
      `Occupation`
    ),
    validatorConfig
  );

  const sourceOfFundsOptions = createOptionListFromNestedList(employmentStatusValidator.value?.value, SOURCE_OF_FUNDS, t);
  const sourceOfFundsValidator = useValidator(
    sourceOfFundsOptions.find((x) => x.value === employment?.sourceOfFunds),
    Validators.custom((value) => isSourceOfFunds(value, employmentStatusValidator.value?.value), t('Source of Funds is required'), `SourceOfFunds`),
    validatorConfig
  );

  const occupationSpecifyValidator = useValidator(
    employment?.occupationSpecify ?? EMPTY_STRING,
    Validators.custom(
      (value) => isOtherDetailsValid(value, occupationValidator.value?.value),
      t('Please enter information to continue. Minimum 4 characters'),
      `OccupationSpecify`
    ),
    validatorConfig
  );

  const sourceOfFundsSpecifyValidator = useValidator(
    employment?.sourceOfFundsSpecify ?? EMPTY_STRING,
    Validators.custom(
      (value) => isOtherDetailsValid(value, sourceOfFundsValidator.value?.value),
      t('Please enter information to continue. Minimum 4 characters'),
      `SourceOfFundsSpecify`
    ),
    validatorConfig
  );

  return {
    employmentStatusValidator,
    companyNameValidator,
    businessNameValidator,
    industryValidator,
    occupationValidator,
    occupationSpecifyValidator,
    sourceOfFundsSpecifyValidator,
    sourceOfFundsValidator
  };
};
