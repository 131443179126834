import { AddressApi } from '@cmctechnology/phoenix-stockbroking-api-client';
import { createConfig } from '../configuration/configuration';
import { createApiConfig, createAxios, Interceptor } from './axiosFactory';

export const createAddressApi = (interceptor?: Interceptor) => {
  const { apiUrl } = createConfig();
  const addressApi = new AddressApi(createApiConfig(), apiUrl, createAxios(interceptor));

  return addressApi;
};
