import React, { useEffect } from 'react';
import {
  Form,
  CardLarge,
  CardSeparator,
  Text,
  Size,
  CardFooter,
  FormGroup,
  Button,
  useTestId,
  useValidator,
  Validators,
  Variant,
  ButtonLink
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CardContentWithoutBottomPadding, CardOptionRadioButton } from '../../Components/CardContentWithoutBottomPadding';
import { IdVerifyProvider, IdVerifyProviderDescriptions, IdVerifyProviders, SINGPASS_HOME_PAGE_URL } from '../../constants/sgp/myinfo';
import { IStore } from '../../Store/Store';
import { ReactComponent as SingpassLogoSvg } from '../../Assets/sgp/singpass_logo_fullcolours.svg';
import { PanelWithSvgAndWrappedText } from '../../Components/PanelWithSvgAndWrappedText';
import { OPEN_EXTERNAL_URL_TARGET } from '../../constants/urlConstants';
import { openExternalUrl, openSingpassOrJumioAuthorisation, selectIdVerifyProvider } from '../../Store/Actions';
import { ILoadJumio } from '../../models/sgp/ILoadJumio';
import { MyInfoPageHeader } from '../../Components/Sgp/MyInfoPageHeader';

export const SingpassLogo = styled(SingpassLogoSvg)`
  min-width: 8.625rem;
  width: 8.625rem; // need both min-width and width to make it responsive for diff devices
  height: 1.5rem;
`;

export interface ISingpassConnectorProps extends ILoadJumio {}
export const SingpassConnector: React.FC<ISingpassConnectorProps> = ({ onLoadJumio, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `sgp_idv_provider`);
  const dispatch = useDispatch();
  const idVerifyProvider = useSelector((store: IStore) => store.local.sgp.idVerifyProvider);
  const idVerifyProviderValidator = useValidator(idVerifyProvider, Validators.required(t('Please make a selection to continue')));
  const jumioAuthoriseUrl = useSelector((store: IStore) => store.local.sgp.jumioAuthoriseUrl);
  const onNextClicked = () => {
    dispatch(selectIdVerifyProvider(idVerifyProviderValidator.value!));
    dispatch(openSingpassOrJumioAuthorisation({ providerType: idVerifyProviderValidator.value! }));
  };

  useEffect(() => {
    if (jumioAuthoriseUrl && idVerifyProviderValidator.value === IdVerifyProvider.Jumio) {
      onLoadJumio();
    }
  }, [jumioAuthoriseUrl]);

  return (
    <Form>
      <CardLarge>
        <MyInfoPageHeader />
        <CardSeparator />
        <CardContentWithoutBottomPadding>
          <CardOptionRadioButton
            onClick={() => idVerifyProviderValidator.handleEvent(IdVerifyProvider.MyInfo)}
            renderLabel={() => (
              <Text size={Size.Large}>
                <Trans t={t}>
                  <b>Apply using Myinfo</b> (recommended)
                </Trans>
              </Text>
            )}
            body={IdVerifyProviderDescriptions[IdVerifyProvider.MyInfo]!(t)}
            selected={idVerifyProviderValidator.value === IdVerifyProvider.MyInfo}
            {...generateTestId(`myInfo`)}
          />
          {idVerifyProviderValidator.value === IdVerifyProvider.MyInfo && (
            <PanelWithSvgAndWrappedText>
              <SingpassLogo />
              <Text size={Size.Medium}>
                {t(
                  'Authenticated through Singpass, Myinfo is a digital personal data platform that pulls your personal data across participating government agencies which helps fill in digital forms automatically. '
                )}
                <ButtonLink onClick={() => openExternalUrl(SINGPASS_HOME_PAGE_URL, OPEN_EXTERNAL_URL_TARGET)}>{t('Find out more.')}</ButtonLink>
              </Text>
            </PanelWithSvgAndWrappedText>
          )}
          <CardOptionRadioButton
            onClick={() => idVerifyProviderValidator.handleEvent(IdVerifyProvider.Jumio)}
            renderLabel={() => (
              <Text size={Size.Large} textAlign='left'>
                <Trans t={t}>
                  <b>{IdVerifyProviders[IdVerifyProvider.Jumio]!(t)}</b>
                </Trans>
              </Text>
            )}
            body={IdVerifyProviderDescriptions[IdVerifyProvider.Jumio]!(t)}
            selected={idVerifyProviderValidator.value === IdVerifyProvider.Jumio}
            {...generateTestId(`jumio`)}
          />
        </CardContentWithoutBottomPadding>
        <CardFooter>
          {idVerifyProviderValidator.invalid && (
            <Text size={Size.Large} variant={Variant.Error}>
              {t('Please make a selection to continue')}
            </Text>
          )}
          <FormGroup>
            <Button centered label={t('Next')} onClick={onNextClicked} validators={[idVerifyProviderValidator]} {...generateTestId(`next`)} />
          </FormGroup>
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
