import { IGroupedOption, ISelectOption, SelectOptionType } from '@cmctechnology/phoenix-stockbroking-web-design';
import { TFunction } from 'react-i18next';
import { createConfig } from '../configuration/configuration';
import { KeyValueTranslated, Translated } from '../models/translation';

export const mapKeyValueTranslatedToOption: (keyValue: KeyValueTranslated, key: string, t: TFunction) => ISelectOption = (keyValue, key, t) => ({
  value: key,
  label: keyValue[key](t)
});

const createOptionListWithTopOptions: (categoryLabelForNonTopOptions: string, topOptionCodes: string[], list: ISelectOption[]) => SelectOptionType[] = (
  categoryLabelForNonTopOptions,
  topOptionCodes,
  list
) => {
  const options: SelectOptionType[] = list.filter((x) => topOptionCodes.includes(x.value)).sort((a, b) => a.label.localeCompare(b.label));

  const groupedOption: IGroupedOption = {
    label: categoryLabelForNonTopOptions,
    options: list.filter((x) => !topOptionCodes.includes(x.value)).sort((a, b) => a.label.localeCompare(b.label))
  };

  options.push(groupedOption);

  return options;
};

export const createKeyValueTranslatedToOptionList = (
  list: KeyValueTranslated,
  t: TFunction,
  sortFn: (a: ISelectOption, b: ISelectOption) => number = (a, b) => a.label.localeCompare(b.label)
) =>
  Object.keys(list)
    .map((x) => mapKeyValueTranslatedToOption(list, x, t))
    .sort(sortFn);

export const createOptionListFromNestedList = (
  key: string | undefined,
  nestedList: Record<string, KeyValueTranslated>,
  t: TFunction,
  sortFn: (a: ISelectOption, b: ISelectOption) => number = (a, b) => a.label.localeCompare(b.label)
) => {
  if (key) {
    const list = nestedList[key];
    if (list) {
      return createKeyValueTranslatedToOptionList(list, t, sortFn);
    }
  }

  return [];
};

export const createCountriesOptionList: (list: ISelectOption[], t: TFunction) => SelectOptionType[] = (list, t) => {
  const { topCountryCodes } = createConfig();
  return createOptionListWithTopOptions(t('Other countries'), topCountryCodes, list);
};

export const createStreetTypesOptionList: (list: ISelectOption[], t: TFunction) => SelectOptionType[] = (list, t) => {
  const { topStreetTypeCodes } = createConfig();
  return createOptionListWithTopOptions(t('Other street types'), topStreetTypeCodes, list);
};

export const createTranslatedArray: (list: Translated[], t: TFunction) => string[] = (list, t) => list.map((i) => i(t));
