import { KeyValueTranslated } from '../../models/translation';

export const NONE_OF_THE_ABOVE = 'None of the above';

export const INVESTMENT_EXPERIENCE: KeyValueTranslated = {
  'Exchange Traded Funds': (t) => t('Exchange Traded Funds'),
  'Exchange Traded Notes': (t) => t('Exchange Traded Notes'),
  'Structured/Covered Warrants': (t) => t('Structured/Covered Warrants'),
  Futures: (t) => t('Futures'),
  'Stock Options': (t) => t('Stock Options'),
  [NONE_OF_THE_ABOVE]: (t) => t('None of the above')
};

export const EDUCATION_SUBJECT: KeyValueTranslated = {
  Accountancy: (t) => t('Accountancy'),
  'Actuarial science': (t) => t('Actuarial science'),
  Business: (t) => t('Business'),
  'Business administration': (t) => t('Business administration'),
  'Business management': (t) => t('Business management'),
  'Business studies': (t) => t('Business studies'),
  'Capital markets': (t) => t('Capital markets'),
  Commerce: (t) => t('Commerce'),
  'Computational finance': (t) => t('Computational finance'),
  Economics: (t) => t('Economics'),
  Finance: (t) => t('Finance'),
  'Finance related professional qualification': (t) => t('Finance related professional qualification'),
  'Financial planning': (t) => t('Financial planning'),
  'Financial engineering': (t) => t('Financial engineering'),
  Insurance: (t) => t('Insurance'),
  [NONE_OF_THE_ABOVE]: (t) => t('None of the above')
};

export const WORK_EXPERIENCE: KeyValueTranslated = {
  Accountancy: (t) => t('Accountancy'),
  'Actuarial science': (t) => t('Actuarial science'),
  'Financial risk management': (t) => t('Financial risk management'),
  'Investment products': (t) => t('Investment products'),
  Treasury: (t) => t('Treasury'),
  'Legal expertise relating to any of the above': (t) => t('Legal expertise relating to any of the above'),
  [NONE_OF_THE_ABOVE]: (t) => t('None of the above')
};
