import {
  CardHeader,
  CardLarge,
  CardSeparator,
  Heading1,
  useTestId,
  FormGroup,
  Button,
  CardFooter,
  useValidator,
  Validators,
  Text,
  Size,
  Variant
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardContentWithoutBottomPadding, CardOptionRadioButton } from '../Components/CardContentWithoutBottomPadding';
import { AccountTypeInput, ACCOUNT_TYPES, ACCOUNT_TYPE_DESCRIPTIONS } from '../models/application';
import { INextPage } from '../models/IPageNavigation';
import { selectAccountType } from '../Store/Actions';
import { IStore } from '../Store/Store';

export interface ISelectAccountTypeProps extends INextPage {}

export const SelectAccountType: React.FC<ISelectAccountTypeProps> = ({ onNext, ...rest }) => {
  const { generateTestId } = useTestId(rest, `account`);
  const accountType = useSelector((store: IStore) => store.local.accountType);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const accountTypeValidator = useValidator(accountType, Validators.required(t('Please choose one account type')));

  const onNextClicked = () => {
    dispatch(selectAccountType(accountTypeValidator.value!));
    onNext();
  };

  return (
    <CardLarge>
      <CardHeader>
        <Heading1>{t('Choose account type')}</Heading1>
      </CardHeader>
      <CardSeparator />
      <CardContentWithoutBottomPadding>
        <CardOptionRadioButton
          onClick={() => accountTypeValidator.handleEvent(AccountTypeInput.Individual)}
          label={ACCOUNT_TYPES[AccountTypeInput.Individual](t)}
          body={ACCOUNT_TYPE_DESCRIPTIONS[AccountTypeInput.Individual](t)}
          selected={accountTypeValidator.value === AccountTypeInput.Individual}
          {...generateTestId(`individual`)}
        />
        <CardOptionRadioButton
          onClick={() => accountTypeValidator.handleEvent(AccountTypeInput.Joint)}
          label={ACCOUNT_TYPES[AccountTypeInput.Joint](t)}
          body={ACCOUNT_TYPE_DESCRIPTIONS[AccountTypeInput.Joint](t)}
          selected={accountTypeValidator.value === AccountTypeInput.Joint}
          {...generateTestId(`joint`)}
        />
        <CardOptionRadioButton
          onClick={() => accountTypeValidator.handleEvent(AccountTypeInput.Minor)}
          label={ACCOUNT_TYPES[AccountTypeInput.Minor](t)}
          body={ACCOUNT_TYPE_DESCRIPTIONS[AccountTypeInput.Minor](t)}
          selected={accountTypeValidator.value === AccountTypeInput.Minor}
          {...generateTestId(`minor`)}
        />
        <CardOptionRadioButton
          onClick={() => accountTypeValidator.handleEvent(AccountTypeInput.SMSF)}
          label={ACCOUNT_TYPES[AccountTypeInput.SMSF](t)}
          body={ACCOUNT_TYPE_DESCRIPTIONS[AccountTypeInput.SMSF](t)}
          selected={accountTypeValidator.value === AccountTypeInput.SMSF}
          {...generateTestId(`smsf`)}
        />
        <CardOptionRadioButton
          onClick={() => accountTypeValidator.handleEvent(AccountTypeInput.Company)}
          label={ACCOUNT_TYPES[AccountTypeInput.Company](t)}
          body={ACCOUNT_TYPE_DESCRIPTIONS[AccountTypeInput.Company](t)}
          selected={accountTypeValidator.value === AccountTypeInput.Company}
          {...generateTestId(`company`)}
        />
        <CardOptionRadioButton
          onClick={() => accountTypeValidator.handleEvent(AccountTypeInput.Trust)}
          label={ACCOUNT_TYPES[AccountTypeInput.Trust](t)}
          body={ACCOUNT_TYPE_DESCRIPTIONS[AccountTypeInput.Trust](t)}
          selected={accountTypeValidator.value === AccountTypeInput.Trust}
          {...generateTestId(`trust`)}
        />
      </CardContentWithoutBottomPadding>
      <CardFooter>
        {accountTypeValidator.invalid && (
          <Text size={Size.Large} variant={Variant.Error}>
            {t('Please choose one account type')}
          </Text>
        )}
        <FormGroup>
          <Button centered label={t('Next')} onClick={onNextClicked} validators={[accountTypeValidator]} {...generateTestId(`next`)} />
        </FormGroup>
      </CardFooter>
    </CardLarge>
  );
};
