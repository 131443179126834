import { decode, Jwt, JwtPayload } from 'jsonwebtoken';
import { CRC_NAME } from '../constants/tokenCustomAttributes';

export const ERROR_CANNOT_DECODE_TOKEN: string = 'Cannot decode Token';
export const ERROR_CRC_NOT_FOUND: string = 'Cannot decode Token';

export const getCrc = (idToken: string): string => {
  const decodedToken: null | Jwt = decode(idToken, { complete: true }); // TODO this is wrong. validate
  if (!decodedToken) {
    throw new Error(ERROR_CANNOT_DECODE_TOKEN);
  }
  const crc = ((decodedToken as Jwt).payload as JwtPayload)[CRC_NAME] as string;
  if (!crc) {
    throw new Error(ERROR_CRC_NOT_FOUND);
  }
  return crc;
};
