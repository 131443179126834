import React from 'react';
import { FormCol, FormRow, Label, Text, FormGroup, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import { MedicareCardColour } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useTranslation } from 'react-i18next';
import { IMedicareDetails, MEDICARE_CARD_COLOURS } from '../models/identification';
import { ReviewEditButton } from '../Components/ReviewEditButton';
import { buildFullName } from '../models/medicare';
import { IPersonalDetails } from '../models/profile';

const showMedicareExpiryDate = (medicareCard: IMedicareDetails) => {
  return medicareCard.cardColour === MedicareCardColour.Green
    ? `${medicareCard.expiryDate?.month}/${medicareCard.expiryDate?.year}`
    : `${medicareCard.expiryDate?.day}/${medicareCard.expiryDate?.month}/${medicareCard.expiryDate?.year}`;
};

export interface IReviewIdentificationPassportProps {
  medicare: IMedicareDetails;
  personalDetails: IPersonalDetails;
  onEdit: () => void;
}

export const ReviewIdentificationMedicare: React.FC<IReviewIdentificationPassportProps> = ({ medicare, personalDetails, onEdit }) => {
  const { t } = useTranslation();
  const nameOnCard = buildFullName(personalDetails.firstName, personalDetails.lastName, medicare.middleName);
  return (
    <FormGroup>
      <FormRow>
        <FormCol percentWidth={80}>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Type of ID')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{t('Medicare')}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Card colour')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{medicare.cardColour && MEDICARE_CARD_COLOURS[medicare.cardColour]?.(t)}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Card number')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{medicare.cardNumber}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Reference number')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{medicare.referenceNumber}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Name on card')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{nameOnCard}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Valid to')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{showMedicareExpiryDate(medicare)}</Text>
            </FormCol>
          </FormRow>
        </FormCol>
        <FormCol percentWidth={20}>
          <FormRow>
            <ReviewEditButton label={t('Edit')} onClick={onEdit} />
          </FormRow>
        </FormCol>
      </FormRow>
    </FormGroup>
  );
};
