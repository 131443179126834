import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import { App } from './App';

const DEFAULT_LANGUAGE = 'en';

declare global {
  interface Window {
    OptanonActiveGroups: string | undefined;
    OptanonWrapper: () => void;
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(backend)
  .init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    nsSeparator: '::',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

const ROOT_SELECTOR = '#root';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector(ROOT_SELECTOR)
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
