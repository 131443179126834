import { ISelectOption } from '@cmctechnology/phoenix-stockbroking-web-design';
import { EMPLOYED, OTHER, RETIRED, SELF_EMPLOYED, STUDENT, UNEMPLOYED } from '../../../constants/sgp/employment';
import { MIN_4_CHARACTERS } from '../../../constants/commonConstants';

export const isEmployed = (employmentStatus?: string) => {
  return employmentStatus === EMPLOYED;
};

export const isSelfEmployed = (employmentStatus?: string) => {
  return employmentStatus === SELF_EMPLOYED;
};

export const isEmployedOrSelfEmployed = (employmentStatus?: string) => {
  return employmentStatus === EMPLOYED || employmentStatus === SELF_EMPLOYED;
};

export const isStudentOrRetiredOrUnemployed = (employmentStatus?: string) => {
  return employmentStatus === STUDENT || employmentStatus === RETIRED || employmentStatus === UNEMPLOYED;
};

export const isMoreDetailsNeeded = (details?: string) => {
  return details === OTHER;
};

export const isCompanyNameValid = (companyName?: string, employmentStatus?: string) => {
  return !isEmployed(employmentStatus) || !!companyName;
};

export const isBusinessNameValid = (businessName?: string, employmentStatus?: string) => {
  return !isSelfEmployed(employmentStatus) || !!businessName;
};

export const isIndustryValid = (industry?: ISelectOption, employmentStatus?: string) => {
  return !isEmployedOrSelfEmployed(employmentStatus) || !!industry;
};

export const isOccupationValid = (occupationOptions: ISelectOption[], occupation?: ISelectOption, employmentStatus?: string) => {
  return !(isEmployedOrSelfEmployed(employmentStatus) && occupationOptions.length > 0) || !!occupation;
};

export const isOtherDetailsValid = (otherDetails?: string, details?: string) => {
  return !isMoreDetailsNeeded(details) || (!!otherDetails && otherDetails.length >= MIN_4_CHARACTERS);
};

export const isSourceOfFunds = (sourceOfFunds?: ISelectOption, employmentStatus?: string) => {
  return !isStudentOrRetiredOrUnemployed(employmentStatus) || !!sourceOfFunds;
};
