import { ServiceProviderTypeEnum } from '@cmctechnology/phoenix-stockbroking-api-client';
import { KeyValueTranslated } from '../../models/translation';

export const SINGPASS_HOME_PAGE_URL = 'https://www.singpass.gov.sg/main/';

export const IdVerifyProviders: Partial<KeyValueTranslated<ServiceProviderTypeEnum>> = {
  [ServiceProviderTypeEnum.MyInfo]: (t) => t('Apply using Myinfo (recommended)'),
  [ServiceProviderTypeEnum.Jumio]: (t) => t('Apply without using Myinfo')
};

export const IdVerifyProviderDescriptions: Partial<KeyValueTranslated<ServiceProviderTypeEnum>> = {
  [ServiceProviderTypeEnum.MyInfo]: (t) =>
    t(
      `You won't need to fill in your personal information on our application form and you may not be required to provide any physical documentation as proof of identity.`
    ),
  // TODO: The sentence could be improved when do a full review with compliance team, suggest "For anyone who is not a Singapore citizen or permanent resident."
  [ServiceProviderTypeEnum.Jumio]: (t) => t('For anyone who is not a Singapore citizen or Singapore resident.')
};

export { ServiceProviderTypeEnum as IdVerifyProvider };
