/* eslint-disable i18next/no-literal-string */
import React from 'react';
import {
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Text,
  Variant,
  ValidationErrorMessage,
  Size,
  ISelectOption,
  Select
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList, createStreetTypesOptionList } from '../../common/mappings';
import {
  AddressType,
  AddressValidator,
  AUSTRALIAN_STATES,
  AUSTRALIAN_STREET_TYPES,
  CITY_SUBURB_MAX_LENGTH,
  STREET_NAME_MAX_LENGTH,
  STREET_NUMBER_MAX_LENGTH,
  UNIT_NUMBER_MAX_LENGTH
} from '../../constants/addressConstants';
import { IAddressFormProps } from './AddressFormProps';
import { AutoComplete } from '../../constants/autocompleteConstants';
import { PositiveNumericFormControl } from '../PositiveNumericFormControl';

export const ManualAddressForm: React.FC<IAddressFormProps> = ({ addressType, address, onAddressChanged, validators }) => {
  const isResidentialAddress = addressType === AddressType.Residential;

  const { t } = useTranslation();
  const unitNumberValidator = validators[AddressValidator.UnitNumberValidator];
  const streetNumberValidator = validators[AddressValidator.StreetNumberValidator];
  const streetNameValidator = validators[AddressValidator.StreetNameValidator];
  const streetTypeValidator = validators[AddressValidator.StreetTypeValidator];
  const cityValidator = validators[AddressValidator.CityValidator];
  const stateValidator = validators[AddressValidator.StateValidator];
  const postcodeValidator = validators[AddressValidator.PostcodeValidator];

  const streetTypesSelectOptions = createKeyValueTranslatedToOptionList(AUSTRALIAN_STREET_TYPES, t);

  const statesSelectOption = createKeyValueTranslatedToOptionList(AUSTRALIAN_STATES, t);

  return (
    <Form>
      <FormGroup>
        <FormLabel>{t('Unit number (optional)')}</FormLabel>
        <FormControl
          autoFocus
          type='text'
          name='unitNumber'
          placeholder={t('Enter your unit number')}
          value={unitNumberValidator.value}
          onChange={(e) => {
            unitNumberValidator.handleEvent(e.target.value, !unitNumberValidator.validated);
            onAddressChanged({ ...address, unitNumber: e.target.value.trim() });
          }}
          onBlur={(e) => {
            unitNumberValidator.handleEvent(e.target.value.trim());
            onAddressChanged({ ...address, unitNumber: e.target.value.trim() });
          }}
          invalid={unitNumberValidator.invalid}
          maxLength={UNIT_NUMBER_MAX_LENGTH}
        />

        {unitNumberValidator.validated &&
          unitNumberValidator.results
            .filter((x) => !x.valid)
            .map((x) => (
              <Text key={x.id} variant={Variant.Error} size={Size.Medium}>
                {x.message}
              </Text>
            ))}
      </FormGroup>
      <FormGroup>
        <FormLabel>{isResidentialAddress ? t('Street number') : t('Street number (optional)')}</FormLabel>
        <FormControl
          type='text'
          name='streetNumber'
          placeholder={t('Enter your street number')}
          value={streetNumberValidator.value}
          onChange={(e) => {
            streetNumberValidator.handleEvent(e.target.value, !streetNumberValidator.validated);
            onAddressChanged({ ...address, streetNumber: e.target.value.trim() });
          }}
          onBlur={(e) => {
            streetNumberValidator.handleEvent(e.target.value.trim());
            onAddressChanged({ ...address, streetNumber: e.target.value.trim() });
          }}
          invalid={streetNumberValidator.invalid}
          maxLength={STREET_NUMBER_MAX_LENGTH}
        />

        {streetNumberValidator.validated &&
          streetNumberValidator.results
            .filter((x) => !x.valid)
            .map((x) => (
              <Text key={x.id} variant={Variant.Error} size={Size.Medium}>
                {x.message}
              </Text>
            ))}
      </FormGroup>
      <FormGroup>
        <FormLabel>{isResidentialAddress ? t('Street name') : t('Street name/Post box')}</FormLabel>

        <FormControl
          type='text'
          name='streetName'
          placeholder={isResidentialAddress ? t('Enter your street name') : t('Enter your street name/Post box')}
          value={streetNameValidator.value}
          onChange={(e) => {
            streetNameValidator.handleEvent(e.target.value, !streetNameValidator.validated);
            onAddressChanged({ ...address, streetName: e.target.value.trim() });
          }}
          onBlur={(e) => {
            streetNameValidator.handleEvent(e.target.value.trim());
            onAddressChanged({ ...address, streetName: e.target.value.trim() });
          }}
          invalid={streetNameValidator.invalid}
          maxLength={STREET_NAME_MAX_LENGTH}
        />

        {streetNameValidator.validated &&
          streetNameValidator.results
            .filter((x) => !x.valid)
            .map((x) => (
              <Text key={x.id} variant={Variant.Error} size={Size.Medium}>
                {x.message}
              </Text>
            ))}
      </FormGroup>
      <FormGroup>
        <FormLabel>{isResidentialAddress ? t('Street type') : t('Street type (optional)')}</FormLabel>
        <Select
          value={streetTypeValidator.value}
          options={createStreetTypesOptionList(streetTypesSelectOptions, t)}
          onChange={(option) => {
            onAddressChanged({ ...address, streetType: (option as ISelectOption)?.value || '' });
            streetTypeValidator.handleEvent(option);
          }}
          placeholder={t('Select street type')}
          invalid={streetTypeValidator.invalid}
          isClearable
        />
        <ValidationErrorMessage size={Size.Medium} validator={streetTypeValidator} />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('Suburb/City')}</FormLabel>
        <FormControl
          type='text'
          name='city'
          autoComplete={AutoComplete.Suburb}
          placeholder={t('Enter your suburb/city')}
          value={cityValidator.value}
          onChange={(e) => {
            onAddressChanged({ ...address, city: e.target.value.trim() });
            cityValidator.handleEvent(e.target.value, !cityValidator.validated);
          }}
          onBlur={(e) => {
            onAddressChanged({ ...address, city: e.target.value.trim() });
            cityValidator.handleEvent(e.target.value.trim());
          }}
          invalid={cityValidator.invalid}
          maxLength={CITY_SUBURB_MAX_LENGTH}
        />
        <ValidationErrorMessage size={Size.Medium} validator={cityValidator} />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('State')}</FormLabel>
        <Select
          value={stateValidator.value}
          options={statesSelectOption}
          placeholder={t('Select state')}
          onChange={(option) => {
            onAddressChanged({ ...address, state: (option as ISelectOption)?.value || '' });
            stateValidator.handleEvent(option);
          }}
          invalid={stateValidator.invalid}
          isClearable
        />
        <ValidationErrorMessage size={Size.Medium} validator={stateValidator} />
      </FormGroup>
      <FormGroup>
        <FormLabel>{t('Postcode')}</FormLabel>
        <PositiveNumericFormControl
          name='postcode'
          autoComplete={AutoComplete.PostCode}
          placeholder={t('4 digit postcode')}
          value={postcodeValidator.value}
          onChange={(e) => {
            onAddressChanged({ ...address, postcode: e.target.value.trim() });
            postcodeValidator.handleEvent(e.target.value, !postcodeValidator.validated);
          }}
          onBlur={(e) => {
            onAddressChanged({ ...address, postcode: e.target.value.trim() });
            postcodeValidator.handleEvent(e.target.value.trim());
          }}
          invalid={postcodeValidator.invalid}
        />

        {postcodeValidator.validated &&
          postcodeValidator.results
            .filter((x) => !x.valid)
            .map((x) => (
              <Text key={x.id} variant={Variant.Error} size={Size.Medium}>
                {x.message}
              </Text>
            ))}
      </FormGroup>
    </Form>
  );
};
