import { useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../../../common/mappings';
import { ANNUAL_INCOME, PURPOSE_OF_ACCOUNT, VALUE_OF_SAVINGS } from '../../../constants/sgp/financialBackground';
import { ISgpFinancialBackground } from '../../../models/sgp/profile';
import { ISelectOptionOrUndefined } from '../../../models/ISelectOptionOrUndefined';

export const useFinancialBackgroundValidators = (financialBackground?: ISgpFinancialBackground) => {
  const { t } = useTranslation();

  const validatorConfig = {
    validated: financialBackground?.validated,
    debounceMs: 0
  };

  const annualIncomeOptions = createKeyValueTranslatedToOptionList(ANNUAL_INCOME, t);
  const valueOfSavingsOptions = createKeyValueTranslatedToOptionList(VALUE_OF_SAVINGS, t);
  const purposeOfAccountOptions = createKeyValueTranslatedToOptionList(PURPOSE_OF_ACCOUNT, t);

  const annualIncomeValidator = useValidator<ISelectOptionOrUndefined>(
    annualIncomeOptions.find((x) => x.value === financialBackground?.annualIncome),
    Validators.required(t('Annual income is required'), `AnnualIncome`),
    validatorConfig
  );

  const valueOfSavingsValidator = useValidator<ISelectOptionOrUndefined>(
    valueOfSavingsOptions.find((x) => x.value === financialBackground?.valueOfSavingsInvestments),
    Validators.required(t('Value of savings and investments is required'), `ValueOfSavings`),
    validatorConfig
  );

  const purposeOfAccountValidator = useValidator<ISelectOptionOrUndefined>(
    purposeOfAccountOptions.find((x) => x.value === financialBackground?.purposeOfAccount),
    Validators.required(t('Purpose of account is required'), `PurposeOfAccount`),
    validatorConfig
  );

  return {
    annualIncomeValidator,
    valueOfSavingsValidator,
    purposeOfAccountValidator
  };
};
