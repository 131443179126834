import { TFunction } from 'react-i18next';
import { Translated } from '../../models/translation';
import { SGP_CMC_COOKIE_POLICY, SGP_CMC_PRIVACY_STATEMENT, SGP_CMC_RISK_DISCLOSURE, SGP_CMC_TERMS_CONDITIONS } from './urlConstants';
import { TermsAndConditionsType } from '../../models/termsAndConditions';

export const SGP_RISK_DISCLOSURE = 'sg-risk-disclosure';

export const TERMS_AND_CONDITIONS: Array<Translated> = [
  (t) =>
    t(
      'You acknowledge that you have read, understood and agree to the CMC Invest General Terms and Conditions, CMC Invest Risk Disclosure Document, CMC Invest Privacy Policy and the CMC Invest Cookies Policy'
    ),
  (t) =>
    t(
      'You authorise CMC Invest to open a JPM Morgan Bank Account for the purpose of depositing your monies for settling transactions on your CMC Invest account pursuant to the CMC  Invest Terms and Conditions'
    ),
  (t) =>
    t(
      'You represent and warrant that the information you have provided to CMC Invest about yourself is accurate and not misleading in any material respect. In addition, you will notify CMC Invest immediately if any of that information materially changes or ceases to be true or correct.'
    ),
  (t) => t('You understand that you can opt out of education and marketing email communications at any time after your application has been submitted.')
];

export const TERMS_AND_CONDITIONS_LINKS: TermsAndConditionsType[] = [
  {
    key: `sg-general-terms-conditions`,
    label: (t: TFunction) => t('CMC Invest General Terms and Conditions'),
    url: SGP_CMC_TERMS_CONDITIONS
  },
  {
    key: SGP_RISK_DISCLOSURE,
    label: (t: TFunction) => t('CMC Invest Risk Disclosure Document'),
    url: SGP_CMC_RISK_DISCLOSURE,
    schedule: [
      (t) => t('Schedule 1 - Risk Disclosure Document in accordance with the Securities and Futures (Licensing and Conduct of Business) Regulations'),
      (t) =>
        t(
          'Schedule 2 - Risk Warning Statement For Overseas-Listed Investment Products provided as prescribed under paragraph 29D of the MAS Notice on the Sale of Investment Products [SFA04-N12]'
        )
    ]
  },
  {
    key: `sg-privacy-policy`,
    label: (t: TFunction) => t('CMC Invest Privacy Policy'),
    url: SGP_CMC_PRIVACY_STATEMENT
  },
  {
    key: `sg-cookies-policy`,
    label: (t: TFunction) => t('CMC Invest Cookies Policy'),
    url: SGP_CMC_COOKIE_POLICY
  }
];
