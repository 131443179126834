import { DayMonthYear1, MedicareCardColour } from '@cmctechnology/phoenix-stockbroking-api-client';
import dayjs from 'dayjs';
import { MedicareExpiryDateValidation } from '../models/validation';
import { DATE_FORMAT, DATE_REGEX, DATE_SPAN_ONE, DATE_SPAN_TWO, DATE_SPAN_ZERO, getLastDayOfMonth, MONTH_UNIT, YEAR_UNIT } from '../utils/dateUtils';

export const dateValidator = {
  checkYearsAbove: (input: DayMonthYear1, numberOfYears: number): boolean => {
    const dateStr = `${input.year}-${input.month}-${input.day}`;

    if (!DATE_REGEX.test(dateStr) || numberOfYears <= DATE_SPAN_ZERO) return false;

    const date = dayjs(dateStr, DATE_FORMAT);

    const yearsAgo = dayjs().subtract(numberOfYears, YEAR_UNIT);

    return date.isBefore(yearsAgo);
  },

  checkMedicareExpiryDate: (input: DayMonthYear1, color?: MedicareCardColour): MedicareExpiryDateValidation => {
    const dateStr = color === MedicareCardColour.Green ? `${input.year}-${input.month}-${DATE_SPAN_ONE}` : `${input.year}-${input.month}-${input.day}`;

    if (!DATE_REGEX.test(dateStr)) return MedicareExpiryDateValidation.Invalid;

    const lastDateOfThisMonthStr = `${input.year}-${input.month}-${getLastDayOfMonth(input.year, input.month)}`;
    const validTo = dayjs(color === MedicareCardColour.Green ? lastDateOfThisMonthStr : dateStr, DATE_FORMAT);

    const now = dayjs();
    const twoMonthsLater = now.add(DATE_SPAN_TWO, MONTH_UNIT);

    if (validTo.isBefore(now)) {
      // expired
      return MedicareExpiryDateValidation.Expired;
    }
    if (validTo.isBefore(twoMonthsLater)) {
      // expire within 2 months
      return MedicareExpiryDateValidation.Within2Months;
    }
    return MedicareExpiryDateValidation.Valid;
  }
};
