import { BusinessRegion } from '../configuration/configuration';
import { IPage, NavigationStep, Page } from './page';

export const BUSINESS_REGION_PATH_PATTERN = `/:businessRegion`;

export const pageConstants = {
  defaultTitle: (t: any) => t('CMC Markets Invest')
};

export const pages: Record<Page, IPage> = {
  [Page.IdentificationSelect]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/identification-select`,
    navigationStep: () => NavigationStep.Identification,
    public: true
  },
  [Page.VerifyEmailConfirmation]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/verify-email-confirmation`,
    navigationStep: () => NavigationStep.Profile,
    public: true
  },
  [Page.VerifyYourEmail]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/verify-your-email`,
    navigationStep: () => NavigationStep.Profile,
    public: true
  },
  [Page.SelectAccountType]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/select-account-type`,
    navigationStep: () => NavigationStep.Profile,
    public: true
  },
  [Page.FundYourAccount]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/fund-your-account`,
    navigationStep: () => NavigationStep.Profile,
    public: true
  },
  [Page.Home]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/`,
    navigationStep: () => NavigationStep.Profile,
    public: true
  },
  [Page.CreateLogIn]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/create-log-in`,
    navigationStep: () => NavigationStep.Profile,
    public: true
  },
  [Page.LogIn]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/login`,
    public: true
  },
  [Page.ProfileEmployment]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/profile-employment`,
    navigationStep: (businessRegion: BusinessRegion) => (businessRegion === BusinessRegion.SGP ? NavigationStep.AboutYou : NavigationStep.Profile)
  },
  [Page.ProfileTaxDetails]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/profile-tax-details`,
    navigationStep: (businessRegion: BusinessRegion) => (businessRegion === BusinessRegion.SGP ? NavigationStep.AboutYou : NavigationStep.Profile)
  },
  [Page.ProfileYourAddress]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/profile-your-address`,
    navigationStep: (businessRegion: BusinessRegion) => (businessRegion === BusinessRegion.SGP ? NavigationStep.AboutYou : NavigationStep.Profile)
  },
  [Page.ProfilePersonalDetails]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/profile-personal-details`,
    navigationStep: (businessRegion: BusinessRegion) => (businessRegion === BusinessRegion.SGP ? NavigationStep.AboutYou : NavigationStep.Profile)
  },
  [Page.AccountReadyInformation]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/account-ready-information`
  },
  [Page.TermsConfirmation]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/terms-confirmation`,
    navigationStep: () => NavigationStep.ReviewAndSubmit
  },
  [Page.ReviewAndSubmit]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/review-and-submit`,
    navigationStep: () => NavigationStep.ReviewAndSubmit
  },
  [Page.IdentificationDriversLicence]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/identification-drivers-licence`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.IdentificationMedicare]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/identification-medicare`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.IdentificationPassport]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/identification-passport`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.IdentificationPEP]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/identification-questions`
  },
  [Page.ForgotPassword]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/forgot-password`,
    public: true
  },
  [Page.FundIntercept]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/fund-intercept`
  },
  [Page.SingpassConnector]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/singpass-connector`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.SingpassCallback]: {
    // Myinfo sandbox api require the callback url to be localhost:3001/callback and cannot customize the path.
    path: `${BUSINESS_REGION_PATH_PATTERN}/callback`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.JumioConnector]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/jumio-connector`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.JumioProcessing]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/jumio-processing`,
    navigationStep: () => NavigationStep.Identification
  },
  [Page.FinancialBackground]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/profile-financial-background`,
    navigationStep: () => NavigationStep.AboutYou
  },
  [Page.KnowledgeExperience]: {
    path: `${BUSINESS_REGION_PATH_PATTERN}/profile-knowledge-experience`,
    navigationStep: () => NavigationStep.AboutYou
  }
};

export const ROUTE_HISTORY_ACTION_POP = 'POP';
export const ROUTE_HISTORY_ACTION_REPLACE = 'REPLACE';
