import { Route, Switch } from 'react-router-dom';
import { BusinessRegionPath } from '../constants/businessRegionPathConstants';
import { AusRoutes } from './AusRoutes';
import { SgpRoutes } from './SgpRoutes';

export const BusinessRegionRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${BusinessRegionPath.AUS}`}>
        <AusRoutes />
      </Route>
      <Route path={`${BusinessRegionPath.SGP}`}>
        <SgpRoutes />
      </Route>
      <Route path={`${BusinessRegionPath.MyInfoCallbackForLocalOnly}`}>
        <SgpRoutes />
      </Route>
      <Route path={`${BusinessRegionPath.Other}`}>
        <AusRoutes />
      </Route>
    </Switch>
  );
};
