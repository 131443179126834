import {
  CardHeader,
  CardLarge,
  CardSeparator,
  CardContent,
  Heading1,
  Size,
  Text,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Select,
  ValidationErrorMessage,
  ISelectOption,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { GoBackIcon } from '../../Components/GoBackIcon';
import { ProfileCardFooter } from '../../Components/ProfileCardFooter';
import { EMPTY_STRING } from '../../constants/commonConstants';
import { useAddressValidators } from '../../hooks/sgp/validators/useAddressValidators';
import { INextPage, IPreviousPage } from '../../models/IPageNavigation';
import { COUNTRIES_RESIDENCE } from '../../models/sgp/country';
import { ISgpAddressDetails } from '../../models/sgp/profile';
import { saveAddressDetails } from '../../Store/Actions';
import { IStore } from '../../Store/Store';

interface IProfileYourAddressProps extends INextPage, Partial<IPreviousPage> {}

export const ProfileYourAddress: React.FC<IProfileYourAddressProps> = ({ onNext, onPrevious, ...rest }) => {
  const { generateTestId } = useTestId(rest, `sg.addressDetails`);

  const addressDetails = useSelector((store: IStore) => store.remote.sgp.profile.addressDetails);
  const countryOfResidence = useSelector((store: IStore) => store.persist!.sgp.countryOfResidence);

  const validatorConfig = {
    validated: addressDetails.validated
  };

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const isManualEntry = () => !!countryOfResidence && countryOfResidence !== addressDetails.countryCode;

  const { firstLineValidator, townCityValidator, postcodeValidator, countryValidator } = useAddressValidators(
    !isManualEntry() ? addressDetails : undefined,
    validatorConfig.validated
  );

  const [secondLine, setSecondLine] = useState(isManualEntry() || !addressDetails.line2 ? EMPTY_STRING : addressDetails.line2);
  const [thirdLine, setThirdLine] = useState(isManualEntry() || !addressDetails.line3 ? EMPTY_STRING : addressDetails.line3);

  const onNextClicked = () => {
    const updatedAddressDetails: ISgpAddressDetails = {
      line1: firstLineValidator.value,
      line2: secondLine,
      line3: thirdLine,
      city: townCityValidator.value,
      postCode: postcodeValidator.value,
      countryCode: countryValidator.value?.value,
      isManualEntry: isManualEntry()
    };

    dispatch(saveAddressDetails(updatedAddressDetails));

    onNext();
  };

  const countriesSelectOption = createKeyValueTranslatedToOptionList(COUNTRIES_RESIDENCE, t);

  return (
    <CardLarge>
      <CardHeader>
        {onPrevious && <GoBackIcon onClick={onPrevious} />}
        <Heading1>{t('Address')}</Heading1>
        <Text size={Size.Large}>{t('Please provide your residential address details')}</Text>
      </CardHeader>
      <CardSeparator />
      <CardContent>
        <Form>
          <FormGroup>
            <FormLabel>{t('First line of address')}</FormLabel>
            <FormControl
              type='text'
              name='firstLine'
              placeholder={t('Enter the first line of your address')}
              disabled={addressDetails.isLine1Readonly && !isManualEntry()}
              value={firstLineValidator.value}
              onChange={(e) => {
                firstLineValidator.handleEvent(e.target.value, !firstLineValidator.validated);
              }}
              onBlur={(e) => {
                firstLineValidator.handleEvent(e.target.value.trim());
              }}
              invalid={firstLineValidator.invalid}
              {...generateTestId(`firstLine.input`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={firstLineValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Address line 2 (optional)')}</FormLabel>
            <FormControl
              disabled={addressDetails.isLine2Readonly && !isManualEntry()}
              type='text'
              name='secondLine'
              placeholder={t('Enter line 2 of your address')}
              value={secondLine}
              onChange={(e) => {
                setSecondLine(e.target.value);
              }}
              {...generateTestId(`secondLine.input`)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Address line 3 (optional)')}</FormLabel>
            <FormControl
              disabled={addressDetails.isLine3Readonly && !isManualEntry()}
              type='text'
              name='thirdLine'
              placeholder={t('Enter line 3 of your address')}
              value={thirdLine}
              onChange={(e) => {
                setThirdLine(e.target.value);
              }}
              {...generateTestId(`thirdLine.input`)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Town/City')}</FormLabel>
            <FormControl
              disabled={addressDetails.isCityReadonly && !isManualEntry()}
              type='text'
              name='townCity'
              placeholder={t('Enter your town/city')}
              value={townCityValidator.value}
              onChange={(e) => {
                townCityValidator.handleEvent(e.target.value, !townCityValidator.validated);
              }}
              onBlur={(e) => {
                townCityValidator.handleEvent(e.target.value.trim());
              }}
              invalid={townCityValidator.invalid}
              {...generateTestId(`town.input`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={townCityValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Postcode')}</FormLabel>
            <FormControl
              disabled={addressDetails.isPostCodeReadonly && !isManualEntry()}
              type='text'
              name='postcode'
              placeholder={t('Enter your postcode')}
              value={postcodeValidator.value}
              onChange={(e) => {
                postcodeValidator.handleEvent(e.target.value, !postcodeValidator.validated);
              }}
              onBlur={(e) => {
                postcodeValidator.handleEvent(e.target.value.trim());
              }}
              invalid={postcodeValidator.invalid}
              {...generateTestId(`postcode.input`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={postcodeValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Country')}</FormLabel>
            <Select
              options={countriesSelectOption}
              placeholder={t('Select country')}
              isClearable
              value={countryValidator.value}
              onChange={(option) => {
                countryValidator.handleEvent(option as ISelectOption);
              }}
              isDisabled={addressDetails.isCountryCodeReadonly && !isManualEntry()}
              invalid={countryValidator.invalid}
              {...generateTestId(`country.select`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={countryValidator} />
          </FormGroup>
        </Form>
      </CardContent>

      <ProfileCardFooter
        nextButtonLabel={t('Next')}
        saveButtonLabel={t('Save')}
        onNext={onNextClicked}
        onCancel={onNext}
        validators={[firstLineValidator, townCityValidator, postcodeValidator, countryValidator]}
        {...generateTestId()}
      />
    </CardLarge>
  );
};
