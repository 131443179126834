import { KeyValueTranslated } from '../../models/translation';

export const ANNUAL_INCOME: KeyValueTranslated = {
  '0': (t) => t('0'),
  '1-19,999': (t) => t('1-19,999'),
  '20,000 - 39,999': (t) => t('20,000 - 39,999'),
  '40,000 - 99,999': (t) => t('40,000 - 99,999'),
  '100,000 - 199,999': (t) => t('100,000 - 199,999'),
  '200,000 - 299,999': (t) => t('200,000 - 299,999'),
  '300,000 - 999,999': (t) => t('300,000 - 999,999'),
  '1,000,000 +': (t) => t('1,000,000 +')
};

export const VALUE_OF_SAVINGS: KeyValueTranslated = {
  '0': (t) => t('0'),
  '1-19,999': (t) => t('1-19,999'),
  '20,000 - 39,999': (t) => t('20,000 - 39,999'),
  '40,000 - 99,999': (t) => t('40,000 - 99,999'),
  '100,000 - 199,999': (t) => t('100,000 - 199,999'),
  '200,000 - 299,999': (t) => t('200,000 - 299,999'),
  '300,000 - 999,999': (t) => t('300,000 - 999,999'),
  '1,000,000 - 1,999,999': (t) => t('1,000,000 - 1,999,999'),
  '2,000,000 +': (t) => t('2,000,000 +')
};

export const PURPOSE_OF_ACCOUNT: KeyValueTranslated = {
  Dividend: (t) => t('Dividend'),
  'Trading profits': (t) => t('Trading profits'),
  'Long-term growth': (t) => t('Long-term growth'),
  'Diversify portfolio': (t) => t('Diversify portfolio')
};
