import {
  AddressInput,
  AustralianTaxInfo,
  EmploymentDetails,
  OverseasTaxInfo2,
  PersonalAddressDetails,
  PersonalDetails,
  TaxResidentType
} from '@cmctechnology/phoenix-stockbroking-api-client';
import { KeyValueTranslated, Translated } from './translation';

export interface IPersonalDetails extends PersonalDetails {
  validated?: boolean;
}

export interface IAddressInput extends AddressInput {
  unformattedAddressKey?: string;
}

export interface IPersonalAddressDetails extends Omit<PersonalAddressDetails, 'residentialAddress' | 'postalAddress'> {
  validated?: boolean;
  residentialAddress: IAddressInput;
  postalAddress: IAddressInput;
}

export interface IProfile {
  personalDetails: IPersonalDetails;
  addressDetails: IPersonalAddressDetails;
  taxDetails: ITaxDetails;
  employmentDetails: EmploymentDetails;
}

export interface ITaxDetails {
  validated?: boolean;
  taxResidentType?: TaxResidentType;
  australianTaxInfo: AustralianTaxInfo;
  overseasTaxInfo: OverseasTaxInfo2;
}

interface ITaxResidentValue {
  value: Translated;
  isAustralianTaxResident: boolean;
  isOverseasTaxResident: boolean;
}

export enum OccupationOthersType {
  Homemaker = 'Homemaker/Unemployed',
  Unemployed = 'Unemployed'
}

export enum Occupations {
  Accountant = 'Accountant',
  Business_Owner = 'Business Owner',
  Chief_Executives_General_Managers_Legislators = 'Chief Executives, General Manager & Legislators',
  Clerical_Administrative_Workers = 'Clerical and Administrative workers',
  Community_Personal_Service_Workers = 'Community and personal service workers',
  Computer_Programmer_Technician = 'Computer Programmer/Technician',
  Doctor_Specialist_Surgeon = 'Doctor, Specialist or Surgeon',
  Employees = 'Employees',
  Engineer = 'Engineer',
  Hospitality_Worker = 'Hospitality worker',
  Labourers = 'Labourers',
  Machinery_Operators_Drivers = 'Machineary operators and drivers',
  Manager_Middle_Level = 'Manager (Middle Level)',
  Manager_Senior_Level = 'Manager (Senior Level)',
  Military = 'Military',
  Professionals = 'Professionals',
  Retired = 'Retired',
  Sales_Workers = 'Sales workers',
  Service_Worker_Other = 'Service Worker (Other)',
  Student = 'Student',
  Teacher = 'Teacher',
  Technical_Other = 'Technical (Other)',
  Technicians_Trades_Workers = 'Technicians and trade workers',
  Trades_Person_Other = 'Trades Person (Other)',
  TruckDriver = 'Truck Driver'
}

export const OCCUPATIONS: KeyValueTranslated = {
  [Occupations.Accountant]: (t) => t('Accountant'),
  [Occupations.Business_Owner]: (t) => t('Business Owner'),
  [Occupations.Chief_Executives_General_Managers_Legislators]: (t) => t('Chief Executives, General Managers and Legislators'),
  [Occupations.Clerical_Administrative_Workers]: (t) => t('Clerical and administrative workers'),
  [Occupations.Community_Personal_Service_Workers]: (t) => t('Community and personal service workers'),
  [Occupations.Computer_Programmer_Technician]: (t) => t('Computer Programmer/Technician'),
  [Occupations.Doctor_Specialist_Surgeon]: (t) => t('Doctor, Specialist or Surgeon'),
  [Occupations.Employees]: (t) => t('Employees'),
  [Occupations.Engineer]: (t) => t('Engineer'),
  [OccupationOthersType.Homemaker]: (t) => t('Homemaker'),
  [Occupations.Hospitality_Worker]: (t) => t('Hospitality worker'),
  [Occupations.Labourers]: (t) => t('Labourers'),
  [Occupations.Machinery_Operators_Drivers]: (t) => t('Machinery operators and drivers'),
  [Occupations.Manager_Middle_Level]: (t) => t('Manager (Middle Level)'),
  [Occupations.Manager_Senior_Level]: (t) => t('Manager (Senior Level)'),
  [Occupations.Military]: (t) => t('Military'),
  [Occupations.Professionals]: (t) => t('Professionals'),
  [Occupations.Retired]: (t) => t('Retired'),
  [Occupations.Sales_Workers]: (t) => t('Sales workers'),
  [Occupations.Service_Worker_Other]: (t) => t('Service Worker (Other)'),
  [Occupations.Student]: (t) => t('Student'),
  [Occupations.Teacher]: (t) => t('Teacher'),
  [Occupations.Technical_Other]: (t) => t('Technical (Other)'),
  [Occupations.Technicians_Trades_Workers]: (t) => t('Technicians and trades workers'),
  [Occupations.Trades_Person_Other]: (t) => t('Trades Person (Other)'),
  [Occupations.TruckDriver]: (t) => t('Truck Driver'),
  [OccupationOthersType.Unemployed]: (t) => t('Unemployed')
};

export const TAX_RESIDENT_TYPES: Record<TaxResidentType, ITaxResidentValue> = {
  [TaxResidentType.Australia]: { value: (t) => t('Australia'), isAustralianTaxResident: true, isOverseasTaxResident: false },
  [TaxResidentType.Overseas]: { value: (t) => t('Overseas'), isAustralianTaxResident: false, isOverseasTaxResident: true },
  [TaxResidentType.AUOverseas]: { value: (t) => t('AU + Overseas'), isAustralianTaxResident: true, isOverseasTaxResident: true }
};

export const AUS_TAX_EXEMPTION_REASONS: KeyValueTranslated = {
  Pensioner: (t) => t('Pensioner'),
  'Other eligible Centrelink benefit': (t) => t('Other eligible Centrelink benefit'),
  'Not required to lodge tax return': (t) => t('Not required to lodge tax return'),
  'Provider of consumer or business finance': (t) => t('Provider of consumer or business finance'),
  'Norfolk Island resident': (t) => t('Norfolk Island resident'),
  'Non-resident': (t) => t('Non-resident')
};

export const UNABLE_TO_OBTAIN_TIN_REASON = 'I am unable to obtain a TIN';

export const OVERSEAS_TAX_EXEMPTION_REASONS: KeyValueTranslated = {
  'I have applied for a TIN and will inform you upon receipt': (t) => t('I have applied for a TIN and will inform you upon receipt'),
  [UNABLE_TO_OBTAIN_TIN_REASON]: (t) => t('I am unable to obtain a TIN'),
  "The country doesn't issue TINs": (t) => t(`The country doesn't issue TINs`),
  "The country doesn't require collection of TINs": (t) => t(`The country doesn't require collection of TINs`)
};

export const defaultTaxDetails: ITaxDetails = {
  australianTaxInfo: { tfn: '', haveTaxExemption: false, taxExemptionReason: '' },
  overseasTaxInfo: { country: '', tin: '', haveTaxExemption: false, taxExemptionReason: '' }
};
