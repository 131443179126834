import { breakpoint, Button, ButtonLink, CardFooter, Notification, Size, Text, useTestId, Variant } from '@cmctechnology/phoenix-stockbroking-web-design';
import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SAVE_PAUSE_TIME } from '../constants/commonConstants';
import { INextPage } from '../models/IPageNavigation';
import { IStore } from '../Store/Store';
import { NotificationCardFooter } from './NotificationCardFooter';
import { InlineButton } from './InlineButton';

interface IProfileCardFooterProps extends INextPage {
  nextButtonLabel: string;
  saveButtonLabel: string;
  onCancel: () => void;
  validators?: IValidator<any>[];
}

const ActionCardFooter = styled(CardFooter)`
  padding-top: 0;
`;

const CentralisedButtonLink = styled(ButtonLink)`
  @media (max-width: ${breakpoint.mobile}) {
    margin-top: 1.5rem;
  }
`;

export const ProfileCardFooter: React.FC<IProfileCardFooterProps> = ({ nextButtonLabel, saveButtonLabel, onNext, onCancel, validators, ...rest }) => {
  const reviewSection = useSelector((store: IStore) => store.local.reviewSection);
  const [saved, setSaved] = useState(false);

  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `profile`);

  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        onNext();
      }, SAVE_PAUSE_TIME);
    }
  }, [saved]);

  if (saved) {
    return (
      <NotificationCardFooter>
        <Notification variant={Variant.Success}>
          <Text size={Size.Large}>{t('Your details have been saved')}</Text>
        </Notification>
      </NotificationCardFooter>
    );
  }

  if (!reviewSection) {
    return (
      <ActionCardFooter>
        <Button label={nextButtonLabel} onClick={onNext} validators={validators} {...generateTestId(`next`)} />
      </ActionCardFooter>
    );
  }

  return (
    <ActionCardFooter>
      <InlineButton label={saveButtonLabel} onClick={() => setSaved(true)} validators={validators} />
      <CentralisedButtonLink onClick={onCancel}>{t('Cancel')}</CentralisedButtonLink>
    </ActionCardFooter>
  );
};
