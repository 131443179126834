import { useRef, useState } from 'react';
import {
  ISelectOption,
  Text,
  Variant,
  ValidationErrorMessage,
  Size,
  useTestId,
  Form,
  FormGroup,
  SelectAsync
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { IAddressFormProps } from './AddressFormProps';
import { getAddressErrorMessage } from '../../models/addressErrorCodes/addressErrorCodes';
import { addressFormat, addressSearch } from '../../Store/Actions';
import { AddressValidator, ADDRESS_SEARCH_DEBOUNCE_MS } from '../../constants/addressConstants';

const combineAddressLines = (...addressLines: string[]) =>
  addressLines
    .map((x) => (x || '').trim())
    .filter((x) => !!x)
    .join(' ');

const FormGroupWithOptionalTopMargin = styled(FormGroup)<{ removeTopMargin?: boolean }>`
  padding-bottom: 1rem;
  ${({ removeTopMargin }) =>
    removeTopMargin &&
    css`
      margin-top: 0;
    `}
`;

export const UnformattedAddressForm: React.FC<IAddressFormProps> = ({
  validated,
  addressType,
  address,
  onAddressChanged,
  removeTopMargin,
  validators,
  autoFocus,
  ...rest
}) => {
  const { t } = useTranslation();
  const residentialAddressControlRef = useRef<HTMLDivElement | null>(null);
  const { generateTestId } = useTestId(rest, `unformattedAddress`);

  const lookUpAddressValidator = validators[AddressValidator.LookUpAddressValidator];

  const [errorMessage, setErrorMessage] = useState<string>();

  const dispatch = useDispatch();

  const formatAddress = async (globalAddressKey: string) => {
    const format = await addressFormat(globalAddressKey, dispatch, (errorCode) => setErrorMessage(getAddressErrorMessage(errorCode, t)));

    if (!format) {
      onAddressChanged({ ...address, unformattedAddressKey: undefined, unformattedAddress: '' });
      return;
    }

    const addressLines = combineAddressLines(format.address_line_1, format.address_line_2, format.address_line_3);

    const addressZone = combineAddressLines(format.locality, format.region, format.postal_code);

    const fullAddress = `${addressLines}, ${addressZone}`;

    onAddressChanged({
      ...address,
      isUnformatted: true,
      unformattedAddressKey: globalAddressKey,
      unformattedAddress: fullAddress
    });
  };

  const onChange = (option?: ISelectOption) => {
    lookUpAddressValidator.handleEvent(option);

    if (!option?.value) {
      onAddressChanged({ ...address, unformattedAddressKey: undefined, unformattedAddress: '' });
      return;
    }

    formatAddress(option.value);
  };

  const loadAddressOptions = async (addressLookup: string) => {
    const items = await addressSearch(addressLookup, dispatch, (errorCode) => setErrorMessage(getAddressErrorMessage(errorCode, t)));

    const options = items.map((x) => ({ value: x.global_address_key, label: x.text } as ISelectOption));

    return options;
  };

  return (
    <Form>
      <FormGroupWithOptionalTopMargin removeTopMargin={removeTopMargin}>
        <SelectAsync
          placeholder={t('Look up {{addressType}} address', { addressType })}
          value={lookUpAddressValidator.value}
          loadOptions={loadAddressOptions}
          debounceInMs={ADDRESS_SEARCH_DEBOUNCE_MS}
          onChange={onChange}
          isClearable
          isSearchable
          ref={(e) => {
            residentialAddressControlRef.current = e?.controlRef as HTMLDivElement;
            if (autoFocus) {
              e?.focus();
            }
          }}
          {...generateTestId()}
        />
        <ValidationErrorMessage size={Size.Medium} validator={lookUpAddressValidator} />
        {errorMessage && (
          <Text variant={Variant.Error} size={Size.Medium}>
            {errorMessage}
          </Text>
        )}
      </FormGroupWithOptionalTopMargin>
    </Form>
  );
};
