import { KeyValueTranslated } from '../../models/translation';

export const EMPLOYED = 'Employed';
export const SELF_EMPLOYED = 'Self-employed';
export const STUDENT = 'Student';
export const RETIRED = 'Retired';
export const UNEMPLOYED = 'Unemployed';

export const INDUSTRY_ACCOUNTANTS_AND_TRUSTEES = 'Accountants and trustees';
export const INDUSTRY_AGRICULTURAL = 'Agricultural';
export const INDUSTRY_CATERING_HOSPITALITY = 'Catering or hospitality';
export const INDUSTRY_CHARITY = 'Charity';
export const INDUSTRY_COMPANY_SERVICES = 'Company Services';
export const INDUSTRY_DEFENCE_MILITARY = 'Defence, military, arms / weapons trade';
export const INDUSTRY_EDUCATION = 'Education';
export const INDUSTRY_EMERGENCY_SERVICES = 'Emergency Services';
export const INDUSTRY_ENERGY = 'Energy';
export const INDUSTRY_ENGINEERING = 'Engineering';
export const INDUSTRY_EXPORT_IMPORT = 'Export or import';
export const INDUSTRY_FINANCIAL_SERVICES_BANK_INSURANCE = 'Financial Services – Banks/Insurance';
export const INDUSTRY_FINANCIAL_SERVICES_INVESTMENT_PRODUCTS = 'Financial Services - Investment Products';
export const INDUSTRY_GAMBLING_CASINO = 'Gambling/Casino';
export const INDUSTRY_GOVERNMENT_OR_PUBLIC_SECTOR = 'Government or public sector';
export const INDUSTRY_LEGAL = 'Legal';
export const INDUSTRY_LEISURE_TOURISM = 'Leisure, entertainment, or tourism';
export const INDUSTRY_MANUFACTURING = 'Manufacturing';
export const INDUSTRY_MEDIA_MARKETING_ADVERTISING = 'Media / Marketing / Advertising';
export const INDUSTRY_MONEY_REMITTANCE_MONEYLENDING = 'Money Remittance / Moneylending  / Pawnbrokers / New Payment Providers';
export const INDUSTRY_NGO_NPO = 'Non-Governmental Organisation (NGO) / Non-Profit Organisation (NPO)';
export const INDUSTRY_OTHER = 'Other';
export const INDUSTRY_PRECIOUS_METALS_STONES = 'Precious Metals or stones';
export const INDUSTRY_PROPERTY_CONSTRUCTION_TRADE = 'Property, construction, or trade';
export const INDUSTRY_RETAIL = 'Retail';
export const INDUSTRY_RETAIL_AUCTION = 'Retail - Auction';
export const INDUSTRY_RETAIL_HIGH_VALUE_GOODS = 'Retail - High-value goods';
export const INDUSTRY_RETAIL_WHOLESALE = 'Retail - Wholesale';
export const INDUSTRY_SOCIAL_CARE_SERVICES_HEALTH_MEDICINE = 'Social care, services, health, or medicine';
export const INDUSTRY_SPORTS = 'Sports';
export const INDUSTRY_TECHNOLOGY = 'Technology';
export const INDUSTRY_TELECOMMUNICATIONS = 'Telecommunications';
export const INDUSTRY_TRANSPORT_LOGISTICS = 'Transport / Logistics';

const INDUSTRY_DEFAULT_OCCUPATION_LIST: KeyValueTranslated = {
  'Company owner': (t) => t('Company owner'),
  'Director or board member': (t) => t('Director or board member'),
  Other: (t) => t('Other'),
  Partner: (t) => t('Partner')
};

const INDUSTRY_FINANCIAL_SERVICES_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  'Customer Services': (t) => t('Customer Services'),
  Executive: (t) => t('Executive'),
  Management: (t) => t('Management'),
  Operations: (t) => t('Operations'),
  Other: (t) => t('Other'),
  Partners: (t) => t('Partners'),
  'Sales Trader': (t) => t('Sales Trader'),
  Technology: (t) => t('Technology')
};

const INDUSTRY_RETAIL_OCCUPATION_LIST: KeyValueTranslated = {
  Administration: (t) => t('Administration'),
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  'Customer Service': (t) => t('Customer Service'),
  Management: (t) => t('Management'),
  Operations: (t) => t('Operations'),
  Other: (t) => t('Other')
};

const INDUSTRY_AGRICULTURAL_OCCUPATION_LIST: KeyValueTranslated = {
  'Sole accountant': (t) => t('Sole accountant'),
  Agronomist: (t) => t('Agronomist'),
  'Animal nutritionist': (t) => t('Animal nutritionist'),
  Ecologist: (t) => t('Ecologist'),
  Engineer: (t) => t('Engineer'),
  Horticulturist: (t) => t('Horticulturist'),
  Other: (t) => t('Other'),
  Scientist: (t) => t('Scientist'),
  Technician: (t) => t('Technician'),
  Worker: (t) => t('Worker')
};

const INDUSTRY_CATERING_HOSPITALITY_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Management: (t) => t('Management'),
  Other: (t) => t('Other'),
  Service: (t) => t('Service')
};

const INDUSTRY_COMPANY_SERVICES_OCCUPATION_LIST: KeyValueTranslated = {
  Administration: (t) => t('Administration'),
  'Company Owner': (t) => t('Company Owner'),
  'Customer Service': (t) => t('Customer Service'),
  Director: (t) => t('Director'),
  Executive: (t) => t('Executive'),
  Management: (t) => t('Management'),
  Other: (t) => t('Other')
};

const INDUSTRY_DEFENCE_MILITARY_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner': (t) => t('Company Owner'),
  'Director or board member': (t) => t('Director or board member'),
  Other: (t) => t('Other'),
  Partner: (t) => t('Partner'),
  'Ranks below Officer level': (t) => t('Ranks below Officer level'),
  'Ranks equivalent to Officer and above': (t) => t('Ranks equivalent to Officer and above')
};

const INDUSTRY_EDUCATION_OCCUPATION_LIST: KeyValueTranslated = {
  Administrator: (t) => t('Administrator'),
  'General staff member': (t) => t('General staff member'),
  Other: (t) => t('Other'),
  'Teacher / Principal': (t) => t('Teacher / Principal')
};

const INDUSTRY_EMERGENCY_SERVICES_OCCUPATION_LIST: KeyValueTranslated = {
  Firefighter: (t) => t('Firefighter'),
  Medic: (t) => t('Medic'),
  Other: (t) => t('Other'),
  Paramedic: (t) => t('Paramedic'),
  'Security Officer': (t) => t('Security Officer'),
  Technician: (t) => t('Technician')
};

const INDUSTRY_ENERGY_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Electrician: (t) => t('Electrician'),
  Engineer: (t) => t('Engineer'),
  Management: (t) => t('Management'),
  Operator: (t) => t('Operator'),
  Other: (t) => t('Other'),
  Technician: (t) => t('Technician')
};

const INDUSTRY_EXPORT_IMPORT_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  'Customer Service': (t) => t('Customer Service'),
  Other: (t) => t('Other'),
  Technician: (t) => t('Technician')
};

const INDUSTRY_GAMBLING_CASINO_OCCUPATION_LIST: KeyValueTranslated = {
  'Customer Services': (t) => t('Customer Services'),
  Other: (t) => t('Other'),
  'Owner/Management': (t) => t('Owner/Management')
};

const INDUSTRY_GOVERNMENT_OR_PUBLIC_SECTOR_OCCUPATION_LIST: KeyValueTranslated = {
  'Ambassador or consul': (t) => t('Ambassador or consul'),
  'Board member/Director of Enterprise State-Owned': (t) => t('Board member/Director of Enterprise State-Owned'),
  'Chief Executive in State-Owned Enterprise': (t) => t('Chief Executive in State-Owned Enterprise'),
  'Director in State-Owned Enterprise': (t) => t('Director in State-Owned Enterprise'),
  'Director/Deputy Director/Board Members/Equivalent function of an international organisation': (t) =>
    t('Director/Deputy Director/Board Members/Equivalent function of an international organisation'),
  'Member of city council': (t) => t('Member of city council'),
  'Member of parliament': (t) => t('Member of parliament'),
  'Member of the Supreme court ': (t) => t('Member of the Supreme court '),
  Other: (t) => t('Other'),
  'Senior Civil Servant with Procurement /Contracts Management Responsibilities': (t) =>
    t('Senior Civil Servant with Procurement /Contracts Management Responsibilities'),
  'Senior Member of Armed Forces ': (t) => t('Senior Member of Armed Forces '),
  'Voivode/Governor /Director/Manager of the main/central/voivodeship/local special administration body': (t) =>
    t('Voivode/Governor /Director/Manager of the main/central/voivodeship/local special administration body')
};

const INDUSTRY_LEGAL_OCCUPATION_LIST: KeyValueTranslated = {
  Administration: (t) => t('Administration'),
  Associate: (t) => t('Associate'),
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Lawyer: (t) => t('Lawyer'),
  Other: (t) => t('Other'),
  Paralegal: (t) => t('Paralegal'),
  Partner: (t) => t('Partner')
};

const INDUSTRY_LEISURE_TOURISM_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  'Customer Service': (t) => t('Customer Service'),
  Management: (t) => t('Management'),
  Other: (t) => t('Other')
};

const INDUSTRY_MEDIA_MARKETING_ADVERTISING_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Digital: (t) => t('Digital'),
  Events: (t) => t('Events'),
  Management: (t) => t('Management'),
  Media: (t) => t('Media'),
  'News Reporter': (t) => t('News Reporter'),
  Other: (t) => t('Other'),
  Production: (t) => t('Production'),
  Publicist: (t) => t('Publicist'),
  'Social Media': (t) => t('Social Media'),
  Technician: (t) => t('Technician')
};

const INDUSTRY_NGO_NPO_OCCUPATION_LIST: KeyValueTranslated = {
  'Director or board member': (t) => t('Director or board member'),
  Other: (t) => t('Other')
};

const INDUSTRY_OTHER_OCCUPATION_LIST: KeyValueTranslated = {
  Other: (t) => t('Other')
};

const INDUSTRY_PRECIOUS_METALS_STONES_OCCUPATION_LIST: KeyValueTranslated = {
  'Dealer / Trader': (t) => t('Dealer / Trader'),
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Goldsmith: (t) => t('Goldsmith'),
  Other: (t) => t('Other'),
  'General staff member': (t) => t('General staff member')
};

const INDUSTRY_PROPERTY_CONSTRUCTION_TRADE_OCCUPATION_LIST: KeyValueTranslated = {
  Builder: (t) => t('Builder'),
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Electrician: (t) => t('Electrician'),
  Management: (t) => t('Management'),
  Other: (t) => t('Other'),
  Plumber: (t) => t('Plumber'),
  'Real Estate Agent': (t) => t('Real Estate Agent')
};

const INDUSTRY_SOCIAL_CARE_SERVICES_HEALTH_MEDICINE_OCCUPATION_LIST: KeyValueTranslated = {
  Administration: (t) => t('Administration'),
  'Customer Services': (t) => t('Customer Services'),
  'General staff member': (t) => t('General staff member'),
  Other: (t) => t('Other'),
  Pharmacist: (t) => t('Pharmacist'),
  'Social Worker': (t) => t('Social Worker'),
  'Specialist/General Practitioner': (t) => t('Specialist/General Practitioner')
};

const INDUSTRY_SPORTS_OCCUPATION_LIST: KeyValueTranslated = {
  'Agent or representative': (t) => t('Agent or representative'),
  Other: (t) => t('Other')
};

const INDUSTRY_TECHNOLOGY_OCCUPATION_LIST: KeyValueTranslated = {
  'Company Owner/Director': (t) => t('Company Owner/Director'),
  Engineer: (t) => t('Engineer'),
  Management: (t) => t('Management'),
  Other: (t) => t('Other'),
  Programmer: (t) => t('Programmer'),
  Security: (t) => t('Security'),
  Support: (t) => t('Support')
};

const INDUSTRY_TELECOMMUNICATIONS_OCCUPATION_LIST: KeyValueTranslated = {
  'Customer Services': (t) => t('Customer Services'),
  'Director/Executive': (t) => t('Director/Executive'),
  Engineers: (t) => t('Engineers'),
  Management: (t) => t('Management'),
  Other: (t) => t('Other'),
  Technician: (t) => t('Technician')
};

const INDUSTRY_ACCOUNTANTS_AND_TRUSTEES_OCCUPATION_LIST: KeyValueTranslated = {
  ...INDUSTRY_DEFAULT_OCCUPATION_LIST,
  'Sole accountant': (t) => t('Sole accountant')
};

export const SOURCE_OF_FUNDS_PART_TIME_WORK = 'Part-time work';

export const OTHER = 'Other';

export const EMPLOYMENT_STATUS: KeyValueTranslated = {
  [EMPLOYED]: (t) => t('Employed'),
  [SELF_EMPLOYED]: (t) => t('Self-employed'),
  [STUDENT]: (t) => t('Student'),
  [RETIRED]: (t) => t('Retired'),
  [UNEMPLOYED]: (t) => t('Unemployed')
};

export const INDUSTRY_NAME: KeyValueTranslated = {
  [INDUSTRY_ACCOUNTANTS_AND_TRUSTEES]: (t) => t('Accountants and trustees'),
  [INDUSTRY_AGRICULTURAL]: (t) => t('Agricultural'),
  [INDUSTRY_CATERING_HOSPITALITY]: (t) => t('Catering or hospitality'),
  [INDUSTRY_CHARITY]: (t) => t('Charity'),
  [INDUSTRY_COMPANY_SERVICES]: (t) => t('Company Services'),
  [INDUSTRY_DEFENCE_MILITARY]: (t) => t('Defence, military, arms / weapons trade'),
  [INDUSTRY_EDUCATION]: (t) => t('Education'),
  [INDUSTRY_EMERGENCY_SERVICES]: (t) => t('Emergency Services'),
  [INDUSTRY_ENERGY]: (t) => t('Energy'),
  [INDUSTRY_ENGINEERING]: (t) => t('Engineering'),
  [INDUSTRY_EXPORT_IMPORT]: (t) => t('Export or import'),
  [INDUSTRY_FINANCIAL_SERVICES_BANK_INSURANCE]: (t) => t('Financial Services – Banks/Insurance'),
  [INDUSTRY_FINANCIAL_SERVICES_INVESTMENT_PRODUCTS]: (t) => t('Financial Services - Investment Products'),
  [INDUSTRY_GAMBLING_CASINO]: (t) => t('Gambling/Casino'),
  [INDUSTRY_GOVERNMENT_OR_PUBLIC_SECTOR]: (t) => t('Government or public sector'),
  [INDUSTRY_LEGAL]: (t) => t('Legal'),
  [INDUSTRY_LEISURE_TOURISM]: (t) => t('Leisure, entertainment, or tourism'),
  [INDUSTRY_MANUFACTURING]: (t) => t('Manufacturing'),
  [INDUSTRY_MEDIA_MARKETING_ADVERTISING]: (t) => t('Media / Marketing / Advertising'),
  [INDUSTRY_MONEY_REMITTANCE_MONEYLENDING]: (t) => t('Money Remittance / Moneylending  / Pawnbrokers / New Payment Providers'),
  [INDUSTRY_NGO_NPO]: (t) => t('Non-Governmental Organisation (NGO) / Non-Profit Organisation (NPO)'),
  [INDUSTRY_OTHER]: (t) => t('Other'),
  [INDUSTRY_PRECIOUS_METALS_STONES]: (t) => t('Precious Metals or stones'),
  [INDUSTRY_PROPERTY_CONSTRUCTION_TRADE]: (t) => t('Property, construction, or trade'),
  [INDUSTRY_RETAIL]: (t) => t('Retail'),
  [INDUSTRY_RETAIL_AUCTION]: (t) => t('Retail - Auction'),
  [INDUSTRY_RETAIL_HIGH_VALUE_GOODS]: (t) => t('Retail - High-value goods'),
  [INDUSTRY_RETAIL_WHOLESALE]: (t) => t('Retail - Wholesale'),
  [INDUSTRY_SOCIAL_CARE_SERVICES_HEALTH_MEDICINE]: (t) => t('Social care, services, health, or medicine'),
  [INDUSTRY_SPORTS]: (t) => t('Sports'),
  [INDUSTRY_TECHNOLOGY]: (t) => t('Technology'),
  [INDUSTRY_TELECOMMUNICATIONS]: (t) => t('Telecommunications'),
  [INDUSTRY_TRANSPORT_LOGISTICS]: (t) => t('Transport / Logistics')
};

export const OCCUPATION: Record<string, KeyValueTranslated> = {
  [INDUSTRY_ACCOUNTANTS_AND_TRUSTEES]: INDUSTRY_ACCOUNTANTS_AND_TRUSTEES_OCCUPATION_LIST,
  [INDUSTRY_AGRICULTURAL]: INDUSTRY_AGRICULTURAL_OCCUPATION_LIST,
  [INDUSTRY_CATERING_HOSPITALITY]: INDUSTRY_CATERING_HOSPITALITY_OCCUPATION_LIST,
  [INDUSTRY_CHARITY]: INDUSTRY_DEFAULT_OCCUPATION_LIST,
  [INDUSTRY_COMPANY_SERVICES]: INDUSTRY_COMPANY_SERVICES_OCCUPATION_LIST,
  [INDUSTRY_DEFENCE_MILITARY]: INDUSTRY_DEFENCE_MILITARY_OCCUPATION_LIST,
  [INDUSTRY_EDUCATION]: INDUSTRY_EDUCATION_OCCUPATION_LIST,
  [INDUSTRY_EMERGENCY_SERVICES]: INDUSTRY_EMERGENCY_SERVICES_OCCUPATION_LIST,
  [INDUSTRY_ENERGY]: INDUSTRY_ENERGY_OCCUPATION_LIST,
  [INDUSTRY_ENGINEERING]: INDUSTRY_DEFAULT_OCCUPATION_LIST,
  [INDUSTRY_EXPORT_IMPORT]: INDUSTRY_EXPORT_IMPORT_OCCUPATION_LIST,
  [INDUSTRY_FINANCIAL_SERVICES_BANK_INSURANCE]: INDUSTRY_FINANCIAL_SERVICES_OCCUPATION_LIST,
  [INDUSTRY_FINANCIAL_SERVICES_INVESTMENT_PRODUCTS]: INDUSTRY_FINANCIAL_SERVICES_OCCUPATION_LIST,
  [INDUSTRY_GAMBLING_CASINO]: INDUSTRY_GAMBLING_CASINO_OCCUPATION_LIST,
  [INDUSTRY_GOVERNMENT_OR_PUBLIC_SECTOR]: INDUSTRY_GOVERNMENT_OR_PUBLIC_SECTOR_OCCUPATION_LIST,
  [INDUSTRY_LEGAL]: INDUSTRY_LEGAL_OCCUPATION_LIST,
  [INDUSTRY_LEISURE_TOURISM]: INDUSTRY_LEISURE_TOURISM_OCCUPATION_LIST,
  [INDUSTRY_MANUFACTURING]: INDUSTRY_DEFAULT_OCCUPATION_LIST,
  [INDUSTRY_MEDIA_MARKETING_ADVERTISING]: INDUSTRY_MEDIA_MARKETING_ADVERTISING_OCCUPATION_LIST,
  [INDUSTRY_MONEY_REMITTANCE_MONEYLENDING]: INDUSTRY_DEFAULT_OCCUPATION_LIST,
  [INDUSTRY_NGO_NPO]: INDUSTRY_NGO_NPO_OCCUPATION_LIST,
  [INDUSTRY_OTHER]: INDUSTRY_OTHER_OCCUPATION_LIST,
  [INDUSTRY_PRECIOUS_METALS_STONES]: INDUSTRY_PRECIOUS_METALS_STONES_OCCUPATION_LIST,
  [INDUSTRY_PROPERTY_CONSTRUCTION_TRADE]: INDUSTRY_PROPERTY_CONSTRUCTION_TRADE_OCCUPATION_LIST,
  [INDUSTRY_RETAIL]: INDUSTRY_RETAIL_OCCUPATION_LIST,
  [INDUSTRY_RETAIL_AUCTION]: INDUSTRY_DEFAULT_OCCUPATION_LIST,
  [INDUSTRY_RETAIL_HIGH_VALUE_GOODS]: INDUSTRY_RETAIL_OCCUPATION_LIST,
  [INDUSTRY_RETAIL_WHOLESALE]: INDUSTRY_RETAIL_OCCUPATION_LIST,
  [INDUSTRY_SOCIAL_CARE_SERVICES_HEALTH_MEDICINE]: INDUSTRY_SOCIAL_CARE_SERVICES_HEALTH_MEDICINE_OCCUPATION_LIST,
  [INDUSTRY_SPORTS]: INDUSTRY_SPORTS_OCCUPATION_LIST,
  [INDUSTRY_TECHNOLOGY]: INDUSTRY_TECHNOLOGY_OCCUPATION_LIST,
  [INDUSTRY_TELECOMMUNICATIONS]: INDUSTRY_TELECOMMUNICATIONS_OCCUPATION_LIST,
  [INDUSTRY_TRANSPORT_LOGISTICS]: INDUSTRY_DEFAULT_OCCUPATION_LIST
};

export const SOURCE_OF_FUNDS_BASE: KeyValueTranslated = {
  'Savings / Investments': (t) => t('Savings / Investments'),
  'Benefits or borrowing': (t) => t('Benefits or borrowing'),
  Pension: (t) => t('Pension'),
  'Property income': (t) => t('Property income'),
  [OTHER]: (t) => t('Other')
};

export const SOURCE_OF_FUNDS: Record<string, KeyValueTranslated> = {
  [STUDENT]: {
    [SOURCE_OF_FUNDS_PART_TIME_WORK]: (t) => t('Part-time work'),
    ...SOURCE_OF_FUNDS_BASE
  },
  [RETIRED]: {
    [SOURCE_OF_FUNDS_PART_TIME_WORK]: (t) => t('Part-time work'),
    ...SOURCE_OF_FUNDS_BASE
  },
  [UNEMPLOYED]: {
    'Family or partner support': (t) => t('Family or partner support'),
    ...SOURCE_OF_FUNDS_BASE
  }
};
