import { BusinessRegion } from '../configuration/configuration';

export enum Page {
  AccountReadyInformation = 'AccountReadyInformation',
  CreateLogIn = 'CreateLogIn',
  Home = 'Home',
  IdentificationDriversLicence = 'IdentificationDriversLicence',
  IdentificationMedicare = 'IdentificationMedicare',
  IdentificationPassport = 'IdentificationPassport',
  IdentificationSelect = 'IdentificationSelect',
  IdentificationPEP = 'IdentificationQuestions',
  ProfileEmployment = 'ProfileEmployment',
  ProfilePersonalDetails = 'ProfilePersonalDetails',
  ProfileTaxDetails = 'ProfileTaxDetails',
  ProfileYourAddress = 'ProfileYourAddress',
  ReviewAndSubmit = 'ReviewAndSubmit',
  SelectAccountType = 'SelectAccountType',
  FundYourAccount = 'FundYourAccount',
  TermsConfirmation = 'TermsConfirmation',
  VerifyEmailConfirmation = 'VerifyEmailConfirmation',
  VerifyYourEmail = 'VerifyYourEmail',
  LogIn = 'LogIn',
  ForgotPassword = 'ForgotPassword',
  FundIntercept = 'FundIntercept',

  // sgp specific pages start from here
  SingpassConnector = 'SingpassConnector',
  SingpassCallback = 'SingpassCallback',
  JumioConnector = 'JumioConnector',
  JumioProcessing = 'JumioProcessing',
  FinancialBackground = 'FinancialBackground',
  KnowledgeExperience = 'KnowledgeExperience'
}

export enum NavigationStep {
  Profile = 'Profile',
  Identification = 'Identification',
  ReviewAndSubmit = 'ReviewAndSubmit',
  AboutYou = 'AboutYou'
}

export interface IPage {
  title?: string;
  path: string;
  navigationStep?: (businessRegion: BusinessRegion) => NavigationStep;
  public?: boolean;
}

const PATH_BACKSLASH_INDEX_AFTER_BUSINESS_REGION = 2;
export const getPagePathWithoutBusinessRegion = (path: string) => {
  return path.split('/')[PATH_BACKSLASH_INDEX_AFTER_BUSINESS_REGION];
};
