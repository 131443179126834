import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { pageConstants } from '../models/pagePaths';
import { useParamsToSetBusinessRegion } from '../hooks/useParamsToSetBusinessRegion';
import { IPage } from '../models/page';

export interface IPageHeadProps {
  page: IPage;
}

export const PageHead: React.FC<IPageHeadProps> = ({ page }) => {
  const { t } = useTranslation();
  useParamsToSetBusinessRegion();
  return (
    <Helmet>
      <title>{page.title ?? pageConstants.defaultTitle(t)}</title>
      <meta name='title' content={page.title ?? pageConstants.defaultTitle(t)} />
      <meta property='og:title' content={page.title ?? pageConstants.defaultTitle(t)} />
      <meta
        name='description'
        content={t('Join over 1 million investors on an award winning platform and access $0 brokerage on US, UK, Canadian and Japanese markets.')}
      />
      <meta
        property='og:description'
        content={t('Join over 1 million investors on an award winning platform and access $0 brokerage on US, UK, Canadian and Japanese markets.')}
      />
      <meta
        property='twitter:description'
        content={t('Join over 1 million investors on an award winning platform and access $0 brokerage on US, UK, Canadian and Japanese markets.')}
      />
    </Helmet>
  );
};
