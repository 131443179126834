/* eslint-disable no-console */
/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getBigScriptConfig, IBigScriptConfig } from '../configuration/configuration';
import { updateAnalyticsEnabled } from '../Store/Actions';

export const Analytics: React.FC<{ region: string; language: string }> = ({ region, language }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const initBigScripAnalytics = async (config: IBigScriptConfig) => {
      // adding one-trust meta tag
      const oneTrustMeta = document.createElement('meta');
      oneTrustMeta.name = 'onetrust-key';
      oneTrustMeta.dataset.key = config.oneTrust;
      document.head.append(oneTrustMeta);

      // adding mParticle meta tag
      const mParticleMeta = document.createElement('meta');
      mParticleMeta.name = 'mparticle-key';
      mParticleMeta.dataset.key = config.mparticle;
      mParticleMeta.dataset.instance_name = 'default_instance';
      mParticleMeta.dataset.plan_id = 'web';
      document.head.append(mParticleMeta);

      const script = document.createElement('script');
      script.src = config.scriptUrl;
      script.id = 'cookies';
      script.type = 'module';
      script.dataset.language = config.language;
      script.dataset.region = config.region;
      script.nonce = 'true';
      document.head.append(script);
      dispatch(updateAnalyticsEnabled(true));
    };
    //
    const config = getBigScriptConfig();
    config.language = language || config.language;
    config.region = region || config.region;
    initBigScripAnalytics(config);
  }, []);

  return <></>;
};
