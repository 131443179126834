import { breakpoint, CircularIcon, IconNames, Label, Panel, Size, Variant, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';

const PanelWithIconAndText = styled(Panel)`
  line-break: anywhere;
  margin: 0.5rem 0;
  padding: 1rem;
  ${CircularIcon} {
    padding: 0;
    width: 1.5rem;
    height: 1.5rem;
  }
  @media (max-width: ${breakpoint.mobile}) {
    flex-direction: row;
    gap: 1rem;
  }
`;

const LabelTextContainer = styled.div``;

export interface IFundInterceptPanelProps {
  label: string;
  text?: string;
}

export const FundInterceptPanel: React.FC<IFundInterceptPanelProps> = ({ label, text }) => {
  return (
    <PanelWithIconAndText>
      <CircularIcon name={IconNames.InfoWithoutCircle} size={Size.Small} variant={Variant.Info} />
      <LabelTextContainer>
        <Label>{label}</Label>
        <Text size={Size.Large}>{text}</Text>
      </LabelTextContainer>
    </PanelWithIconAndText>
  );
};
