/* eslint-disable no-console */
/* eslint-disable i18next/no-literal-string */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getRecaptchaConfig, IRecaptchaConfiguration } from '../configuration/configuration';
import { updateRecaptchaEnabled } from '../Store/Actions';

export const Recaptcha: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const initRecaptcha = async (config: IRecaptchaConfiguration) => {
      // inject google recaptcha script
      const script = document.createElement('script');
      script.src = `${config.enterpriseScriptUrl}?render=${config.renderKey}`;
      script.async = true;
      script.defer = true;
      document.head.append(script);
      dispatch(updateRecaptchaEnabled(true));
    };

    const config = getRecaptchaConfig();
    initRecaptcha(config);
  }, []);

  return <></>;
};
