import { Heading1, Size, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { CardHeaderWithText } from '../CardHeaderWithText';

export const MyInfoPageHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CardHeaderWithText textWithSmallMargin>
      <Heading1>{t('Myinfo')}</Heading1>
      <Text textAlign='center' size={Size.Large}>
        {t(
          'Apply using Myinfo (authenticated through Singpass) if you are a Singapore Citizen or Singapore Resident and have a National Registration Identity Card (NRIC) number or a Foreign Identification Number (FIN).'
        )}
      </Text>
    </CardHeaderWithText>
  );
};
