import React from 'react';
import styled from 'styled-components';
import { Link, Logo } from '@cmctechnology/phoenix-stockbroking-web-design';
import { CMC_MARKETS_WEBSITE_URL } from '../../constants/urlConstants';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
  background-color: ${(props) => props.theme.colours.navigationBar.background};
  left: 0;
  top: 0;
  padding: 0 2rem;
`;

export const PageWithPodHeader: React.FC = (): JSX.Element => {
  return (
    <HeaderWrapper>
      <Link href={CMC_MARKETS_WEBSITE_URL}>
        <Logo />
      </Link>
    </HeaderWrapper>
  );
};
