import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardLarge,
  Heading1,
  CardHeader,
  CardSeparator,
  CardContent,
  FormGroup,
  Text,
  Button,
  IThemeProps,
  Size,
  FormGroupSeparator,
  Variant,
  Notification,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { forgotPasswordSubmit, signIn } from '../../Store/Actions';
import { AUTHENTICATION_CODE_LENGTH } from '../../constants/verifyYourEmail';
import { IStore, actions } from '../../Store/Store';
import { ApiRequest, ApiRequestStatus } from '../../models/apiRequestResult';
import { sendButtonIcon, confirmButtonLabel, FORGOT_PASSWORD_SUCCESS_PAUSE_TIME } from '../../constants/forgotPasswordConstants';
import { GoBackIcon } from '../GoBackIcon';
import { VerifyCodeSection } from './VerifyCodeSection';
import { CreateNewPasswordSection } from './CreateNewPasswordSection';
import { getForgotPasswordErrorMessage } from '../../models/usersErrorCodes/forgotPasswordErrorCodes';
import { getSignInErrorMessage } from '../../models/usersErrorCodes/signInErrorCodes';
import { IPageNavigation } from '../../models/IPageNavigation';
import { BUSINESS_REGION_TO_BRAND_MAPPINGS } from '../../constants/loginConstants';
import { BusinessRegion } from '../../configuration/configuration';
import { useBusinessRegion } from '../../hooks/useBusinessRegion';

interface IForgotPasswordSubmitCardProps extends IPageNavigation {
  username: string;
}

const Wrapper = styled(CardLarge)`
  width: 100%;
  margin: 1.5rem;
`;

const Header = styled(CardHeader)`
  padding: 0;
  width: 100%;
  height: 8.5rem;
  justify-content: center;

  ${Heading1} {
    margin-top: 0;
    margin-bottom: 0;
    color: ${(props: IThemeProps) => props.theme.colours.card.header.text};
  }
`;

const Content = styled(CardContent)`
  width: 100%;
  box-sizing: border-box;
  padding: 3rem 3rem;
  justify-content: left;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: 12rem;
`;

export const ForgotPasswordSubmitCard: React.FC<IForgotPasswordSubmitCardProps> = ({ username, onNext, onPrevious, ...rest }): JSX.Element => {
  const { generateTestId } = useTestId(rest, `ForgotPasswordSubmitCard`);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { businessRegion } = useBusinessRegion();

  let closeTimeoutId: ReturnType<typeof setTimeout>;
  const { status: forgotPasswordSubmitStatus } = useSelector((store: IStore) => store.local.apiResults[ApiRequest.ForgotPasswordSubmit]);
  const { status: signInStatus } = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignIn]);
  const [verificationCode, setVerificationCode] = useState(``);
  const [isFormValid, setIsFormValid] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({ valid: false, value: `` });
  const isSubmissionEnabled = forgotPasswordSubmitStatus !== ApiRequestStatus.InProgress && signInStatus !== ApiRequestStatus.InProgress;
  const forgotPasswordSubmitErrorCode = useSelector((store: IStore) => store.local.apiResults[ApiRequest.ForgotPasswordSubmit].errorCode);
  const signInErrorCode = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignIn].errorCode);
  const brandInSignInRequest = BUSINESS_REGION_TO_BRAND_MAPPINGS[businessRegion as BusinessRegion];

  const resetRequestStates = () => {
    dispatch(actions.apiRequestReset(ApiRequest.ForgotPassword));
    dispatch(actions.apiRequestReset(ApiRequest.ForgotPasswordSubmit));
    dispatch(actions.apiRequestReset(ApiRequest.SignIn));
  };

  const onSubmit = () => {
    dispatch(forgotPasswordSubmit({ username, password: passwordValidation.value as string, code: verificationCode }));
  };

  const onGoBack = () => {
    resetRequestStates();
    onPrevious();
  };

  const onClose = () => {
    clearTimeout(closeTimeoutId);
    onNext();
  };

  useEffect(() => {
    setIsFormValid(!!passwordValidation.valid && verificationCode.length === AUTHENTICATION_CODE_LENGTH);

    if (forgotPasswordSubmitStatus === ApiRequestStatus.Success && signInStatus === ApiRequestStatus.NotSubmitted) {
      dispatch(signIn({ username, password: passwordValidation.value as string, brand: brandInSignInRequest }));
    }

    if (signInStatus === ApiRequestStatus.Success) {
      closeTimeoutId = setTimeout(() => {
        onNext();
      }, FORGOT_PASSWORD_SUCCESS_PAUSE_TIME);
    }
  }, [forgotPasswordSubmitStatus, signInStatus, passwordValidation, verificationCode]);

  return (
    <Wrapper>
      <Header>
        <GoBackIcon onClick={onGoBack} />
        <Heading1>{t('Reset password')}</Heading1>
      </Header>
      <CardSeparator />
      <Content>
        <VerifyCodeSection username={username} setVerificationCode={(code) => setVerificationCode(code)} />
        <FormGroupSeparator />
        <CreateNewPasswordSection setPasswordValidation={setPasswordValidation} />
        {signInStatus !== ApiRequestStatus.Success && (
          <FormGroup>
            <SubmitButton
              icon={sendButtonIcon[forgotPasswordSubmitStatus]}
              label={confirmButtonLabel[forgotPasswordSubmitStatus](t)}
              disabled={!isFormValid}
              onClick={() => isSubmissionEnabled && onSubmit()}
              {...generateTestId(`send`)}
            />
          </FormGroup>
        )}
        {forgotPasswordSubmitStatus === ApiRequestStatus.Failed && forgotPasswordSubmitErrorCode && (
          <FormGroup>
            <Notification variant={Variant.Error}>
              <Text size={Size.Large} variant={Variant.Error}>
                {getForgotPasswordErrorMessage(forgotPasswordSubmitErrorCode, t)}
              </Text>
            </Notification>
          </FormGroup>
        )}
        {signInStatus === ApiRequestStatus.Failed && signInErrorCode && (
          <FormGroup>
            <Notification variant={Variant.Error}>
              <Text size={Size.Large} variant={Variant.Error}>
                {getSignInErrorMessage(signInErrorCode, t)}
              </Text>
            </Notification>
          </FormGroup>
        )}
        {signInStatus === ApiRequestStatus.Success && (
          <FormGroup>
            <Notification variant={Variant.Success} onClose={onClose}>
              <Text size={Size.Large} variant={Variant.Success}>
                <Trans t={t}>Great! Your password has been updated.</Trans>
              </Text>
            </Notification>
          </FormGroup>
        )}
      </Content>
    </Wrapper>
  );
};
