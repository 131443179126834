import {
  CheckBox,
  FormControl,
  FormGroup,
  FormRow,
  Size,
  ValidationErrorMessage,
  Validators,
  useValidator,
  Text,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IQuestionProps } from '../../models/pepQuestions';

export const PEPQuestion3: React.FC<IQuestionProps> = ({ selected, setSelected, setValidators, answers, setAnswers, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `pepQ3`);

  const q3FirstNameValidator = useValidator<string>('', Validators.required(t('Required')));
  const q3MiddleNameValidator = useValidator<string>(
    '',
    Validators.custom(() => true, '')
  );
  const q3LastNameValidator = useValidator<string>('', Validators.required(t('Required')));

  useEffect(() => {
    if (selected) setValidators([q3FirstNameValidator, q3MiddleNameValidator, q3LastNameValidator]);
    else setValidators([]);

    setAnswers({
      ...answers,
      question3: {
        answered: selected ?? false,
        firstName: q3FirstNameValidator.value,
        middleName: q3MiddleNameValidator.value,
        lastName: q3LastNameValidator.value
      }
    });
  }, [selected, q3FirstNameValidator.value, q3MiddleNameValidator.value, q3LastNameValidator.value]);

  return (
    <FormGroup>
      <Text>{t('Are you known by any other name or alias?')}</Text>
      <FormGroup>
        <FormRow>
          <CheckBox
            label={t('Yes')}
            checked={selected}
            onChange={(e) => {
              setSelected(e.target.checked);
            }}
            disabled={selected}
            {...generateTestId(`yes`)}
          />
          <CheckBox
            label={t('No')}
            checked={selected === false}
            onChange={(e) => {
              setSelected(!e.target.checked);
            }}
            disabled={selected === false}
            {...generateTestId(`no`)}
          />
        </FormRow>
      </FormGroup>

      {selected && (
        <FormGroup>
          <Text>{t('If yes, please provide details of the name.')}</Text>
          <FormControl
            type='text'
            name='firstName'
            placeholder={t('Enter the first name')}
            value={q3FirstNameValidator.value}
            onChange={(e) => {
              q3FirstNameValidator.handleEvent(e.target.value);
            }}
            invalid={q3FirstNameValidator.invalid}
            {...generateTestId(`firstNameInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q3FirstNameValidator} />
          <FormControl
            type='text'
            name='middleName'
            placeholder={t('Enter the middle name (optional)')}
            value={q3MiddleNameValidator.value}
            onChange={(e) => {
              q3MiddleNameValidator.handleEvent(e.target.value);
            }}
            invalid={q3MiddleNameValidator.invalid}
            {...generateTestId(`middleNameInput`)}
          />
          <FormControl
            type='text'
            name='lastName'
            placeholder={t('Enter the last name')}
            value={q3LastNameValidator.value}
            onChange={(e) => {
              q3LastNameValidator.handleEvent(e.target.value);
            }}
            invalid={q3LastNameValidator.invalid}
            {...generateTestId(`lastNameInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q3LastNameValidator} />
        </FormGroup>
      )}
    </FormGroup>
  );
};
