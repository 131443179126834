import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  CardLarge,
  Heading1,
  Heading3,
  CardHeader,
  CardSeparator,
  CardContent,
  FormGroup,
  Text,
  FormControl,
  Button,
  IThemeProps,
  Size,
  Validators,
  useValidator,
  ValidationErrorMessage,
  Variant,
  Notification,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { ApiRequestStatus, ApiRequest } from '../../models/apiRequestResult';
import { emailRegex } from '../../constants/loginConstants';
import { forgotPassword } from '../../Store/Actions';
import { IStore, actions } from '../../Store/Store';
import { sendButtonIcon, sendButtonLabel, FORGOT_PASSWORD_SUCCESS_PAUSE_TIME } from '../../constants/forgotPasswordConstants';
import { GoBackIcon } from '../GoBackIcon';
import { IPreviousPage } from '../../models/IPageNavigation';

interface IForgotPasswordCardProps extends IPreviousPage {
  onNext: (email: string) => void;
}

const Wrapper = styled(CardLarge)`
  width: 100%;
  margin: 1.5rem;
`;

const Header = styled(CardHeader)`
  padding: 0;
  height: 8.5rem;
  justify-content: center;

  ${Heading1} {
    margin-top: 0;
    margin-bottom: 0;
    padding: 1rem;
    color: ${(props: IThemeProps) => props.theme.colours.card.header.text};
  }
`;

const Content = styled(CardContent)`
  width: 100%;
  box-sizing: border-box;
  padding: 3rem 3rem;

  ${Heading3} {
    margin-bottom: 1rem;
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  max-width: 10.5rem;
`;

const UsernameInput = styled(FormControl)`
  padding: 0.75rem;
`;

const SubmitFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ForgotPasswordCard: React.FC<IForgotPasswordCardProps> = ({ onNext, onPrevious, ...rest }): JSX.Element => {
  const { generateTestId } = useTestId(rest, `ForgotPasswordCard`);
  const { status, errorCode } = useSelector((store: IStore) => store.local.apiResults[ApiRequest.ForgotPassword]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState(``);

  const emailValidator = useValidator<string>(``, Validators.required(t(`Please enter your email`)).match(emailRegex, t(`Please enter a valid email`)));
  const isSubmissionEnabled = status !== ApiRequestStatus.InProgress && status !== ApiRequestStatus.Success;

  const onGoBack = () => {
    dispatch(actions.apiRequestReset(ApiRequest.ForgotPassword));
    onPrevious!();
  };

  useEffect(() => {
    if (status === ApiRequestStatus.Success) {
      setTimeout(() => {
        dispatch(actions.apiRequestReset(ApiRequest.ForgotPassword));
        onNext(emailValidator.value);
      }, FORGOT_PASSWORD_SUCCESS_PAUSE_TIME);
    }
  }, [status]);

  const onSubmit = () => {
    dispatch(forgotPassword({ username: emailValidator.value }));
  };

  return (
    <Wrapper>
      <Header>
        <GoBackIcon onClick={onGoBack} />
        <Heading1>{t(`Reset password`)}</Heading1>
      </Header>
      <CardSeparator />
      <Content>
        <FormGroup>
          <Heading3>
            <Trans t={t}>
              <b>Enter your email</b>
            </Trans>
          </Heading3>
          <Text size={Size.Large}>
            <Trans t={t}>
              We will email you a <b>6 digit code</b> to reset your password.
            </Trans>
          </Text>
        </FormGroup>
        <FormGroup>
          <Text size={Size.Large}>
            <Trans t={t}>
              <b>Email</b> (username)
            </Trans>
          </Text>
          <UsernameInput
            type='text'
            placeholder={t('Enter your email')}
            id='logonAccount'
            name='logonAccount'
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              emailValidator.handleEvent(e.target.value, !emailValidator.validated);
            }}
            onBlur={(e) => emailValidator.handleEvent(e.target.value)}
            invalid={emailValidator.invalid}
          />
          <ValidationErrorMessage size={Size.Medium} validator={emailValidator} />
        </FormGroup>
        <SubmitFormGroup>
          <SubmitButton
            icon={sendButtonIcon[status]}
            label={sendButtonLabel[status](t)}
            onClick={() => isSubmissionEnabled && onSubmit()}
            disabled={!emailValidator.valid}
            {...generateTestId(`send`)}
          />
          {status === ApiRequestStatus.Failed && errorCode && (
            <Notification variant={Variant.Error}>
              <Text size={Size.Large} variant={Variant.Error}>
                <Trans t={t}>Something went wrong, please try again!</Trans>
              </Text>
            </Notification>
          )}
        </SubmitFormGroup>
      </Content>
    </Wrapper>
  );
};
