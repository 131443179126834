import { AddressErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { BaseErrorCode } from '../baseErrorCodes';
import { Translated } from '../translation';
import { baseAddressErrorCodeMappings } from './baseAddressErrorCodeMappings';

export const addressErrorCodeMappings: Record<AddressErrorCode, BaseErrorCode> = {
  ...baseAddressErrorCodeMappings
};

export const addressErrorMessages: Record<BaseErrorCode, Translated> = {
  [BaseErrorCode.UnexpectedError]: (t) => t('Sorry, something went wrong. Please try again or enter your address manually.')
};

export const getAddressErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = errorCode as AddressErrorCode;

  return addressErrorMessages[addressErrorCodeMappings[errorCodeEnum]](t);
};
