import {
  breakpoint,
  ButtonLink,
  Button,
  CardContent,
  CardFooter,
  CardLarge,
  CardSeparator,
  FormGroup,
  FormRow,
  Heading1,
  Text,
  Size,
  CardHeader
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { OPEN_EXTERNAL_URL_TARGET } from '../constants/urlConstants';
import { termsAndConditions } from '../models/application';
import { acceptTnCAndSubmitApplication, openExternalUrl, submitPromoCode } from '../Store/Actions';
import { IPreviousPage } from '../models/IPageNavigation';
import { GoBackIcon } from '../Components/GoBackIcon';
import { PromoCodeInput } from '../Components/PromoCodeInput';
import { usePromoCodeValidator } from '../hooks/usePromoCodeValidator';

const TextWithBulletPoint = styled(Text)`
  display: list-item;
`;

const FormGroupWithLeftPadding = styled(FormGroup)`
  padding-left: 2rem;
`;

const LeftJustifiedFormRow = styled(FormRow)`
  @media (max-width: ${breakpoint.mobile}) {
    justify-content: left;
  }
`;
export interface ITermsConfirmationProps extends IPreviousPage {
  onAccept: () => void;
}

export const TermsConfirmation: React.FC<ITermsConfirmationProps> = ({ onAccept, onPrevious }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState(window?.localStorage?.PromoCode ?? '');

  const { promoCodeValidator } = usePromoCodeValidator(promoCode);

  useEffect(() => {
    promoCodeValidator.validate();
  }, []);

  const onAcceptClicked = () => {
    dispatch(submitPromoCode(promoCodeValidator.value));
    dispatch(acceptTnCAndSubmitApplication());
    onAccept();
  };

  return (
    <CardLarge>
      <CardHeader>
        <GoBackIcon onClick={onPrevious} />
        <Heading1>{t('Almost done!')}</Heading1>
      </CardHeader>
      <CardSeparator />
      <CardContent>
        <FormRow>
          <Text size={Size.Large}>{t('Please read the following documents before you apply for a CMC Markets Invest account:')}</Text>
        </FormRow>
        <FormGroup>
          {termsAndConditions.map((term) => (
            <React.Fragment key={term.key}>
              <LeftJustifiedFormRow>
                <ButtonLink style={{ textAlign: 'left' }} onClick={() => openExternalUrl(term.url, OPEN_EXTERNAL_URL_TARGET)}>
                  {term.label(t)}
                </ButtonLink>
              </LeftJustifiedFormRow>
            </React.Fragment>
          ))}
        </FormGroup>
        <FormGroup>
          <Text size={Size.Large} textAlign='left'>
            <Trans t={t}>
              By clicking <b>Accept + Confirm:</b>
            </Trans>
          </Text>
        </FormGroup>
        <FormGroupWithLeftPadding>
          <TextWithBulletPoint size={Size.Large} textAlign='left'>
            {t(
              'I acknowledge that I have read, understood and agree to the above documents (including the CHESS Sponsorship Agreement contained in Part G of the Terms and Conditions)'
            )}
          </TextWithBulletPoint>
        </FormGroupWithLeftPadding>
        <FormGroupWithLeftPadding>
          <TextWithBulletPoint size={Size.Large} textAlign='left'>
            {t(
              'I authorise CMC Markets Stockbroking to open an ANZ Cash Active account for the purpose of settling transactions on my CMC Markets Invest account pursuant to the CMC Markets Invest Terms and Conditions'
            )}
          </TextWithBulletPoint>
        </FormGroupWithLeftPadding>
        <FormGroupWithLeftPadding>
          <TextWithBulletPoint size={Size.Large} textAlign='left'>
            {t(
              'I consent to CMC Markets Stockbroking collecting my personal information for the purposes of this account application and disclosing it to third parties in order to verify my identity'
            )}
          </TextWithBulletPoint>
        </FormGroupWithLeftPadding>
      </CardContent>
      <CardSeparator />
      <CardContent>
        <PromoCodeInput validator={promoCodeValidator} promoCode={promoCode} setPromoCode={setPromoCode} />
      </CardContent>
      <CardFooter>
        <FormGroup>
          <FormRow>
            <Button
              label={t('Accept + Confirm')}
              onClick={() => onAcceptClicked()}
              disabled={promoCodeValidator.value !== '' && (promoCodeValidator.validating || promoCodeValidator.invalid)}
            />
          </FormRow>
        </FormGroup>
      </CardFooter>
    </CardLarge>
  );
};
