import { KeyValueTranslated } from '../models/translation';

export const COUNTRY_CODE_AUS = 'AUS';
export const COUNTRY_CODE_USA = 'USA';

export const COUNTRIES_ALL: KeyValueTranslated = {
  ABW: (t) => t('Aruba'),
  AFG: (t) => t('Afghanistan'),
  AGO: (t) => t('Angola'),
  AIA: (t) => t('Anguilla'),
  ALA: (t) => t('Åland Islands'),
  ALB: (t) => t('Albania'),
  AND: (t) => t('Andorra'),
  ANT: (t) => t('Netherlands Antilles'),
  ARE: (t) => t('United Arab Emirates'),
  ARG: (t) => t('Argentina'),
  ARM: (t) => t('Armenia'),
  ASM: (t) => t('American Samoa'),
  ATA: (t) => t('Antarctica'),
  ATF: (t) => t('French Southern Territories'),
  ATG: (t) => t('Antigua and Barbuda'),
  [COUNTRY_CODE_AUS]: (t) => t('Australia'),
  AUT: (t) => t('Austria'),
  AZE: (t) => t('Azerbaijan'),
  BDI: (t) => t('Burundi'),
  BEL: (t) => t('Belgium'),
  BEN: (t) => t('Benin'),
  BFA: (t) => t('Burkina Faso'),
  BGD: (t) => t('Bangladesh'),
  BGR: (t) => t('Bulgaria'),
  BHR: (t) => t('Bahrain'),
  BHS: (t) => t('Bahamas'),
  BIH: (t) => t('Bosnia and Herzegovina'),
  BLM: (t) => t('Saint Barthélemy'),
  BLR: (t) => t('Belarus'),
  BLZ: (t) => t('Belize'),
  BMU: (t) => t('Bermuda'),
  BOL: (t) => t('Bolivia'),
  BRA: (t) => t('Brazil'),
  BRB: (t) => t('Barbados'),
  BRN: (t) => t('Brunei Darussalam'),
  BTN: (t) => t('Bhutan'),
  BVT: (t) => t('Bouvet Island'),
  BWA: (t) => t('Botswana'),
  CAF: (t) => t('Central African Republic'),
  CAN: (t) => t('Canada'),
  CCK: (t) => t('Cocos (Keeling) Islands'),
  CHE: (t) => t('Switzerland'),
  CHL: (t) => t('Chile'),
  CHN: (t) => t('China'),
  CIV: (t) => t("Côte d'Ivoire"),
  CMR: (t) => t('Cameroon'),
  COD: (t) => t('Congo, Democratic Republic of the'),
  COG: (t) => t('Congo'),
  COK: (t) => t('Cook Islands'),
  COL: (t) => t('Colombia'),
  COM: (t) => t('Comoros'),
  CPV: (t) => t('Cape Verde'),
  CRI: (t) => t('Costa Rica'),
  CUB: (t) => t('Cuba'),
  CXR: (t) => t('Christmas Island'),
  CYM: (t) => t('Cayman Islands'),
  CYP: (t) => t('Cyprus'),
  CZE: (t) => t('Czech Republic'),
  DEU: (t) => t('Germany'),
  DJI: (t) => t('Djibouti'),
  DMA: (t) => t('Dominica'),
  DNK: (t) => t('Denmark'),
  DOM: (t) => t('Dominican Republic'),
  DZA: (t) => t('Algeria'),
  ECU: (t) => t('Ecuador'),
  EGY: (t) => t('Egypt'),
  ERI: (t) => t('Eritrea'),
  ESH: (t) => t('Western Sahara'),
  ESP: (t) => t('Spain'),
  EST: (t) => t('Estonia'),
  ETH: (t) => t('Ethiopia'),
  FIN: (t) => t('Finland'),
  FJI: (t) => t('Fiji'),
  FLK: (t) => t('Falkland Islands (Malvinas)'),
  FRA: (t) => t('France'),
  FRO: (t) => t('Faroe Islands'),
  FSM: (t) => t('Micronesia, Federated States of'),
  GAB: (t) => t('Gabon'),
  GBR: (t) => t('United Kingdom'),
  GEO: (t) => t('Georgia'),
  GGY: (t) => t('Guernsey'),
  GHA: (t) => t('Ghana'),
  GIB: (t) => t('Gibraltar'),
  GIN: (t) => t('Guinea'),
  GLP: (t) => t('Guadeloupe'),
  GMB: (t) => t('Gambia'),
  GNB: (t) => t('Guinea-Bissau'),
  GNQ: (t) => t('Equatorial Guinea'),
  GRC: (t) => t('Greece'),
  GRD: (t) => t('Grenada'),
  GRL: (t) => t('Greenland'),
  GTM: (t) => t('Guatemala'),
  GUF: (t) => t('French Guiana'),
  GUM: (t) => t('Guam'),
  GUY: (t) => t('Guyana'),
  HKG: (t) => t('Hong Kong'),
  HMD: (t) => t('Heard Island and McDonald Islands'),
  HND: (t) => t('Honduras'),
  HRV: (t) => t('Croatia'),
  HTI: (t) => t('Haiti'),
  HUN: (t) => t('Hungary'),
  IDN: (t) => t('Indonesia'),
  IMN: (t) => t('Isle of Man'),
  IND: (t) => t('India'),
  IOT: (t) => t('British Indian Ocean Territory'),
  IRL: (t) => t('Ireland'),
  IRN: (t) => t('Iran, Islamic Republic of'),
  IRQ: (t) => t('Iraq'),
  ISL: (t) => t('Iceland'),
  ISR: (t) => t('Israel'),
  ITA: (t) => t('Italy'),
  JAM: (t) => t('Jamaica'),
  JEY: (t) => t('Jersey'),
  JOR: (t) => t('Jordan'),
  JPN: (t) => t('Japan'),
  KAZ: (t) => t('Kazakhstan'),
  KEN: (t) => t('Kenya'),
  KGZ: (t) => t('Kyrgyzstan'),
  KHM: (t) => t('Cambodia'),
  KIR: (t) => t('Kiribati'),
  KNA: (t) => t('Saint Kitts and Nevis'),
  KOR: (t) => t('Korea, Republic of'),
  KWT: (t) => t('Kuwait'),
  LAO: (t) => t("Lao People's Democratic Republic"),
  LBN: (t) => t('Lebanon'),
  LBR: (t) => t('Liberia'),
  LBY: (t) => t('Libya'),
  LCA: (t) => t('Saint Lucia'),
  LIE: (t) => t('Liechtenstein'),
  LKA: (t) => t('Sri Lanka'),
  LSO: (t) => t('Lesotho'),
  LTU: (t) => t('Lithuania'),
  LUX: (t) => t('Luxembourg'),
  LVA: (t) => t('Latvia'),
  MAC: (t) => t('Macao'),
  MAF: (t) => t('Saint Martin (French part)'),
  MAR: (t) => t('Morocco'),
  MCO: (t) => t('Monaco'),
  MDA: (t) => t('Moldova, Republic of'),
  MDG: (t) => t('Madagascar'),
  MDV: (t) => t('Maldives'),
  MEX: (t) => t('Mexico'),
  MHL: (t) => t('Marshall Islands'),
  MKD: (t) => t('Macedonia, Republic of'),
  MLI: (t) => t('Mali'),
  MLT: (t) => t('Malta'),
  MMR: (t) => t('Myanmar'),
  MNE: (t) => t('Montenegro'),
  MNG: (t) => t('Mongolia'),
  MNP: (t) => t('Northern Mariana Islands'),
  MOZ: (t) => t('Mozambique'),
  MRT: (t) => t('Mauritania'),
  MSR: (t) => t('Montserrat'),
  MTQ: (t) => t('Martinique'),
  MUS: (t) => t('Mauritius'),
  MWI: (t) => t('Malawi'),
  MYS: (t) => t('Malaysia'),
  MYT: (t) => t('Mayotte'),
  NAM: (t) => t('Namibia'),
  NCL: (t) => t('New Caledonia'),
  NER: (t) => t('Niger'),
  NFK: (t) => t('Norfolk Island'),
  NGA: (t) => t('Nigeria'),
  NIC: (t) => t('Nicaragua'),
  NIU: (t) => t('Niue'),
  NLD: (t) => t('Netherlands'),
  NOR: (t) => t('Norway'),
  NPL: (t) => t('Nepal'),
  NRU: (t) => t('Nauru'),
  NZL: (t) => t('New Zealand'),
  OMN: (t) => t('Oman'),
  PAK: (t) => t('Pakistan'),
  PAN: (t) => t('Panama'),
  PCN: (t) => t('Pitcairn'),
  PER: (t) => t('Peru'),
  PHL: (t) => t('Philippines'),
  PLW: (t) => t('Palau'),
  PNG: (t) => t('Papua New Guinea'),
  POL: (t) => t('Poland'),
  PRI: (t) => t('Puerto Rico'),
  PRK: (t) => t("Korea, Democratic People's Republic of"),
  PRT: (t) => t('Portugal'),
  PRY: (t) => t('Paraguay'),
  PSE: (t) => t('Palestinian Territory'),
  PYF: (t) => t('French Polynesia'),
  QAT: (t) => t('Qatar'),
  REU: (t) => t('Reunion'),
  ROU: (t) => t('Romania'),
  RUS: (t) => t('Russian Federation'),
  RWA: (t) => t('Rwanda'),
  SAU: (t) => t('Saudi Arabia'),
  SDN: (t) => t('Sudan'),
  SEN: (t) => t('Senegal'),
  SGP: (t) => t('Singapore'),
  SGS: (t) => t('South Georgia and the South Sandwich Islands'),
  SHN: (t) => t('Saint Helena'),
  SJM: (t) => t('Svalbard and Jan Mayen Islands'),
  SLB: (t) => t('Solomon Islands'),
  SLE: (t) => t('Sierra Leone'),
  SLV: (t) => t('El Salvador'),
  SMR: (t) => t('San Marino'),
  SOM: (t) => t('Somalia'),
  SPM: (t) => t('Saint Pierre and Miquelon'),
  SRB: (t) => t('Serbia'),
  STP: (t) => t('Sao Tome and Principe'),
  SUR: (t) => t('Suriname'),
  SVK: (t) => t('Slovakia'),
  SVN: (t) => t('Slovenia'),
  SWE: (t) => t('Sweden'),
  SWZ: (t) => t('Swaziland'),
  SYC: (t) => t('Seychelles'),
  SYR: (t) => t('Syrian Arab Republic'),
  TCA: (t) => t('Turks and Caicos Islands'),
  TCD: (t) => t('Chad'),
  TGO: (t) => t('Togo'),
  THA: (t) => t('Thailand'),
  TJK: (t) => t('Tajikistan'),
  TKL: (t) => t('Tokelau'),
  TKM: (t) => t('Turkmenistan'),
  TLS: (t) => t('Timor-Leste'),
  TON: (t) => t('Tonga'),
  TTO: (t) => t('Trinidad and Tobago'),
  TUN: (t) => t('Tunisia'),
  TUR: (t) => t('Turkey'),
  TUV: (t) => t('Tuvalu'),
  TWN: (t) => t('Taiwan, Republic of China'),
  TZA: (t) => t('Tanzania, United Republic of'),
  UGA: (t) => t('Uganda'),
  UKR: (t) => t('Ukraine'),
  UMI: (t) => t('United States Minor Outlying Islands'),
  URY: (t) => t('Uruguay'),
  [COUNTRY_CODE_USA]: (t) => t('United States of America'),
  UZB: (t) => t('Uzbekistan'),
  VAT: (t) => t('Holy See (Vatican City State)'),
  VCT: (t) => t('Saint Vincent and the Grenadines'),
  VEN: (t) => t('Venezuela, Bolivarian Republic of'),
  VGB: (t) => t('Virgin Islands, British'),
  VIR: (t) => t('Virgin Islands, U.S.'),
  VNM: (t) => t('Vietnam'),
  VUT: (t) => t('Vanuatu'),
  WLF: (t) => t('Wallis and Futuna'),
  WSM: (t) => t('Samoa'),
  YEM: (t) => t('Yemen'),
  ZAF: (t) => t('South Africa'),
  ZMB: (t) => t('Zambia'),
  ZWE: (t) => t('Zimbabwe')
};

export const { [COUNTRY_CODE_AUS]: COUNTRY_CODE_ONLY_AUS, ...COUNTRIES_EXCEPT_AUS } = COUNTRIES_ALL;
