export enum AustraliaTaxValidator {
  TfnValidator = 'TfnValidator',
  AusTaxExemptionReasonValidator = 'AusTaxExemptionReasonValidator'
}

export enum OverseasTaxValidator {
  CountryValidator = 'CountryValidator',
  TinValidator = 'TinValidator',
  OverseasTaxExemptionReasonValidator = 'OverseasTaxExemptionReasonValidator',
  OverseasTaxExemptionExplanationValidator = 'OverseasTaxExemptionExplanationValidator'
}
