import { IconNames } from '@cmctechnology/phoenix-stockbroking-web-design';
import { ApiRequestStatus } from '../models/apiRequestResult';
import { KeyValueTranslated } from '../models/translation';

export const sendButtonIcon = {
  [ApiRequestStatus.NotSubmitted]: undefined,
  [ApiRequestStatus.InProgress]: IconNames.BarChart2,
  [ApiRequestStatus.Success]: IconNames.Check,
  [ApiRequestStatus.Failed]: undefined
};

export const sendButtonLabel: KeyValueTranslated<ApiRequestStatus> = {
  [ApiRequestStatus.NotSubmitted]: (t) => t(`Send`),
  [ApiRequestStatus.InProgress]: (t) => t(`Sending...`),
  [ApiRequestStatus.Success]: (t) => t(`Sent`),
  [ApiRequestStatus.Failed]: (t) => t(`Send`)
};

export const confirmButtonLabel: KeyValueTranslated<ApiRequestStatus> = {
  [ApiRequestStatus.NotSubmitted]: (t) => t(`Confirm password`),
  [ApiRequestStatus.InProgress]: (t) => t(`Confirming...`),
  [ApiRequestStatus.Success]: (t) => t(`Confirmed`),
  [ApiRequestStatus.Failed]: (t) => t(`Confirm password`)
};

export const FORGOT_PASSWORD_SUCCESS_PAUSE_TIME = 3000;
