import React from 'react';
import { FundInterceptScreen } from '../Components/FundIntercept/FundInterceptScreen';
import { PageWithPod } from '../Components/PageWithPod/PageWithPod';
import { INextPage } from '../models/IPageNavigation';

interface IFundInterceptProps extends INextPage {}

export const FundIntercept: React.FC<IFundInterceptProps> = ({ onNext }) => {
  return (
    <PageWithPod>
      <FundInterceptScreen onNext={onNext} />
    </PageWithPod>
  );
};
