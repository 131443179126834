import React, { useEffect } from 'react';
import {
  Button,
  CardContent,
  CardFooter,
  CardHeader,
  CardLarge,
  FormRow,
  Heading1,
  CircularIcon,
  Heading2,
  Text,
  IconNames,
  Size,
  breakpoint,
  Panel,
  Label,
  Variant,
  ButtonLink,
  FormGroupSeparator
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { IStore } from '../Store/Store';
import {
  applicationStatusLabel,
  accountStatusLabel,
  applicationReadyHeaderTitles,
  accountStatusLabelMapping,
  applicationStatusMapping,
  applicationReadyHeaderTitlesMapping,
  ApplicationStatus,
  hasChangedIdDetailsAfterLastIdVerify
} from '../models/application';
import { ApiRequest } from '../models/apiRequestResult';
import { openExternalUrl, pollingApplicationStatusAndUpdateApplicationWaitingTimer } from '../Store/Actions';
import { AccountProcessing } from '../Components/AccountProcessing';
import { CMC_CONTACT_US } from '../constants/urlConstants';
import { mobileCardHeaderHeading1FontCss } from '../Components/Mobile/MobileCardHeader';
import { AppStoreButtons } from '../Components/AppStoreButtons/AppStoreButtons';

const baseCardContentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
`;

const StyledCardContent = styled(CardContent)`
  padding-bottom: 0;
  ${baseCardContentCss}
  ${Heading1} {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  ${mobileCardHeaderHeading1FontCss}
`;

const StyledFormRowWithBottomMargin = styled(FormRow)`
  margin-bottom: 2.5rem;
`;

const StyledText = styled(Text)`
  white-space: normal;
`;

export interface IPanelWithIconAndTextProps {
  needFlexWrap: boolean;
}

const PanelWithIconAndText = styled(Panel)<IPanelWithIconAndTextProps>`
  line-break: anywhere;
  margin-bottom: 0;
  width: 100%;
  ${Label} {
    flex: 1;
  }
  ${FormGroupSeparator} {
    margin: 0;
  }
  ${(props: IPanelWithIconAndTextProps) =>
    props.needFlexWrap &&
    css`
      flex-wrap: wrap;
      line-break: normal;
    `}
`;

const StyledTextWithTopMargin = styled(Text)`
  margin-top: 1rem;
`;

const CircularIconWithoutPadding = styled(CircularIcon)`
  && {
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
  }
`;

const ExploreButton = styled(Button)`
  @media (max-width: ${breakpoint.mobile}) {
    display: none;
  }
`;

export interface IAccountReadyInformationProps {
  onStart: () => void;
  onGoBack: () => void;
  onFundIntercept: () => void;
}

export const AccountReadyInformation: React.FC<IAccountReadyInformationProps> = ({ onStart, onGoBack, onFundIntercept }) => {
  const email = useSelector((store: IStore) => store.remote.email);
  const submitResponse = useSelector((store: IStore) => store.local.applicationSubmitResponse);
  const applicationStatus = useSelector((store: IStore) => store.local.applicationStatus);
  const submitResponseResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SubmitApplication]);
  const applicationModalWaitingTimeElapsed = useSelector((store: IStore) => store.local.applicationModalWaitingTimeElapsed);
  const latestApplicationId = useSelector((store: IStore) => store.local.latestApplicationId);

  const submitResponseResultErrorCode = submitResponseResult.errorCode;
  const isAccountReady = applicationStatus === ApplicationStatus.Completed || applicationStatus === ApplicationStatus.CashAccountCreated;
  const isFinalise = applicationStatus === ApplicationStatus.Finalise;
  const showSubmitErrorMessageScreen = submitResponse?.error || submitResponseResultErrorCode;
  const showProcessScreen = !(isAccountReady || latestApplicationId || applicationModalWaitingTimeElapsed);
  const hasChangedIdDetails = hasChangedIdDetailsAfterLastIdVerify(submitResponseResultErrorCode);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const applicationGuid = submitResponse?.applicationGuid;

  useEffect(() => {
    if (applicationGuid || latestApplicationId) {
      dispatch(pollingApplicationStatusAndUpdateApplicationWaitingTimer(latestApplicationId || applicationGuid!));
    }
  }, [applicationGuid, latestApplicationId]);

  if (showSubmitErrorMessageScreen) {
    return (
      <CardLarge>
        <CardHeader />
        <CardContent>
          <Heading2>{t('Something went wrong')}</Heading2>
          {!hasChangedIdDetails && <Text size={Size.Medium}>{t('Please review your details, make sure they are all correct and then submit again.')}</Text>}
          {hasChangedIdDetails && (
            <Text size={Size.Medium}>
              {t('Please review your personal details and residential address, then run another ID check in the Identification screen.')}
            </Text>
          )}
        </CardContent>
        <CardFooter>
          <Button label={t('Review details')} onClick={() => onGoBack()} />
        </CardFooter>
      </CardLarge>
    );
  }
  return (
    <CardLarge>
      <AccountProcessing showProcessScreen={showProcessScreen} />
      {!showProcessScreen && (
        <StyledCardContent>
          <FormRow>
            <CircularIcon name={isAccountReady ? IconNames.CheckCircle : IconNames.Clock} size={Size.Large} />
          </FormRow>
          <FormRow>
            <Heading1>{applicationReadyHeaderTitles[applicationReadyHeaderTitlesMapping[applicationStatus]](t)}</Heading1>
          </FormRow>
          <StyledFormRowWithBottomMargin>
            {isAccountReady && (
              <Text size={Size.Large} textAlign='center'>
                {t('Deposit funds to start investing')}
              </Text>
            )}
            {!isAccountReady && (
              <Text size={Size.Large} textAlign='center'>
                {isFinalise && (
                  <Trans t={t}>
                    <b>Please provide valid ID to complete your application.</b>
                    <br />
                    Once your ID has been verified, you can start trading.
                    <br />
                    Enjoy instant access to our platform features today.
                  </Trans>
                )}
                {!isFinalise && (
                  <Trans t={t}>
                    Once your account has been created, you can start trading.
                    <br />
                    Enjoy instant access to our platform features today.
                  </Trans>
                )}
              </Text>
            )}
          </StyledFormRowWithBottomMargin>
          <PanelWithIconAndText needFlexWrap={false}>
            <CircularIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />
            <Label>{t('Login username')}</Label>
            <StyledText size={Size.Large}>{email}</StyledText>
          </PanelWithIconAndText>
          {!isAccountReady && (
            <PanelWithIconAndText needFlexWrap={false}>
              <CircularIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />
              <Label>{t('Platform access')}</Label>
              <StyledText size={Size.Large}>{t('Available')}</StyledText>
            </PanelWithIconAndText>
          )}
          <PanelWithIconAndText needFlexWrap>
            {isAccountReady && <CircularIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />}
            {!isAccountReady && <CircularIconWithoutPadding variant={Variant.Error} name={IconNames.AlertCircleWithoutCircle} size={Size.Small} />}
            <Label>{t('Application status')}</Label>
            <StyledText size={Size.Large} variant={!isAccountReady ? Variant.Error : undefined}>
              {applicationStatusLabel[applicationStatusMapping[applicationStatus]](t)}
            </StyledText>
            {isFinalise && (
              <>
                <FormGroupSeparator />
                <StyledText size={Size.Large}>
                  <Trans t={t}>
                    We&apos;ve emailed you details on how to provide valid ID.
                    <br />
                    Please follow the instructions so we can complete your application.
                  </Trans>
                </StyledText>
              </>
            )}
            {submitResponse?.applicationCustomerReference && (
              <StyledText size={Size.Large}>
                {t('Your application reference is ')} {submitResponse.applicationCustomerReference}
              </StyledText>
            )}
          </PanelWithIconAndText>
          <PanelWithIconAndText needFlexWrap={false}>
            {isAccountReady && <CircularIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />}
            {!isAccountReady && <CircularIconWithoutPadding name={IconNames.ClockWithoutCircle} size={Size.Small} />}
            <Label>{t('Account status')}</Label>
            <StyledText size={Size.Large}>{accountStatusLabel[accountStatusLabelMapping[applicationStatus]](t)}</StyledText>
          </PanelWithIconAndText>
        </StyledCardContent>
      )}
      <CardFooter>
        <AppStoreButtons />
        <ExploreButton label={isAccountReady ? t('Start Investing') : t('Explore platform')} onClick={isAccountReady ? onFundIntercept : onStart} />
        {!isAccountReady && (
          <StyledTextWithTopMargin size={Size.Medium} textAlign='center'>
            {t('Need help?')} <ButtonLink onClick={() => openExternalUrl(CMC_CONTACT_US)}>{t('Contact Us')}</ButtonLink>
          </StyledTextWithTopMargin>
        )}
      </CardFooter>
    </CardLarge>
  );
};
