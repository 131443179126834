export const removeWhiteSpace = (value: string) => value.trim().replace(/\s/g, '');

export const isNotEmpty = (value: string) => {
  return !!(value && value.length > 0);
};

export const hasValidValue = (value: string | undefined | null) => {
  return !!value && isNotEmpty(value);
};

// Only allowed characters are:  ' / , - (apostrophe, comma, forward-slash and hyphen)
export const removeIllegalCharacters = (name: string) => {
  if (!name) {
    return name;
  }

  let newName = name.replace(/[!"#$%&()*+.:;<=>?@[\\\]^_`{|}~]/g, ' ');
  newName = newName.replace(/\s+/g, ' ');

  return newName.trim();
};
