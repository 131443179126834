import { UsersErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { Translated } from '../translation';
import { BaseErrorCode, baseErrorMessages } from '../baseErrorCodes';
import { baseUserErrorCodeMappings } from './baseUserErrorCodeMappings';

enum SignUpErrorCode {
  InvalidPassword = 'InvalidPassword',
  UsernameExists = 'UsernameExists'
}

// Map backend error code [UsersErrorCode] to client error code[SignUpErrorCode]
const signUpErrorCodeMappings: Record<UsersErrorCode, BaseErrorCode | SignUpErrorCode> = {
  ...baseUserErrorCodeMappings,
  [UsersErrorCode.InvalidPasswordException]: SignUpErrorCode.InvalidPassword,
  [UsersErrorCode.UsernameExistsException]: SignUpErrorCode.UsernameExists
};

const signUpErrorMessages: Record<BaseErrorCode | SignUpErrorCode, Translated> = {
  ...baseErrorMessages,
  [SignUpErrorCode.UsernameExists]: (t) => t('Sorry, the email address is already in use. Please enter a different email.'),
  [SignUpErrorCode.InvalidPassword]: (t) => t(`Please use a different password, the one you provided is not secure.`)
};

export const getSignUpErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = errorCode as UsersErrorCode;

  return signUpErrorMessages[signUpErrorCodeMappings[errorCodeEnum]](t);
};
