import { TFunction } from 'i18next';
import { Translated } from '../../translation';

// https://github.com/Jumio/implementation-guides/blob/master/netverify/netverify-web-v4.md#after-the-user-journey
export enum JumioAuthoriseErrorCode {
  ErrorOnServer = '9100',
  AuthorizationTokenIssue = '9200',
  SessionExpired = '9210',
  TransmittingImageError = '9300',
  VerificationStep = '9400',
  NoNetworkConnection = '9800',
  UnexpectedErrorInClient = '9801',
  ProblemWhileCommunicateWithServer = '9810',
  FileUploadOrCameraPermission = '9820',
  FaceCaptureFailed = '9821',
  BrowserNotSupportCamera = '9822',
  NoAcceptableSubmissionIn3Attempts = '9835'
}

const JUMIO_ERROR_MESSAGES: Record<JumioAuthoriseErrorCode, Translated> = {
  [JumioAuthoriseErrorCode.ErrorOnServer]: (t) => t(`Sorry, an unknown error has occurred. Please try again.`),
  [JumioAuthoriseErrorCode.AuthorizationTokenIssue]: (t) => t(`Sorry, something went wrong. Please try again.`),
  [JumioAuthoriseErrorCode.SessionExpired]: (t) => t(`Your session has expired. Please try again.`),
  [JumioAuthoriseErrorCode.TransmittingImageError]: (t) => t(`Sorry, an unknown error has occurred. Please try again.`),
  [JumioAuthoriseErrorCode.VerificationStep]: (t) => t(`Sorry, an unknown error has occurred. Please try again.`),
  [JumioAuthoriseErrorCode.TransmittingImageError]: (t) => t(`There is no internet connection. Please connect to an available network and try again.`),
  [JumioAuthoriseErrorCode.NoNetworkConnection]: (t) => t(`Sorry, something went wrong. Please try again later.`),
  [JumioAuthoriseErrorCode.UnexpectedErrorInClient]: (t) => t(`Sorry, something went wrong. Please try again later.`),
  [JumioAuthoriseErrorCode.ProblemWhileCommunicateWithServer]: (t) => t(`Sorry, something went wrong. Please try again later.`),
  [JumioAuthoriseErrorCode.FileUploadOrCameraPermission]: (t) =>
    t(`File upload is not enabled on your device. Please enable file upload in your settings and try again.`),
  [JumioAuthoriseErrorCode.FaceCaptureFailed]: (t) => t(`Sorry, something went wrong with the face capture process. Please try again.`),
  [JumioAuthoriseErrorCode.BrowserNotSupportCamera]: (t) =>
    t(
      `Your camera is not supported by your browser. Please make sure your browser has permission to use your camera and try again or try again using a different browser.`
    ),
  [JumioAuthoriseErrorCode.NoAcceptableSubmissionIn3Attempts]: (t) => t(`Your submission has not been accepted.`)
};

const getAuthoriseErrorCodeEnum = (errorCode: string) =>
  Object.values<string>(JumioAuthoriseErrorCode).includes(errorCode) ? (errorCode as JumioAuthoriseErrorCode) : JumioAuthoriseErrorCode.UnexpectedErrorInClient;

export const getAuthoriseErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = getAuthoriseErrorCodeEnum(errorCode);
  return JUMIO_ERROR_MESSAGES[errorCodeEnum](t);
};
