import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormGroup,
  Text,
  breakpoint,
  VerificationCode,
  Size,
  ButtonLink,
  CircularIcon,
  IconNames,
  Variant
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { forgotPassword } from '../../Store/Actions';
import { IStore } from '../../Store/Store';
import { ApiRequest, ApiRequestStatus } from '../../models/apiRequestResult';

interface IVerifyCodeSectionProps {
  username: string;
  setVerificationCode: (code: string) => void;
}

const VerificationCodeWrapper = styled(VerificationCode)`
  margin-top: 0.5rem;

  input {
    width: 3rem;
    height: 3rem;
  }

  @media (max-width: ${breakpoint.mobile}) {
    gap: 0.5rem;
  }
`;

const StepHeading = styled(Text)`
  margin-bottom: 1rem;
`;

const LinkButton = styled(ButtonLink)`
  padding: 0;
`;

const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const ResendGroupRow = styled(GroupRow)`
  margin-top: 0.75rem;
`;

const CheckIcon = styled(CircularIcon)`
  min-width: 1rem;
  min-height: 1rem;
`;

const AlertIcon = styled(CircularIcon)`
  padding: 0;
`;

const VerificationCheckIcon = styled(CheckIcon)`
  margin-top: 0.5rem;
`;

const ResendStatusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
`;

export const VerifyCodeSection: React.FC<IVerifyCodeSectionProps> = ({ username, setVerificationCode }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status: forgotPasswordStatus } = useSelector((store: IStore) => store.local.apiResults[ApiRequest.ForgotPassword]);

  const { status: signInStatus } = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignIn]);
  const [resetAll, setResetAll] = useState(false);

  const onResendCode = () => {
    setResetAll(true);
    dispatch(forgotPassword({ username }));
  };

  useEffect(() => {
    if (forgotPasswordStatus === ApiRequestStatus.InProgress) {
      setResetAll(false);
    }
  }, [forgotPasswordStatus]);

  return (
    <>
      <FormGroup>
        <StepHeading size={Size.Large}>
          <Trans t={t}>
            <b>STEP 1</b>
          </Trans>
        </StepHeading>
        <Text size={Size.Large}>
          <Trans t={t}>
            Check your email and enter <b>6 digit code</b> we sent you.
          </Trans>
        </Text>
      </FormGroup>
      <FormGroup>
        <Text size={Size.Large}>
          <Trans t={t}>
            <b>6 digit code</b>
          </Trans>
        </Text>
        <GroupRow>
          <VerificationCodeWrapper type='number' onChange={(code: string) => setVerificationCode(code)} resetAll={resetAll} />
          {signInStatus === ApiRequestStatus.Success && <VerificationCheckIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />}
        </GroupRow>
        <ResendGroupRow>
          <LinkButton type='button' onClick={onResendCode}>
            {t('Resend code')}
          </LinkButton>
          {forgotPasswordStatus === ApiRequestStatus.Failed && (
            <ResendStatusRow>
              <AlertIcon name={IconNames.AlertCircle} size={Size.Large} variant={Variant.Error} />
              <Text size={Size.Large} variant={Variant.Error}>
                <Trans t={t}>Something went wrong!</Trans>
              </Text>
            </ResendStatusRow>
          )}
          {forgotPasswordStatus === ApiRequestStatus.Success && (
            <ResendStatusRow>
              <CheckIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />
              <Text size={Size.Large}>
                <Trans t={t}>New code has been sent!</Trans>
              </Text>
            </ResendStatusRow>
          )}
        </ResendGroupRow>
      </FormGroup>
    </>
  );
};
