import { COUNTRY_CODE_SG, COUNTRY_CODE_MY, COUNTRY_CODE_PH, COUNTRY_CODE_TH, COUNTRY_CODE_TW, COUNTRY_CODE_VN } from './sgp/country';

export enum InternationalDiallingCodes {
  Australia = '+61',
  Singapore = '+65',
  Thailand = '+66',
  Vietnam = '+84',
  Philippines = '+63',
  Taiwan = '+886',
  Malaysia = '+60'
}

export const INTERNATIONAL_DIALLING_CODES: Record<string, InternationalDiallingCodes> = {
  [COUNTRY_CODE_SG]: InternationalDiallingCodes.Singapore,
  [COUNTRY_CODE_MY]: InternationalDiallingCodes.Malaysia,
  [COUNTRY_CODE_PH]: InternationalDiallingCodes.Philippines,
  [COUNTRY_CODE_TH]: InternationalDiallingCodes.Thailand,
  [COUNTRY_CODE_TW]: InternationalDiallingCodes.Taiwan,
  [COUNTRY_CODE_VN]: InternationalDiallingCodes.Vietnam
};
