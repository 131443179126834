import { TFunction } from 'i18next';
import { Translated } from '../../translation';

// https://api.singpass.gov.sg/library/myinfo/developers/resources-error-codes
export enum AuthoriseErrorCode {
  UserNotGiveConsent = 'access_denied',
  UnderMaintenance = '503',
  Unknown = '500'
}

const NextStepMessages: Record<AuthoriseErrorCode, Translated> = {
  [AuthoriseErrorCode.UserNotGiveConsent]: (t) =>
    t(`You can try again and consent to transferring your data or you can continue your application and verify your identity using another provider.`),
  [AuthoriseErrorCode.UnderMaintenance]: (t) => t(`You can continue your application and verify your identity using another provider.`),
  [AuthoriseErrorCode.Unknown]: (t) => t(`You can try again or continue your application and verify your identity using another provider.`)
};

const AuthoriseErrorMessages: Record<AuthoriseErrorCode, Translated> = {
  [AuthoriseErrorCode.UserNotGiveConsent]: (t) =>
    t(`Sorry! We were unable to retrieve your personal information as you haven't consented to transfer your data from Myinfo.`),
  [AuthoriseErrorCode.UnderMaintenance]: (t) =>
    t(`Sorry! We were unable to retrieve your personal information as the Myinfo service is currently unavailable.`),
  [AuthoriseErrorCode.Unknown]: (t) => t(`Sorry! We were unable to retrieve your personal information due to an unknown error with the Myinfo service.`)
};

const getAuthoriseErrorCodeEnum = (errorCode: string) =>
  Object.values<string>(AuthoriseErrorCode).includes(errorCode) ? (errorCode as AuthoriseErrorCode) : AuthoriseErrorCode.Unknown;

export const getAuthoriseErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = getAuthoriseErrorCodeEnum(errorCode);
  return AuthoriseErrorMessages[errorCodeEnum](t);
};

export const getNextStepMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = getAuthoriseErrorCodeEnum(errorCode);
  return NextStepMessages[errorCodeEnum](t);
};
