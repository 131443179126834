import { TradingAccountApi } from '@cmctechnology/phoenix-stockbroking-api-client';
import { createConfig } from '../configuration/configuration';
import { createApiConfig, createAxios, Interceptor } from './axiosFactory';

export const createTradingAccountApi = (interceptor?: Interceptor) => {
  const { apiUrl } = createConfig();
  const usersApi = new TradingAccountApi(createApiConfig(), apiUrl, createAxios(interceptor));

  return usersApi;
};
