import { breakpoint } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: ${breakpoint.tablet}) {
    flex-direction: column;
    height: auto;
    width: auto;
  }
`;
export const CardWrapper = styled.div`
  margin: 1.5rem;
  @media only screen and (max-width: 90.5rem) {
    display: flex;
    justify-content: center;
  }

  @media only screen and (max-width: ${breakpoint.mobileSmall}) {
    width: 100%;
    margin-bottom: 0;
  }
`;

export const PodWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem;
`;

export const ImagePod = styled.img`
  max-height: 35rem;
  width: 100%;
`;
