import {
  CardContent,
  CardHeader,
  CardLarge,
  CardSeparator,
  Form,
  FormGroup,
  Heading1,
  Select,
  Size,
  ValidationErrorMessage,
  Text,
  ISelectOption,
  useTestId,
  FormLabel
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { GoBackIcon } from '../../Components/GoBackIcon';
import { ProfileCardFooter } from '../../Components/ProfileCardFooter';
import { SubText } from '../../Components/Subheader/SubText';
import { TooltipLabelAndText } from '../../Components/TooltipLableAndText';
import { ANNUAL_INCOME, PURPOSE_OF_ACCOUNT, VALUE_OF_SAVINGS } from '../../constants/sgp/financialBackground';
import { useFinancialBackgroundValidators } from '../../hooks/sgp/validators/useFinancialBackgroundValidators';
import { INextPage, IPreviousPage } from '../../models/IPageNavigation';
import { ISgpFinancialBackground } from '../../models/sgp/profile';
import { saveFinancialBackground } from '../../Store/Actions';
import { IStore } from '../../Store/Store';
import { sortMoneyRange } from '../../utils/moneyUtils';

export interface IFinancialBackgroundProps extends INextPage, Partial<IPreviousPage> {}

export const FinancialBackground: React.FC<IFinancialBackgroundProps> = ({ onNext, onPrevious, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `sg.financialBackground`);
  const dispatch = useDispatch();

  const financialBackground = useSelector((store: IStore) => store.remote.sgp.profile.financialBackground);

  const annualIncomeRef = useRef<HTMLInputElement | null>(null);
  const valueOfSavingsRef = useRef<HTMLInputElement | null>(null);

  const { annualIncomeValidator, valueOfSavingsValidator, purposeOfAccountValidator } = useFinancialBackgroundValidators(financialBackground);

  const annualIncomeOptions = createKeyValueTranslatedToOptionList(ANNUAL_INCOME, t, sortMoneyRange);
  const valueOfSavingsOptions = createKeyValueTranslatedToOptionList(VALUE_OF_SAVINGS, t, sortMoneyRange);
  const purposeOfAccountOptions = createKeyValueTranslatedToOptionList(PURPOSE_OF_ACCOUNT, t, sortMoneyRange);

  const onNextClicked = () => {
    const updatedFinancialBackground: ISgpFinancialBackground = {
      annualIncome: annualIncomeValidator.value?.value,
      valueOfSavingsInvestments: valueOfSavingsValidator.value?.value,
      purposeOfAccount: purposeOfAccountValidator.value?.value
    };

    dispatch(saveFinancialBackground(updatedFinancialBackground));

    onNext();
  };

  return (
    <Form>
      <CardLarge>
        <CardHeader>
          {onPrevious && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Financial Background')}</Heading1>
          <SubText size={Size.Large}>{t('For regulatory purposes we require some information about your financial background')}</SubText>
        </CardHeader>
        <CardSeparator />
        <CardContent>
          <FormGroup ref={annualIncomeRef} {...generateTestId()}>
            <TooltipLabelAndText
              label={t('Annual income')}
              text={t('If required, please convert to Singapore Dollars before making your selection')}
              parentRef={annualIncomeRef}
              {...generateTestId(`annualIncome.tooltip`)}
            />
            <Text>{t('Select value in Singapore Dollars (SGD)')}</Text>
            <Select
              value={annualIncomeValidator.value}
              options={annualIncomeOptions}
              onChange={(option) => annualIncomeValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Select annual income value (SGD)')}
              isSearchable
              isClearable
              invalid={annualIncomeValidator.invalid}
              {...generateTestId(`annualIncome.select`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={annualIncomeValidator} />
          </FormGroup>
          <FormGroup ref={valueOfSavingsRef}>
            <TooltipLabelAndText
              label={t('Value of savings and investments')}
              text={t(`If required, please convert to Singapore Dollars before making your selection`)}
              parentRef={valueOfSavingsRef}
              {...generateTestId(`valueOfSavings.tooltip`)}
            />
            <Text>{t('Select value in Singapore Dollars (SGD)')}</Text>
            <Select
              value={valueOfSavingsValidator.value}
              options={valueOfSavingsOptions}
              onChange={(option) => valueOfSavingsValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Select value of savings and investments (SGD)')}
              isSearchable
              isClearable
              invalid={valueOfSavingsValidator.invalid}
              {...generateTestId(`valueOfSavings.select`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={valueOfSavingsValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Purpose of account')}</FormLabel>
            <Select
              value={purposeOfAccountValidator.value}
              options={purposeOfAccountOptions}
              onChange={(option) => purposeOfAccountValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Select purpose of account')}
              isSearchable
              isClearable
              invalid={purposeOfAccountValidator.invalid}
              {...generateTestId(`purposeOfAccount.select`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={purposeOfAccountValidator} />
          </FormGroup>
        </CardContent>
        <ProfileCardFooter
          nextButtonLabel={t('Confirm')}
          saveButtonLabel={t('Confirm')}
          onNext={onNextClicked}
          onCancel={onNext}
          validators={[annualIncomeValidator, valueOfSavingsValidator, purposeOfAccountValidator]}
          {...generateTestId(`confirm`)}
        />
      </CardLarge>
    </Form>
  );
};
