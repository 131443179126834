import React from 'react';
import { useTranslation } from 'react-i18next';
import google_bg from '../../Assets/google-bg.svg';
import { FundInterceptCard } from './FundInterceptCard';
import { INextPage } from '../../models/IPageNavigation';
import { CardWrapper, ContentWrapper, ImagePod, PodWrapper } from '../PageWithPod/PageWithPodContent';

interface IFundInterceptScreenProps extends INextPage {}

export const FundInterceptScreen: React.FC<IFundInterceptScreenProps> = ({ onNext }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <CardWrapper>
        <FundInterceptCard onNext={onNext} />
      </CardWrapper>
      <PodWrapper>
        <ImagePod src={google_bg} alt={t('google background')} />
      </PodWrapper>
    </ContentWrapper>
  );
};
