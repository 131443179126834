import { IdentificationType } from '../models/identification';
import { ReviewSection } from '../models/reviewAndSubmit';
import { IValidatorMessage, IPatternValidator } from '../models/validation';

export const DRIVERS_LICENCE_NUMBER_REGEX_RULES: Record<string, IPatternValidator> = {
  '': { pattern: /.*/, message: (t) => t('NaN') },
  ACT: { pattern: /^\d{1,10}$/, message: (t) => t('format: 1 - 10 digits') },
  NSW: { pattern: /^[\dA-Za-z]{6,8}$/, message: (t) => t('format: 6 - 8 digits or alphabetical characters') },
  NT: { pattern: /^\d{1,10}$/, message: (t) => t('format: 1 - 10 digits') },
  QLD: { pattern: /^\d{8,9}$/, message: (t) => t('format: 8 - 9 digits') },
  SA: { pattern: /^[\dA-Za-z]{6}$/, message: (t) => t('format: 6 digits or alphabetical characters') },
  TAS: { pattern: /^[\dA-Za-z]{6,8}$/, message: (t) => t('format: 6 - 8 digits or alphabetical characters') },
  VIC: { pattern: /^\d{1,10}$/, message: (t) => t('format: 1 - 10 digits') },
  WA: { pattern: /^\d{7}$/, message: (t) => t('format: 7 digits') }
};

export const DRIVERS_LICENCE_CARD_NUMBER_REGEX_RULES: Record<string, IPatternValidator> = {
  '': { pattern: /.*/, message: (t) => t('NaN') },
  ACT: { pattern: /^[\dA-Za-z]{10}$/, message: (t) => t('format: 10 digits or alphabetical characters') },
  NSW: { pattern: /^\d{10}$/, message: (t) => t('format: 10 digits') },
  NT: { pattern: /^\d{6,8}$/, message: (t) => t('format: 6 - 8 digits') },
  QLD: { pattern: /^[\dA-Za-z]{10}$/, message: (t) => t('format: 10 digits or alphabetical characters') },
  SA: { pattern: /^[\dA-Za-z]{9}$/, message: (t) => t('format: 9 digits or alphabetical characters') },
  TAS: { pattern: /^[\dA-Za-z]{9}$/, message: (t) => t('format: 9 digits or alphabetical characters') },
  VIC: { pattern: /^[\dA-Za-z]{8}$/, message: (t) => t('format: 8 digits or alphabetical characters') },
  WA: { pattern: /^[\dA-Za-z]{8,10}$/, message: (t) => t('format: 8 - 10 digits or alphabetical characters') }
};

export const FIELD_REGEX_RULES: Record<string, IPatternValidator> = {
  MedicareCardNumber: { pattern: /^\d{10}$/, message: (t) => t('format: 10 digits') },
  MedicareReferenceNumber: { pattern: /^[1-9]$/, message: (t) => t('format: 1 digit between 1 and 9') },
  AustralianPassportNumber: { pattern: /^[\dA-Za-z]{7,9}$/, message: (t) => t('format: 7 - 9 digits or alphabetical characters') },
  InternationalPassportNumber: { pattern: /^[\dA-Za-z]{1,14}$/, message: (t) => t('format: 1 - 14 digits or alphabetical characters') }
};

export const FIELD_VALIDATION_MESSAGES: Record<string, IValidatorMessage> = {
  Tfn: { message: (t) => t('Your TFN is invalid, please enter a valid TFN') },
  MedicareInvalid: { message: (t) => t('Your Medicare card expire date is invalid, please enter a valid date') },
  MedicareWithin2Months: { message: (t) => t('Your Medicare card will expire within 2 months, please use replacement card or select a different form of ID') },
  MedicareExpired: { message: (t) => t('Your Medicare card has expired, please use replacement card or select a different form of ID') }
};

export const DOB_VALIDATION_MESSAGES: Record<string, IValidatorMessage> = {
  Over18Years: { message: (t) => t('You must be over 18 to apply for an individual account') },
  InvalidDob: { message: (t) => t('Please enter a valid date of birth') }
};

export const CONFIRM_DOB_VALIDATION_MESSAGES: Record<string, IValidatorMessage> = {
  NotMatch: { message: (t) => t('Date of birth does not match. Please re-enter.') }
};

export const IdentificationReviewSection = {
  [IdentificationType.DriversLicence]: ReviewSection.IdentificationDriversLicence,
  [IdentificationType.Passport]: ReviewSection.IdentificationPassport,
  [IdentificationType.Medicare]: ReviewSection.IdentificationMedicare,
  [IdentificationType.None]: ReviewSection.IdentificationMedicare
};
