import {
  CheckBox,
  FormGroup,
  FormRow,
  ISelectOption,
  NumericInput,
  Select,
  Size,
  ValidationErrorMessage,
  Validators,
  useValidator,
  Text,
  Button,
  FormCol,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { createCountriesOptionList, createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { COUNTRIES_ALL } from '../../constants/countryConstants';
import { MinusIcon } from '../MinusIcon';
import { IQuestionProps } from '../../models/pepQuestions';
import { preventScrolling } from '../../events/onFocus';

export const InlineButton = styled(Button)`
  display: inline;
  margin-top: 2em;
  margin-right: 2em;
  max-width: 13em;
`;

export const PEPQuestion2: React.FC<IQuestionProps> = ({ selected, setSelected, validators, setValidators, answers, setAnswers, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `pepQ2`);
  const [q2AdditionalCountry1, setQ2AdditionalCountry1] = useState<boolean>(false);
  const [q2AdditionalCountry2, setQ2AdditionalCountry2] = useState<boolean>(false);

  const countriesSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t).filter((c) => c.label !== 'Australia');

  const q2CountryValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q2DurationYearValidator = useValidator<string>('', Validators.required(t('Required')));
  const q2DurationMonthsValidator = useValidator<string>('', Validators.required(t('Required')));
  const q2AdditionalCountryValidator1 = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q2AdditionalDurationYearValidator1 = useValidator<string>('', Validators.required(t('Required')));
  const q2AdditionalDurationMonthsValidator1 = useValidator<string>('', Validators.required(t('Required')));
  const q2AdditionalCountryValidator2 = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q2AdditionalDurationYearValidator2 = useValidator<string>('', Validators.required(t('Required')));
  const q2AdditionalDurationMonthsValidator2 = useValidator<string>('', Validators.required(t('Required')));

  useEffect(() => {
    const validatorsToAdd = [q2CountryValidator, q2DurationYearValidator, q2DurationMonthsValidator];
    if (q2AdditionalCountry1) validatorsToAdd.push(q2AdditionalCountryValidator1, q2AdditionalDurationYearValidator1, q2AdditionalDurationMonthsValidator1);
    if (q2AdditionalCountry2) validatorsToAdd.push(q2AdditionalCountryValidator2, q2AdditionalDurationYearValidator2, q2AdditionalDurationMonthsValidator2);

    if (selected) setValidators([...validatorsToAdd]);
    else setValidators([]);

    setAnswers({
      ...answers,
      question2: {
        answered: selected ?? false,
        country: q2CountryValidator.value?.value,
        durationYears: q2DurationYearValidator.value,
        durationMonths: q2DurationMonthsValidator.value,
        country2: q2AdditionalCountryValidator1.value?.value,
        durationYears2: q2AdditionalDurationYearValidator1.value,
        durationMonths2: q2AdditionalDurationMonthsValidator1.value,
        country3: q2AdditionalCountryValidator2.value?.value,
        durationYears3: q2AdditionalDurationYearValidator2.value,
        durationMonths3: q2AdditionalDurationMonthsValidator2.value
      }
    });
  }, [
    selected,
    q2AdditionalCountry1,
    q2AdditionalCountry2,
    q2CountryValidator.value,
    q2DurationYearValidator.value,
    q2DurationMonthsValidator.value,
    q2AdditionalCountryValidator1.value,
    q2AdditionalDurationYearValidator1.value,
    q2AdditionalDurationMonthsValidator1.value,
    q2AdditionalCountryValidator2.value,
    q2AdditionalDurationYearValidator2.value,
    q2AdditionalDurationMonthsValidator2.value
  ]);

  const addAdditionalCountry = () => {
    if (!q2AdditionalCountry1) {
      setQ2AdditionalCountry1(true);
      setValidators([...validators, q2AdditionalCountryValidator1, q2AdditionalDurationYearValidator1, q2AdditionalDurationMonthsValidator1]);
    } else {
      setQ2AdditionalCountry2(true);
      setValidators([...validators, q2AdditionalCountryValidator2, q2AdditionalDurationYearValidator2, q2AdditionalDurationMonthsValidator2]);
    }
  };

  return (
    <FormGroup>
      <Text>{t('Do you currently or have you previously held citizenship of another country (excluding Australia)?')}</Text>
      <FormGroup>
        <FormRow>
          <CheckBox
            label={t('Yes')}
            checked={selected}
            onChange={(e) => {
              setSelected(e.target.checked);
            }}
            disabled={selected}
            {...generateTestId(`yes`)}
          />
          <CheckBox
            label={t('No')}
            checked={selected === false}
            onChange={(e) => {
              setSelected(!e.target.checked);
            }}
            disabled={selected === false}
            {...generateTestId(`no`)}
          />
        </FormRow>
      </FormGroup>

      {selected && (
        <FormGroup>
          <Text>{t('Please provide the country and duration of your citizenship.')}</Text>
          <FormGroup {...generateTestId(`countryInput`)} style={{ marginTop: '0' }}>
            <Select
              value={q2CountryValidator.value}
              options={createCountriesOptionList(countriesSelectOptions, t)}
              placeholder={t('Select country')}
              onChange={(option) => {
                q2CountryValidator.handleEvent(option as ISelectOption);
              }}
              invalid={q2CountryValidator.invalid}
              isClearable
            />
            <ValidationErrorMessage size={Size.Medium} validator={q2CountryValidator} />
          </FormGroup>
          <NumericInput
            value={q2DurationYearValidator.value}
            onChange={(e) => {
              q2DurationYearValidator.handleEvent(e.target.value);
            }}
            placeholder={t('Years')}
            invalid={q2DurationYearValidator.invalid}
            onFocus={(e) => preventScrolling(e)}
            {...generateTestId(`yearInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q2DurationYearValidator} />
          <NumericInput
            value={q2DurationMonthsValidator.value}
            onChange={(e) => {
              q2DurationMonthsValidator.handleEvent(e.target.value);
            }}
            placeholder={t('Months')}
            invalid={q2DurationMonthsValidator.invalid}
            onFocus={(e) => preventScrolling(e)}
            {...generateTestId(`monthInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q2DurationMonthsValidator} />
          <InlineButton
            label={t('Add another Citizenship')}
            onClick={() => addAdditionalCountry()}
            size={Size.Medium}
            disabled={q2AdditionalCountry1 && q2AdditionalCountry2}
            {...generateTestId(`addCitizenshipButton`)}
          />

          {q2AdditionalCountry1 && (
            <FormGroup>
              <Text>{t('Please provide the country and duration of your citizenship.')}</Text>
              <FormRow>
                <FormCol percentWidth={95}>
                  <FormGroup {...generateTestId(`countryInput2`)}>
                    <Select
                      value={q2AdditionalCountryValidator1.value}
                      options={createCountriesOptionList(countriesSelectOptions, t)}
                      placeholder={t('Select country')}
                      onChange={(option) => {
                        q2AdditionalCountryValidator1.handleEvent(option as ISelectOption);
                      }}
                      invalid={q2AdditionalCountryValidator1.invalid}
                      isClearable
                    />
                  </FormGroup>
                </FormCol>
                <MinusIcon
                  onClick={() => {
                    setQ2AdditionalCountry1(false);
                  }}
                  {...generateTestId(`removeCountryButton`)}
                />
              </FormRow>
              <ValidationErrorMessage size={Size.Medium} validator={q2AdditionalCountryValidator1} />
              <NumericInput
                value={q2AdditionalDurationYearValidator1.value}
                onChange={(e) => {
                  q2AdditionalDurationYearValidator1.handleEvent(e.target.value);
                }}
                placeholder={t('Years')}
                invalid={q2AdditionalDurationYearValidator1.invalid}
                onFocus={(e) => preventScrolling(e)}
                {...generateTestId(`yearInput2`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={q2AdditionalDurationYearValidator1} />
              <NumericInput
                value={q2AdditionalDurationMonthsValidator1.value}
                onChange={(e) => {
                  q2AdditionalDurationMonthsValidator1.handleEvent(e.target.value);
                }}
                placeholder={t('Months')}
                invalid={q2AdditionalDurationMonthsValidator1.invalid}
                onFocus={(e) => preventScrolling(e)}
                {...generateTestId(`monthInput2`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={q2AdditionalDurationMonthsValidator1} />
            </FormGroup>
          )}

          {q2AdditionalCountry2 && (
            <FormGroup>
              <Text>{t('Please provide the country and duration of your citizenship.')}</Text>
              <FormRow>
                <FormCol percentWidth={95}>
                  <FormGroup {...generateTestId(`countryInput3`)}>
                    <Select
                      value={q2AdditionalCountryValidator2.value}
                      options={createCountriesOptionList(countriesSelectOptions, t)}
                      placeholder={t('Select country')}
                      onChange={(option) => {
                        q2AdditionalCountryValidator2.handleEvent(option as ISelectOption);
                      }}
                      invalid={q2AdditionalCountryValidator2.invalid}
                      isClearable
                    />
                  </FormGroup>
                </FormCol>
                <MinusIcon
                  onClick={() => {
                    setQ2AdditionalCountry2(false);
                  }}
                  {...generateTestId(`removeCountryButton2`)}
                />
              </FormRow>
              <ValidationErrorMessage size={Size.Medium} validator={q2AdditionalCountryValidator2} />
              <NumericInput
                value={q2AdditionalDurationYearValidator2.value}
                onChange={(e) => {
                  q2AdditionalDurationYearValidator2.handleEvent(e.target.value, !q2AdditionalDurationYearValidator2.validated);
                }}
                placeholder={t('Years')}
                invalid={q2AdditionalDurationYearValidator2.invalid}
                onFocus={(e) => preventScrolling(e)}
                {...generateTestId(`yearInput3`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={q2AdditionalDurationYearValidator2} />
              <NumericInput
                value={q2AdditionalDurationMonthsValidator2.value}
                onChange={(e) => {
                  q2AdditionalDurationMonthsValidator2.handleEvent(e.target.value, !q2AdditionalDurationMonthsValidator2.validated);
                }}
                placeholder={t('Months')}
                invalid={q2AdditionalDurationMonthsValidator2.invalid}
                onFocus={(e) => preventScrolling(e)}
                {...generateTestId(`monthInput3`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={q2AdditionalDurationMonthsValidator2} />
            </FormGroup>
          )}
        </FormGroup>
      )}
    </FormGroup>
  );
};
