import {
  CheckBox,
  FormControl,
  FormGroup,
  FormRow,
  ISelectOption,
  Select,
  Size,
  ValidationErrorMessage,
  Validators,
  useValidator,
  Text,
  FormCol,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { IQuestionProps } from '../../models/pepQuestions';
import { MONTHS_DISPLAY_WITH_NUMBERS, generateYearsDisplayWithNumbers } from '../../constants/commonConstants';

export const PEPQuestion4: React.FC<IQuestionProps> = ({ selected, setSelected, setValidators, answers, setAnswers, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `pepQ4`);

  const monthsSelectOptions = createKeyValueTranslatedToOptionList(MONTHS_DISPLAY_WITH_NUMBERS, t);
  const yearsSelectOptions = generateYearsDisplayWithNumbers();

  const q4PositionValidator = useValidator<string>('', Validators.required(t('Required')));
  const q4FromMonthValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q4FromYearValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q4ToMonthValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q4ToYearValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));

  useEffect(() => {
    if (selected) setValidators([q4PositionValidator, q4FromMonthValidator, q4FromYearValidator, q4ToMonthValidator, q4ToYearValidator]);
    else setValidators([]);

    setAnswers({
      ...answers,
      question4: {
        answered: selected ?? false,
        position: q4PositionValidator.value,
        fromMonth: q4FromMonthValidator.value?.value,
        fromYear: q4FromYearValidator.value?.value,
        toMonth: q4ToMonthValidator.value?.value,
        toYear: q4ToYearValidator.value?.value
      }
    });
  }, [selected, q4PositionValidator.value, q4FromMonthValidator.value, q4FromYearValidator.value, q4ToMonthValidator.value, q4ToYearValidator.value]);

  return (
    <FormGroup>
      <Text>
        {t(
          'Do you or a close family member currently hold or have previously held a senior government position or a senior executive position within an international or domestic organization?'
        )}
      </Text>
      <FormGroup>
        <FormRow>
          <CheckBox
            label={t('Yes')}
            checked={selected}
            onChange={(e) => {
              setSelected(e.target.checked);
            }}
            disabled={selected}
            {...generateTestId(`yes`)}
          />
          <CheckBox
            label={t('No')}
            checked={selected === false}
            onChange={(e) => {
              setSelected(!e.target.checked);
            }}
            disabled={selected === false}
            {...generateTestId(`no`)}
          />
        </FormRow>
      </FormGroup>

      {selected && (
        <FormGroup>
          <Text>{t('Please provide details of the position and duration held.')}</Text>
          <FormControl
            type='text'
            name='positionName'
            placeholder={t('Enter the position')}
            value={q4PositionValidator.value}
            onChange={(e) => {
              q4PositionValidator.handleEvent(e.target.value);
            }}
            invalid={q4PositionValidator.invalid}
            {...generateTestId(`positionInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q4PositionValidator} />
          <Text>{t('FROM')}</Text>
          <FormRow>
            <FormCol percentWidth={50}>
              <FormGroup {...generateTestId(`fromMonthInput`)} style={{ marginTop: '0' }}>
                <Select
                  value={q4FromMonthValidator.value}
                  options={monthsSelectOptions}
                  placeholder={t('Select month')}
                  onChange={(option) => {
                    q4FromMonthValidator.handleEvent(option as ISelectOption);
                  }}
                  invalid={q4FromMonthValidator.invalid}
                  isClearable
                />
                <ValidationErrorMessage size={Size.Medium} validator={q4FromMonthValidator} />
              </FormGroup>
            </FormCol>
            <FormCol percentWidth={50}>
              <FormGroup {...generateTestId(`fromYearInput`)} style={{ marginTop: '0' }}>
                <Select
                  value={q4FromYearValidator.value}
                  options={yearsSelectOptions}
                  placeholder={t('Select year')}
                  onChange={(option) => {
                    q4FromYearValidator.handleEvent(option as ISelectOption);
                  }}
                  invalid={q4FromYearValidator.invalid}
                  isClearable
                />
                <ValidationErrorMessage size={Size.Medium} validator={q4FromYearValidator} />
              </FormGroup>
            </FormCol>
          </FormRow>
          <Text>{t('TO')}</Text>
          <FormRow>
            <FormCol percentWidth={50}>
              <FormGroup {...generateTestId(`toMonthInput`)} style={{ marginTop: '0' }}>
                <Select
                  value={q4ToMonthValidator.value}
                  options={monthsSelectOptions}
                  placeholder={t('Select month')}
                  onChange={(option) => {
                    q4ToMonthValidator.handleEvent(option as ISelectOption);
                  }}
                  invalid={q4ToMonthValidator.invalid}
                  isClearable
                />
                <ValidationErrorMessage size={Size.Medium} validator={q4ToMonthValidator} />
              </FormGroup>
            </FormCol>
            <FormCol percentWidth={50}>
              <FormGroup {...generateTestId(`toYearInput`)} style={{ marginTop: '0' }}>
                <Select
                  value={q4ToYearValidator.value}
                  options={yearsSelectOptions}
                  placeholder={t('Select year')}
                  onChange={(option) => {
                    q4ToYearValidator.handleEvent(option as ISelectOption);
                  }}
                  invalid={q4ToYearValidator.invalid}
                  isClearable
                />
                <ValidationErrorMessage size={Size.Medium} validator={q4ToYearValidator} />
              </FormGroup>
            </FormCol>
          </FormRow>
        </FormGroup>
      )}
    </FormGroup>
  );
};
