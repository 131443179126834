import React, { useState } from 'react';
import styled from 'styled-components';
import { IPageNavigation } from '../../models/IPageNavigation';
import { ForgotPasswordCard } from './ForgotPasswordCard';
import { ForgotPasswordSubmitCard } from './ForgotPasswordSubmitCard';

interface IForgotPasswordScreenProps extends IPageNavigation {}

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ForgotPasswordScreen: React.FC<IForgotPasswordScreenProps> = ({ onNext, onPrevious }): JSX.Element => {
  const [sentUser, setSentUser] = useState(``);

  return (
    <Wrapper>
      {sentUser && <ForgotPasswordSubmitCard username={sentUser} onNext={onNext} onPrevious={() => setSentUser(``)} />}
      {!sentUser && <ForgotPasswordCard onNext={(email: string) => setSentUser(email)} onPrevious={onPrevious!} />}
    </Wrapper>
  );
};
