import {
  CardContent,
  CardFooter,
  CardLarge,
  CardSeparator,
  CheckBox,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Heading1,
  ISelectOption,
  Select,
  Size,
  Text,
  useTestId,
  ValidationErrorMessage
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { CardHeaderWithText } from '../../Components/CardHeaderWithText';
import { CenteredButton } from '../../Components/CenteredButton';
import { FormGroupWithMoreBottomMargin } from '../../Components/FormGroupWithMoreBottomMargin';
import { GoBackIcon } from '../../Components/GoBackIcon';
import { useTaxValidators } from '../../hooks/sgp/validators/useTaxValidators';
import { INextPage, IPreviousPage } from '../../models/IPageNavigation';
import { COUNTRIES_ALL } from '../../models/sgp/country';
import { ISgpTaxInformation } from '../../models/sgp/profile';
import { saveTaxInformation } from '../../Store/Actions';
import { IStore } from '../../Store/Store';
import { hasValidValue } from '../../utils/stringUtils';

interface IProfileTaxDetailsProps extends INextPage, Partial<IPreviousPage> {}

export const ProfileTaxDetails: React.FC<IProfileTaxDetailsProps> = ({ onNext, onPrevious, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `sg.taxInfo`);
  const dispatch = useDispatch();

  const personalDetails = useSelector((store: IStore) => store.remote.sgp.profile.personalDetails);
  const taxInformation = useSelector((store: IStore) => store.remote.sgp.profile.taxInformation);

  const countriesSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t);
  const countryOfResidence = countriesSelectOptions.find((c) => c.value === personalDetails.countryOfResidence);
  const countriesWithoutResidentialSelectOptions = countriesSelectOptions.filter((c) => c.value !== personalDetails.countryOfResidence);

  const { tinValidator, isOtherCountryResidentValidator, otherTinValidator, otherCountryResidentValidator } = useTaxValidators(personalDetails, taxInformation);

  const onConfirm = () => {
    const updatedTaxInformation: ISgpTaxInformation = {
      primaryTaxNumber: tinValidator.value,
      primaryCountryCode: countryOfResidence?.value,
      isTaxResidentOtherCountry: isOtherCountryResidentValidator.value,
      otherCountryCode: otherCountryResidentValidator.value?.value,
      otherTaxNumber: otherTinValidator.value
    };

    dispatch(saveTaxInformation(updatedTaxInformation));

    onNext();
  };

  return (
    <Form>
      <CardLarge>
        <CardHeaderWithText textWithSmallMargin>
          {onPrevious && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Tax Information')}</Heading1>

          <Text textAlign='center' size={Size.Large}>
            {t('We require some information about your citizenship and tax residency to comply with international tax regulations')}
          </Text>
        </CardHeaderWithText>

        <CardSeparator />
        <CardContent>
          <FormGroup>
            <FormLabel>{t('Country')}</FormLabel>
            <FormControl type='text' value={countryOfResidence?.label} name='country' disabled {...generateTestId(`country.input`)} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Tax Identification Number')}</FormLabel>
            <FormControl
              autoFocus
              type='text'
              name='tfn'
              placeholder={t('Enter your Tax Identification Number')}
              value={tinValidator.value}
              onChange={(e) => {
                tinValidator.handleEvent(e.target.value, !tinValidator.validated);
              }}
              onBlur={(e) => {
                tinValidator.handleEvent(e.target.value.trim());
              }}
              disabled={hasValidValue(personalDetails.nric) || hasValidValue(personalDetails.fin)}
              invalid={tinValidator.invalid}
              {...generateTestId(`tin.input`)}
            />

            <ValidationErrorMessage size={Size.Medium} validator={tinValidator} />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('Are you a tax resident in any other country?')}</FormLabel>
            <Text>{t('If you are a US citizen or resident you must include United States of America as one of your countries of tax residency.')}</Text>
            <FormGroup>
              <CheckBox
                label={t('Yes')}
                checked={isOtherCountryResidentValidator.value === true}
                onChange={(e) => isOtherCountryResidentValidator.handleEvent(e.target.checked)}
                invalid={isOtherCountryResidentValidator.invalid}
                {...generateTestId(`yes`)}
              />
            </FormGroup>
            <FormGroup>
              <CheckBox
                label={t('No')}
                checked={isOtherCountryResidentValidator.value === false}
                onChange={(e) => isOtherCountryResidentValidator.handleEvent(!e.target.checked)}
                invalid={isOtherCountryResidentValidator.invalid}
                {...generateTestId(`no`)}
              />
            </FormGroup>

            <ValidationErrorMessage size={Size.Medium} validator={isOtherCountryResidentValidator} />
          </FormGroup>
          {isOtherCountryResidentValidator.value && (
            <>
              <FormGroup>
                <FormLabel>{t('Country of tax residence')}</FormLabel>
                <Select
                  value={otherCountryResidentValidator.value}
                  options={countriesWithoutResidentialSelectOptions}
                  onChange={(option) => otherCountryResidentValidator.handleEvent(option as ISelectOption, !otherCountryResidentValidator.validated)}
                  placeholder={t('Select country')}
                  isClearable
                  invalid={otherCountryResidentValidator.invalid}
                  {...generateTestId(`otherCountryOfTax.select`)}
                />
                <ValidationErrorMessage size={Size.Medium} validator={otherCountryResidentValidator} />
              </FormGroup>
              <FormGroup>
                <FormLabel>{t('Tax Identification Number')}</FormLabel>
                <FormControl
                  type='text'
                  name='tfn'
                  placeholder={t('Enter your Tax Identification Number')}
                  value={otherTinValidator.value}
                  onChange={(e) => {
                    otherTinValidator.handleEvent(e.target.value, !otherTinValidator.validated);
                  }}
                  onBlur={(e) => {
                    otherTinValidator.handleEvent(e.target.value.trim());
                  }}
                  invalid={otherTinValidator.invalid}
                  {...generateTestId(`otherTFN.input`)}
                />

                <ValidationErrorMessage size={Size.Medium} validator={otherTinValidator} />
              </FormGroup>
            </>
          )}
        </CardContent>
        <CardSeparator />
        <CardFooter>
          <FormGroupWithMoreBottomMargin>
            <Text textAlign='center' size={Size.Large}>
              {t(`By clicking 'Confirm', you declare that the tax information you have provided is true and correct`)}
            </Text>
          </FormGroupWithMoreBottomMargin>
          <CenteredButton
            label={t('Confirm')}
            onClick={onConfirm}
            validators={[tinValidator, isOtherCountryResidentValidator, otherCountryResidentValidator, otherTinValidator]}
            {...generateTestId(`confirm`)}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
