import React from 'react';
import { LoginScreen } from '../Components/Login/LoginScreen';
import { PageWithPod } from '../Components/PageWithPod/PageWithPod';
import { INextPage } from '../models/IPageNavigation';

interface ILoginProps extends INextPage {
  onForgotPassword: () => void;
}

export const Login: React.FC<ILoginProps> = ({ onNext, onForgotPassword }) => {
  return (
    <PageWithPod>
      <LoginScreen onNext={onNext} onForgotPassword={onForgotPassword} />
    </PageWithPod>
  );
};
