import { IconNames, IconName, Variant, IValidationResult } from '@cmctechnology/phoenix-stockbroking-web-design';

export const getPasswordValidationVariantAndIcon = (validated: boolean, validationResult?: IValidationResult): { name: IconName; variant?: Variant } => {
  if (!validated) {
    return { name: IconNames.Minus };
  }

  if (validationResult?.valid) {
    return { name: IconNames.Check, variant: Variant.Success };
  }
  return { name: IconNames.Plus, variant: Variant.Error };
};
