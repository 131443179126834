import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import { Translated } from './translation';

export interface IValidatorMessage {
  message: Translated;
}

export interface IPatternValidator extends IValidatorMessage {
  pattern: RegExp;
}

export enum MedicareExpiryDateValidation {
  Valid = 'Valid',
  Invalid = 'MedicareInvalid',
  Expired = 'MedicareExpired',
  Within2Months = 'MedicareWithin2Months'
}

export type KeyValueValidator<K extends keyof any = string> = Record<K, IValidator<any>>;

export interface IFieldValidation {
  regex: RegExp;
  message: Translated;
}
