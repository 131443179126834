import { Heading1, Size, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { CardHeaderWithText } from '../CardHeaderWithText';

export const JumioPageHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CardHeaderWithText textWithSmallMargin>
      <Heading1>{t('Identification')}</Heading1>
      <Text textAlign='center' size={Size.Large}>
        {t(
          'To apply for an account we need to verify your identity. This service, provided by Jumio, will scan your ID documents to verify who you are and pull your personal data from your document(s) into your application.'
        )}
      </Text>
    </CardHeaderWithText>
  );
};
