import {
  Address,
  Address1,
  AddressInput,
  CountryCode,
  DateInDayMonthYear1,
  DayMonthYear1,
  DriversLicence1,
  EmploymentDetails,
  GetVerifyIdentityResponse,
  MedicareCard1,
  OverseasTaxInfo,
  OverseasTaxInfo1,
  OverseasTaxInfo2,
  Passport,
  State,
  TaxDetails,
  TaxDetails1,
  VerificationOutcome1,
  VerifyIdentityRequest2
} from '@cmctechnology/phoenix-stockbroking-api-client';
import { EMPTY_STRING, STRING_SPACE } from '../constants/commonConstants';
import { defaultAddress, defaultDateInDayMonthYear } from '../models/common';
import {
  defaultPassport,
  IdentificationValidationStatusMapping,
  IDriversLicenceDetails,
  IIdentificationDetails,
  IMedicareDetails,
  IPassportDetails
} from '../models/identification';
import { defaultTaxDetails, IAddressInput, IPersonalDetails, IProfile, ITaxDetails } from '../models/profile';
import { IStore } from '../Store/Store';

export const toDateInDayMonthYear = (date: DayMonthYear1): DateInDayMonthYear1 => ({
  day: Number(date.day),
  month: Number(date.month),
  year: Number(date.year)
});

export const toDriversLicence = (details?: IDriversLicenceDetails): DriversLicence1 | undefined =>
  details
    ? {
        number: details.licenceNumber,
        state: details.licenceState as State,
        cardNumber: details.licenceCardNumber
      }
    : undefined;

export const toMedicareCard = (details?: IMedicareDetails): MedicareCard1 | undefined =>
  details
    ? {
        cardColour: details.cardColour,
        cardNumber: details.cardNumber,
        referenceNumber: details.referenceNumber,
        expiryDate: toDateInDayMonthYear(details.expiryDate),
        middleName: details.middleName !== STRING_SPACE ? details.middleName : undefined
      }
    : undefined;

export const toPassport = (personalDetails: IPersonalDetails, details?: IPassportDetails): Passport | undefined =>
  details
    ? {
        issuingCountry: details.countryOfIssue as CountryCode,
        number: details.passportNumber,
        firstName: personalDetails.firstName,
        middleName: personalDetails.middleName,
        surname: personalDetails.lastName
      }
    : undefined;

export const toUnformattedAddress = (details: AddressInput): Address => ({
  qasFullAddress: details.unformattedAddress,
  country: CountryCode.AUS
});

export const toFormattedAddress = (details: AddressInput): Address => ({
  unitNumber: details.unitNumber,
  streetNumber: details.streetNumber,
  street: details.streetName,
  streetType: details.streetType,
  city: details.city,
  postcode: details.postcode,
  state: details.state,
  country: CountryCode.AUS
});

const toAddress = (isUnformatted: boolean, addressInput: IAddressInput) =>
  isUnformatted ? toUnformattedAddress(addressInput) : toFormattedAddress(addressInput);

export const toOverseasTaxInfo = (details: OverseasTaxInfo2): OverseasTaxInfo1 | undefined =>
  details.country
    ? {
        ...details,
        country: details.country as CountryCode
      }
    : undefined;

const toTaxDetails = (details: ITaxDetails): TaxDetails1 => ({
  taxResidentType: details.taxResidentType,
  australianTaxInfo: details.australianTaxInfo,
  overseasTaxInfo: toOverseasTaxInfo(details.overseasTaxInfo)
});

// The above converters are to convert to api request format from ui; the followings are opposite to convert to ui format from api request format
const LEFT_PAD_STRING_FOR_MONTH = '0';
const STRING_LENGTH_FOR_MONTH = 2;
const toDateInDayMonthYearForUi = (date: DateInDayMonthYear1): DayMonthYear1 => ({
  day: String(date?.day),
  month: String(date?.month).padStart(STRING_LENGTH_FOR_MONTH, LEFT_PAD_STRING_FOR_MONTH),
  year: String(date?.year)
});

export const toAddressForUi = (address: Address1 | undefined): IAddressInput | undefined =>
  address
    ? {
        isUnformatted: !!address.qasFullAddress,
        unformattedAddress: address.qasFullAddress || defaultAddress.unformattedAddress,
        unformattedAddressKey: address.qasFullAddress || defaultAddress.unformattedAddress,
        unitNumber: address.unitNumber || defaultAddress.unitNumber,
        streetNumber: address.streetNumber || defaultAddress.streetNumber,
        streetName: address.street || defaultAddress.streetName,
        streetType: address.streetType || defaultAddress.streetType,
        city: address.city || defaultAddress.city,
        state: address.state || defaultAddress.state,
        postcode: address.postcode || defaultAddress.postcode
      }
    : undefined;

const consentToIdCheck = true;

export const toDriversLicenceForUi = (driversLicence?: DriversLicence1 | undefined): IDriversLicenceDetails | undefined =>
  driversLicence
    ? {
        licenceNumber: driversLicence.number as string,
        licenceState: driversLicence.state as string,
        licenceCardNumber: driversLicence.cardNumber as string,
        consentToIdCheck
      }
    : undefined;

export const toMedicareCardForUi = (medicareCard?: MedicareCard1 | undefined): IMedicareDetails | undefined =>
  medicareCard
    ? {
        cardColour: medicareCard.cardColour,
        cardNumber: medicareCard.cardNumber as string,
        referenceNumber: medicareCard.referenceNumber as string,
        expiryDate: toDateInDayMonthYearForUi(medicareCard.expiryDate!) || defaultDateInDayMonthYear,
        consentToIdCheck,
        middleName: medicareCard.middleName ? medicareCard.middleName : STRING_SPACE
      }
    : undefined;
export const toPassportForUi = (passport?: Passport): IPassportDetails | undefined =>
  passport ? { ...defaultPassport, countryOfIssue: passport.issuingCountry as string, passportNumber: passport.number as string, consentToIdCheck } : undefined;

export const toOverseasTaxInfoForUi = (overseasTaxInfo: OverseasTaxInfo | undefined): OverseasTaxInfo2 => overseasTaxInfo ?? defaultTaxDetails.overseasTaxInfo;

export const toTaxDetailsForUi = (taxDetails: TaxDetails | undefined): ITaxDetails =>
  taxDetails ? { ...taxDetails, overseasTaxInfo: toOverseasTaxInfoForUi(taxDetails.overseasTaxInfo) } : defaultTaxDetails;

export const identityVerificationConverter = {
  convertToUsersVerifyIdentityRequest: (currentState: IStore) => {
    const {
      remote: {
        profile: { personalDetails, addressDetails, taxDetails, employmentDetails },
        identificationDetails
      }
    } = currentState;

    const request: VerifyIdentityRequest2 = {
      title: personalDetails.title,
      firstName: personalDetails.firstName,
      middleName: personalDetails.middleName || undefined,
      surname: personalDetails.lastName,
      address: toAddress(addressDetails.residentialAddress.isUnformatted, addressDetails.residentialAddress),
      dateOfBirth: toDateInDayMonthYear(personalDetails.dob),
      postalSameAsResidential: addressDetails.postalSameAsResidential,
      postalAddress: toAddress(addressDetails.postalAddress.isUnformatted, addressDetails.postalAddress)
    };

    request.identificationType = identificationDetails.identificationType;
    request.driversLicence = toDriversLicence(identificationDetails.driversLicence);
    request.medicareCard = toMedicareCard(identificationDetails.medicare);
    request.passport = toPassport(personalDetails, identificationDetails.passport);

    request.taxDetails = toTaxDetails(taxDetails);
    request.employment = employmentDetails;

    return request;
  },

  convertToUserIdentification: (verifyIdentityResponse: GetVerifyIdentityResponse) => {
    const userData = verifyIdentityResponse.userData!;
    const status = verifyIdentityResponse.status!;
    const identityVerifyMessageId = verifyIdentityResponse.requestReference;
    const identityQuestionsRequired: boolean | undefined = verifyIdentityResponse.globalScreeningPEPCheck;

    const personalDetails: IPersonalDetails = {
      title: userData.title ?? EMPTY_STRING,
      firstName: userData.firstName,
      middleName: userData.middleName ?? EMPTY_STRING,
      lastName: userData.surname,
      dob: toDateInDayMonthYearForUi(userData.dateOfBirth!),
      validated: true
    };
    const residentialAddress: IAddressInput = toAddressForUi(userData.address)!;
    const postalAddress: IAddressInput = toAddressForUi(userData.postalAddress)!;
    const driversLicence: IDriversLicenceDetails | undefined = toDriversLicenceForUi(userData.driversLicence);
    const passport: IPassportDetails | undefined = toPassportForUi(userData.passport);
    const medicare: IMedicareDetails | undefined = toMedicareCardForUi(userData.medicareCard);
    const employmentDetails: EmploymentDetails = userData.employment || { occupation: EMPTY_STRING };
    const taxDetails: ITaxDetails = toTaxDetailsForUi(userData.taxDetails);
    const identificationDetails: IIdentificationDetails = {
      identificationType: userData.identificationType,
      driversLicence,
      passport,
      medicare,
      status: IdentificationValidationStatusMapping[status],
      identityQuestionsRequired
    };
    const identityVerifyInProgressAfterLogin = status === VerificationOutcome1.IN_PROGRESS;
    const profile: IProfile = {
      personalDetails,
      addressDetails: {
        residentialAddress,
        postalSameAsResidential: userData.postalSameAsResidential!,
        postalAddress
      },
      taxDetails,
      employmentDetails
    };

    return { profile, identificationDetails, identityVerifyInProgressAfterLogin, identityVerifyMessageId };
  }
};
