import { TFunction } from 'i18next';
import { FULL_STOP } from '../constants/commonConstants';

export const isNameNotStartedWithFullStop = (firstOrLastName: string) => !firstOrLastName.startsWith(FULL_STOP);

export const getErrorMessageForFirstNameAllowedCharacters = (t: TFunction) => {
  return t('This field can only contain alpha characters, hyphens, apostrophe and spaces');
};

export const getErrorMessageForLastNameAllowedCharacters = (t: TFunction) => {
  return t('This field can only contain alpha characters, hyphens, apostrophe and spaces');
};

export const getErrorMessageForMiddleNameAllowedCharacters = (t: TFunction) => {
  return t('This field can only contain alpha characters, hyphens, apostrophe and spaces');
};

export const getErrorMessageForNameStartingWithFullStop = (t: TFunction) => {
  return t('Invalid name');
};
