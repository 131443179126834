import { KeyValueTranslated } from '../translation';

export const SGP_PERSON_TITLES: KeyValueTranslated = {
  Mr: (t) => t('Mr'),
  Mrs: (t) => t('Mrs'),
  Ms: (t) => t('Ms'),
  Miss: (t) => t('Miss'),
  Dr: (t) => t('Dr'),
  Prof: (t) => t('Prof')
};
