import { CircularIcon, FormLabel, FormRow, IconNames, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IAddressFormProps } from './AddressFormProps';
import { UnformattedAddressForm } from './UnformattedAddressForm';
import { defaultAddress } from '../../models/common';
import { ManualAddressForm } from './ManualAddressForm';
import { IconMarginLeftCss } from '../IconCss';

export const ArrowRightIcon = styled(CircularIcon)`
  ${IconMarginLeftCss}
`;

export const AddressForm: React.FC<IAddressFormProps> = ({ validated, addressType, address, onAddressChanged, removeTopMargin, validators, autoFocus }) => {
  const { t } = useTranslation();
  const changeAddressFormatting = (isUnformatted: boolean) => {
    onAddressChanged({ ...defaultAddress, isUnformatted });
  };

  if (address.isUnformatted) {
    return (
      <>
        <UnformattedAddressForm
          validated={validated}
          addressType={addressType}
          address={address}
          onAddressChanged={onAddressChanged}
          removeTopMargin={removeTopMargin}
          validators={validators}
          autoFocus={autoFocus}
        />
        <FormRow>
          <FormLabel
            onClick={() => {
              changeAddressFormatting(false);
            }}
          >
            {t('Or enter address manually')} <ArrowRightIcon name={IconNames.ArrowRight} size={Size.Small} />
          </FormLabel>
        </FormRow>
      </>
    );
  }

  return (
    <>
      <FormRow>
        <FormLabel
          onClick={() => {
            changeAddressFormatting(true);
          }}
        >
          {t('Lookup address')} <ArrowRightIcon name={IconNames.ArrowRight} size={Size.Small} />
        </FormLabel>
      </FormRow>
      <ManualAddressForm validated={validated} addressType={addressType} address={address} onAddressChanged={onAddressChanged} validators={validators} />
    </>
  );
};
