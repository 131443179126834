/* eslint-disable i18next/no-literal-string */
import { breakpoint } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';
import { ReactComponent as SeedlingTreesSvg } from '../Assets/Seedling_Trees.svg';

export const SeedlingTreesBackground = styled(SeedlingTreesSvg).attrs({
  viewBox: '0 0 1891 516'
})`
  width: 100%;
  position: fixed;
  top: 30%;
  @media (max-width: ${breakpoint.mobile}) {
    display: none;
  }
`;

export const BackgroundContainer = styled.div`
  padding: 2.5rem 5rem;
  @media (max-width: ${breakpoint.mobile}) {
    padding: 2.5rem 1.5rem;
  }
`;

export const PageBackground: React.FC = ({ ...props }) => {
  return (
    <>
      <SeedlingTreesBackground />
      <BackgroundContainer>{props.children}</BackgroundContainer>
    </>
  );
};
