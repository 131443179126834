import { ModalProvider, themeBaseLight } from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { BusinessRegion } from './configuration/configuration';
import { BusinessRegionProvider } from './hooks/useBusinessRegion';
import { BusinessRegionRoutes } from './routes/BusinessRegionRoutes';
import { Store } from './Store/Store';

export const App: React.FC = () => {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={themeBaseLight}>
        <HelmetProvider>
          <ModalProvider />
          <BusinessRegionProvider defaultBusinessRegion={BusinessRegion.AUS}>
            <BrowserRouter>
              <BusinessRegionRoutes />
            </BrowserRouter>
          </BusinessRegionProvider>
        </HelmetProvider>
      </ThemeProvider>
    </Provider>
  );
};
