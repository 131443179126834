import React, { useEffect, useRef, useState } from 'react';
import {
  breakpoint,
  Form,
  FormGroup,
  FormLabel,
  RadioButtonGroup,
  RadioButton,
  RadioButtonLabel,
  FormControl,
  CardContent,
  CardFooter,
  CardLarge,
  CardSeparator,
  Heading1,
  Text,
  CheckBox,
  ValidationErrorMessage,
  TextArea,
  Size,
  CardHeader,
  useTestId,
  Select
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { AustralianTaxInfo, OverseasTaxInfo, TaxResidentType } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import {
  AUS_TAX_EXEMPTION_REASONS,
  defaultTaxDetails,
  ITaxDetails,
  OVERSEAS_TAX_EXEMPTION_REASONS,
  TAX_RESIDENT_TYPES,
  UNABLE_TO_OBTAIN_TIN_REASON
} from '../models/profile';
import { IStore } from '../Store/Store';
import { submitTaxDetails } from '../Store/Actions';
import { COUNTRIES_EXCEPT_AUS, COUNTRY_CODE_USA } from '../constants/countryConstants';
import { createCountriesOptionList, createKeyValueTranslatedToOptionList } from '../common/mappings';
import { TooltipLabelAndText } from '../Components/TooltipLableAndText';
import { tooltipLabel, tooltipText, Tooltips } from '../models/tooltip';
import { ProfileCardFooter } from '../Components/ProfileCardFooter';
import { GoBackIcon } from '../Components/GoBackIcon';
import { useTaxValidators } from '../hooks/useTaxValidators';
import { KeyValueValidator } from '../models/validation';
import { AustraliaTaxValidator, OverseasTaxValidator } from '../constants/taxConstants';
import { INextPage, IPreviousPage } from '../models/IPageNavigation';
import { ENABLED_TABINDEX_FOR_NON_INPUT } from '../constants/commonConstants';
import { ifEnterKey } from '../events/onKeyDown';
import { filterByStartWith } from '../models/filterOption';
import { FullWidthCardContent } from '../Components/FullWidthCardContent';
import { PositiveNumericFormControl } from '../Components/PositiveNumericFormControl';

export const WideRadioButtonLabel = styled(RadioButtonLabel)`
  @media (max-width: ${breakpoint.mobile}) {
    width: 9rem;
  }
`;

export interface IProfileTaxDetailsProps extends INextPage, Partial<IPreviousPage> {}

interface IAustraliaTaxDetailsProps {
  onChange: (details: AustralianTaxInfo) => void;
  validators: KeyValueValidator<AustraliaTaxValidator>;
}

const AustraliaTaxDetails: React.FC<IAustraliaTaxDetailsProps> = ({ validators, onChange, ...rest }) => {
  const australianTaxInfo = useSelector((store: IStore) => store.remote.profile.taxDetails.australianTaxInfo);
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `taxDetails`);

  const tfnValidator = validators[AustraliaTaxValidator.TfnValidator];
  const [haveAusTaxExemption, setHaveAusTaxExemption] = useState(australianTaxInfo.haveTaxExemption);
  const tfnControlRef = useRef<HTMLInputElement | null>(null);

  const ausTaxExemptionReasonsSelectOptions = createKeyValueTranslatedToOptionList(AUS_TAX_EXEMPTION_REASONS, t);

  const ausTaxExemptionReasonValidator = validators[AustraliaTaxValidator.AusTaxExemptionReasonValidator];

  useEffect(() => {
    const details: AustralianTaxInfo = {
      tfn: tfnValidator.value,
      haveTaxExemption: haveAusTaxExemption,
      taxExemptionReason: ausTaxExemptionReasonValidator.value?.value ?? ''
    };
    onChange(details);
  }, [tfnValidator.value, haveAusTaxExemption, ausTaxExemptionReasonValidator.value]);

  return (
    <CardContent>
      <FormGroup>
        <TooltipLabelAndText label={tooltipLabel[Tooltips.TfnNumber](t)} text={tooltipText[Tooltips.TfnNumber](t)} parentRef={tfnControlRef} />
        <PositiveNumericFormControl
          autoFocus
          placeholder={t('9 digit Tax File Number')}
          value={tfnValidator.value}
          onChange={(e) => tfnValidator.handleEvent(e.target.value, !tfnValidator.validated)}
          onBlur={(e) => tfnValidator.handleEvent(e.target.value)}
          disabled={haveAusTaxExemption}
          invalid={tfnValidator.invalid}
          ref={tfnControlRef}
          {...generateTestId(`tfn`)}
        />
        <ValidationErrorMessage size={Size.Medium} validator={tfnValidator} />
        <Text size={Size.Medium}>{t('You can complete this later in your settings')}</Text>
      </FormGroup>
      <FormGroup>
        <CheckBox
          label={t('I have a tax exemption')}
          checked={haveAusTaxExemption}
          onChange={(e) => setHaveAusTaxExemption(e.target.checked)}
          disabled={!!tfnValidator.value}
        />
      </FormGroup>
      {haveAusTaxExemption && (
        <FormGroup>
          <Select
            value={ausTaxExemptionReasonValidator.value}
            options={ausTaxExemptionReasonsSelectOptions}
            onChange={(option) => ausTaxExemptionReasonValidator.handleEvent(option)}
            placeholder={t('Select reason')}
            invalid={ausTaxExemptionReasonValidator.invalid}
            isClearable
          />
          <ValidationErrorMessage size={Size.Medium} validator={ausTaxExemptionReasonValidator} />
        </FormGroup>
      )}
    </CardContent>
  );
};

interface IOverseasTaxDetails {
  taxResidentType: TaxResidentType;
  onChange: (details: OverseasTaxInfo) => void;
  validators: KeyValueValidator<OverseasTaxValidator>;
}

const OverseasTaxDetails: React.FC<IOverseasTaxDetails> = ({ validators, taxResidentType, onChange }) => {
  const overseasTaxInfo = useSelector((store: IStore) => store.remote.profile.taxDetails.overseasTaxInfo);
  const overseasTaxControlRef = useRef<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const countriesExceptAusSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_EXCEPT_AUS, t);
  const countryValidator = validators[OverseasTaxValidator.CountryValidator];

  const [haveOverseasTaxExemption, setHaveOverseasTaxExemption] = useState(overseasTaxInfo.haveTaxExemption);
  const tinValidator = validators[OverseasTaxValidator.TinValidator];

  const overseasTaxExemptionReasonsSelectOptions = createKeyValueTranslatedToOptionList(OVERSEAS_TAX_EXEMPTION_REASONS, t);
  const overseasTaxExemptionReasonValidator = validators[OverseasTaxValidator.OverseasTaxExemptionReasonValidator];
  const overseasTaxExemptionExplanationValidator = validators[OverseasTaxValidator.OverseasTaxExemptionExplanationValidator];

  useEffect(() => {
    const details: OverseasTaxInfo = {
      country: countryValidator.value?.value ?? '',
      tin: tinValidator.value,
      haveTaxExemption: haveOverseasTaxExemption,
      taxExemptionReason: overseasTaxExemptionReasonValidator.value?.value ?? '',
      taxExemptionExplanation: overseasTaxExemptionExplanationValidator.value
    };

    onChange(details);
  }, [
    countryValidator.value,
    haveOverseasTaxExemption,
    tinValidator.value,
    overseasTaxExemptionReasonValidator.value,
    overseasTaxExemptionExplanationValidator.value
  ]);

  const isCountryNotSelectedNorUSA = !countryValidator.value || countryValidator.value!.value !== COUNTRY_CODE_USA;
  const isTinDisabled = haveOverseasTaxExemption && countryValidator.value?.value !== COUNTRY_CODE_USA;
  const isTinVisible = countryValidator.value && (!haveOverseasTaxExemption || countryValidator.value!.value === COUNTRY_CODE_USA);

  return (
    <FullWidthCardContent>
      <FormGroup>
        {taxResidentType === TaxResidentType.Overseas && (
          <TooltipLabelAndText
            label={tooltipLabel[Tooltips.CountryOfTaxResidence](t)}
            text={tooltipText[Tooltips.CountryOfTaxResidence](t)}
            parentRef={overseasTaxControlRef}
          />
        )}
        {taxResidentType === TaxResidentType.AUOverseas && (
          <TooltipLabelAndText
            label={tooltipLabel[Tooltips.OtherCountryOfTaxResidence](t)}
            text={tooltipText[Tooltips.OtherCountryOfTaxResidence](t)}
            parentRef={overseasTaxControlRef}
          />
        )}
        <Select
          value={countryValidator.value}
          options={createCountriesOptionList(countriesExceptAusSelectOptions, t)}
          onChange={(option) => countryValidator.handleEvent(option)}
          placeholder={t('Select country')}
          isSearchable
          isClearable
          invalid={countryValidator.invalid}
          ref={(e) => {
            overseasTaxControlRef.current = e?.controlRef as HTMLDivElement;
            if (!countryValidator.value && taxResidentType !== TaxResidentType.AUOverseas) {
              e?.focus();
            }
          }}
          filterOption={filterByStartWith}
        />
        <ValidationErrorMessage size={Size.Medium} validator={countryValidator} />
      </FormGroup>
      <>
        {isTinVisible && (
          <FormGroup>
            <FormLabel>{t('Tax Identification Number')}</FormLabel>
            <FormControl
              type='text'
              placeholder={t('Enter your Tax Identification Number')}
              value={tinValidator.value}
              onChange={(e) => tinValidator.handleEvent(e.target.value)}
              onBlur={(e) => tinValidator.handleEvent(e.target.value)}
              disabled={isTinDisabled}
              invalid={tinValidator.invalid}
            />
            <ValidationErrorMessage size={Size.Medium} validator={tinValidator} />
          </FormGroup>
        )}
        {isCountryNotSelectedNorUSA && (
          <FormGroup>
            <CheckBox
              label={t('I have a tax exemption')}
              checked={haveOverseasTaxExemption}
              onChange={(e) => setHaveOverseasTaxExemption(e.target.checked)}
              disabled={!!tinValidator.value}
            />
          </FormGroup>
        )}
        {haveOverseasTaxExemption && isCountryNotSelectedNorUSA && (
          <>
            <FormGroup>
              <Select
                value={overseasTaxExemptionReasonValidator.value}
                options={overseasTaxExemptionReasonsSelectOptions}
                onChange={(option) => overseasTaxExemptionReasonValidator.handleEvent(option)}
                placeholder={t('Select reason')}
                isClearable
                invalid={overseasTaxExemptionReasonValidator.invalid}
              />
              <ValidationErrorMessage size={Size.Medium} validator={overseasTaxExemptionReasonValidator} />
            </FormGroup>

            {overseasTaxExemptionReasonValidator.value?.value === UNABLE_TO_OBTAIN_TIN_REASON && (
              <FormGroup>
                <FormLabel>{t('Please provide explanation')}</FormLabel>
                <TextArea
                  value={overseasTaxExemptionExplanationValidator.value}
                  onChange={(e) => overseasTaxExemptionExplanationValidator.handleEvent(e.target.value)}
                  onBlur={(e) => overseasTaxExemptionExplanationValidator.handleEvent(e.target.value)}
                  invalid={overseasTaxExemptionExplanationValidator.invalid}
                />
                <ValidationErrorMessage size={Size.Medium} validator={overseasTaxExemptionExplanationValidator} />
              </FormGroup>
            )}
          </>
        )}
      </>
    </FullWidthCardContent>
  );
};

const TaxDetailsContainer = styled(CardContent)<{ hasSelected: boolean }>`
  ${({ hasSelected }) => hasSelected && `padding-bottom: 0;`}
`;

const PaddedCardSeparator = styled(CardSeparator)`
  margin-top: 2.5rem;
`;

const ConfirmCardFooter = styled(CardFooter)`
  padding-bottom: 1.5rem;
  @media (max-width: ${breakpoint.mobile}) {
    width: 100%;
  }
`;

export const ProfileTaxDetails: React.FC<IProfileTaxDetailsProps> = ({ onNext, onPrevious, ...rest }) => {
  const taxDetails = useSelector((store: IStore) => store.remote.profile.taxDetails);
  const { generateTestId } = useTestId(rest, `taxDetails`);

  const [australiaTaxDetails, setAustraliaTaxDetails] = useState<AustralianTaxInfo>();
  const [overseasTaxDetails, setOverseasTaxDetails] = useState<OverseasTaxInfo>();

  const australianTaxInfo = useSelector((store: IStore) => store.remote.profile.taxDetails.australianTaxInfo);
  const overseasTaxInfo = useSelector((store: IStore) => store.remote.profile.taxDetails.overseasTaxInfo);

  const { t } = useTranslation();

  const [taxResidentType, setTaxResidentType] = useState(taxDetails.taxResidentType);

  const dispatch = useDispatch();

  const taxResidentTypeValue = taxResidentType ? TAX_RESIDENT_TYPES[taxResidentType] : undefined;

  const onTaxResidentTypeChanged = (taxResidentTypeOption: TaxResidentType) => {
    setTaxResidentType(taxResidentTypeOption);
    setAustraliaTaxDetails(undefined);
    setOverseasTaxDetails(undefined);
  };
  const onTaxResidentTypeRadioButtonChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onTaxResidentTypeChanged(e.target.value as TaxResidentType);
  };

  const taxValidators = useTaxValidators(australianTaxInfo, overseasTaxInfo);
  const australiaTaxValidators: KeyValueValidator<AustraliaTaxValidator> = {
    [AustraliaTaxValidator.TfnValidator]: taxValidators.tfnValidator,
    [AustraliaTaxValidator.AusTaxExemptionReasonValidator]: taxValidators.ausTaxExemptionReasonValidator
  };
  const overseasTaxValidators: KeyValueValidator<OverseasTaxValidator> = {
    [OverseasTaxValidator.CountryValidator]: taxValidators.countryValidator,
    [OverseasTaxValidator.TinValidator]: taxValidators.tinValidator,
    [OverseasTaxValidator.OverseasTaxExemptionReasonValidator]: taxValidators.overseasTaxExemptionReasonValidator,
    [OverseasTaxValidator.OverseasTaxExemptionExplanationValidator]: taxValidators.overseasTaxExemptionExplanationValidator
  };

  const australiaTaxValidatorsWithoutExemption = [australiaTaxValidators[AustraliaTaxValidator.TfnValidator]];
  const overseasTaxValidatorsWithoutExemption = [
    overseasTaxValidators[OverseasTaxValidator.CountryValidator],
    overseasTaxValidators[OverseasTaxValidator.TinValidator]
  ];
  const [inEffectValidators, setInEffectValidators] = useState<IValidator<any>[] | undefined>();
  const [inEffectValidatorsForAustralia, setInEffectValidatorsForAustralia] = useState<IValidator<AustraliaTaxValidator>[] | undefined>();
  const [inEffectValidatorsForOverseas, setInEffectValidatorsForOverseas] = useState<IValidator<OverseasTaxValidator>[] | undefined>();

  const australiaTaxValidatorsWithExemption = [australiaTaxValidators[AustraliaTaxValidator.AusTaxExemptionReasonValidator]];

  const overseasTaxValidatorsWithExemption = [
    overseasTaxValidators[OverseasTaxValidator.CountryValidator],
    overseasTaxValidators[OverseasTaxValidator.OverseasTaxExemptionReasonValidator]
  ];
  const overseasTaxValidatorsWithExemptionAndUnableToObtainTin = [
    overseasTaxValidators[OverseasTaxValidator.CountryValidator],
    overseasTaxValidators[OverseasTaxValidator.OverseasTaxExemptionReasonValidator],
    overseasTaxValidators[OverseasTaxValidator.OverseasTaxExemptionExplanationValidator]
  ];

  const setInEffectValidatorsForAustralianTax = (details: AustralianTaxInfo) => {
    const needValidateOverseas = inEffectValidatorsForOverseas && taxResidentType === TaxResidentType.AUOverseas;

    if (details.haveTaxExemption) {
      if (needValidateOverseas) {
        setInEffectValidators([...inEffectValidatorsForOverseas, ...australiaTaxValidatorsWithExemption]);
      } else {
        setInEffectValidators([...australiaTaxValidatorsWithExemption]);
      }
      setInEffectValidatorsForAustralia([...australiaTaxValidatorsWithExemption]);
    } else {
      if (needValidateOverseas) {
        setInEffectValidators([...inEffectValidatorsForOverseas, ...australiaTaxValidatorsWithoutExemption]);
      } else {
        setInEffectValidators([...australiaTaxValidatorsWithoutExemption]);
      }
      setInEffectValidatorsForAustralia([...australiaTaxValidatorsWithoutExemption]);
    }
  };

  const setInEffectValidatorsForOverseasTax = (details: OverseasTaxInfo) => {
    const needValidateAustralia = inEffectValidatorsForAustralia && taxResidentType === TaxResidentType.AUOverseas;
    const exemptionBecauseNotUnableToObtainTin = details.taxExemptionReason !== UNABLE_TO_OBTAIN_TIN_REASON;

    if (details.haveTaxExemption) {
      if (needValidateAustralia) {
        if (exemptionBecauseNotUnableToObtainTin) {
          setInEffectValidators([...inEffectValidatorsForAustralia, ...overseasTaxValidatorsWithExemption]);
          setInEffectValidatorsForOverseas([...overseasTaxValidatorsWithExemption]);
        } else {
          setInEffectValidators([...inEffectValidatorsForAustralia, ...overseasTaxValidatorsWithExemptionAndUnableToObtainTin]);
          setInEffectValidatorsForOverseas([...overseasTaxValidatorsWithExemptionAndUnableToObtainTin]);
        }
      } else if (exemptionBecauseNotUnableToObtainTin) {
        setInEffectValidators([...overseasTaxValidatorsWithExemption]);
        setInEffectValidatorsForOverseas([...overseasTaxValidatorsWithExemption]);
      } else {
        setInEffectValidators([...overseasTaxValidatorsWithExemptionAndUnableToObtainTin]);
        setInEffectValidatorsForOverseas([...overseasTaxValidatorsWithExemptionAndUnableToObtainTin]);
      }
    } else {
      if (needValidateAustralia) {
        setInEffectValidators([...inEffectValidatorsForAustralia, ...overseasTaxValidatorsWithoutExemption]);
      } else {
        setInEffectValidators([...overseasTaxValidatorsWithoutExemption]);
      }
      setInEffectValidatorsForOverseas([...overseasTaxValidatorsWithoutExemption]);
    }
  };

  const onNextClicked = () => {
    const tax: ITaxDetails = {
      taxResidentType,
      australianTaxInfo: australiaTaxDetails ?? defaultTaxDetails.australianTaxInfo,
      overseasTaxInfo: overseasTaxDetails ?? defaultTaxDetails.overseasTaxInfo
    };
    dispatch(submitTaxDetails(tax));
    onNext();
  };

  useEffect(() => {
    return () => {
      setInEffectValidators([]);
    };
  }, []);

  return (
    <Form>
      <CardLarge>
        <CardHeader>
          {onPrevious && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Tax details')}</Heading1>
        </CardHeader>
        <CardSeparator />
        <TaxDetailsContainer hasSelected={!!taxResidentType}>
          <FormGroup>
            <FormLabel>{t('Where are you a tax resident?')}</FormLabel>
            <RadioButtonGroup {...generateTestId(`resident`)}>
              {Object.keys(TAX_RESIDENT_TYPES).map((type) => (
                <React.Fragment key={type}>
                  <RadioButton
                    name='taxResidentType'
                    id={type}
                    value={type}
                    checked={taxResidentType === type}
                    onChange={(e) => onTaxResidentTypeRadioButtonChanged(e)}
                  />
                  <WideRadioButtonLabel
                    htmlFor={type}
                    tabIndex={ENABLED_TABINDEX_FOR_NON_INPUT}
                    onKeyDown={(e) => ifEnterKey(e, () => onTaxResidentTypeChanged((e.target as HTMLLabelElement).htmlFor as TaxResidentType))}
                  >
                    {TAX_RESIDENT_TYPES[type as TaxResidentType].value(t)}
                  </WideRadioButtonLabel>
                </React.Fragment>
              ))}
            </RadioButtonGroup>
            <Text size={Size.Medium}>{t('If you are a US citizen or resident you must include USA as one of your countries of tax residency.')}</Text>
          </FormGroup>
        </TaxDetailsContainer>
        {taxResidentType === TaxResidentType.AUOverseas && <PaddedCardSeparator />}
        {taxResidentTypeValue?.isAustralianTaxResident && (
          <AustraliaTaxDetails
            onChange={(details) => {
              setAustraliaTaxDetails(details);
              setInEffectValidatorsForAustralianTax(details);
            }}
            validators={australiaTaxValidators}
          />
        )}
        {taxResidentType === TaxResidentType.AUOverseas && <CardSeparator />}
        {taxResidentType && taxResidentTypeValue?.isOverseasTaxResident && (
          <OverseasTaxDetails
            taxResidentType={taxResidentType}
            onChange={(details) => {
              setOverseasTaxDetails(details);
              setInEffectValidatorsForOverseasTax(details);
            }}
            validators={overseasTaxValidators}
          />
        )}
        {!!taxResidentType && (
          <>
            <CardSeparator />
            <ConfirmCardFooter>
              <Text textAlign='center' size={Size.Large}>
                <Trans t={t}>
                  By clicking &apos;Confirm&apos;, you declare
                  <br />
                  the information provided is true and correct
                </Trans>
              </Text>
            </ConfirmCardFooter>
            <ProfileCardFooter
              nextButtonLabel={t('Confirm')}
              saveButtonLabel={t('Confirm')}
              onNext={onNextClicked}
              onCancel={onNext}
              validators={inEffectValidators && [...inEffectValidators]}
            />
          </>
        )}
      </CardLarge>
    </Form>
  );
};
