import { Logo, NavigationBar, NavigationLink } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { pages } from '../models/pagePaths';
import { NavigationStep } from '../models/page';
import { IStore } from '../Store/Store';
import { BusinessRegion } from '../configuration/configuration';

export const Navigation = () => {
  const { t } = useTranslation();
  const page = useSelector((store: IStore) => store.local.page);
  const [selectedLink, setSelectedLink] = useState<NavigationStep>();

  useEffect(() => {
    setSelectedLink(pages[page].navigationStep?.(BusinessRegion.AUS) as NavigationStep);
  }, [page]);

  if (!selectedLink) {
    return (
      <NavigationBar>
        <Logo />
      </NavigationBar>
    );
  }

  return (
    <NavigationBar renderLogo={() => <Logo />}>
      <NavigationLink
        selected={selectedLink === NavigationStep.Profile}
        visited={selectedLink === NavigationStep.Identification || selectedLink === NavigationStep.ReviewAndSubmit}
        label={t('PROFILE')}
      />
      <NavigationLink
        selected={selectedLink === NavigationStep.Identification}
        visited={selectedLink === NavigationStep.ReviewAndSubmit}
        label={t('IDENTIFICATION')}
        shortLabel={t('ID')}
      />
      <NavigationLink selected={selectedLink === NavigationStep.ReviewAndSubmit} label={t('REVIEW + SUBMIT')} shortLabel={t('REVIEW')} />
    </NavigationBar>
  );
};
