/* eslint-disable i18next/no-literal-string */
import { IAnalyticsConfig } from '@cmctechnology/webinvest-analytics-compat';
import {
  AU_DOMAIN,
  CMC_MARKETS_INVEST_SIGNUP_API_URL,
  AU_INVEST_CMC_MARKETS_SIGNUP_API_URL,
  STAGING_SIGNUP_API_URL,
  GOOGLE_RECAPTCHA_ENTERPRISE_URL
} from '../constants/urlConstants';

export interface IConfiguration {
  apiUrl: string;
  topCountryCodes: string[];
  topStreetTypeCodes: string[];
  signOutTimeoutMins: number;
  analytics: IAnalyticsConfig;
  appStage: AppStage;
  activeBusinessRegions: string[];
  recaptchaConfiguration: IRecaptchaConfiguration;
}

export enum AppStage {
  Dev = 'Development',
  Staging = 'Staging',
  Prod = 'Production'
}

export enum BusinessRegion {
  AUS = 'au',
  SGP = 'sg'
}

interface IOneTrustConfig {
  scriptUrl: string;
  domain: string;
}

export interface IBigScriptConfig {
  scriptUrl: string;
  region: string;
  language: string;
  mparticle: string;
  oneTrust: string;
}

export interface IRecaptchaConfiguration {
  isEnabled: boolean;
  renderKey: string;
  enterpriseScriptUrl: string;
}

// Code defined by mParticle
export const initOptions = (options: any) => {
  // eslint-disable-next-line no-param-reassign
  options.contentSecurityNonce = `hVwQrR81zYgVYWPaKcB273RdGfONtljskBEYaXJbRs`;
};

const enableAnalyticsOverride = new URLSearchParams(window.location.search).get('analytics') === 'true';

const baseConfig: IConfiguration = {
  apiUrl: 'https://api.dev.onboarding.sb.nonprod.aws.cmclabs.io',
  topCountryCodes: ['AUS', 'CAN', 'CHN', 'IND', 'NZL', 'PAK', 'PHL', 'LKA', 'GBR', 'USA', 'VNM'],
  topStreetTypeCodes: ['Street', 'Road', 'Avenue', 'Crescent', 'Close', 'Lane', 'Way'],
  signOutTimeoutMins: 60,
  appStage: AppStage.Dev,
  analytics: {
    debug: true,
    mParticle: {
      isEnabled: enableAnalyticsOverride,
      apiKey: `us1-f5ed7156e4ef694ca8934651e5655363`, // gitleaks:allow
      isDevelopmentMode: true,
      workspaceToken: `6abe6ec6-0ebf-43b1-893a-25c19ee9a4b5`, // gitleaks:allow
      logLevel: `warning`,
      dataPlan: {
        planId: `web`,
        planVersion: 1
      },
      customFlags: {
        28: {
          initOptions
        }
      },
      enabledPlugins: {
        amplitude: true,
        braze: true,
        googleAnalytics: false, // disabled due to cross-domain tags
        googleTagManager: true,
        googleAnalytics4: true,
        bing: true,
        twitter: true,
        facebook: true,
        oneTrust: true,
        googleAds: true
      }
    },
    googleAnalytics: {
      isEnabled: false,
      debug: false,
      trackingId: `UA-7023626-77`,
      autoLinkDomains: [`cmcmarkets.com`, `cmcmarketsstockbroking.com.au`, `invest.cmcmarkets.com.au`, `cmcmarketsinvest.com`]
    },
    fullStory: {
      isEnabled: false,
      organisationId: `o-1AC8FX-na1`
    },
    braze: {
      isEnabled: true,
      apiKey: `874cc832-6643-4a22-8bb1-3355555e51aa`, // gitleaks:allow
      baseUrl: `sdk.fra-01.braze.eu`,
      contentSecurityNonce: `hVwQrR81zYgVYWPaKcB273RdGfONtljskBEYaXJbRs` // viewer-response.js
    }
  },
  activeBusinessRegions: [BusinessRegion.AUS, BusinessRegion.SGP],
  recaptchaConfiguration: {
    isEnabled: true,
    renderKey: '6Lecaj4pAAAAAC1gqSd6AGkNa2wZr416C4bhFYz-', // gitleaks:allow
    enterpriseScriptUrl: GOOGLE_RECAPTCHA_ENTERPRISE_URL
  }
};

export const getSignUpApiUrl = (appStage: AppStage) => {
  const domain = window.location.hostname;
  switch (appStage) {
    case AppStage.Prod:
      return !domain.includes(AU_DOMAIN) ? CMC_MARKETS_INVEST_SIGNUP_API_URL : AU_INVEST_CMC_MARKETS_SIGNUP_API_URL;
    case AppStage.Staging:
      return STAGING_SIGNUP_API_URL;
    default:
      return process.env.API_URL!;
  }
};

export const devConfig: IConfiguration = {
  ...baseConfig
};

export const stagingConfig: IConfiguration = {
  ...baseConfig,
  apiUrl: getSignUpApiUrl(AppStage.Staging),
  appStage: AppStage.Staging
};

export const prodConfig: IConfiguration = {
  ...baseConfig,
  apiUrl: getSignUpApiUrl(AppStage.Prod),
  appStage: AppStage.Prod,
  analytics: {
    ...baseConfig.analytics,
    mParticle: {
      ...baseConfig.analytics.mParticle,
      isDevelopmentMode: false,
      isEnabled: true
    },
    fullStory: {
      ...baseConfig.analytics.fullStory,
      organisationId: `o-1AC7DG-na1`,
      isEnabled: true
    },
    googleAnalytics: {
      ...baseConfig.analytics.googleAnalytics,
      isEnabled: true
    },
    braze: {
      ...baseConfig.analytics.braze,
      apiKey: `93464080-41c3-4549-b6fa-ef638a263c94` // gitleaks:allow
    }
  },
  recaptchaConfiguration: {
    ...baseConfig.recaptchaConfiguration,
    renderKey: '6LeuOfApAAAAAG2yl2YOMaqvTuiuNUlsST6yAD2L', // gitleaks:allow
    isEnabled: true
  }
};

const configs: Record<string, IConfiguration> = {
  production: prodConfig,
  staging: stagingConfig,
  development: devConfig
};

export const oneTrustDevConfig: IOneTrustConfig = {
  scriptUrl: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
  domain: '6cdd987a-d1bd-4784-a32a-a204a538aa3c-test'
};

export const oneTrustStagingConfig: IOneTrustConfig = {
  ...oneTrustDevConfig
};

export const oneTrustProdConfig: IOneTrustConfig = {
  ...oneTrustDevConfig,
  domain: '6cdd987a-d1bd-4784-a32a-a204a538aa3c'
};

const oneTrustConfigs: Record<string, IOneTrustConfig> = {
  production: oneTrustProdConfig,
  staging: oneTrustStagingConfig,
  development: oneTrustDevConfig
};

const bigScriptStagingConfig: IBigScriptConfig = {
  scriptUrl: 'https://uat-cdn.cmcmarkets.com/global/scripts/big-script/latest/cookies.js',
  mparticle: 'us1-f5ed7156e4ef694ca8934651e5655363',
  oneTrust: '0190e24d-d8ec-7e41-882a-99100400691a-test',
  region: 'au',
  language: 'en'
};

const bigScriptDevConfig: IBigScriptConfig = {
  scriptUrl: 'https://uat-cdn.cmcmarkets.com/global/scripts/big-script/latest/cookies.js',
  mparticle: 'us1-f5ed7156e4ef694ca8934651e5655363',
  oneTrust: '0190e24d-d8ec-7e41-882a-99100400691a-test',
  region: 'au',
  language: 'en'
};

const bigScriptProdConfig: IBigScriptConfig = {
  scriptUrl: 'https://cdn.cmcmarkets.com/global/scripts/big-script/latest/cookies.js',
  mparticle: 'us1-f5ed7156e4ef694ca8934651e5655363',
  oneTrust: '0190e24d-d8ec-7e41-882a-99100400691a',
  region: 'au',
  language: 'en'
};

export const bigScriptConfigs: Record<string, IBigScriptConfig> = {
  production: bigScriptProdConfig,
  staging: bigScriptStagingConfig,
  development: bigScriptDevConfig
};

export const createConfig: () => IConfiguration = () => {
  const config = configs[process.env.REACT_APP_STAGE || 'development'];
  // set API URL test a different API endpoint in development
  const devApiURL = process.env.API_URL || sessionStorage.getItem('API_URL');
  if (process.env.REACT_APP_STAGE === 'production') {
    config.apiUrl = getSignUpApiUrl(AppStage.Prod);
  } else if (process.env.REACT_APP_STAGE === 'staging') {
    config.apiUrl = getSignUpApiUrl(AppStage.Staging);
  } else if (devApiURL) {
    config.apiUrl = devApiURL;
  }
  return config;
};

export const getOneTrustConfig: () => IOneTrustConfig = () => {
  return oneTrustConfigs[process.env.REACT_APP_STAGE || 'development'];
};

export const getBigScriptConfig: () => IBigScriptConfig = () => {
  return bigScriptConfigs[process.env.REACT_APP_STAGE || 'development'];
};

export const getRecaptchaConfig: () => IRecaptchaConfiguration = () => {
  return createConfig().recaptchaConfiguration;
};
