/* eslint-disable no-console */
import { getRecaptchaConfig } from '../configuration/configuration';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const getRecaptchaToken = async (): Promise<string | undefined> => {
  const config = getRecaptchaConfig();
  const recaptchaToken = await new Promise<string | undefined>((resolve) => {
    if (window.grecaptcha) {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(config.renderKey, { action: 'username_available' })
          .then((token: string) => {
            resolve(token);
            return true;
          })
          .catch(() => {
            resolve(undefined);
            return false;
          });
      });
    } else resolve(undefined);
  });
  return recaptchaToken;
};
