/* eslint-disable unicorn/prefer-object-from-entries */
import { useEffect } from 'react';
import { PROMO_CODE } from '../constants/termsConfirmationConstants';

export const usePromoCode = () => {
  useEffect(() => {
    const search = window.location.search;

    const searchList = search?.slice(1).split('&');

    const searchObj = searchList?.reduce<Record<string, string>>((acc, curr) => {
      const [key, val] = curr.split('=');
      acc[key] = val;
      return acc;
    }, {});

    if (searchObj?.promo !== undefined) {
      window.localStorage.setItem(PROMO_CODE, decodeURIComponent(searchObj.promo));
    } else if (searchObj?.referrer !== undefined) {
      window.localStorage.setItem(PROMO_CODE, decodeURIComponent(searchObj.referrer));
    }
  }, [window.location.search]);
};
