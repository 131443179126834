import {
  CheckBox,
  FormControl,
  FormGroup,
  FormRow,
  ISelectOption,
  Select,
  Size,
  ValidationErrorMessage,
  Validators,
  useValidator,
  Text,
  FormCol,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { IQuestionProps } from '../../models/pepQuestions';
import { MONTHS_DISPLAY_WITH_NUMBERS, generateYearsDisplayWithNumbers } from '../../constants/commonConstants';

export const PEPQuestion6: React.FC<IQuestionProps> = ({ selected, setSelected, setValidators, answers, setAnswers, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `pepQ6`);

  const monthsSelectOptions = createKeyValueTranslatedToOptionList(MONTHS_DISPLAY_WITH_NUMBERS, t);
  const yearsSelectOptions = generateYearsDisplayWithNumbers();

  const q6DisqualificationValidator = useValidator<string>('', Validators.required(t('Required')));
  const q6MonthValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q6YearValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));

  useEffect(() => {
    if (selected) setValidators([q6DisqualificationValidator, q6MonthValidator, q6YearValidator]);
    else setValidators([]);

    setAnswers({
      ...answers,
      question6: {
        answered: selected ?? false,
        disqualification: q6DisqualificationValidator.value,
        month: q6MonthValidator.value?.value,
        year: q6YearValidator.value?.value
      }
    });
  }, [selected, q6DisqualificationValidator.value, q6MonthValidator.value, q6YearValidator.value]);

  return (
    <FormGroup>
      <Text>{t('Have you ever been disqualified, banned and/or faced any penalties by a government regulator?')}</Text>
      <FormGroup>
        <FormRow>
          <CheckBox
            label={t('Yes')}
            checked={selected}
            onChange={(e) => {
              setSelected(e.target.checked);
            }}
            disabled={selected}
            {...generateTestId(`yes`)}
          />
          <CheckBox
            label={t('No')}
            checked={selected === false}
            onChange={(e) => {
              setSelected(!e.target.checked);
            }}
            disabled={selected === false}
            {...generateTestId(`no`)}
          />
        </FormRow>
      </FormGroup>

      {selected && (
        <FormGroup>
          <Text>{t('Please provide details of the disqualification faced and the date of disqualification.')}</Text>
          <FormControl
            type='text'
            name='positionName'
            placeholder={t('Enter the disqualification')}
            value={q6DisqualificationValidator.value}
            onChange={(e) => {
              q6DisqualificationValidator.handleEvent(e.target.value);
            }}
            invalid={q6DisqualificationValidator.invalid}
            {...generateTestId(`disqualificationInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q6DisqualificationValidator} />
          <FormRow>
            <FormCol percentWidth={50}>
              <FormGroup {...generateTestId(`monthInput`)} style={{ marginTop: '0' }}>
                <Select
                  value={q6MonthValidator.value}
                  options={monthsSelectOptions}
                  placeholder={t('Select month')}
                  onChange={(option) => {
                    q6MonthValidator.handleEvent(option as ISelectOption);
                  }}
                  invalid={q6MonthValidator.invalid}
                  isClearable
                />
                <ValidationErrorMessage size={Size.Medium} validator={q6MonthValidator} />
              </FormGroup>
            </FormCol>
            <FormCol percentWidth={50}>
              <FormGroup {...generateTestId(`yearInput`)} style={{ marginTop: '0' }}>
                <Select
                  value={q6YearValidator.value}
                  options={yearsSelectOptions}
                  placeholder={t('Select year')}
                  onChange={(option) => {
                    q6YearValidator.handleEvent(option as ISelectOption);
                  }}
                  invalid={q6YearValidator.invalid}
                  isClearable
                />
                <ValidationErrorMessage size={Size.Medium} validator={q6YearValidator} />
              </FormGroup>
            </FormCol>
          </FormRow>
        </FormGroup>
      )}
    </FormGroup>
  );
};
