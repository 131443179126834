import { KeyValueTranslated } from '../translation';

export enum IdentityDocumentType {
  ForeignIdentificationNumber = 'FIN',
  NationalRegistrationIdentityCard = 'NRIC'
}

export const IDENTITY_DOCUMENT_TYPES: KeyValueTranslated = {
  [IdentityDocumentType.ForeignIdentificationNumber]: (t) => t('Foreign Identification (FIN)'),
  [IdentityDocumentType.NationalRegistrationIdentityCard]: (t) => t('National Registration Identity Card (NRIC)')
};
