import { breakpoint, Button, ButtonLink, FormGroup, Label, Notification, Size, Text, useTestId, Variant } from '@cmctechnology/phoenix-stockbroking-web-design';
import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { submitButtonIcon, submitButtonLabel } from '../constants/identificationValidationConstants';
import { hasExceededRequestLimitForVerifyIdStatus, IdentificationValidationStatus } from '../models/identification';
import { KeyValueTranslated } from '../models/translation';
import { actions, IStore } from '../Store/Store';
import { CenteredButton } from './CenteredButton';
import { NotificationCardFooter } from './NotificationCardFooter';
import { ApiRequest } from '../models/apiRequestResult';

export const InlineFlexIdCheckButton = styled(Button)`
  display: inline-flex;
  margin-right: 2em;
`;

const FormGroupWithLessPadding = styled(FormGroup)`
  // make longer text span one row only for not mobile screen
  @media only screen and (min-width: ${breakpoint.tablet}) {
    margin: 2rem -2rem 0;
  }
`;

const WarningMessageForSlowResponse: React.FC = () => {
  const { t } = useTranslation();
  return (
    <FormGroupWithLessPadding>
      <Label>{t(`This is taking longer than expected. Please don't close your browser.`)}</Label>
    </FormGroupWithLessPadding>
  );
};

interface IIdentificationCardFooterProps {
  validationStatus: IdentificationValidationStatus;
  onSubmit: () => void;
  onClose: () => void;
  validators?: IValidator<any>[];
}

const reviewButtonLabel: KeyValueTranslated<IdentificationValidationStatus> = {
  ...submitButtonLabel,
  [IdentificationValidationStatus.NotStarted]: (t) => t('Save')
};

export const IdentificationCardFooter: React.FC<IIdentificationCardFooterProps> = ({ validationStatus, onSubmit, onClose, validators, ...rest }) => {
  const reviewSection = useSelector((store: IStore) => store.local.reviewSection);
  const identityVerifyNeedLongerWaitingTime = useSelector((store: IStore) => store.local.identityVerifyNeedLongerWaitingTime);
  const identityVerifyTimeout = useSelector((store: IStore) => store.local.identityVerifyTimeout);
  const verifyIdentityStatusResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.VerifyIdentityStatus]);
  const hasExceededRequestLimitWhenVerifyIdStatus = hasExceededRequestLimitForVerifyIdStatus(verifyIdentityStatusResult.errorCode);
  const identityVerifyInProgressAfterLogin = useSelector((store: IStore) => store.local.identityVerifyInProgressAfterLogin);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `identification`);
  const isLoading =
    validationStatus === IdentificationValidationStatus.InProgress &&
    !identityVerifyTimeout &&
    !hasExceededRequestLimitWhenVerifyIdStatus &&
    !identityVerifyInProgressAfterLogin;

  const onClosed = () => {
    if (!reviewSection) {
      dispatch(actions.reviewSectionClear());
    }
    onClose();
  };

  if (validationStatus === IdentificationValidationStatus.Passed) {
    return (
      <NotificationCardFooter>
        <Notification variant={Variant.Success} onClose={onClosed}>
          <Text size={Size.Large}>{t('Your ID has been submitted successfully')}</Text>
        </Notification>
      </NotificationCardFooter>
    );
  }

  if (!reviewSection) {
    return (
      <>
        <FormGroup>
          <CenteredButton
            icon={submitButtonIcon[validationStatus]}
            label={submitButtonLabel[validationStatus](t)}
            onClick={onSubmit}
            validators={validators}
            {...generateTestId(`checkMyID`)}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </FormGroup>
        {identityVerifyNeedLongerWaitingTime && <WarningMessageForSlowResponse />}
      </>
    );
  }

  return (
    <>
      <FormGroup>
        <InlineFlexIdCheckButton
          icon={submitButtonIcon[validationStatus]}
          label={reviewButtonLabel[validationStatus](t)}
          onClick={onSubmit}
          validators={validators}
          isLoading={isLoading}
          disabled={isLoading}
        />
        <ButtonLink onClick={onClosed}>{t('Cancel')}</ButtonLink>
      </FormGroup>
      {identityVerifyNeedLongerWaitingTime && <WarningMessageForSlowResponse />}
    </>
  );
};
