import React from 'react';
import {
  FormCol,
  FormRow,
  Label,
  Text,
  CardContent,
  Heading3,
  FormGroup,
  FormLabel,
  Size,
  FormGroupSeparator
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { TaxResidentType } from '@cmctechnology/phoenix-stockbroking-api-client';
import { AUS_TAX_EXEMPTION_REASONS, ITaxDetails, OVERSEAS_TAX_EXEMPTION_REASONS, TAX_RESIDENT_TYPES } from '../models/profile';
import { COUNTRIES_ALL, COUNTRY_CODE_AUS } from '../constants/countryConstants';
import { ReviewEditButton } from '../Components/ReviewEditButton';

export interface IReviewProfilePersonalDetailsProps {
  taxDetails: ITaxDetails;
  onEdit: () => void;
}

export const ReviewProfileTaxDetails: React.FC<IReviewProfilePersonalDetailsProps> = ({ taxDetails, onEdit }) => {
  const { t } = useTranslation();

  const taxResidentTypeValue = taxDetails.taxResidentType ? TAX_RESIDENT_TYPES[taxDetails.taxResidentType] : undefined;
  const isAustralianTaxResident = taxResidentTypeValue?.isAustralianTaxResident && taxDetails.australianTaxInfo;
  const isOverseasTaxResident = taxResidentTypeValue?.isOverseasTaxResident && taxDetails.overseasTaxInfo;

  return (
    <CardContent>
      <FormRow>
        <FormCol percentWidth={80}>
          <Heading3>{t('Tax details')}</Heading3>
        </FormCol>
        <FormCol percentWidth={20}>
          <FormRow>
            <ReviewEditButton label={t('Edit')} onClick={onEdit} />
          </FormRow>
        </FormCol>
      </FormRow>
      {isAustralianTaxResident && (
        <>
          <FormGroup>
            <FormRow>
              <FormCol percentWidth={40}>
                <Label>{t('Country')}</Label>
              </FormCol>
              <FormCol percentWidth={60}>
                <Text size={Size.Medium}>{COUNTRIES_ALL[COUNTRY_CODE_AUS]?.(t)}</Text>
              </FormCol>
            </FormRow>
            {taxDetails.australianTaxInfo.tfn && (
              <FormRow>
                <FormCol percentWidth={40}>
                  <Label>{t('TFN/TIN')}</Label>
                </FormCol>
                <FormCol percentWidth={60}>
                  <Text size={Size.Medium}>{taxDetails.australianTaxInfo.tfn}</Text>
                </FormCol>
              </FormRow>
            )}
            {taxDetails.australianTaxInfo.haveTaxExemption && !!taxDetails.australianTaxInfo.taxExemptionReason && (
              <FormRow>
                <FormCol percentWidth={40}>
                  <Label>{t('Exemption')}</Label>
                </FormCol>
                <FormCol percentWidth={60}>
                  <Text size={Size.Medium}>{AUS_TAX_EXEMPTION_REASONS[taxDetails.australianTaxInfo.taxExemptionReason]?.(t)}</Text>
                </FormCol>
              </FormRow>
            )}
          </FormGroup>
        </>
      )}
      {isOverseasTaxResident && (
        <>
          {taxDetails.taxResidentType === TaxResidentType.AUOverseas && <FormGroupSeparator />}
          <FormGroup>
            <FormRow>
              <FormCol percentWidth={40}>
                <Label>{t('Country')}</Label>
              </FormCol>
              <FormCol percentWidth={60}>
                <Text size={Size.Medium}>{COUNTRIES_ALL[taxDetails.overseasTaxInfo.country]?.(t)}</Text>
              </FormCol>
            </FormRow>
            {taxDetails.overseasTaxInfo.tin && (
              <FormRow>
                <FormCol percentWidth={40}>
                  <FormLabel>{t('TFN/TIN')}</FormLabel>
                </FormCol>
                <FormCol percentWidth={60}>
                  <Text size={Size.Medium}>{taxDetails.overseasTaxInfo.tin}</Text>
                </FormCol>
              </FormRow>
            )}
            {taxDetails.overseasTaxInfo.haveTaxExemption && !!taxDetails.overseasTaxInfo.taxExemptionReason && (
              <FormRow>
                <FormCol percentWidth={40}>
                  <Label>{t('Exemption')}</Label>
                </FormCol>
                <FormCol percentWidth={60}>
                  <Text size={Size.Medium}>{OVERSEAS_TAX_EXEMPTION_REASONS[taxDetails.overseasTaxInfo.taxExemptionReason]?.(t)}</Text>
                </FormCol>
              </FormRow>
            )}
          </FormGroup>
        </>
      )}
    </CardContent>
  );
};
