import React, { useEffect } from 'react';
import {
  Button,
  CardContent,
  CardFooter,
  CardHeader,
  CardLarge,
  FormRow,
  Heading1,
  CircularIcon,
  Heading2,
  Text,
  IconNames,
  Size,
  breakpoint,
  Panel,
  Label,
  Variant,
  ButtonLink,
  FormGroupSeparator
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { IStore } from '../../Store/Store';
import { ApplicationStatus } from '../../models/application';
import { ApiRequest } from '../../models/apiRequestResult';
import { openExternalUrl, pollingApplicationStatusAndUpdateApplicationWaitingTimer } from '../../Store/Actions';
import { AccountProcessing } from '../../Components/AccountProcessing';
import { mobileCardHeaderHeading1FontCss } from '../../Components/Mobile/MobileCardHeader';
import { SGP_CMC_CONTACT_US } from '../../constants/sgp/urlConstants';
import { AppStoreButtons } from '../../Components/AppStoreButtons/AppStoreButtons';

const baseCardContentCss = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
`;

const StyledCardContent = styled(CardContent)`
  padding-bottom: 0;
  ${baseCardContentCss}
  ${Heading1} {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  ${mobileCardHeaderHeading1FontCss}
`;

const StyledCardFooter = styled(CardFooter)`
  display: flex;
`;

const StyledFormRowWithBottomMargin = styled(FormRow)`
  margin-bottom: 2.5rem;
`;

const StyledText = styled(Text)`
  white-space: normal;
`;

const ExploreButton = styled(Button)`
  @media (max-width: ${breakpoint.mobile}) {
    display: none;
  }
`;

const PanelWithIconAndText = styled(Panel)`
  line-break: anywhere;
  margin-bottom: 0;
  width: 100%;
  ${Label} {
    flex: 1;
  }
  ${FormGroupSeparator} {
    margin: 0;
  }
`;

const StyledTextWithTopMargin = styled(Text)`
  margin-top: 1rem;
`;

export interface IAccountReadyInformationProps {
  onStart: () => void;
  onGoBack: () => void;
}

export const AccountReadyInformation: React.FC<IAccountReadyInformationProps> = ({ onStart, onGoBack }) => {
  const email = useSelector((store: IStore) => store.remote.email);
  const submitSgpResponse = useSelector((store: IStore) => store.local.sgp.applicationSubmitResponse);
  const applicationStatus = useSelector((store: IStore) => store.local.applicationStatus);
  const submitResponseResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SubmitApplication]);
  const applicationModalWaitingTimeElapsed = useSelector((store: IStore) => store.local.applicationModalWaitingTimeElapsed);
  const latestApplicationId = useSelector((store: IStore) => store.local.latestApplicationId);
  const submitResponseResultErrorCode = submitResponseResult.errorCode;
  const isAccountReady = applicationStatus === ApplicationStatus.Completed || applicationStatus === ApplicationStatus.CashAccountCreated;
  const showSubmitErrorMessageScreen = submitSgpResponse?.error || submitResponseResultErrorCode;
  const showProcessScreen = !(isAccountReady || latestApplicationId || applicationModalWaitingTimeElapsed);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const username = email || submitSgpResponse?.username;
  const applicationGuid = submitSgpResponse?.applicationGuid;

  useEffect(() => {
    if (applicationGuid || latestApplicationId) {
      dispatch(pollingApplicationStatusAndUpdateApplicationWaitingTimer(latestApplicationId || applicationGuid!));
    }
  }, [applicationGuid, latestApplicationId]);

  if (showSubmitErrorMessageScreen) {
    return (
      <CardLarge>
        <CardHeader />
        <CardContent>
          <Heading2>{t('Something went wrong')}</Heading2>
          <Text size={Size.Medium}>{t('Please review your details, make sure they are all correct and then submit again.')}</Text>
        </CardContent>
        <CardFooter>
          <Button label={t('Review details')} onClick={() => onGoBack()} />
        </CardFooter>
      </CardLarge>
    );
  }
  return (
    <CardLarge>
      <AccountProcessing showProcessScreen={showProcessScreen} />
      {!showProcessScreen && (
        <StyledCardContent>
          <FormRow>
            <CircularIcon name={IconNames.Clock} size={Size.Large} />
          </FormRow>
          <FormRow>
            <Heading1>{t('Thanks for your application')}</Heading1>
          </FormRow>
          <StyledFormRowWithBottomMargin>
            <Text size={Size.Large} textAlign='center'>
              <Trans t={t}>
                <b>Congratulations! We have received your application, and it is currently being processed.</b>
                <br />
                Please note that you will not be able to engage in any trading activities until your application is finalised. However, in the meantime you can
                log in to the platform to explore our available features.
                <br />
                If we require any supporting documents, we will contact you via phone or email. If all required information is complete, we will finalise your
                application within the next 24 hours and send you a welcome email with your account details.
                <br />
              </Trans>
              {submitSgpResponse?.reference && (
                <>
                  {t('Your application reference is ')} {submitSgpResponse.reference}
                </>
              )}
            </Text>
          </StyledFormRowWithBottomMargin>
          <PanelWithIconAndText>
            <CircularIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />
            <Label>{t('Login username')}</Label>
            <StyledText size={Size.Large}>{username}</StyledText>
          </PanelWithIconAndText>

          <PanelWithIconAndText>
            <CircularIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />
            <Label>{t('Platform access')}</Label>
            <StyledText size={Size.Large}>{t('Available')}</StyledText>
          </PanelWithIconAndText>
        </StyledCardContent>
      )}
      <StyledCardFooter>
        <AppStoreButtons />

        <ExploreButton label={t('Explore Web Platform')} onClick={onStart} />

        <StyledTextWithTopMargin size={Size.Medium} textAlign='center'>
          {t('Need help?')} <ButtonLink onClick={() => openExternalUrl(SGP_CMC_CONTACT_US)}>{t('Contact Us')}</ButtonLink>
        </StyledTextWithTopMargin>
      </StyledCardFooter>
    </CardLarge>
  );
};
