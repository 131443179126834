import { useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../../../common/mappings';
import { COUNTRIES_RESIDENCE } from '../../../models/sgp/country';
import { ISgpAddressDetails } from '../../../models/sgp/profile';
import { ISelectOptionOrUndefined } from '../../../models/ISelectOptionOrUndefined';
import { EMPTY_STRING } from '../../../constants/commonConstants';

export const useAddressValidators = (address: ISgpAddressDetails | undefined, validated: boolean | undefined) => {
  const { t } = useTranslation();

  const validatorConfig = {
    validated,
    debounceMs: 0
  };

  const firstLineValidator = useValidator(
    address?.line1 ?? EMPTY_STRING,
    Validators.required(t('First line of your address is required'), `FirstLine`),
    validatorConfig
  );
  const townCityValidator = useValidator(address?.city ?? EMPTY_STRING, Validators.required(t('Town/city is required'), `TownCity`), validatorConfig);
  const postcodeValidator = useValidator(address?.postCode ?? EMPTY_STRING, Validators.required(t('Postcode is required'), `Postcode`), validatorConfig);

  const countriesSelectOption = createKeyValueTranslatedToOptionList(COUNTRIES_RESIDENCE, t);

  const countryValidator = useValidator<ISelectOptionOrUndefined>(
    countriesSelectOption.find((x) => x.value === address?.countryCode),
    Validators.required(t('Country is required'), `Country`),
    validatorConfig
  );

  return {
    firstLineValidator,
    townCityValidator,
    postcodeValidator,
    countryValidator
  };
};
