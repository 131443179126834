import { breakpoint, CardContent, CircularIcon, Heading1, Label, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';
import { mobileCardHeaderHeading1FontCss } from './Mobile/MobileCardHeader';

export const ModalTopIcon = styled(CircularIcon)`
  stroke-width: 0.12rem;
  min-width: 1rem;
`;

export const ModalText = styled(Text)`
  max-width: 19.25rem;
  white-space: normal;
  ${Label} {
    display: inline-block;
  }
  @media (max-width: ${breakpoint.mobile}) {
    flex-direction: column;
    margin-left: 0;
  }
`;

export const ModalCardContent = styled(CardContent)`
  padding: 5rem 3.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${Heading1} {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  @media (max-width: ${breakpoint.mobile}) {
    padding: 9.313rem 1.5rem;
    max-width: 22rem;
  }
  ${mobileCardHeaderHeading1FontCss}
`;
