import { useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../common/mappings';
import {
  AddressType,
  AUSTRALIAN_STATES,
  AUSTRALIAN_STREET_TYPES,
  invalidPostcodeValidations,
  invalidStreetNameValidationsForPostalAddress,
  invalidStreetNameValidationsForResidentialAddress,
  invalidStreetNumberValidations,
  invalidUnitNumberValidations,
  SimpleAddressRequiredErrorMessage,
  SimpleAddressRequiredField
} from '../constants/addressConstants';
import { IAddressInput } from '../models/profile';
import { ISelectOptionOrUndefined } from '../models/ISelectOptionOrUndefined';

export const useAddressValidators = (addressInput: IAddressInput, validated: boolean | undefined, addressType: AddressType) => {
  const { t } = useTranslation();
  const validatorConfig = {
    validated
  };

  const lookupErrorMessageKey =
    addressType === AddressType.Residential ? SimpleAddressRequiredField.ResidentialLookup : SimpleAddressRequiredField.PostalLookup;
  const lookUpAddressValidator = useValidator<ISelectOptionOrUndefined>(
    addressInput.unformattedAddressKey && addressInput.unformattedAddress
      ? { value: addressInput.unformattedAddressKey, label: addressInput.unformattedAddress }
      : undefined,
    Validators.required(SimpleAddressRequiredErrorMessage[lookupErrorMessageKey](t)),
    validatorConfig
  );

  const unitNumberValidator = useValidator(
    addressInput.unitNumber,
    addressType === AddressType.Residential
      ? Validators.combine(
          Object.keys(invalidUnitNumberValidations).map((key) =>
            Validators.custom((value) => !invalidUnitNumberValidations[key].regex.test(value), invalidUnitNumberValidations[key].message(t), key)
          )
        )
      : Validators.custom(() => true, ''),
    validatorConfig
  );

  const streetNumberValidator = useValidator(
    addressInput.streetNumber,
    addressType === AddressType.Residential
      ? Validators.combine(
          Object.keys(invalidStreetNumberValidations).map((key) =>
            Validators.custom((value) => !invalidStreetNumberValidations[key].regex.test(value), invalidStreetNumberValidations[key].message(t), key)
          )
        )
      : Validators.custom(() => true, ''),

    validatorConfig
  );

  const streetNameValidator = useValidator(
    addressInput.streetName,
    addressType === AddressType.Residential
      ? Validators.combine(
          Object.keys(invalidStreetNameValidationsForResidentialAddress).map((key) =>
            Validators.custom(
              (value) => !invalidStreetNameValidationsForResidentialAddress[key].regex.test(value),
              invalidStreetNameValidationsForResidentialAddress[key].message(t),
              key
            )
          )
        )
      : Validators.combine(
          Object.keys(invalidStreetNameValidationsForPostalAddress).map((key) =>
            Validators.custom(
              (value) => !invalidStreetNameValidationsForPostalAddress[key].regex.test(value),
              invalidStreetNameValidationsForPostalAddress[key].message(t),
              key
            )
          )
        ),
    validatorConfig
  );

  const streetTypesSelectOptions = createKeyValueTranslatedToOptionList(AUSTRALIAN_STREET_TYPES, t);
  const streetTypeValidator = useValidator<ISelectOptionOrUndefined>(
    streetTypesSelectOptions.find((x) => x.value === addressInput.streetType),
    addressType === AddressType.Residential
      ? Validators.required(SimpleAddressRequiredErrorMessage[SimpleAddressRequiredField.StreetType](t))
      : Validators.custom(() => true, ''),
    validatorConfig
  );

  const cityValidator = useValidator(addressInput.city, Validators.required(SimpleAddressRequiredErrorMessage[SimpleAddressRequiredField.Suburb](t)));

  const statesSelectOption = createKeyValueTranslatedToOptionList(AUSTRALIAN_STATES, t);
  const stateValidator = useValidator<ISelectOptionOrUndefined>(
    statesSelectOption.find((x) => x.value === addressInput.state),
    Validators.required(SimpleAddressRequiredErrorMessage[SimpleAddressRequiredField.State](t)),
    validatorConfig
  );

  const postcodeValidator = useValidator(
    addressInput.postcode,
    Validators.combine(
      Object.keys(invalidPostcodeValidations).map((key) =>
        Validators.custom((value) => !invalidPostcodeValidations[key].regex.test(value), invalidPostcodeValidations[key].message(t), key)
      )
    ),
    validatorConfig
  );

  return {
    lookUpAddressValidator,
    unitNumberValidator,
    streetNumberValidator,
    streetNameValidator,
    streetTypeValidator,
    cityValidator,
    stateValidator,
    postcodeValidator
  };
};
