import { breakpoint, Icon, IconNames, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';
import { ENABLED_TABINDEX_FOR_NON_INPUT, ENTER_KEY } from '../constants/commonConstants';

// TODO move to web-design
const triggerIconOnClickWhenPressEnterKey = (e: React.KeyboardEvent<SVGElement>, onClick: React.MouseEventHandler<SVGElement> | undefined) => {
  const { key } = e;
  if (key !== ENTER_KEY) return;
  if (onClick) {
    onClick(e as any);
  }
};

export const GoBackIcon = styled(Icon).attrs(({ onClick }) => ({
  name: IconNames.ArrowLeft,
  size: Size.Large,
  tabIndex: ENABLED_TABINDEX_FOR_NON_INPUT,
  onClick,
  onKeyDown: (e) => {
    triggerIconOnClickWhenPressEnterKey(e, onClick);
  }
}))`
  cursor: pointer;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  padding: 0;
  @media (max-width: ${breakpoint.mobile}) {
    top: 0.85rem;
    left: 0.5rem;
  }
`;
