import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEffectiveBusinessRegion } from '../models/route';
import { useBusinessRegion } from './useBusinessRegion';

export const useParamsToSetBusinessRegion = () => {
  const { businessRegion: previousBusinessRegion, setBusinessRegion } = useBusinessRegion();
  const { businessRegion } = useParams<{ businessRegion: string }>();

  useEffect(() => {
    const effectiveBusinessRegion = getEffectiveBusinessRegion(businessRegion);
    if (previousBusinessRegion !== effectiveBusinessRegion) {
      setBusinessRegion(effectiveBusinessRegion);
    }
  }, [businessRegion]);
};
