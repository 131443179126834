import { KeyValueTranslated } from '../translation';

export const COUNTRY_CODE_SG = 'SG';
export const COUNTRY_CODE_MY = 'MY';
export const COUNTRY_CODE_PH = 'PH';
export const COUNTRY_CODE_TH = 'TH';
export const COUNTRY_CODE_TW = 'TW';
export const COUNTRY_CODE_VN = 'VN';

export const COUNTRIES_ALL: KeyValueTranslated = {
  AB: (t) => t('ARUBA'),
  AD: (t) => t('ANDORRA'),
  AE: (t) => t('UNITED ARAB EMIRATES'),
  AF: (t) => t('AFGHANISTAN'),
  AG: (t) => t('ANTIGUA'),
  AI: (t) => t('ANGUILLA'),
  AL: (t) => t('ALBANIA'),
  AM: (t) => t('ARMENIA'),
  AN: (t) => t('NETHERLANDS ANTILLES'),
  AO: (t) => t('ANGOLA'),
  AR: (t) => t('ARGENTINA'),
  AS: (t) => t('AMERICAN SAMOA'),
  AT: (t) => t('AUSTRIA'),
  AU: (t) => t('AUSTRALIA'),
  AZ: (t) => t('AZERBAIJAN'),
  BA: (t) => t('BOSNIA-HERZEGOVINA'),
  BB: (t) => t('BARBADOS'),
  BD: (t) => t('BANGLADESH'),
  BE: (t) => t('BELGIUM'),
  BF: (t) => t('BURKINA FASO'),
  BG: (t) => t('BULGARIA'),
  BH: (t) => t('BAHRAIN'),
  BI: (t) => t('BURUNDI'),
  BJ: (t) => t('BENIN'),
  BL: (t) => t('BELARUS'),
  BM: (t) => t('BERMUDA'),
  BN: (t) => t('BRUNEI'),
  BO: (t) => t('BOLIVIA'),
  BQ: (t) => t('BRITISH ANTARCTIC TERRITORY'),
  BR: (t) => t('BRAZIL'),
  BS: (t) => t('BAHAMAS'),
  BT: (t) => t('BHUTAN'),
  BU: (t) => t('MYANMAR'),
  BW: (t) => t('BOTSWANA'),
  BZ: (t) => t('BELIZE'),
  CA: (t) => t('CANADA'),
  CB: (t) => t('CROATIA'),
  CC: (t) => t('COCOS KEELING ISLAND'),
  CD: (t) => t('CHANNEL ISLANDS'),
  CF: (t) => t('CENTRAL AFRICAN REPUBLIC'),
  CG: (t) => t('CONGO'),
  CH: (t) => t('SWITZERLAND'),
  CI: (t) => t('IVORY COAST'),
  CK: (t) => t('COOK ISLANDS'),
  CL: (t) => t('CHILE'),
  CM: (t) => t('CAMEROON'),
  CN: (t) => t('CHINA'),
  CO: (t) => t('COLOMBIA'),
  CR: (t) => t('COSTA RICA'),
  CT: (t) => t('CANTON & ENDERBURY ISLANDS'),
  CU: (t) => t('CUBA'),
  CV: (t) => t('CAPE VERDE'),
  CX: (t) => t('CHRISTMAS ISLAND'),
  CY: (t) => t('CYPRUS'),
  CZ: (t) => t('CZECH REPUBLIC'),
  DG: (t) => t('GERMANY'),
  DJ: (t) => t('DJIBOUTI'),
  DK: (t) => t('DENMARK'),
  DM: (t) => t('DOMINICA'),
  DO: (t) => t('DOMINICAN REPUBLIC'),
  DZ: (t) => t('ALGERIA'),
  EC: (t) => t('ECUADOR'),
  EG: (t) => t('EGYPT'),
  EH: (t) => t('WESTERN SAHARA'),
  EN: (t) => t('ESTONIA'),
  ER: (t) => t('ERITREA'),
  ES: (t) => t('SPAIN'),
  ET: (t) => t('ETHIOPIA'),
  EW: (t) => t('ESWATINI'),
  FI: (t) => t('FINLAND'),
  FJ: (t) => t('FIJI'),
  FK: (t) => t('FALKLAND ISLANDS'),
  FO: (t) => t('FAEROE ISLANDS'),
  FQ: (t) => t('FRENCH SOUTHERN & ANTARCTIC TERRITORIES'),
  FR: (t) => t('FRANCE'),
  GA: (t) => t('GABON'),
  GB: (t) => t('UNITED KINGDOM'),
  GD: (t) => t('GRENADA'),
  GF: (t) => t('FRENCH GUIANA'),
  GH: (t) => t('GHANA'),
  GI: (t) => t('GIBRALTAR'),
  GK: (t) => t('GUERNSEY'),
  GL: (t) => t('GREENLAND'),
  GM: (t) => t('GAMBIA'),
  GN: (t) => t('GUINEA'),
  GO: (t) => t('GEORGIA'),
  GP: (t) => t('GUADELOUPE'),
  GQ: (t) => t('EQUATORIAL GUINEA'),
  GR: (t) => t('GREECE'),
  GT: (t) => t('GUATEMALA'),
  GU: (t) => t('GUAM'),
  GW: (t) => t('GUINEA-BISSAU'),
  GY: (t) => t('GUYANA'),
  GZ: (t) => t('GAZA'),
  HK: (t) => t('HONG KONG'),
  HM: (t) => t('HEARD ISLAND & MCDONALD ISLANDS'),
  HN: (t) => t('HONDURAS'),
  HS: (t) => t('HONG KONG SAR'),
  HT: (t) => t('HAITI'),
  HU: (t) => t('HUNGARY'),
  HV: (t) => t('UPPER VOLTA'),
  ID: (t) => t('INDONESIA'),
  IE: (t) => t('IRELAND'),
  IL: (t) => t('ISRAEL'),
  IN: (t) => t('INDIA'),
  IO: (t) => t('BRITISH INDIAN OCEAN TERRITORY'),
  IQ: (t) => t('IRAQ'),
  IR: (t) => t('IRAN'),
  IS: (t) => t('ICELAND'),
  IT: (t) => t('ITALY'),
  JM: (t) => t('JAMAICA'),
  JO: (t) => t('JORDAN'),
  JP: (t) => t('JAPAN'),
  JT: (t) => t('JOHNSTON ISLAND'),
  KA: (t) => t('CAMBODIA'),
  KE: (t) => t('KENYA'),
  KG: (t) => t('KIRGHIZIA'),
  KI: (t) => t('KIRIBATI'),
  KM: (t) => t('COMOROS'),
  KN: (t) => t('ST. KITTS-NEVIS'),
  KP: (t) => t('D.P.R. KOREA'),
  KR: (t) => t('KOREA, SOUTH'),
  KS: (t) => t('KYRGYZSTAN'),
  KV: (t) => t('KOSOVO'),
  KW: (t) => t('KUWAIT'),
  KY: (t) => t('CAYMAN ISLANDS'),
  KZ: (t) => t('KAZAKHSTAN'),
  LA: (t) => t('LAOS'),
  LB: (t) => t('LEBANON'),
  LC: (t) => t('ST. LUCIA'),
  LH: (t) => t('LITHUANIA'),
  LI: (t) => t('LIECHTENSTEIN'),
  LK: (t) => t('SRI LANKA'),
  LR: (t) => t('LIBERIA'),
  LS: (t) => t('LESOTHO'),
  LU: (t) => t('LUXEMBOURG'),
  LV: (t) => t('LATVIA'),
  LY: (t) => t('LIBYA'),
  MA: (t) => t('MOROCCO'),
  MB: (t) => t('MACEDONIA'),
  MC: (t) => t('MONACO'),
  MD: (t) => t('MOLDOVA'),
  ME: (t) => t('MAYOTTE'),
  MF: (t) => t('MACAO SAR'),
  MG: (t) => t('MADAGASCAR'),
  MH: (t) => t('MARSHALL ISLANDS'),
  MI: (t) => t('MIDWAY ISLANDS'),
  MJ: (t) => t('MONTENEGRO'),
  ML: (t) => t('MALI'),
  MM: (t) => t('ISLE OF MAN'),
  MN: (t) => t('MONGOLIA'),
  MO: (t) => t('MACAO'),
  MQ: (t) => t('MARTINIQUE'),
  MR: (t) => t('MAURITANIA'),
  MS: (t) => t('MONTSERRAT'),
  MT: (t) => t('MALTA'),
  MU: (t) => t('MAURITIUS'),
  MV: (t) => t('MALDIVES'),
  MW: (t) => t('MALAWI'),
  MX: (t) => t('MEXICO'),
  MY: (t) => t('MALAYSIA'),
  MZ: (t) => t('MOZAMBIQUE'),
  NA: (t) => t('NAMIBIA'),
  NC: (t) => t('NEW CALEDONIA'),
  NE: (t) => t('NIGER'),
  NF: (t) => t('NORFOLK ISLAND'),
  NG: (t) => t('NIGERIA'),
  NI: (t) => t('NICARAGUA'),
  NL: (t) => t('NETHERLANDS'),
  NM: (t) => t('NORTH MACEDONIA'),
  NO: (t) => t('NORWAY'),
  NP: (t) => t('NEPAL'),
  NR: (t) => t('NAURU'),
  NU: (t) => t('NIUE ISLAND'),
  NZ: (t) => t('NEW ZEALAND'),
  OM: (t) => t('OMAN'),
  PA: (t) => t('PANAMA'),
  PB: (t) => t('PALESTINE'),
  PC: (t) => t('PACIFIC ISLAND TRUST TERRITORY'),
  PE: (t) => t('PERU'),
  PF: (t) => t('FRENCH POLYNESIA'),
  PG: (t) => t('PAPUA NEW GUINEA'),
  PH: (t) => t('PHILIPPINES'),
  PK: (t) => t('PAKISTAN'),
  PL: (t) => t('POLAND'),
  PM: (t) => t('ST. PIERRE & MIQUELON'),
  PN: (t) => t('PITCAIRN ISLAND'),
  PR: (t) => t('PUERTO RICO'),
  PT: (t) => t('PORTUGAL'),
  PW: (t) => t('PALAU'),
  PY: (t) => t('PARAGUAY'),
  PZ: (t) => t('PANAMA CANAL ZONE'),
  QA: (t) => t('QATAR'),
  RE: (t) => t('REUNION'),
  RF: (t) => t('RUSSIA'),
  RO: (t) => t('ROMANIA'),
  RS: (t) => t('SERBIA'),
  RW: (t) => t('RWANDA'),
  SA: (t) => t('SAUDI ARABIA'),
  SB: (t) => t('SOLOMON ISLANDS'),
  SC: (t) => t('SEYCHELLES'),
  SD: (t) => t('SUDAN'),
  SE: (t) => t('SWEDEN'),
  SF: (t) => t('SERBIA/MONTENEGRO'),
  [COUNTRY_CODE_SG]: (t) => t('SINGAPORE'),
  SH: (t) => t('ST. HELENA'),
  SI: (t) => t('SLOVENIA'),
  SK: (t) => t('SLOVAK REPUBLIC'),
  SL: (t) => t('SIERRA LEONE'),
  SM: (t) => t('SAN MARINO'),
  SN: (t) => t('SENEGAL'),
  SO: (t) => t('SOMALIA'),
  SR: (t) => t('SURINAME'),
  ST: (t) => t('SAO TOME & PRINCIPE'),
  SV: (t) => t('EL SALVADOR'),
  SX: (t) => t('SOUTH SUDAN'),
  SY: (t) => t('SYRIA'),
  SZ: (t) => t('SWAZILAND'),
  TC: (t) => t('TURKS & CAICOS ISLANDS'),
  TD: (t) => t('CHAD'),
  TE: (t) => t('TIMOR'),
  TG: (t) => t('TOGO'),
  TH: (t) => t('THAILAND'),
  TI: (t) => t('TAJIKISTAN'),
  TK: (t) => t('TOKELAU ISLANDS'),
  TM: (t) => t('TURKMENISTAN'),
  TN: (t) => t('TUNISIA'),
  TO: (t) => t('TONGA'),
  TP: (t) => t('EAST TIMOR'),
  TR: (t) => t('TURKEY'),
  TT: (t) => t('TRINIDAD & TOBAGO'),
  TV: (t) => t('TUVALU'),
  TW: (t) => t('TAIWAN'),
  TZ: (t) => t('TANZANIA'),
  UG: (t) => t('UGANDA'),
  UN: (t) => t('UNKNOWN'),
  UR: (t) => t('UKRAINE'),
  US: (t) => t('UNITED STATES'),
  UY: (t) => t('URUGUAY'),
  UZ: (t) => t('UZBEKISTAN'),
  VA: (t) => t('VATICAN CITY STATE'),
  VC: (t) => t('ST. VINCENT'),
  VE: (t) => t('VENEZUELA'),
  VG: (t) => t('BRITISH VIRGIN ISLANDS'),
  VI: (t) => t('US VIRGIN ISLANDS'),
  VN: (t) => t('VIETNAM'),
  VU: (t) => t('VANUATU'),
  WF: (t) => t('WALLIS AND FUTUNA'),
  WK: (t) => t('WAKE ISLAND'),
  WM: (t) => t('SAMOA'),
  XX: (t) => t('OTHERS'),
  YM: (t) => t('YEMEN'),
  YU: (t) => t('YUGOSLAVIA'),
  ZA: (t) => t('SOUTH AFRICA'),
  ZM: (t) => t('ZAMBIA'),
  ZR: (t) => t('DEMOCRATIC REPUBLIC OF CONGO'),
  ZW: (t) => t('ZIMBABWE')
};

export const COUNTRIES_RESIDENCE: KeyValueTranslated = {
  [COUNTRY_CODE_SG]: (t) => t('SINGAPORE'),
  [COUNTRY_CODE_MY]: (t) => t('MALAYSIA'),
  [COUNTRY_CODE_PH]: (t) => t('PHILIPPINES'),
  [COUNTRY_CODE_TH]: (t) => t('THAILAND'),
  [COUNTRY_CODE_TW]: (t) => t('TAIWAN'),
  [COUNTRY_CODE_VN]: (t) => t('VIETNAM')
};

export const isValidCountyOfResidence = (countryCode: string) =>
  [COUNTRY_CODE_SG, COUNTRY_CODE_TH, COUNTRY_CODE_VN, COUNTRY_CODE_PH, COUNTRY_CODE_TW, COUNTRY_CODE_MY].includes(countryCode);
