import { UsersErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { Translated } from '../translation';
import { BaseErrorCode, baseErrorMessages } from '../baseErrorCodes';
import { baseUserErrorCodeMappings } from './baseUserErrorCodeMappings';

enum VerifyEmailErrorCode {
  NotVerifiedEmail = 'NotVerifiedEmail',
  CodeDeliveryFailed = 'CodeDeliveryFailed',
  CodeMismatch = 'CodeMismatch'
}

// Map backend error code [UsersErrorCode] to client error code[VerifyEmailErrorCode]
const verifyEmailErrorCodeMappings: Record<UsersErrorCode, BaseErrorCode | VerifyEmailErrorCode> = {
  ...baseUserErrorCodeMappings,
  [UsersErrorCode.SignUpConfirmFail]: VerifyEmailErrorCode.NotVerifiedEmail,
  [UsersErrorCode.CodeDeliveryFailureException]: VerifyEmailErrorCode.CodeDeliveryFailed,
  [UsersErrorCode.CodeMismatchException]: VerifyEmailErrorCode.CodeMismatch
};

const verifyEmailErrorMessages: Record<BaseErrorCode | VerifyEmailErrorCode, Translated> = {
  ...baseErrorMessages,
  [VerifyEmailErrorCode.CodeDeliveryFailed]: (t) => t('Sorry, the code could not be resent. Please try again.'),
  [VerifyEmailErrorCode.NotVerifiedEmail]: (t) => t('Sorry, your email has not been verified!'),
  [VerifyEmailErrorCode.CodeMismatch]: (t) => t('Sorry, the code provided is wrong, please try again or request a new code!')
};

export const getVerifyingEmailErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = errorCode as UsersErrorCode;

  return verifyEmailErrorMessages[verifyEmailErrorCodeMappings[errorCodeEnum]](t);
};
