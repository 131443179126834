import { CardContent, CardLarge, CircularIcon, FormRow, Heading1, IconNames, Size, Text, useModalState } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BusinessRegion } from '../configuration/configuration';
import { useBusinessRegion } from '../hooks/useBusinessRegion';
import { ApplicationReadyHeaderTitles, applicationReadyHeaderTitles } from '../models/application';
import { mobileCardHeaderHeading1FontCss } from './Mobile/MobileCardHeader';

const CardContentOverlay = styled(CardContent)`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  min-height: 37.5rem;
  ${mobileCardHeaderHeading1FontCss}
`;

export const AccountProcessingModal: React.FC = () => {
  const { t } = useTranslation();
  const { businessRegion } = useBusinessRegion();

  return (
    <CardLarge>
      <CardContentOverlay>
        <FormRow>
          <CircularIcon name={IconNames.BarChart2} size={Size.Large} />
        </FormRow>
        <FormRow>
          <Heading1>{applicationReadyHeaderTitles[ApplicationReadyHeaderTitles.ProcessingYourApplication](t)}</Heading1>
        </FormRow>
        <FormRow>
          <Text size={Size.Large} textAlign='center'>
            {businessRegion === BusinessRegion.AUS && (
              <Trans t={t}>
                We&apos;re verifying your details and setting up your CMC Invest account
                <br />
              </Trans>
            )}
            {businessRegion === BusinessRegion.SGP &&
              t('Hold tight! We are processing your information and setting up your CMC Markets Singapore Invest Account.')}
          </Text>
        </FormRow>
      </CardContentOverlay>
    </CardLarge>
  );
};

export interface IAccountProcessingProps {
  showProcessScreen: boolean;
}

export const AccountProcessing: React.FC<IAccountProcessingProps> = ({ showProcessScreen }) => {
  const [, setModalState] = useModalState();
  useEffect(() => {
    setModalState({
      open: showProcessScreen,
      dialog: <AccountProcessingModal />,
      disableBackdropClick: true
    });
    return () => {
      setModalState({
        open: false
      });
    };
  }, [showProcessScreen]);
  return <></>;
};
