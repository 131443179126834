import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import styled from 'styled-components';
import { Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import { IPasswordValidation } from '../constants/loginConstants';

export const NoMarginTextSmall = styled(Text)`
  && {
    margin: 0;
  }
`;

export const CANNOT_START_END_WITH_SPACES_VALIDATION_ID = 'cannotStartEndWithSpaces';

export const startOrEndWithSpaces = (passwordValidator: IValidator<string>) => {
  const validationResultForStarOrEndWithSpaces = passwordValidator.results.find((x) => x.id === CANNOT_START_END_WITH_SPACES_VALIDATION_ID);
  return passwordValidator.validated && validationResultForStarOrEndWithSpaces && !validationResultForStarOrEndWithSpaces.valid;
};

export const getPasswordValidationsWithoutCheckingStartEndSpaces = (passwordValidations: IPasswordValidation[]) => {
  return passwordValidations.filter((x) => x.id !== CANNOT_START_END_WITH_SPACES_VALIDATION_ID);
};
