import { Dispatch, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { createConfig } from '../configuration/configuration';
import { pages } from '../models/pagePaths';
import { Page } from '../models/page';
import { resetStoreAndRedirectToLoginPage } from '../Store/Actions';

dayjs.extend(duration);

const { signOutTimeoutMins } = createConfig();

const DAYJS_MINUTES_KEY = 'minutes';

const getTimeoutMs = (authenticatedAt: number) => {
  const now = dayjs();
  const expiry = dayjs.unix(authenticatedAt).add(signOutTimeoutMins, DAYJS_MINUTES_KEY);

  const diff = dayjs.duration(expiry.diff(now));

  const timeoutMs = diff.asMilliseconds();

  return timeoutMs < 0 ? 0 : timeoutMs;
};

// TODO: This is just a temporary solution. We should refresh the tokens every 5 mins instead after MVP.
export const useAuthorisationTimeout = (dispatch: Dispatch<any>, page: Page, authenticatedAt?: number) => {
  const [authenticated, setAuthenticated] = useState(!!authenticatedAt);

  useEffect(() => {
    let timeout: number | undefined;

    if (authenticatedAt) {
      setAuthenticated(true);
      const timeoutMs = getTimeoutMs(authenticatedAt);

      timeout = window.setTimeout(() => {
        setAuthenticated(false);
        dispatch(resetStoreAndRedirectToLoginPage());
      }, timeoutMs);
    } else if (!pages[page].public) {
      setAuthenticated(false);
      dispatch(resetStoreAndRedirectToLoginPage());
    }

    return () => {
      window.clearTimeout(timeout);
    };
  }, [page, authenticatedAt]);

  return { authenticated };
};
