import { Icon, IconNames, Size, Variant } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';

export const MinusIcon = styled(Icon).attrs(({ onClick }) => ({
  name: IconNames.MinusSquare,
  size: Size.Large,
  variant: Variant.Error,
  onClick
}))`
  cursor: pointer;
`;
