import React from 'react';
import styled from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';
import { Text, Size, IThemeProps, Link } from '@cmctechnology/phoenix-stockbroking-web-design';
import { PageWithPodHeader } from './PageWithPodHeader';
import { CMC_MARKETS_WEBSITE_LEGAL, OPEN_EXTERNAL_URL_TARGET } from '../../constants/urlConstants';
import { HelpButton } from '../Login/HelpButton';
import { useBusinessRegion } from '../../hooks/useBusinessRegion';
import { BusinessRegion } from '../../configuration/configuration';
import { SGP_CMC_IMPORTANT_INFO } from '../../constants/sgp/urlConstants';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Footer = styled.div`
  max-width: 75rem;
  display: flex;
  align-items: center;

  ${Text} {
    padding: 1.25rem 1.25rem 2.5rem;
    color: ${(props: IThemeProps) => props.theme.colours.default.text};
    transition: color 1s linear;
  }

  ${Link} {
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      color: ${(props: IThemeProps) => props.theme.colours.default.text};
    }
  }
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  // todo: This is a tmp solution, should be replaced by pod colors.
  // it doesn't exist in the theme pallets, so leaving hardcoded here.
  background-color: #40d3ee;
`;

export const PageWithPod: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const disclaimerDate = new Date().getFullYear();
  const { businessRegion } = useBusinessRegion();

  return (
    <Wrapper>
      <Background />
      <PageWithPodHeader />
      {children}
      <Footer>
        <Text size={Size.Small}>
          {businessRegion === BusinessRegion.SGP ? (
            <Trans t={t}>
              <b>Disclaimer:</b> © CMC Markets Singapore Invest Pte. Ltd.. Co. Reg. No./UEN 202217639M. Regulated and licensed by the Monetary Authority of
              Singapore (Capital Markets Services License No: 101320). Refer to
              <Link href={SGP_CMC_IMPORTANT_INFO} target={OPEN_EXTERNAL_URL_TARGET} size={Size.Small}>
                <b> our Website </b>
              </Link>
              for Terms and Conditions, Risk Disclosures, Privacy Statement, Dispute Handling process and other important information.
            </Trans>
          ) : (
            <Trans t={t}>
              <b>Disclaimer:</b> © CMC Markets Stockbroking Ltd {{ disclaimerDate }} Participant of ASX Group. ABN 69 081 002 851 Australian Financial Services
              No:246381. Refer to
              <Link href={CMC_MARKETS_WEBSITE_LEGAL} target={OPEN_EXTERNAL_URL_TARGET} size={Size.Small}>
                <b> our Website </b>
              </Link>
              for Terms and Conditions, Privacy Statement, Risk Notice, Dispute Handling process and other important legal documents.
            </Trans>
          )}
        </Text>
      </Footer>
      <HelpButton />
    </Wrapper>
  );
};
