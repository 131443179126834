import { CardContent } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled, { css } from 'styled-components';

export interface IFullWidthCardContentProps {
  needSmallPadding: boolean;
}

export const FullWidthCardContent = styled(CardContent)<IFullWidthCardContentProps>`
  width: 100%;
  margin: 0;
  padding: 0; // remove all of our paddings since iframe content already has the padding from jumio styling
  min-height: 15rem;
  ${(props: IFullWidthCardContentProps) =>
    props.needSmallPadding &&
    css`
      padding: 2rem;
    `}
`;
