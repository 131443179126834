// TODO will do the validation in the manual input ticket
/* istanbul ignore file */
import { Validators, useValidator } from '@cmctechnology/phoenix-stockbroking-web-design';
import { DayMonthYear, DayMonthYear1 } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { createKeyValueTranslatedToOptionList } from '../../../common/mappings';
import { EMPTY_STRING } from '../../../constants/commonConstants';
import { DATE_FORMAT, FORMATTED_MONTH_FIXED_LENGTH, invalidMiddleNameValidations, MIDDLE_NAME_LENGTH_WHEN_NO_VALUE } from '../../../constants/personalDetails';
import { getErrorMessageForNameStartingWithFullStop, isNameNotStartedWithFullStop } from '../../../models/personalDetails';
import { ISgpPersonalDetails } from '../../../models/sgp/profile';
import { SGP_PERSON_TITLES } from '../../../models/sgp/titleConstants';
import { DOB_VALIDATION_MESSAGES } from '../../../constants/validationConstants';
import { COUNTRIES_ALL, COUNTRIES_RESIDENCE } from '../../../models/sgp/country';
import { IDENTITY_DOCUMENT_TYPES } from '../../../models/sgp/identityDocumentType';
import { dateValidator } from '../../../validators/dateValidator';
import { MINIMUM_AGE, SGP_NAME_ALLOWED_CHARACTER_REGEX, SGP_MIDDLE_NAME_ALLOWED_CHARACTER_REGEX } from '../../../constants/sgp/profileDetails';
import { ISelectOptionOrUndefined } from '../../../models/ISelectOptionOrUndefined';
import { isUserEnteredNameValid } from './personalDetailsConditions';
import { removeIllegalCharacters } from '../../../utils/stringUtils';

export const usePersonalDetailsValidators = (personalDetails: ISgpPersonalDetails) => {
  const { t } = useTranslation();
  const validatorConfig = {
    validated: personalDetails.validated
  };

  const titleSelectOptions = createKeyValueTranslatedToOptionList(SGP_PERSON_TITLES, t);
  const titleValidator = useValidator<ISelectOptionOrUndefined>(
    titleSelectOptions.find((x) => x.value === personalDetails.title),
    Validators.required(t('Required')),
    validatorConfig
  );

  const clientEnteredNameValidator = useValidator(
    personalDetails.useClientEnteredName ?? false,
    Validators.required(t('Required'), `ClientEnteredName`),
    validatorConfig
  );

  const firstNameValidator = useValidator(
    personalDetails.clientEnteredFirstName ?? EMPTY_STRING,
    Validators.custom((value) => !clientEnteredNameValidator.value || !!value, t('Required')).custom(
      (value: string) => !clientEnteredNameValidator.value || SGP_NAME_ALLOWED_CHARACTER_REGEX.test(value),
      t('This field can only contain alpha characters, hyphens, apostrophe and spaces')
    ),
    validatorConfig
  );

  const middleNameValidator = useValidator(
    personalDetails.clientEnteredMiddleName ?? EMPTY_STRING,
    Validators.combine(
      Object.keys(invalidMiddleNameValidations).map((key) =>
        Validators.custom((value: string) => !invalidMiddleNameValidations[key].regex.test(value), invalidMiddleNameValidations[key].message(t), key)
      )
    ).custom(
      (value: string) =>
        value.length === MIDDLE_NAME_LENGTH_WHEN_NO_VALUE ||
        (value.length > MIDDLE_NAME_LENGTH_WHEN_NO_VALUE && SGP_MIDDLE_NAME_ALLOWED_CHARACTER_REGEX.test(value)),
      t('This field can only contain alpha characters, hyphens, apostrophe and spaces')
    ),
    validatorConfig
  );
  const lastNameValidator = useValidator(
    personalDetails.clientEnteredLastName ?? EMPTY_STRING,
    Validators.custom((value) => !clientEnteredNameValidator.value || !!value, t('Required'))
      .custom(
        (value: string) => !clientEnteredNameValidator.value || SGP_NAME_ALLOWED_CHARACTER_REGEX.test(value),
        t('This field can only contain alpha characters, hyphens, apostrophe and spaces')
      )
      .custom((value: string) => !clientEnteredNameValidator.value || isNameNotStartedWithFullStop(value), getErrorMessageForNameStartingWithFullStop(t)),
    validatorConfig
  );

  const aliasNameValidator = useValidator(
    personalDetails.aliasName!,
    Validators.custom(() => true, EMPTY_STRING),
    validatorConfig
  );

  const hanyuPinyinNameValidator = useValidator(
    personalDetails.hanyuPinyinName!,
    Validators.custom(() => true, EMPTY_STRING),
    validatorConfig
  );

  const hanyuPinyinAliasNameValidator = useValidator(
    personalDetails.hanyuPinyinAliasName!,
    Validators.custom(() => true, EMPTY_STRING),
    validatorConfig
  );

  const isInvalidDate = (value: DayMonthYear) => {
    const dateStr = `${value.year}/${value?.month?.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0')}/${value?.day?.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0')}`;
    return !value.day || !value.month || !value.year || dayjs(dateStr, DATE_FORMAT).format(DATE_FORMAT) !== dateStr;
  };

  const convertToDayMonthYear1 = (value: DayMonthYear): DayMonthYear1 => {
    const dayMonthYear1: DayMonthYear1 = {
      day: value?.day!,
      month: value?.month ? value?.month!.padStart(FORMATTED_MONTH_FIXED_LENGTH, '0') : EMPTY_STRING,
      year: value?.year!
    };
    return dayMonthYear1;
  };

  const isDob18YearsAbove = (value: DayMonthYear) => {
    const dob18YearsAbove = dateValidator.checkYearsAbove(convertToDayMonthYear1(value), MINIMUM_AGE);
    return dob18YearsAbove;
  };

  const dobValidator = useValidator<DayMonthYear>(
    personalDetails.dateOfBirth!,
    Validators.custom((value: DayMonthYear) => !isInvalidDate(value), DOB_VALIDATION_MESSAGES.InvalidDob.message(t)).custom(
      (value: DayMonthYear) => isDob18YearsAbove(value),
      DOB_VALIDATION_MESSAGES.Over18Years.message(t)
    ),
    validatorConfig
  );

  const countryOfResidenceSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_RESIDENCE, t);

  const countryOfResidenceValidator = useValidator(
    countryOfResidenceSelectOptions.find((x) => x.value === personalDetails?.countryOfResidence),
    Validators.required(t('The Country of residence is required')),
    validatorConfig
  );

  const nationalitySelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t);
  const nationalityValidator = useValidator(
    nationalitySelectOptions.find((x) => x.value === personalDetails?.nationality),
    Validators.required(t('The Nationality is required')),
    validatorConfig
  );

  const identityDocumentTypeSelectOptions = createKeyValueTranslatedToOptionList(IDENTITY_DOCUMENT_TYPES, t);
  const identityDocumentTypeValidator = useValidator<ISelectOptionOrUndefined>(
    identityDocumentTypeSelectOptions.find((x) => x.value === personalDetails.identityDocumentType),
    Validators.required(t('Required')),
    validatorConfig
  );

  const nationalRegistrationIdentityCardNumberValidator = useValidator(
    personalDetails.nric ?? EMPTY_STRING,
    Validators.required(t('Required')),
    validatorConfig
  );

  const foreignIdentificationNumberValidator = useValidator(personalDetails.fin ?? EMPTY_STRING, Validators.required(t('Required')), validatorConfig);

  const passExpiryDateValidator = useValidator<DayMonthYear>(
    personalDetails.passExpiryDate!,
    Validators.custom((value: DayMonthYear) => !isInvalidDate(value), t('Invalid pass expiry date')),
    validatorConfig
  );

  const fullNameValidator = useValidator(
    personalDetails.fullName ?? EMPTY_STRING,
    Validators.custom(
      (value: string) =>
        !clientEnteredNameValidator.value ||
        isUserEnteredNameValid(removeIllegalCharacters(value), [firstNameValidator.value, middleNameValidator.value, lastNameValidator.value]),
      t('Required')
    ),
    validatorConfig
  );

  return {
    titleValidator,
    firstNameValidator,
    middleNameValidator,
    lastNameValidator,
    aliasNameValidator,
    hanyuPinyinNameValidator,
    hanyuPinyinAliasNameValidator,
    dobValidator,
    countryOfResidenceValidator,
    nationalityValidator,
    identityDocumentTypeValidator,
    nationalRegistrationIdentityCardNumberValidator,
    foreignIdentificationNumberValidator,
    passExpiryDateValidator,
    clientEnteredNameValidator,
    fullNameValidator
  };
};
