import { createConfig, BusinessRegion } from '../configuration/configuration';
import { BUSINESS_REGION_PATH_PATTERN, pages } from './pagePaths';
import { Page } from './page';

const { activeBusinessRegions } = createConfig();
export const getEffectiveBusinessRegion = (businessRegion: string) => (activeBusinessRegions.includes(businessRegion) ? businessRegion : BusinessRegion.AUS);

export const createPagePathWithBusinessRegion = (page: Page, businessRegion: string = BusinessRegion.AUS) => {
  const effectiveBusinessRegion = getEffectiveBusinessRegion(businessRegion);
  const businessRegionPath = `/${effectiveBusinessRegion}`;
  return pages[page].path.replace(BUSINESS_REGION_PATH_PATTERN, `${businessRegionPath}`);
};
