import React, { useEffect, useState } from 'react';
import {
  FormRow,
  CardContent,
  CardLarge,
  Heading1,
  ButtonLink,
  Form,
  VerificationCode,
  CircularIcon,
  Variant,
  IconNames,
  LoadingBar,
  Notification,
  breakpoint,
  Size,
  Text,
  CardFooter,
  useModalState
} from '@cmctechnology/phoenix-stockbroking-web-design';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { resendCode, verifyYourEmail } from '../Store/Actions';
import { IStore } from '../Store/Store';
import { AUTHENTICATION_CODE_LENGTH, VERIFY_EMAIL_PAGE_PAUSE_TIME_AFTER_SUCCESS_VERIFICATION } from '../constants/verifyYourEmail';
import { ApiRequest, ApiRequestStatus } from '../models/apiRequestResult';
import { getVerifyingEmailErrorMessage } from '../models/usersErrorCodes/verifyEmailErrorCodes';
import { INextPage } from '../models/IPageNavigation';
import { mobileCardHeaderHeading1FontCss } from '../Components/Mobile/MobileCardHeader';

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-bottom: 4.5rem;
  box-sizing: border-box;

  @media (max-width: ${breakpoint.mobile}) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  ${mobileCardHeaderHeading1FontCss}
`;

const StyledIcon = styled(CircularIcon)`
  margin-top: 7.5rem;
  stroke-width: 0.12rem;
`;

const StyledVerificationCode = styled(VerificationCode)`
  margin-top: 2rem;
  margin-bottom: 0.625rem;

  @media (max-width: ${breakpoint.mobile}) {
    gap: 0.5rem;
  }
`;

const StyledCardFooter = styled(CardFooter)`
  padding-top: 1.5rem;
  padding-bottom: 4.5rem;

  @media (max-width: ${breakpoint.mobile}) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export interface IVerifyYourEmailProps extends INextPage {
  email: string;
  password: string;
}
export const VerifyYourEmail: React.FC<IVerifyYourEmailProps> = ({ onNext, email, password }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const verifyYourEmailResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.VerifyYourEmail]);
  const resendConfirmationCodeResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.ResendConfirmationCode]);
  const [resetAll, setResetAll] = useState(false);

  useEffect(() => {
    if (verifyYourEmailResult.status === ApiRequestStatus.Success) {
      setTimeout(() => {
        onNext();
      }, VERIFY_EMAIL_PAGE_PAUSE_TIME_AFTER_SUCCESS_VERIFICATION);
    }
  }, [verifyYourEmailResult]);

  useEffect(() => {
    if (resendConfirmationCodeResult.status === ApiRequestStatus.InProgress) {
      setResetAll(false);
    }
  }, [resendConfirmationCodeResult.status]);

  const onCodeChanged = (code: string) => {
    if (code.length !== AUTHENTICATION_CODE_LENGTH || verifyYourEmailResult.status === ApiRequestStatus.Success) return;
    dispatch(verifyYourEmail({ username: email, password, verificationCode: code }));
  };

  const onResendCode = () => {
    setResetAll(true);
    dispatch(resendCode({ username: email }));
  };

  const showResendCodeLink =
    verifyYourEmailResult.status !== ApiRequestStatus.Success &&
    verifyYourEmailResult.status !== ApiRequestStatus.InProgress &&
    resendConfirmationCodeResult.status !== ApiRequestStatus.InProgress;

  return (
    <Form>
      <CardLarge>
        <StyledCardContent>
          <FormRow>
            <StyledIcon name={IconNames.Mail} size={Size.Large} />
          </FormRow>
          <FormRow>
            <Heading1>{t('Confirm your email')}</Heading1>
          </FormRow>
          <FormRow>
            <Text size={Size.Large} textAlign='center'>
              <Trans t={t}>
                Enter the <b>{{ AUTHENTICATION_CODE_LENGTH }} digit</b> code we sent to your email.
                <br />
                (Your code will be valid for 15 minutes)
              </Trans>
            </Text>
          </FormRow>
          <StyledVerificationCode
            type='number'
            onChange={onCodeChanged}
            disabled={verifyYourEmailResult.status === ApiRequestStatus.InProgress}
            resetAll={resetAll}
          />
          {showResendCodeLink && <ButtonLink onClick={() => onResendCode()}>{t('Resend code')}</ButtonLink>}
        </StyledCardContent>
        <StyledCardFooter>
          {resendConfirmationCodeResult.status === ApiRequestStatus.Success && (
            <Notification variant={Variant.Success}>
              <Text size={Size.Large}>{t('We have resent the code to your email.')}</Text>
            </Notification>
          )}
          {(verifyYourEmailResult.status === ApiRequestStatus.InProgress || resendConfirmationCodeResult.status === ApiRequestStatus.InProgress) && (
            <LoadingBar />
          )}
          {verifyYourEmailResult.status === ApiRequestStatus.Failed && verifyYourEmailResult.errorCode && (
            <Notification variant={Variant.Error}>
              <Text size={Size.Large} variant={Variant.Error}>
                {getVerifyingEmailErrorMessage(verifyYourEmailResult.errorCode, t)}
              </Text>
            </Notification>
          )}
          {resendConfirmationCodeResult.status === ApiRequestStatus.Failed && resendConfirmationCodeResult.errorCode && (
            <Notification variant={Variant.Error}>
              <Text size={Size.Large} variant={Variant.Error}>
                {getVerifyingEmailErrorMessage(resendConfirmationCodeResult.errorCode, t)}
              </Text>
            </Notification>
          )}
          {verifyYourEmailResult.status === ApiRequestStatus.Success && (
            <Notification variant={Variant.Success} onClose={() => onNext()}>
              <Text size={Size.Large}>{t('Great! Your email is confirmed.')}</Text>
            </Notification>
          )}
        </StyledCardFooter>
      </CardLarge>
    </Form>
  );
};

export const VerifyYourEmailModal: React.FC<IVerifyYourEmailProps> = ({ onNext, email, password }) => {
  const [, setModalState] = useModalState();
  useEffect(() => {
    setModalState({
      open: true,
      dialog: <VerifyYourEmail email={email} password={password} onNext={onNext} />,
      disableBackdropClick: true
    });
    return () => {
      setModalState({
        open: false
      });
    };
  }, []);
  return <></>;
};
