import * as React from 'react';
import { ReactNode, useState } from 'react';
import { BusinessRegion } from '../configuration/configuration';

interface IBusinessRegionContext {
  businessRegion: string;
  setBusinessRegion: (businessRegion: string) => void;
}

interface ICountryCodeProvider {
  defaultBusinessRegion: string;
  children: ReactNode;
}
/* istanbul ignore next */
const defaultCountryCodeContext: IBusinessRegionContext = {
  businessRegion: BusinessRegion.AUS,
  setBusinessRegion: () => {}
};

const BusinessRegionContext = React.createContext<IBusinessRegionContext>(defaultCountryCodeContext);

export const useBusinessRegion = () => React.useContext(BusinessRegionContext);
export const BusinessRegionProvider = (props: ICountryCodeProvider) => {
  const { children, defaultBusinessRegion } = props;

  const [businessRegion, setBusinessRegion] = useState(defaultBusinessRegion);
  return <BusinessRegionContext.Provider value={{ businessRegion, setBusinessRegion }}>{children}</BusinessRegionContext.Provider>;
};
