import {
  CardLarge,
  CardSeparator,
  Heading1,
  Text,
  Size,
  useTestId,
  CardFooter,
  FormGroup,
  Button,
  Validators,
  useValidator,
  Variant
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CardHeaderWithText } from '../Components/CardHeaderWithText';
import { GoBackIcon } from '../Components/GoBackIcon';
import { BankName } from '../constants/fundYourAccount';
import { AccountTypeInput, PaymentMethod } from '../models/application';
import { IPreviousPage } from '../models/IPageNavigation';
import { AccountTypeToBankNameMappings, OpenCashAccountDescription } from '../models/fundYourAccount';
import { selectPaymentMethod } from '../Store/Actions';
import { IStore } from '../Store/Store';
import { CardContentWithoutBottomPadding, CardOptionRadioButton } from '../Components/CardContentWithoutBottomPadding';

export interface IFundYourAccountProps extends IPreviousPage {
  onNext: (paymentMethod: PaymentMethod, accountType: AccountTypeInput, bankName: BankName) => void;
}

export const FundYourAccount: React.FC<IFundYourAccountProps> = ({ onNext, onPrevious, ...rest }) => {
  const { generateTestId } = useTestId(rest, `payment`);
  // || AccountTypeInput.Individual is required to provide a default value to make it work with browser's 'Refresh' button when the localstorage is gone
  // so it can navigate to home page safely.
  const accountType = useSelector((store: IStore) => store.local.accountType || AccountTypeInput.Individual);
  const paymentMethod = useSelector((store: IStore) => store.local.paymentMethod);
  const bankName = AccountTypeToBankNameMappings[accountType!];

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paymentMethodValidator = useValidator(paymentMethod, Validators.required(''));

  const onNextClicked = () => {
    const selectedPaymentMethod = paymentMethodValidator.value!;
    dispatch(selectPaymentMethod(selectedPaymentMethod));
    onNext(selectedPaymentMethod, accountType!, bankName);
  };

  return (
    <CardLarge>
      <CardHeaderWithText>
        <GoBackIcon onClick={onPrevious} />
        <Heading1>{t('Your funding options')}</Heading1>
        <Text textAlign='center' size={Size.Large}>
          {t("Choose how you'd like to pay for your trades. When you sell trades, it will be paid into the same account.")}
        </Text>
      </CardHeaderWithText>
      <CardSeparator />
      <CardContentWithoutBottomPadding>
        <CardOptionRadioButton
          onClick={() => paymentMethodValidator.handleEvent(PaymentMethod.CashAccount)}
          renderLabel={() => (
            <Text size={Size.Large}>
              <Trans t={t}>
                <b>Standard account</b> (recommended)
              </Trans>
            </Text>
          )}
          body={OpenCashAccountDescription[bankName](t)}
          selected={paymentMethodValidator.value === PaymentMethod.CashAccount}
          {...generateTestId(`cash`)}
        />
        <CardOptionRadioButton
          onClick={() => paymentMethodValidator.handleEvent(PaymentMethod.MarginLoan)}
          label={t('Link a margin loan')}
          body={t('Use an existing margin loan with your lending provider to leverage your trading')}
          selected={paymentMethodValidator.value === PaymentMethod.MarginLoan}
          {...generateTestId(`margin`)}
        />
      </CardContentWithoutBottomPadding>

      <CardFooter>
        {paymentMethodValidator.invalid && (
          <Text size={Size.Large} variant={Variant.Error}>
            {t('Please choose one funding option')}
          </Text>
        )}
        <FormGroup>
          <Button centered label={t('Next')} onClick={onNextClicked} validators={[paymentMethodValidator]} {...generateTestId(`next`)} />
        </FormGroup>
      </CardFooter>
    </CardLarge>
  );
};
