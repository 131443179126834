import { breakpoint, Label, Size, Text, useTestId } from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { CMC_APPLE_APP_STORE_URL, CMC_APP_STORE_REDIRECT_URL, CMC_GOOGLE_PLAY_STORE_URL } from '../../constants/urlConstants';

const AppStoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AppButtons = styled.div`
  display: none;
  @media (max-width: ${breakpoint.mobile}) {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
    justify-content: space-around;
  }
`;

const StyledTextWithBottomMargin = styled(Text)`
  margin-bottom: 1rem;
`;

const AppStoreQRCode = styled(QRCodeSVG)`
  display: block;
  margin: auto;
  margin-bottom: 1.5rem;
  @media (max-width: ${breakpoint.mobile}) {
    display: none;
  }
`;

export const AppStoreButtons: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `AppStoreButtons`);

  return (
    <AppStoreWrapper>
      <Label>{t('Download the CMC Invest Mobile App')}</Label>
      <StyledTextWithBottomMargin size={Size.Medium}>
        <b>{t('to begin your investment journey')}</b>
      </StyledTextWithBottomMargin>
      <AppButtons>
        <a href={CMC_APPLE_APP_STORE_URL} target='_blank' rel='noreferrer'>
          <img src='https://assets.cmcmarkets.com/images/apple-btn.png' width='120' alt={t('Apple App Store Button')} />
        </a>
        <a href={CMC_GOOGLE_PLAY_STORE_URL} target='_blank' rel='noreferrer'>
          <img src='https://assets.cmcmarkets.com/images/googleplaybadge_new.png' width='120' alt={t('Google Play Store Button')} />
        </a>
      </AppButtons>
      <AppStoreQRCode value={CMC_APP_STORE_REDIRECT_URL} {...generateTestId(`QRCode`)} />
    </AppStoreWrapper>
  );
};
