import styled from 'styled-components';
import { Size, Text, Variant } from '@cmctechnology/phoenix-stockbroking-web-design';

interface IListProps {
  items: string[];
  variant?: Variant;
  size?: Size;
}

const ListItemText = styled(Text)`
  padding-bottom: 0.25rem;
`;

const UnsortedList = styled.ul`
  padding-left: 1rem;
`;

const HTML_LI_TAG = 'li';

export const List: React.FC<IListProps> = ({ items, variant, size }) => {
  const listItems = items.map((item) => {
    return (
      <ListItemText key={item} variant={variant} size={size} as={HTML_LI_TAG}>
        {item}
      </ListItemText>
    );
  });

  return <UnsortedList>{listItems}</UnsortedList>;
};
