import { ISelectOption } from '@cmctechnology/phoenix-stockbroking-web-design';
import { EMPTY_STRING } from '../constants/commonConstants';

export const getStartValueOfRange = (moneyRange: string): number => {
  // splitting values by '-' or '+' character
  const rangeValues = moneyRange.split(/[+-]+/);

  // remove space, ',' and '$' from the first range value, then convert it to number
  return Number(rangeValues[0].replace(/[\s$,]/g, EMPTY_STRING));
};

export const sortMoneyRange: (a: ISelectOption, b: ISelectOption) => number = (a, b) => getStartValueOfRange(a.value) - getStartValueOfRange(b.value);
