export const INVALID_INDEX = -1;

export const isUserEnteredNameValid = (fullName: string, userEnteredNameSplit: string[]) => {
  let actualFullName = fullName.toLocaleLowerCase();
  for (const name of userEnteredNameSplit) {
    if (name) {
      if (actualFullName.indexOf(name.toLocaleLowerCase()) > INVALID_INDEX) {
        actualFullName = actualFullName.replace(name.toLocaleLowerCase(), '').trim();
      } else {
        return false;
      }
    }
  }

  return actualFullName.length === 0;
};
