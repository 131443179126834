import React from 'react';
import { ForgotPasswordScreen } from '../Components/Login/ForgotPasswordScreen';
import { PageWithPod } from '../Components/PageWithPod/PageWithPod';
import { IPageNavigation } from '../models/IPageNavigation';

interface IForgotPasswordProps extends IPageNavigation {}

export const ForgotPassword: React.FC<IForgotPasswordProps> = ({ onNext, onPrevious }) => {
  return (
    <PageWithPod>
      <ForgotPasswordScreen onNext={onNext} onPrevious={onPrevious} />
    </PageWithPod>
  );
};
