import React from 'react';
import { useTranslation } from 'react-i18next';
import google_bg from '../../Assets/google-bg.svg';
import { LoginCard } from './LoginCard';
import { INextPage } from '../../models/IPageNavigation';
import { CardWrapper, ContentWrapper, ImagePod, PodWrapper } from '../PageWithPod/PageWithPodContent';

interface ILoginScreenProps extends INextPage {
  onForgotPassword: () => void;
}

export const LoginScreen: React.FC<ILoginScreenProps> = ({ onNext, onForgotPassword }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <CardWrapper>
        <LoginCard onNext={onNext} onForgotPassword={onForgotPassword} />
      </CardWrapper>
      <PodWrapper>
        <ImagePod src={google_bg} alt={t('google background')} />
      </PodWrapper>
    </ContentWrapper>
  );
};
