import React, { useEffect } from 'react';
import { Switch, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FundYourAccount } from '../Pages/FundYourAccount';
import { SelectAccountType } from '../Pages/SelectAccountType';
import { CreateLogin } from '../Pages/CreateLogin';
import { ProfilePersonalDetails } from '../Pages/ProfilePersonalDetails';
import { ProfileAddress } from '../Pages/ProfileAddress';
import { ProfileTaxDetails } from '../Pages/ProfileTaxDetails';
import { ProfileEmployment } from '../Pages/ProfileEmployment';
import { IdentificationMedicare } from '../Pages/IdentificationMedicare';
import { IdentificationPassport } from '../Pages/IdentificationPassport';
import { IdentificationSelect } from '../Pages/IdentificationSelect';
import { ReviewAndSubmit } from '../Pages/ReviewAndSubmit';
import { TermsConfirmation } from '../Pages/TermsConfirmation';
import { AccountReadyInformation } from '../Pages/AccountReadyInformation';
import { IdentificationDriversLicence } from '../Pages/IdentificationDriversLicence';
import { getPagePathWithoutBusinessRegion, Page } from '../models/page';
import { Route } from '../Components/Route';
import { IdentificationType, IdentificationValidationStatus } from '../models/identification';
import { changePage, redirectToExternalUrl } from '../Store/Actions';
import { pages, ROUTE_HISTORY_ACTION_POP, ROUTE_HISTORY_ACTION_REPLACE } from '../models/pagePaths';
import { CMC_PLATFORM_SITE } from '../constants/urlConstants';
import { IStore } from '../Store/Store';
import { applicationConverter } from '../converters/applicationConverter';
import { AccountTypeInput, ApplicationStatus, PaymentMethod } from '../models/application';
import { useAuthorisationTimeout } from '../hooks/useAuthorisationTimeout';
import { Login } from '../Pages/Login';
import { BaseRoute } from '../Components/BaseRoute';
import { ForgotPassword } from '../Pages/ForgotPassword';
import { FundIntercept } from '../Pages/FundIntercept';
import { BankName } from '../constants/fundYourAccount';
import { createPagePathWithBusinessRegion } from '../models/route';
import { BusinessRegion } from '../configuration/configuration';
import { usePromoCode } from '../hooks/usePromoCode';
import { IdentificationPEP } from '../Pages/IdentificationPEP';
import { Analytics } from '../Components/Analytics';

const IdentificationTypePage = {
  [IdentificationType.DriversLicence]: Page.IdentificationDriversLicence,
  [IdentificationType.Passport]: Page.IdentificationPassport,
  [IdentificationType.Medicare]: Page.IdentificationMedicare,
  [IdentificationType.None]: Page.ReviewAndSubmit
};

const useLatestOnboarding = (paymentMethod: PaymentMethod, accountType: AccountTypeInput, bankName: BankName) => {
  return paymentMethod === PaymentMethod.CashAccount && accountType === AccountTypeInput.Individual && bankName === BankName.ANZ;
};

const isAlreadyStartedIdVerification = (idValidationStatus: IdentificationValidationStatus) => idValidationStatus !== IdentificationValidationStatus.NotStarted;
const isAlreadyPassedIdVerification = (idValidationStatus: IdentificationValidationStatus) => idValidationStatus === IdentificationValidationStatus.Passed;

const nextPageToResumePreviousIdentificationType = (identificationType: IdentificationType) => IdentificationTypePage[identificationType];

const nextPageIfNotPassedIdVerification = (idValidationStatus: IdentificationValidationStatus, identificationType?: IdentificationType) =>
  isAlreadyStartedIdVerification(idValidationStatus) ? nextPageToResumePreviousIdentificationType(identificationType!) : Page.ProfilePersonalDetails;

const nextPageIfPEPQuestionsRequired = (identityQuestionsRequired?: boolean) => {
  return identityQuestionsRequired === true ? Page.IdentificationPEP : Page.ReviewAndSubmit;
};

const nextPageIfNotSubmitApplication = (
  idValidationStatus: IdentificationValidationStatus,
  identificationType?: IdentificationType,
  identityQuestionsRequired?: boolean
) =>
  isAlreadyPassedIdVerification(idValidationStatus)
    ? nextPageIfPEPQuestionsRequired(identityQuestionsRequired)
    : nextPageIfNotPassedIdVerification(idValidationStatus, identificationType);

const nextPageIfSubmitApplication = (applicationStatus: ApplicationStatus) => {
  if (applicationStatus === ApplicationStatus.ReadyToTrade) {
    redirectToExternalUrl(CMC_PLATFORM_SITE);
    return undefined;
  }
  return Page.AccountReadyInformation;
};

const nextPageAfterLogin = (
  applicationStatus: ApplicationStatus,
  idValidationStatus: IdentificationValidationStatus,
  identityQuestionsRequired?: boolean,
  lastApplicationId?: string,
  identificationType?: IdentificationType
) =>
  lastApplicationId
    ? nextPageIfSubmitApplication(applicationStatus)
    : nextPageIfNotSubmitApplication(idValidationStatus, identificationType, identityQuestionsRequired);

export const AusRoutes: React.FC = () => {
  // eslint-disable-next-line i18next/no-literal-string
  const { region, language } = { region: 'au', language: 'en' };

  usePromoCode();

  const dispatch = useDispatch();
  const history = useHistory();
  const authenticatedAt = useSelector((store: IStore) => store.local.authenticatedAt);
  const page = useSelector((store: IStore) => store.local.page);
  const latestApplicationId = useSelector((store: IStore) => store.local.latestApplicationId);
  const applicationStatus = useSelector((store: IStore) => store.local.applicationStatus);
  const identificationVerificationStatus = useSelector((store: IStore) => store.remote.identificationDetails.status);
  const identityQuestionsRequired = useSelector((store: IStore) => store.remote.identificationDetails.identityQuestionsRequired ?? false);
  const identificationType = useSelector((store: IStore) => store.remote.identificationDetails.identificationType);
  const currentPathName = history.location.pathname;
  const shouldLoadLoginPage = history.action === ROUTE_HISTORY_ACTION_POP && currentPathName.includes(getPagePathWithoutBusinessRegion(pages[Page.LogIn].path));
  const shouldLoadSelectAccountTypePageWithoutCheckStoreState =
    history.action === ROUTE_HISTORY_ACTION_POP && (currentPathName === pages[Page.Home].path || currentPathName === pages[Page.SelectAccountType].path);
  const shouldLoadSelectAccountTypePageAfterCheckStoreState =
    (history.action === ROUTE_HISTORY_ACTION_POP || history.action === ROUTE_HISTORY_ACTION_REPLACE) && page === Page.Home;
  const shouldLoadSelectAccountTypePage = shouldLoadSelectAccountTypePageWithoutCheckStoreState || shouldLoadSelectAccountTypePageAfterCheckStoreState;

  const onChangePage = (newPage?: Page) => {
    if (newPage) {
      dispatch(changePage(newPage));
    }
  };

  useAuthorisationTimeout(dispatch, page, authenticatedAt);

  useEffect(() => {
    const newPath = createPagePathWithBusinessRegion(page, BusinessRegion.AUS);
    history.push(`${newPath}${window.location.search}`);
  }, [page]);

  const nextPageAfterFundingOptions = () => {
    if (authenticatedAt) {
      onChangePage(Page.ProfilePersonalDetails);
    } else {
      onChangePage(Page.CreateLogIn);
    }
  };

  const onFundYourAccount = (paymentMethod: PaymentMethod, accountType: AccountTypeInput, bankName: BankName) => {
    if (useLatestOnboarding(paymentMethod, accountType, bankName)) {
      nextPageAfterFundingOptions();
    } else {
      const url = applicationConverter.convertToRedirectUrl(paymentMethod, accountType);
      redirectToExternalUrl(url);
    }
  };

  if (shouldLoadLoginPage) {
    onChangePage(Page.LogIn);
  } else if (shouldLoadSelectAccountTypePage) {
    onChangePage(Page.SelectAccountType);
  }
  if (history.action === ROUTE_HISTORY_ACTION_POP && page === Page.AccountReadyInformation) {
    history.goForward();
  }

  return (
    <>
      <Analytics region={region} language={language} />
      <Switch>
        <BaseRoute {...pages[Page.LogIn]}>
          <Login
            onNext={() =>
              onChangePage(
                nextPageAfterLogin(applicationStatus, identificationVerificationStatus, identityQuestionsRequired, latestApplicationId, identificationType)
              )
            }
            onForgotPassword={() => onChangePage(Page.ForgotPassword)}
          />
        </BaseRoute>
        <BaseRoute {...pages[Page.ForgotPassword]}>
          <ForgotPassword onNext={() => onChangePage(Page.ProfilePersonalDetails)} onPrevious={() => onChangePage(Page.LogIn)} />
        </BaseRoute>
        <Route {...pages[Page.SelectAccountType]}>
          <SelectAccountType onNext={() => onChangePage(Page.FundYourAccount)} />
        </Route>
        <Route {...pages[Page.FundYourAccount]}>
          <FundYourAccount onNext={onFundYourAccount} onPrevious={() => onChangePage(Page.SelectAccountType)} />
        </Route>
        <Route {...pages[Page.CreateLogIn]}>
          <CreateLogin
            onNext={() => onChangePage(Page.ProfilePersonalDetails)}
            onLogin={() => onChangePage(Page.LogIn)}
            onPrevious={() => onChangePage(Page.FundYourAccount)}
          />
        </Route>
        <Route {...pages[Page.ProfilePersonalDetails]}>
          <ProfilePersonalDetails onNext={() => onChangePage(Page.ProfileYourAddress)} onPrevious={() => onChangePage(Page.SelectAccountType)} />
        </Route>
        <Route {...pages[Page.ProfileYourAddress]}>
          <ProfileAddress onNext={() => onChangePage(Page.ProfileTaxDetails)} onPrevious={() => onChangePage(Page.ProfilePersonalDetails)} />
        </Route>
        <Route {...pages[Page.ProfileTaxDetails]}>
          <ProfileTaxDetails onNext={() => onChangePage(Page.ProfileEmployment)} onPrevious={() => onChangePage(Page.ProfileYourAddress)} />
        </Route>
        <Route {...pages[Page.ProfileEmployment]}>
          <ProfileEmployment onNext={() => onChangePage(Page.IdentificationSelect)} onPrevious={() => onChangePage(Page.ProfileTaxDetails)} />
        </Route>
        <Route {...pages[Page.IdentificationSelect]}>
          <IdentificationSelect onNext={(idType) => onChangePage(IdentificationTypePage[idType])} onPrevious={() => onChangePage(Page.ProfileEmployment)} />
        </Route>
        <Route {...pages[Page.IdentificationDriversLicence]}>
          <IdentificationDriversLicence
            onNext={() => onChangePage(nextPageIfPEPQuestionsRequired(identityQuestionsRequired))}
            onGoBack={() => onChangePage(Page.ProfilePersonalDetails)}
            onTryAgain={() => onChangePage(Page.IdentificationSelect)}
          />
        </Route>
        <Route {...pages[Page.IdentificationMedicare]}>
          <IdentificationMedicare
            onNext={() => onChangePage(nextPageIfPEPQuestionsRequired(identityQuestionsRequired))}
            onGoBack={() => onChangePage(Page.ProfilePersonalDetails)}
            onTryAgain={() => onChangePage(Page.IdentificationSelect)}
          />
        </Route>
        <Route {...pages[Page.IdentificationPassport]}>
          <IdentificationPassport
            onNext={() => onChangePage(nextPageIfPEPQuestionsRequired(identityQuestionsRequired))}
            onGoBack={() => onChangePage(Page.ProfilePersonalDetails)}
            onTryAgain={() => onChangePage(Page.IdentificationSelect)}
          />
        </Route>
        <Route {...pages[Page.IdentificationPEP]}>
          <IdentificationPEP onNext={() => onChangePage(Page.ReviewAndSubmit)} onPrevious={() => onChangePage(Page.ProfilePersonalDetails)} />
        </Route>
        <Route {...pages[Page.ReviewAndSubmit]}>
          <ReviewAndSubmit
            onConfirm={() => onChangePage(Page.TermsConfirmation)}
            onIdentityEdit={(idType) => onChangePage(IdentificationTypePage[idType])}
            onPrevious={() => onChangePage(Page.ProfilePersonalDetails)}
          />
        </Route>
        <Route {...pages[Page.TermsConfirmation]}>
          <TermsConfirmation onAccept={() => onChangePage(Page.AccountReadyInformation)} onPrevious={() => onChangePage(Page.ReviewAndSubmit)} />
        </Route>
        <Route {...pages[Page.AccountReadyInformation]}>
          <AccountReadyInformation
            onStart={() => redirectToExternalUrl(CMC_PLATFORM_SITE)}
            onGoBack={() => onChangePage(Page.ReviewAndSubmit)}
            onFundIntercept={() => onChangePage(Page.FundIntercept)}
          />
        </Route>
        <BaseRoute {...pages[Page.FundIntercept]}>
          <FundIntercept onNext={() => redirectToExternalUrl(CMC_PLATFORM_SITE)} />
        </BaseRoute>
        <Route {...pages[Page.Home]}>
          <Redirect
            to={{
              pathname: pages[page].path,
              search: window.location.search
            }}
          />
        </Route>
      </Switch>
    </>
  );
};
