import React from 'react';
import { FormCol, FormRow, Label, Text, Heading3, CardContent, FormGroup, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import { PersonalDetails } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useTranslation } from 'react-i18next';
import { OTHER_PERSON_TITLES, PERSON_TITLES } from '../constants/commonConstants';
import { ReviewEditButton } from '../Components/ReviewEditButton';

export interface IReviewProfilePersonalDetailsProps {
  personalDetails: PersonalDetails;
  onEdit: () => void;
}

export const ReviewProfilePersonalDetails: React.FC<IReviewProfilePersonalDetailsProps> = ({ personalDetails, onEdit }) => {
  const { t } = useTranslation();
  return (
    <CardContent>
      <FormRow>
        <FormCol percentWidth={80}>
          <Heading3>{t('Personal details')}</Heading3>
        </FormCol>
        <FormCol percentWidth={20}>
          <FormRow>
            <ReviewEditButton label={t('Edit')} onClick={onEdit} />
          </FormRow>
        </FormCol>
      </FormRow>
      <FormGroup>
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('Title')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            <Text size={Size.Medium}>
              {PERSON_TITLES[personalDetails.title] ? PERSON_TITLES[personalDetails.title]?.(t) : OTHER_PERSON_TITLES[personalDetails.title]?.(t)}
            </Text>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('First name')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            <Text size={Size.Medium}>{personalDetails.firstName}</Text>
          </FormCol>
        </FormRow>
        {personalDetails.middleName && (
          <FormRow>
            <FormCol percentWidth={40}>
              <Label>{t('Middle name')}</Label>
            </FormCol>
            <FormCol percentWidth={60}>
              <Text size={Size.Medium}>{personalDetails.middleName}</Text>
            </FormCol>
          </FormRow>
        )}
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('Last name')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            <Text size={Size.Medium}>{personalDetails.lastName}</Text>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('Date of birth')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            <Text size={Size.Medium}>
              {personalDetails.dob.day}/{personalDetails.dob.month}/{personalDetails.dob.year}
            </Text>
          </FormCol>
        </FormRow>
      </FormGroup>
    </CardContent>
  );
};
