import {
  AddressErrorCode,
  AddressInput,
  AddressQasFormatResponse,
  AddressQasLookupResponse,
  Brand,
  CashAccountDetailsResponse,
  CompleteProcessRequestModel,
  EmploymentDetails,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ForgotPasswordSubmitRequest,
  ForgotPasswordSubmitResponse,
  IdentityStatusResponse,
  LatestApplicationIdResponse,
  OnlineApplicationResponse,
  PersonalAddressDetails,
  PersonalDetails,
  PromoCodeValidationResponse,
  ResendConfirmationCodeRequest,
  ResendConfirmationCodeResponse,
  RetrieveApplicationRequestModel,
  SignInRequest,
  SignInResponse,
  SignUpConfirmRequest,
  SignUpConfirmResponse,
  SignUpRequest,
  SignUpResponse,
  StatusTypeEnum,
  SubmitApplicationResponseModel,
  UsernameAvailableResponse,
  VerificationOutcome1,
  VerifyIdentityRequest,
  VerifyIdentitySubmitReponse
} from '@cmctechnology/phoenix-stockbroking-api-client';
import { AxiosError, AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { StatusCodes } from 'http-status-codes';
import pWaitFor from 'p-wait-for';
import { Dispatch } from 'react';
import { ActivityStatus, outboundExternalLink, Theme, UserAttributes } from '@cmctechnology/webinvest-analytics-compat';
import { ACCOUNT_PROCESSING_PAGE_PAUSE_TIME_AFTER_SUBMIT_APPLICATION } from '../constants/accountProcessing';
import { APPLICATION_POLL_STATUS_INTERVAL, APPLICATION_POLL_TIMEOUT } from '../constants/applicationConstants';
import { applicationConverter } from '../converters/applicationConverter';
import { identityVerificationConverter } from '../converters/identityVerificationCoverter';
import { createAddressApi } from '../factories/addressApiFactory';
import { createApplicationApi } from '../factories/applicationApiFactory';
import { createTradingAccountApi } from '../factories/tradingAccountApiFactory';
import { createUsersApi } from '../factories/usersApiFactory';
import { ApiRequest } from '../models/apiRequestResult';
import { AccountTypeInput, ApplicationStatus, applicationStatusMappings, PaymentMethod } from '../models/application';
import { IDisplayAddress } from '../models/common';
import {
  hasExceededRequestLimitForVerifyIdStatus,
  IdentificationType,
  IdentificationValidationStatus,
  IDriversLicenceDetails,
  IMedicareDetails,
  IPassportDetails
} from '../models/identification';
import { Page } from '../models/page';
import { ITaxDetails } from '../models/profile';
import { actions, IStore } from './Store';
import { getCrc } from '../validators/tokenValidator';
import { ApplicationFormPaths } from '../constants/analyticsConstants';
import { createIdentityApi } from '../factories/identityApiFactory';
import {
  IDENTIFICATION_POLL_ALERT_SLOW_PROCESS_TIME,
  IDENTIFICATION_POLL_STATUS_INTERVAL,
  IDENTIFICATION_POLL_TIMEOUT
} from '../constants/identificationValidationConstants';
import { createIdentityInternationalApi } from '../factories/identityInternationalApiFactory';
import { IdVerifyProvider } from '../constants/sgp/myinfo';
import { OPEN_SELF_URL_TARGET } from '../constants/urlConstants';
import { sgpIdentityVerificationConverter } from '../converters/sgp/identityVerificationConverter';
import { applicationConverter as sgpApplicationConverter } from '../converters/sgp/applicationConverter';
import {
  ISgpAddressDetails,
  ISgpEmployment,
  ISgpFinancialBackground,
  ISgpKnowledgeExperience,
  ISgpLegalTerms,
  ISgpTaxInformation,
  ISgpPersonalDetails
} from '../models/sgp/profile';
import {
  JUMIO_IDENTIFICATION_POLL_ALERT_SLOW_PROCESS_TIME,
  JUMIO_IDENTIFICATION_POLL_STATUS_INTERVAL,
  JUMIO_IDENTIFICATION_POLL_TIMEOUT
} from '../constants/sgp/jumio';
import { IStartProcessRequestModel } from '../models/sgp/singpassConnector';
import { identifyUser, trackApplication, trackApplicationSubmitted } from '../analytics/trackEvents';
import { IPEPQuestionsModel } from '../models/pepQuestions';
import { getRecaptchaToken } from '../common/recaptcha';
import { createConfig } from '../configuration/configuration';
import { createAxios } from '../factories/axiosFactory';

export const changePage = (page: Page) => actions.pageChanged(page);

export const resetStore = () => {
  return async (dispatch: Dispatch<any>) => dispatch(actions.storeReset());
};

export const resetStoreAndRedirectToLoginPage = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(resetStore());
    dispatch(changePage(Page.LogIn));
  };
};

const isErrorDueToNoPermission = (errorStatus: number) => [StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN].includes(errorStatus);

const getAccountType = (getState: () => IStore) => getState().local.accountType;
const getAnalyticsEnabled = (getState: () => IStore) => getState().local.analyticsEnabled;
const getCustomerReference = (getState: () => IStore) => getState().local.applicationSubmitResponse?.applicationCustomerReference;
const getTrackingSubmitted = (getState: () => IStore) => getState().local.trackingApplicationSubmitted;

export const createUnauthorisedInterceptor = (dispatch: Dispatch<any>) => (res: AxiosResponse<any>) => {
  if (isErrorDueToNoPermission(res.status)) {
    dispatch(resetStoreAndRedirectToLoginPage());
  }
};

export const selectAccountType = (accountType: AccountTypeInput) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.SelectAccountType, accountType);
    dispatch(actions.accountTypeSelected(accountType));
  };
};

export const selectPaymentMethod = (paymentMethod: PaymentMethod) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.SelectPaymentMethod, getAccountType(getState), paymentMethod);
    dispatch(actions.paymentMethodSelected(paymentMethod));
  };
};

export const selectIndividualAccountAndCashPayment = () => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.accountTypeSelected(AccountTypeInput.Individual));
    dispatch(actions.paymentMethodSelected(PaymentMethod.CashAccount));
    if (getAnalyticsEnabled(getState)) {
      trackApplication(ApplicationFormPaths.SelectAccountType, AccountTypeInput.Individual);
      trackApplication(ApplicationFormPaths.SelectPaymentMethod, AccountTypeInput.Individual, PaymentMethod.CashAccount);
    }
  };
};

export const submitPersonalDetails = (person: PersonalDetails) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.PersonalDetails, getAccountType(getState));
    dispatch(actions.personalDetailsSubmitted(person));
  };
};

export const pepQuestionsAnswered = (pepQuestions: IPEPQuestionsModel) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.GlobalScreeningPepQuestions, getAccountType(getState));
    dispatch(actions.pepQuestionsAnswered(pepQuestions));
  };
};

export const submitValidatedAddressDetails = (addresses: PersonalAddressDetails) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.AddressDetails, getAccountType(getState));
    dispatch(actions.validatedAddressDetailsSubmitted(addresses));
  };
};

export const submitTaxDetails = (tax: ITaxDetails) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.TaxDetails, getAccountType(getState));
    dispatch(actions.taxDetailsSubmitted(tax));
  };
};

export const submitEmploymentDetails = (employment: EmploymentDetails) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.Employment, getAccountType(getState), undefined, employment.occupation);
    dispatch(actions.employmentDetailsSubmitted(employment));
  };
};

const INTERNAL_SERVER_ERROR_STATUS_CODE = 500;
const getApiResponseFromError = <T>(error: AxiosError) => {
  if (!error?.response || error?.response.status === INTERNAL_SERVER_ERROR_STATUS_CODE) {
    return undefined;
  }
  const { data } = error.response;
  return data as T;
};

let timeoutIdForIdentityVerifyNeedLongerWaitingTime: number | undefined;
let timeoutIdForIdentityVerifyTimeout: number | undefined;

export const fetchVerifyIdentityStatus = async (messageId: string, dispatch: Dispatch<any>) => {
  const identityApi = createIdentityApi(createUnauthorisedInterceptor(dispatch));
  try {
    const { data: identityStatusResponse } = await identityApi.identityVerifyStatus({ messageId });

    if (identityStatusResponse?.status === VerificationOutcome1.ACCEPT) {
      dispatch(actions.identityQuestionsRequiredUpdated(identityStatusResponse.globalScreeningPEPCheck));
      dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.Passed));
    } else if (identityStatusResponse?.status === VerificationOutcome1.IN_PROGRESS) {
      dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.InProgress));
    } else {
      dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.Failed));
    }
    dispatch(actions.apiRequestSent(ApiRequest.VerifyIdentityStatus));
    return identityStatusResponse.status !== VerificationOutcome1.IN_PROGRESS;
  } catch (error) {
    const errorCodes = getApiResponseFromError<IdentityStatusResponse>(error as AxiosError)?.error?.code;
    const errorCode = Array.isArray(errorCodes) ? errorCodes?.[0] : errorCodes;
    dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.VerifyIdentityStatus, errorCode }));
    const errorStatus = (error as AxiosError)?.response?.status;
    if (errorStatus && (isErrorDueToNoPermission(errorStatus) || hasExceededRequestLimitForVerifyIdStatus(errorCode))) {
      return true;
    }
  }
  return false;
};

const finishedIdentityVerify = (getState: () => IStore) => {
  const errorCode = getState().local.apiResults[ApiRequest.VerifyIdentityStatus].errorCode;
  const status = getState().remote.identificationDetails.status;
  return (
    (status !== IdentificationValidationStatus.InProgress && status !== IdentificationValidationStatus.NotStarted) ||
    hasExceededRequestLimitForVerifyIdStatus(errorCode)
  );
};

export const resetIdentityVerifyWarnings = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.identityVerifyNeedLongerWaitingTime(false));
    dispatch(actions.identityVerifyTimeout(false));
    clearTimeout(timeoutIdForIdentityVerifyNeedLongerWaitingTime);
    clearTimeout(timeoutIdForIdentityVerifyTimeout);
  };
};

export const pollingVerifyIdentityStatus = async (messageId: string, dispatch: Dispatch<any>, getState: () => IStore) => {
  dispatch(resetIdentityVerifyWarnings());
  // run timer and pollingStatus in parallel
  timeoutIdForIdentityVerifyNeedLongerWaitingTime = window.setTimeout(() => {
    dispatch(actions.identityVerifyNeedLongerWaitingTime(!finishedIdentityVerify(getState)));
  }, IDENTIFICATION_POLL_ALERT_SLOW_PROCESS_TIME);

  timeoutIdForIdentityVerifyTimeout = window.setTimeout(() => {
    dispatch(actions.identityVerifyNeedLongerWaitingTime(!finishedIdentityVerify(getState)));
    dispatch(actions.identityVerifyTimeout(!finishedIdentityVerify(getState)));
  }, IDENTIFICATION_POLL_TIMEOUT);

  try {
    await pWaitFor(async () => (await fetchVerifyIdentityStatus(messageId, dispatch)) || getState().local.identityVerifyTimeout, {
      interval: IDENTIFICATION_POLL_STATUS_INTERVAL,
      timeout: IDENTIFICATION_POLL_TIMEOUT,
      before: false
    });
    // eslint-disable-next-line no-empty
  } catch {}
};

const submitIdentityVerifyAndPollingVerifyStatus = async (request: VerifyIdentityRequest, dispatch: Dispatch<any>, getState: () => IStore) => {
  try {
    const identityApi = createIdentityApi(createUnauthorisedInterceptor(dispatch));
    const { data: verifyIdentitySubmitResponse } = await identityApi.identityVerifyIdentitySubmit(request);
    const requestReference = verifyIdentitySubmitResponse.requestReference!;
    dispatch(actions.identificationValidationMessageIdUpdated(requestReference));
    await pollingVerifyIdentityStatus(requestReference, dispatch, getState);
  } catch (error) {
    const errorCode = getApiResponseFromError<VerifyIdentitySubmitReponse>(error as AxiosError)?.error?.code;
    dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.VerifyIdentity, errorCode }));
    dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.Failed));
  }
};

export const verifyIdentity = (identificationType: IdentificationType) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState))
      trackApplication(ApplicationFormPaths.IdentityVerification, getAccountType(getState), undefined, undefined, identificationType);
    dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.InProgress));
    dispatch(actions.apiRequestSent(ApiRequest.VerifyIdentity));

    const request = identityVerificationConverter.convertToUsersVerifyIdentityRequest(getState());

    await submitIdentityVerifyAndPollingVerifyStatus(request, dispatch, getState);
  };
};

export const submitDriversLicenceDetails = (driversLicence: IDriversLicenceDetails) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.driversLicenceDetailsSubmitted(driversLicence));

    dispatch(verifyIdentity(IdentificationType.DriversLicence));
  };
};

export const submitMedicareDetails = (medicare: IMedicareDetails) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.medicareDetailsSubmitted(medicare));

    dispatch(verifyIdentity(IdentificationType.Medicare));
  };
};

export const submitPassportDetails = (passport: IPassportDetails) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.passportDetailsSubmitted(passport));

    dispatch(verifyIdentity(IdentificationType.Passport));
  };
};

export const updateTermsAndConditions = (termsAndConditions: string[]) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.TermsAndConditions, getAccountType(getState));
    dispatch(actions.termsAndConditionsSubmitted(termsAndConditions));
  };
};

export const acceptTnCAndSubmitApplication = () => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.apiRequestReset(ApiRequest.SubmitApplication));
    dispatch(actions.resetApplicationSubmitResponse());
    const applicationApi = createApplicationApi(createUnauthorisedInterceptor(dispatch));

    if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.ApplicationSubmitted, getAccountType(getState));

    const submitRequest = applicationConverter.convertToOnlineApplicationRequest(getState());
    try {
      const { data: submitResponse } = await applicationApi.submit(submitRequest);

      dispatch(actions.applicationSubmitted(submitResponse));
      dispatch(actions.apiRequestSent(ApiRequest.SubmitApplication));
    } catch (error) {
      const errorCodes = getApiResponseFromError<OnlineApplicationResponse>(error as AxiosError)?.error?.code;
      const errorCode = Array.isArray(errorCodes) ? errorCodes?.[0] : errorCodes;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.SubmitApplication, errorCode }));
    }
  };
};

export const fetchApplicationStatus = async (applicationGuid: string, dispatch: Dispatch<any>, getState: () => IStore) => {
  const applicationApi = createApplicationApi(createUnauthorisedInterceptor(dispatch));
  try {
    const { data: statusResponse } = await applicationApi.status(applicationGuid);

    dispatch(actions.applicationStatusUpdated(applicationStatusMappings[statusResponse.status!]));
    dispatch(actions.apiRequestSent(ApiRequest.ApplicationStatus));
    if (!statusResponse.status) return false;

    if (
      getAnalyticsEnabled(getState) &&
      getTrackingSubmitted(getState) === false &&
      applicationStatusMappings[statusResponse.status!] !== ApplicationStatus.NotStarted
    ) {
      const strs = getCustomerReference(getState)?.split('/');
      const personId = strs?.[1];
      await trackApplicationSubmitted(ApplicationFormPaths.ApplicationSubmitted, getAccountType(getState), undefined, personId, getState().remote.email);
      dispatch(actions.trackingApplicationSubmitted(true));
    }

    return (
      applicationStatusMappings[statusResponse.status!] === ApplicationStatus.CashAccountCreated ||
      applicationStatusMappings[statusResponse.status!] === ApplicationStatus.Completed
    );
  } catch (error) {
    const errorCodes = getApiResponseFromError<OnlineApplicationResponse>(error as AxiosError)?.error?.code;
    const errorCode = Array.isArray(errorCodes) ? errorCodes?.[0] : errorCodes;
    dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.ApplicationStatus, errorCode }));
    const errorStatus = (error as AxiosError)?.response?.status;
    if (errorStatus && isErrorDueToNoPermission(errorStatus)) {
      return true;
    }
  }
  return false;
};

export const openExternalUrl = (url: string, target?: string) => {
  window.open(url, target);
};

export const redirectToExternalUrl = (url: string) => {
  outboundExternalLink(url);
};

// line1: Address's unit number/street number, street name streetType
// line2: Address's city state postcode
export const getAddressLines = (address: AddressInput): IDisplayAddress => {
  if (address.isUnformatted) {
    const splits = address.unformattedAddress.split(',');

    if (splits.length > 1) {
      const line2 = splits[splits.length - 1].trim();
      const line1 = address.unformattedAddress.slice(0, Math.max(0, address.unformattedAddress.length - line2.length)).trim();

      return { line1, line2 };
    }

    return { line1: address.unformattedAddress, line2: '' };
  }
  const streetNumber = (address.unitNumber && `${address.unitNumber}/`) + (address.streetNumber && `${address.streetNumber}`);

  const line1 = `${streetNumber} ${address.streetName} ${address.streetType}`.trim();
  const line2 = `${address.city} ${address.state} ${address.postcode}`.trim();

  return { line1, line2 };
};

export const signUp = (details: SignUpRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.apiRequestSent(ApiRequest.SignUp));

    try {
      const usersApi = createUsersApi();
      const { data: signUpResult } = await usersApi.signUp(details);
      if (details.brand === Brand.cmcinvestsingapore) {
        const crc = getCrc((signUpResult as SignUpConfirmResponse).idToken!);
        const userAttributes: UserAttributes = {
          Theme: Theme.CmcInvestSingapore,
          ActivityStatus: ActivityStatus.NeverTraded,
          Tenure: 0
        };
        if (getAnalyticsEnabled(getState)) identifyUser(crc, details.email, userAttributes);
        dispatch(actions.authenticatedAtUpdated(dayjs().unix()));
      }
      if (getAnalyticsEnabled(getState)) trackApplication(ApplicationFormPaths.CreateLogIn, getAccountType(getState));

      dispatch(actions.brandUpdated(details.brand));
      dispatch(actions.userInfoUpdated({ email: details.email, mobile: details.phoneNumber }));
      dispatch(actions.apiRequestSucceeded(ApiRequest.SignUp));
    } catch (error) {
      const errorCode = getApiResponseFromError<SignUpResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.SignUp, errorCode }));
    }
  };
};
export const emailAvailable = async (email: string, dispatch: (errorCode?: any) => void) => {
  try {
    const recaptchaToken = await getRecaptchaToken();
    const config = createConfig();
    const axios = createAxios();
    const result = await axios.post(`${config.apiUrl}/users/username-available-v2`, { username: email, token: recaptchaToken });
    dispatch(actions.apiRequestSucceeded(ApiRequest.UsernameAvailable));
    return result.data.available ?? false;
  } catch (error) {
    const errorCode = !error ? 'UNKNOWN' : getApiResponseFromError<UsernameAvailableResponse>(error as AxiosError)?.error?.code;
    dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.UsernameAvailable, errorCode }));
  }
  return false;
};

export const signIn = (signInRequest: SignInRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.apiRequestSent(ApiRequest.SignIn));
    try {
      const usersApi = createUsersApi();
      const signInResult: SignInResponse = (await usersApi.signIn(signInRequest)).data;
      dispatch(actions.authenticatedAtUpdated(dayjs().unix()));
      dispatch(actions.userInfoUpdated({ email: signInRequest.username, mobile: '' }));
      dispatch(actions.brandUpdated(signInResult.brand));
      // We still need allow user to login at any cases whether fetched last applicationId or not
      try {
        const applicationApi = createApplicationApi();
        const { data: latestApplicationResult } = await applicationApi.latestApplicationId();
        dispatch(actions.lastApplicationIdFetched(latestApplicationResult.applicationId));
        if (latestApplicationResult.applicationId) {
          await fetchApplicationStatus(latestApplicationResult.applicationId, dispatch, getState);
        } else {
          try {
            const identityApi = createIdentityApi();
            const { data: getVerifyIdentityResult } = await identityApi.getVerifyIdentity();
            /* istanbul ignore else */
            if (getVerifyIdentityResult) {
              const latestIdentification = identityVerificationConverter.convertToUserIdentification(getVerifyIdentityResult);
              dispatch(actions.latestIdentificationFetched(latestIdentification));
            }
            // eslint-disable-next-line no-empty
          } catch {}
        }
        // eslint-disable-next-line no-empty
      } catch {}

      // TODO: if user is the same as last logged user, we should redirect the user to the last visited page

      dispatch(actions.apiRequestSucceeded(ApiRequest.SignIn));

      const crc = getCrc(signInResult.idToken!);
      const userAttributes: UserAttributes = {
        Theme: Theme.Cmc
      };
      if (getAnalyticsEnabled(getState)) identifyUser(crc, signInRequest.username, userAttributes);
    } catch (error) {
      const errorCode = getApiResponseFromError<LatestApplicationIdResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.SignIn, errorCode }));
    }
  };
};

export const verifyYourEmail = (signUpConfirmRequest: SignUpConfirmRequest) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.apiRequestReset(ApiRequest.ResendConfirmationCode));
    dispatch(actions.apiRequestSent(ApiRequest.VerifyYourEmail));
    const usersApi = createUsersApi();
    try {
      const { data: confirmSignUpResult } = await usersApi.confirmSignUp(signUpConfirmRequest);

      dispatch(actions.apiRequestSucceeded(ApiRequest.VerifyYourEmail));

      dispatch(actions.authenticatedAtUpdated(dayjs().unix()));
      const brand = getState().local.brand;
      // TODO: Find out how to handle signIn failure (i.e. redirect to Sign In or retry)
      dispatch(signIn({ username: signUpConfirmRequest.username, password: signUpConfirmRequest.password, brand }));

      const crc = getCrc(confirmSignUpResult.idToken!);
      const userAttributes: UserAttributes = {
        Theme: Theme.Cmc,
        ActivityStatus: ActivityStatus.NeverTraded,
        Tenure: 0
      };
      if (getAnalyticsEnabled(getState)) identifyUser(crc, signUpConfirmRequest.username, userAttributes);
    } catch (error) {
      const errorCode = getApiResponseFromError<SignUpConfirmResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.VerifyYourEmail, errorCode }));
    }
  };
};

export const forgotPassword = (forgotPasswordRequest: ForgotPasswordRequest) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.apiRequestSent(ApiRequest.ForgotPassword));
    try {
      const usersApi = createUsersApi();
      await usersApi.forgotPassword(forgotPasswordRequest);

      dispatch(actions.apiRequestSucceeded(ApiRequest.ForgotPassword));
    } catch (error) {
      const errorCode = getApiResponseFromError<ForgotPasswordResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.ForgotPassword, errorCode }));
    }
  };
};

export const forgotPasswordSubmit = (forgotPasswordSubmitRequest: ForgotPasswordSubmitRequest) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.apiRequestSent(ApiRequest.ForgotPasswordSubmit));
    try {
      const usersApi = createUsersApi();
      await usersApi.forgotPasswordSubmit(forgotPasswordSubmitRequest);

      dispatch(actions.apiRequestSucceeded(ApiRequest.ForgotPasswordSubmit));
    } catch (error) {
      const errorCode = getApiResponseFromError<ForgotPasswordSubmitResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.ForgotPasswordSubmit, errorCode }));
    }
  };
};

export const resendCode = (resendConfirmationCodeRequest: ResendConfirmationCodeRequest) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.apiRequestReset(ApiRequest.VerifyYourEmail));
    dispatch(actions.apiRequestSent(ApiRequest.ResendConfirmationCode));
    const usersApi = createUsersApi();
    try {
      await usersApi.resendConfirmationCode(resendConfirmationCodeRequest);

      dispatch(actions.apiRequestSucceeded(ApiRequest.ResendConfirmationCode));
    } catch (error) {
      const errorCode = getApiResponseFromError<ResendConfirmationCodeResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.ResendConfirmationCode, errorCode }));
    }
  };
};

export const addressSearch = async (addressLookup: string, dispatch: Dispatch<any>, onError?: (errorCode: string) => void) => {
  try {
    const addressApi = createAddressApi(createUnauthorisedInterceptor(dispatch));

    const { data: response } = await addressApi.addressSearch({ address: addressLookup });

    return response.addressSuggestions ?? [];
  } catch (error) {
    if (onError) {
      const errorCode = getApiResponseFromError<AddressQasLookupResponse>(error as AxiosError)?.error?.code;
      onError(errorCode!);
    }
  }

  return [];
};

export const addressFormat = async (globalAddressKey: string, dispatch: Dispatch<any>, onError: (errorCode: string) => void) => {
  try {
    const addressApi = createAddressApi(createUnauthorisedInterceptor(dispatch));

    const { data: response } = await addressApi.addressFormat(globalAddressKey);

    if (!response.address) {
      onError(AddressErrorCode.AddressFormatFail);
    }

    return response.address;
  } catch (error) {
    const errorCode = getApiResponseFromError<AddressQasFormatResponse>(error as AxiosError)?.error?.code;
    onError(errorCode!);
  }

  return undefined;
};

export const pollingApplicationStatusAndUpdateApplicationWaitingTimer = (applicationGuid: string) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.applicationWaitingTimeElapsed(false));
    // run timer and pollingStatus in parallel
    setTimeout(() => {
      dispatch(actions.applicationWaitingTimeElapsed(true));
    }, ACCOUNT_PROCESSING_PAGE_PAUSE_TIME_AFTER_SUBMIT_APPLICATION);

    try {
      await pWaitFor(() => fetchApplicationStatus(applicationGuid, dispatch, getState), {
        interval: APPLICATION_POLL_STATUS_INTERVAL,
        timeout: APPLICATION_POLL_TIMEOUT
      });
      // eslint-disable-next-line no-empty
    } catch {}
  };
};

export const getCashAccountDetails = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.apiRequestSent(ApiRequest.GetCashAccount));
    try {
      const tradingAccountApi = createTradingAccountApi();
      const { data: cashAccountDetailsResult } = await tradingAccountApi.cashAccountDetails();
      dispatch(actions.cashAccountDetailsFetched(cashAccountDetailsResult));
      dispatch(actions.apiRequestSucceeded(ApiRequest.GetCashAccount));
    } catch (error) {
      const errorCode = getApiResponseFromError<CashAccountDetailsResponse>(error as AxiosError)?.error?.code;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.GetCashAccount, errorCode }));
    }
  };
};

// sgp actions and possibly exported these actions to a separate SgpActions.ts at some point when file grows bigger
export const selectIdVerifyProvider = (idVerifyProvider: IdVerifyProvider) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.idVerifyProviderSelected(idVerifyProvider));
  };
};

export const openSingpassOrJumioAuthorisation = (details: IStartProcessRequestModel) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.apiRequestSent(ApiRequest.GetSingpassOrJumioAuthoriseUrl));
    dispatch(actions.completeMyInfoProcess(false));
    try {
      const identityInternationalApi = createIdentityInternationalApi();

      const request: IStartProcessRequestModel = { ...details, countryOfResidence: getState().persist?.sgp.countryOfResidence };

      const { data: processStartResult } = await identityInternationalApi.processStart(request);
      dispatch(actions.workflowIdFetched(processStartResult.workflowId));
      /* istanbul ignore else */
      if (processStartResult.authorisationUrl) {
        if (details.providerType === IdVerifyProvider.MyInfo) {
          // TODO we can move all the logics of navigation to SgpRoutes.tsx for internal and external navigation
          openExternalUrl(processStartResult.authorisationUrl, OPEN_SELF_URL_TARGET);
        } else {
          dispatch(actions.JumioAuthoriseUrlFetched(processStartResult.authorisationUrl));
        }
      }
      dispatch(actions.apiRequestSucceeded(ApiRequest.GetSingpassOrJumioAuthoriseUrl));
    } catch {
      // TODO will add error handling by consume error?.code once the back end api is ready to return the prop
    }
  };
};

export const updateAuthenticatedAt = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.authenticatedAtUpdated(dayjs().unix()));
  };
};

export const getPersonDataFromMyInfo = (details: CompleteProcessRequestModel) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.apiRequestSent(ApiRequest.GetPersonDataFromMyInfo));
    dispatch(actions.completeMyInfoProcess(true));
    try {
      const identityInternationalApi = createIdentityInternationalApi();
      const { data: processCompleteResult } = await identityInternationalApi.processComplete(details);
      const myInfoUser = processCompleteResult.onboardingModel;
      if (myInfoUser) {
        const profile = sgpIdentityVerificationConverter.convertToUserIdentification(processCompleteResult.onboardingModel!);
        dispatch(actions.personDataFromMyInfoFetched(profile));
      }
      dispatch(actions.apiRequestSucceeded(ApiRequest.GetPersonDataFromMyInfo));
    } catch {
      // TODO will add frontend error handling by consume error?.code once the back end error handling is ready to return the prop
    }
  };
};

export const promoCodeValidation = async (promoCode: string, onError?: (errorCode?: string) => void) => {
  try {
    const usersApi = createUsersApi();
    const { data: result } = await usersApi.promoCodeValidation(promoCode);
    return result.validation ?? false;
  } catch (error) {
    if (onError) {
      const errorCode = getApiResponseFromError<PromoCodeValidationResponse>(error as AxiosError)?.error?.code;
      onError(errorCode);
    }
    return false;
  }
};

export const submitPromoCode = (promoCode: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.promoCodeSubmitted(promoCode));
  };
};

export const submitPromoCodeSgp = (promoCode: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.promoCodeSubmittedSgp(promoCode));
  };
};

export const fetchJumioVerifyIdentityStatus = async (details: RetrieveApplicationRequestModel, dispatch: Dispatch<any>) => {
  const identityInternationalApi = createIdentityInternationalApi();
  try {
    const { data: userRetrieveResponse } = await identityInternationalApi.retrieve(details);
    if (userRetrieveResponse?.statusType === StatusTypeEnum.Success) {
      dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.Passed));
      const jumioUser = userRetrieveResponse.onboardingModel;
      if (jumioUser) {
        const profile = sgpIdentityVerificationConverter.convertToUserIdentification(jumioUser!);
        dispatch(actions.personDataFromJumioFetched(profile));
      }
    } else if (userRetrieveResponse?.statusType === StatusTypeEnum.InProgress) {
      dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.InProgress));
    } else {
      dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.Failed));
    }
    dispatch(actions.apiRequestSent(ApiRequest.GetPersonDataFromJumioStatus));

    return userRetrieveResponse.statusType !== StatusTypeEnum.InProgress;
  } catch {
    // TODO will add frontend error handling by consume error?.code once the back end error handling is ready to return the prop
    // stop polling if error happens
    return true;
  }
};

const finishedJumioIdentityVerify = (getState: () => IStore) => {
  const status = getState().remote.identificationDetails.status;
  return status !== IdentificationValidationStatus.InProgress && status !== IdentificationValidationStatus.NotStarted;
};

export const pollingJumioVerifyIdentityStatus = async (details: RetrieveApplicationRequestModel, dispatch: Dispatch<any>, getState: () => IStore) => {
  dispatch(resetIdentityVerifyWarnings());
  // run timer and pollingStatus in parallel
  timeoutIdForIdentityVerifyNeedLongerWaitingTime = window.setTimeout(() => {
    dispatch(actions.identityVerifyNeedLongerWaitingTime(!finishedJumioIdentityVerify(getState)));
  }, JUMIO_IDENTIFICATION_POLL_ALERT_SLOW_PROCESS_TIME);

  timeoutIdForIdentityVerifyTimeout = window.setTimeout(() => {
    dispatch(actions.identityVerifyNeedLongerWaitingTime(!finishedJumioIdentityVerify(getState)));
    dispatch(actions.identityVerifyTimeout(!finishedJumioIdentityVerify(getState)));
  }, JUMIO_IDENTIFICATION_POLL_TIMEOUT);

  try {
    await pWaitFor(async () => (await fetchJumioVerifyIdentityStatus(details, dispatch)) || getState().local.identityVerifyTimeout, {
      interval: JUMIO_IDENTIFICATION_POLL_STATUS_INTERVAL,
      timeout: JUMIO_IDENTIFICATION_POLL_TIMEOUT,
      before: false
    });
    // eslint-disable-next-line no-empty
  } catch {}
};

export const verifyIdentityByJumio = (details: RetrieveApplicationRequestModel) => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.InProgress));
    dispatch(actions.apiRequestSent(ApiRequest.VerifyIdentity));
    await pollingJumioVerifyIdentityStatus(details, dispatch, getState);
  };
};

export const savePersonalDetails = (personalDetails: ISgpPersonalDetails) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.sgpPersonalDetailsSubmitted(personalDetails));
  };
};

export const saveAddressDetails = (addressDetails: ISgpAddressDetails) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.addressDetailsSubmitted(addressDetails));
  };
};

export const saveTaxInformation = (taxInformation: ISgpTaxInformation) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.taxInformationSubmitted(taxInformation));
  };
};

export const saveLegalTerms = (legalTerms: ISgpLegalTerms[]) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.legalTermsSubmitted(legalTerms));
  };
};

export const submitSgpApplication = () => {
  return async (dispatch: Dispatch<any>, getState: () => IStore) => {
    dispatch(actions.apiRequestReset(ApiRequest.SubmitApplication));
    dispatch(actions.resetSgpApplicationSubmitResponse());

    const submitRequest = sgpApplicationConverter.convertToOnlineApplicationRequest(getState());

    const identityApi = createIdentityInternationalApi(createUnauthorisedInterceptor(dispatch));
    try {
      const { data: response } = await identityApi.submit(submitRequest);

      dispatch(actions.sgpApplicationSubmitted(response));
      dispatch(actions.apiRequestSent(ApiRequest.SubmitApplication));
    } catch (error) {
      const errorCodes = getApiResponseFromError<SubmitApplicationResponseModel>(error as AxiosError)?.error?.code;
      const errorCode = Array.isArray(errorCodes) ? errorCodes?.[0] : errorCodes;
      dispatch(actions.apiRequestFailed({ apiRequest: ApiRequest.SubmitApplication, errorCode }));
    }
  };
};

export const saveFinancialBackground = (financialBackground: ISgpFinancialBackground) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.financialBackgroundSubmitted(financialBackground));
  };
};

export const saveEmployment = (employment: ISgpEmployment) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.sgpEmploymentSubmitted(employment));
  };
};

export const saveKnowledgeExperience = (knowledgeExperience: ISgpKnowledgeExperience) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.knowledgeExperienceSubmitted(knowledgeExperience));
  };
};

export const saveCountryOfResidence = (countryOfResidence?: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.spgCountryOfResidenceSelected(countryOfResidence));
  };
};

export const updateAnalyticsEnabled = (analyticsEnabled: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.analyticsEnabled(analyticsEnabled));
  };
};

export const updateRecaptchaEnabled = (recaptchaEnabled: boolean) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.recaptchaEnabled(recaptchaEnabled));
  };
};
