import { Validators, useValidator } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { EMPTY_STRING } from '../constants/commonConstants';
import { MATCH_ANY_STRING_REGEX } from '../constants/termsConfirmationConstants';
import { promoCodeValidation } from '../Store/Actions';

export const usePromoCodeValidator = (promoCode?: string) => {
  const { t } = useTranslation();

  const promoCodeValidator = useValidator<string>(
    promoCode ?? EMPTY_STRING,
    Validators.custom(
      (value: string) => !value || (value.length > 0 && MATCH_ANY_STRING_REGEX.test(value)),
      t('Please enter a valid promotion code'),
      `Promo`
    ).custom(async (value) => !value || (await promoCodeValidation(value)), t('Sorry, the promotion code you have entered is incorrect or expired.'))
  );

  return { promoCodeValidator };
};
