import React from 'react';
import { FormCol, FormRow, Label, Text, FormGroup, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { IDriversLicenceDetails } from '../models/identification';
import { ReviewEditButton } from '../Components/ReviewEditButton';

export interface IReviewIdentificationPassportProps {
  driversLicence: IDriversLicenceDetails;
  onEdit: () => void;
}

export const ReviewIdentificationDriversLicence: React.FC<IReviewIdentificationPassportProps> = ({ driversLicence, onEdit }) => {
  const { t } = useTranslation();

  return (
    <FormGroup>
      <FormRow>
        <FormCol percentWidth={80}>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Type of ID')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{t("Driver's licence")}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('State of issue')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{driversLicence.licenceState}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Licence Number')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{driversLicence.licenceNumber}</Text>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol percentWidth={53}>
              <Label>{t('Card Number')}</Label>
            </FormCol>
            <FormCol percentWidth={47}>
              <Text size={Size.Medium}>{driversLicence.licenceCardNumber}</Text>
            </FormCol>
          </FormRow>
        </FormCol>
        <FormCol percentWidth={20}>
          <FormRow>
            <ReviewEditButton label={t('Edit')} onClick={onEdit} />
          </FormRow>
        </FormCol>
      </FormRow>
    </FormGroup>
  );
};
