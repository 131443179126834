import { Heading1, Size, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoBackIcon } from './GoBackIcon';
import { CardHeaderWithText } from './CardHeaderWithText';

interface IIdentificationCardHeaderProps {
  onGoBack?: () => void;
}

export const IdentificationCardHeader: React.FC<IIdentificationCardHeaderProps> = ({ onGoBack }) => {
  const { t } = useTranslation();

  return (
    <CardHeaderWithText>
      {onGoBack && <GoBackIcon onClick={onGoBack} />}
      <Heading1>{t('Identification')}</Heading1>
      <Text size={Size.Large} textAlign='center'>
        {t('We need to verify your identity before opening a CMC Invest account. Please enter the details of one of your ID documents.')}
      </Text>
    </CardHeaderWithText>
  );
};
