import { CashAccountDetailsErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { BaseErrorCode, baseErrorMessages } from '../baseErrorCodes';
import { Translated } from '../translation';
import { baseCashAccountDetailsErrorCodeMappings } from './baseCashAccountErrorCodeMappings';

enum CashAccountErrorCode {
  CashAccountNotExist = 'AccountNotExist',
  CashAccountDetailsMissing = 'CashAccountDetailsMissing'
}

// Map backend error code [CashAccountDetailsErrorCode] to client error code[CashAccountErrorCode]
const cashAccountErrorCodeMappings: Record<CashAccountDetailsErrorCode, BaseErrorCode | CashAccountErrorCode> = {
  ...baseCashAccountDetailsErrorCodeMappings,
  [CashAccountDetailsErrorCode.ApplicationNotExists]: CashAccountErrorCode.CashAccountNotExist,
  [CashAccountDetailsErrorCode.CashAccountHasNotCreated]: CashAccountErrorCode.CashAccountNotExist,
  [CashAccountDetailsErrorCode.CashAccountNameMissing]: CashAccountErrorCode.CashAccountDetailsMissing,
  [CashAccountDetailsErrorCode.CashAccountBsbMissing]: CashAccountErrorCode.CashAccountDetailsMissing,
  [CashAccountDetailsErrorCode.CashAccountNumberMissing]: CashAccountErrorCode.CashAccountDetailsMissing
};

export const cashAccountErrorMessages: Record<BaseErrorCode | CashAccountErrorCode, Translated> = {
  ...baseErrorMessages,
  [CashAccountErrorCode.CashAccountNotExist]: (t) => t(`Sorry, your cash account does not exist!`),
  [CashAccountErrorCode.CashAccountDetailsMissing]: (t) => t(`Sorry, your cash account details cannot be found!`)
};

export const getCashAccountErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = errorCode as CashAccountDetailsErrorCode;

  return cashAccountErrorMessages[cashAccountErrorCodeMappings[errorCodeEnum]](t);
};
