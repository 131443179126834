import { IdentityApi } from '@cmctechnology/phoenix-stockbroking-api-client';
import { createConfig } from '../configuration/configuration';
import { createApiConfig, createAxios, Interceptor } from './axiosFactory';

export const createIdentityApi = (interceptor?: Interceptor) => {
  const { apiUrl } = createConfig();
  const identityApi = new IdentityApi(createApiConfig(), apiUrl, createAxios(interceptor));

  return identityApi;
};
