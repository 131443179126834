import { AccountApplicationProgress, ApplicationBadRequestErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { isTodayAfterOrEqualTo, isTodayBeforeOrEqualTo } from '../utils/dateUtils';
import { KeyValueTranslated } from './translation';

export enum PaymentMethod {
  CashAccount = 'CashAccount',
  MarginLoan = 'MarginLoan'
}

enum ApplicationStatusLabel {
  SendingApplication,
  PendingValidID,
  InProgress,
  Completed
}

enum AccountStatusLabel {
  NotReadyToFund,
  ReadyToFund
}

export enum ApplicationReadyHeaderTitles {
  ProcessingYourApplication,
  YourAccountIsReady,
  YourAccountIsPending
}

export enum ApplicationStatus {
  NotStarted = 'NotStarted',
  Finalise = 'Finalise',
  IdVerified = 'IdVerified',
  GbstAccountCreated = 'GbstAccountCreated',
  TradingAccountCreated = 'TradingAccountCreated',
  WorldCheckComplete = 'WorldCheckComplete',
  FraudulentDataCheck = 'FraudulentDataCheck',
  CashAccountCreated = 'CashAccountCreated',
  Completed = 'Completed',
  ReadyToTrade = 'ReadyToTrade'
}

export const applicationStatusMapping: Record<ApplicationStatus, ApplicationStatusLabel> = {
  [ApplicationStatus.NotStarted]: ApplicationStatusLabel.SendingApplication,
  [ApplicationStatus.Finalise]: ApplicationStatusLabel.PendingValidID,
  [ApplicationStatus.IdVerified]: ApplicationStatusLabel.InProgress,
  [ApplicationStatus.GbstAccountCreated]: ApplicationStatusLabel.InProgress,
  [ApplicationStatus.TradingAccountCreated]: ApplicationStatusLabel.InProgress,
  [ApplicationStatus.WorldCheckComplete]: ApplicationStatusLabel.InProgress,
  [ApplicationStatus.FraudulentDataCheck]: ApplicationStatusLabel.InProgress,
  [ApplicationStatus.CashAccountCreated]: ApplicationStatusLabel.Completed,
  [ApplicationStatus.Completed]: ApplicationStatusLabel.Completed,
  [ApplicationStatus.ReadyToTrade]: ApplicationStatusLabel.Completed
};

export const accountStatusLabelMapping: Record<ApplicationStatus, AccountStatusLabel> = {
  [ApplicationStatus.NotStarted]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.Finalise]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.IdVerified]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.GbstAccountCreated]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.TradingAccountCreated]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.WorldCheckComplete]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.FraudulentDataCheck]: AccountStatusLabel.NotReadyToFund,
  [ApplicationStatus.CashAccountCreated]: AccountStatusLabel.ReadyToFund,
  [ApplicationStatus.Completed]: AccountStatusLabel.ReadyToFund,
  [ApplicationStatus.ReadyToTrade]: AccountStatusLabel.ReadyToFund
};

export const applicationReadyHeaderTitlesMapping: Record<ApplicationStatus, ApplicationReadyHeaderTitles> = {
  [ApplicationStatus.NotStarted]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.Finalise]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.IdVerified]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.GbstAccountCreated]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.TradingAccountCreated]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.WorldCheckComplete]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.FraudulentDataCheck]: ApplicationReadyHeaderTitles.YourAccountIsPending,
  [ApplicationStatus.CashAccountCreated]: ApplicationReadyHeaderTitles.YourAccountIsReady,
  [ApplicationStatus.Completed]: ApplicationReadyHeaderTitles.YourAccountIsReady,
  [ApplicationStatus.ReadyToTrade]: ApplicationReadyHeaderTitles.YourAccountIsReady
};

export enum AccountTypeInput {
  Individual = 'Individual',
  Joint = 'Joint',
  Minor = 'Minor',
  SMSF = 'SMSF',
  Company = 'Company',
  Trust = 'Trust'
}

export const ACCOUNT_TYPES: KeyValueTranslated<AccountTypeInput> = {
  [AccountTypeInput.Individual]: (t) => t('Individual'),
  [AccountTypeInput.Joint]: (t) => t('Joint'),
  [AccountTypeInput.Minor]: (t) => t('Minor (under 18)'),
  [AccountTypeInput.SMSF]: (t) => t('SMSF'),
  [AccountTypeInput.Company]: (t) => t('Company'),
  [AccountTypeInput.Trust]: (t) => t('Trust')
};

export const ACCOUNT_TYPE_DESCRIPTIONS: KeyValueTranslated<AccountTypeInput> = {
  [AccountTypeInput.Individual]: (t) => t('Trade in your name (e.g. John Citizen)'),
  [AccountTypeInput.Joint]: (t) => t('Trade with another individual (e.g.  John and Mary Citizen)'),
  [AccountTypeInput.Minor]: (t) => t('Trade as a trustee on behalf of a minor '),
  [AccountTypeInput.SMSF]: (t) => t('Trade on behalf of a registered Self-Managed Super Fund'),
  [AccountTypeInput.Company]: (t) => t('Trade on behalf of a corporate entity that holds a valid Australian Company Number (ACN)'),
  [AccountTypeInput.Trust]: (t) => t('Trade on behalf of a trust (e.g. Family or Investment trust)')
};

export const applicationStatusMappings: Record<AccountApplicationProgress, ApplicationStatus> = {
  [AccountApplicationProgress.Finalise]: ApplicationStatus.Finalise,
  [AccountApplicationProgress.IdVerified]: ApplicationStatus.IdVerified,
  [AccountApplicationProgress.WorldCheckComplete]: ApplicationStatus.WorldCheckComplete,
  [AccountApplicationProgress.FraudulentDataCheck]: ApplicationStatus.FraudulentDataCheck,
  [AccountApplicationProgress.GbstAccountCreated]: ApplicationStatus.GbstAccountCreated,
  [AccountApplicationProgress.TradingAccountCreated]: ApplicationStatus.TradingAccountCreated,
  [AccountApplicationProgress.CashAccountCreated]: ApplicationStatus.CashAccountCreated,
  [AccountApplicationProgress.Completed]: ApplicationStatus.Completed,
  [AccountApplicationProgress.ReadyToTrade]: ApplicationStatus.ReadyToTrade
};

export const applicationStatusLabel: KeyValueTranslated<ApplicationStatusLabel> = {
  [ApplicationStatusLabel.SendingApplication]: (t) => t('Sending Application'),
  [ApplicationStatusLabel.PendingValidID]: (t) => t('Pending valid ID'),
  [ApplicationStatusLabel.InProgress]: (t) => t('In Progress'),
  [ApplicationStatusLabel.Completed]: (t) => t('Completed')
};

export const accountStatusLabel: KeyValueTranslated<AccountStatusLabel> = {
  [AccountStatusLabel.NotReadyToFund]: (t) => t('Not ready to Fund'),
  [AccountStatusLabel.ReadyToFund]: (t) => t('Ready to Fund')
};

export const applicationReadyHeaderTitles: KeyValueTranslated<ApplicationReadyHeaderTitles> = {
  [ApplicationReadyHeaderTitles.ProcessingYourApplication]: (t) => t('Processing your application'),
  [ApplicationReadyHeaderTitles.YourAccountIsReady]: (t) => t('Your account is ready'),
  [ApplicationReadyHeaderTitles.YourAccountIsPending]: (t) => t('Your account is pending')
};

/* eslint-disable i18next/no-literal-string */
export const termsAndConditions = [
  {
    key: 'cmc-fsg',
    label: (t: TFunction) => t('CMC Markets Invest Financial Services Guide'),
    url: 'https://assets.cmcmarkets.com/legal-documents/en-au/CMC_STB_FSG.pdf'
  },
  {
    key: 'cmc-tnc',
    label: (t: TFunction) => t('CMC Markets Invest Terms and Conditions'),
    url: 'https://assets.cmcmarkets.com/legal-documents/en-au/CMC_STB_TCS.pdf'
  },
  {
    key: 'cmc-be',
    label: (t: TFunction) => t('CMC Markets Invest Best Execution Policy'),
    url: 'https://assets.cmcmarkets.com/legal-documents/en-au/CMC_STB_BEP.pdf'
  },
  {
    key: 'cmc-privacy',
    label: (t: TFunction) => t('CMC Markets Privacy Statement'),
    url: 'https://www.cmcmarkets.com/en-au/privacy'
  }
];

const additionalTermsAndConditions = [
  {
    key: 'cmc-legal-doc',
    label: (t: TFunction) =>
      t(
        'Despite any relevant notice period, the CMC Markets Invest Terms and Conditions, Financial Services Guide, and Best Execution Policy ("Legal Documents") relating to my CMC Markets Invest application and Account with CMC Markets Stockbroking Limited will be amended on 10 October 2022 and I hereby agree to be bound by the amended Legal Documents.'
      ),
    url: 'https://www.cmcmarkets.com/en-au/legal-documents/stockbroking-legal-documents',
    start: '2022-08-23',
    end: '2022-10-10'
  }
];

export const activeAdditionalTermsAndConditions = additionalTermsAndConditions.filter(
  (atc) => (!atc.start || isTodayAfterOrEqualTo(atc.start)) && (!atc.end || isTodayBeforeOrEqualTo(atc.end))
);

export const hasChangedIdDetailsAfterLastIdVerify = (errorCode: string | undefined | null) => {
  const errorCodeEnum = errorCode as ApplicationBadRequestErrorCode;
  return errorCodeEnum === ApplicationBadRequestErrorCode.UserIdChanged;
};
