import {
  CardHeader,
  CardLarge,
  CardSeparator,
  CardContent,
  Heading1,
  Text,
  useTestId,
  Link,
  Button,
  CardFooter,
  Size
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GoBackIcon } from '../../Components/GoBackIcon';
import { SGP_RISK_DISCLOSURE, TERMS_AND_CONDITIONS, TERMS_AND_CONDITIONS_LINKS } from '../../constants/sgp/termsConfirmation';
import { OPEN_EXTERNAL_URL_TARGET } from '../../constants/urlConstants';
import { INextPage, IPreviousPage } from '../../models/IPageNavigation';
import { ISgpLegalTerms } from '../../models/sgp/profile';
import { saveLegalTerms, submitPromoCodeSgp, submitSgpApplication } from '../../Store/Actions';
import { List } from '../../Components/List/List';
import { createTranslatedArray } from '../../common/mappings';
import { PromoCodeInput } from '../../Components/PromoCodeInput';
import { usePromoCodeValidator } from '../../hooks/usePromoCodeValidator';

const LinkGroup = styled.div`
  text-align: left;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
`;

const ListLabel = styled(Text)`
  margin: 0 0-0.72rem 0;
  font-weight: normal;
`;

interface ITermsConfirmationProps extends INextPage, Partial<IPreviousPage> {}

export const TermsConfirmation: React.FC<ITermsConfirmationProps> = ({ onNext, onPrevious, ...rest }) => {
  const { generateTestId } = useTestId(rest, `sg.legalDeclaration`);
  const [promoCode, setPromoCode] = useState(window?.localStorage?.PromoCode ?? '');

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { promoCodeValidator } = usePromoCodeValidator(promoCode);

  useEffect(() => {
    promoCodeValidator.validate();
  }, []);

  const onNextClicked = () => {
    dispatch(submitPromoCodeSgp(promoCodeValidator.value));

    const legalTerms: ISgpLegalTerms[] = TERMS_AND_CONDITIONS_LINKS.map((c) => ({
      description: c.label(t)
    }));

    dispatch(saveLegalTerms(legalTerms));
    dispatch(submitSgpApplication());

    onNext();
  };

  const conditions = createTranslatedArray(TERMS_AND_CONDITIONS, t);

  return (
    <CardLarge>
      <CardHeader>
        {onPrevious && <GoBackIcon onClick={onPrevious} />}
        <Heading1>{t('Almost done!')}</Heading1>
      </CardHeader>
      <CardSeparator />
      <CardContent>
        <Text size={Size.Large}>
          {t(`Please read the below documents before you apply for a CMC Markets Singapore Invest Pte. Ltd. ("CMC Invest") account:`)}
        </Text>
        <LinkGroup>
          {TERMS_AND_CONDITIONS_LINKS.map((terms) => (
            <React.Fragment key={terms.key}>
              <Link size={Size.Large} href={terms.url} target={OPEN_EXTERNAL_URL_TARGET}>
                {terms.label(t)}
              </Link>
              {terms.key === SGP_RISK_DISCLOSURE && <ListLabel size={Size.Medium}>{t('Including its schedules as follows:')}</ListLabel>}
              {terms.schedule && <List size={Size.Medium} items={createTranslatedArray(terms.schedule, t)} />}
            </React.Fragment>
          ))}
        </LinkGroup>

        <Text size={Size.Large}>
          <Trans t={t}>
            By clicking <b>&quot;Accept & Submit&quot;:</b>
          </Trans>
        </Text>
        <List size={Size.Large} items={conditions} />
      </CardContent>
      <CardSeparator />
      <CardContent>
        <PromoCodeInput validator={promoCodeValidator} promoCode={promoCode} setPromoCode={setPromoCode} />
      </CardContent>
      <CardFooter>
        <Button
          label={t('Accept & Submit')}
          onClick={onNextClicked}
          disabled={promoCodeValidator.value !== '' && (promoCodeValidator.validating || promoCodeValidator.invalid)}
          {...generateTestId(`submit`)}
        />
      </CardFooter>
    </CardLarge>
  );
};
