import { Configuration } from '@cmctechnology/phoenix-stockbroking-api-client';
import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import { logApiError } from '../logger/logger';

const TOO_MANY_REQUESTS_STATUS_CODE = 429;

const RETRIES = 3;
const RETRY_DELAY_MS = 800;
const RETRY_STATUS_CODES = new Set([TOO_MANY_REQUESTS_STATUS_CODE]);

export type Interceptor = (res: AxiosResponse<any>) => void;

export const createAxios = (responseInterceptor?: Interceptor) => {
  const client = axios.create();
  if (responseInterceptor) {
    client.interceptors.response.use(
      (res) => {
        responseInterceptor(res);
        return res;
      },
      (error) => {
        logApiError(error.response.data.error?.code, error.response.config.url, (error as Error).stack);
        responseInterceptor(error.response);

        return Promise.reject(error);
      }
    );
  }

  axiosRetry(client, {
    retries: RETRIES,
    retryDelay: () => RETRY_DELAY_MS,
    retryCondition: (error) => !!error.response && RETRY_STATUS_CODES.has(error.response.status)
  });

  return client;
};

export const createApiConfig = () => new Configuration({ baseOptions: { withCredentials: true } });
