import styled from 'styled-components';

export const IframeForJumio = styled.iframe.attrs(({ src }) => ({
  src,
  allow: `camera;fullscreen;accelerometer;gyroscope;magnetometer`,
  allowFullScreen: true,
  frameBorder: 0
}))`
  width: 95%;
  height: 38rem;
`;
