import { KeyValueTranslated } from '../models/translation';
import { IFieldValidation } from '../models/validation';

export const AUSTRALIAN_STATES: KeyValueTranslated = {
  ACT: (t) => t('Australian Capital Territory'),
  NSW: (t) => t('New South Wales'),
  NT: (t) => t('Northern Territory'),
  QLD: (t) => t('Queensland'),
  SA: (t) => t('South Australia'),
  TAS: (t) => t('Tasmania'),
  VIC: (t) => t('Victoria'),
  WA: (t) => t('Western Australia')
};

export const AUSTRALIAN_STREET_TYPES: KeyValueTranslated = {
  Avenue: (t) => t('Avenue'),
  Drive: (t) => t('Drive'),
  Highway: (t) => t('Highway'),
  Lane: (t) => t('Lane'),
  Road: (t) => t('Road'),
  Street: (t) => t('Street'),
  Way: (t) => t('Way'),
  Access: (t) => t('Access'),
  Alley: (t) => t('Alley'),
  Amble: (t) => t('Amble'),
  Anchorage: (t) => t('Anchorage'),
  Approach: (t) => t('Approach'),
  Arcade: (t) => t('Arcade'),
  Artery: (t) => t('Artery'),
  Alleyway: (t) => t('Alleyway'),
  Basin: (t) => t('Basin'),
  Beach: (t) => t('Beach'),
  Block: (t) => t('Block'),
  Boulevard: (t) => t('Boulevard'),
  Bend: (t) => t('Bend'),
  Bank: (t) => t('Bank'),
  Bypass: (t) => t('Bypass'),
  Brace: (t) => t('Brace'),
  Brae: (t) => t('Brae'),
  Bridge: (t) => t('Bridge'),
  Break: (t) => t('Break'),
  Brow: (t) => t('Brow'),
  Bowl: (t) => t('Bowl'),
  Broadway: (t) => t('Broadway'),
  Byway: (t) => t('Byway'),
  Circuit: (t) => t('Circuit'),
  'Cul-de-sa': (t) => t('Cul-de-sa'),
  Chase: (t) => t('Chase'),
  Circle: (t) => t('Circle'),
  Close: (t) => t('Close'),
  Colonnade: (t) => t('Colonnade'),
  Circlet: (t) => t('Circlet'),
  Common: (t) => t('Common'),
  Concourse: (t) => t('Concourse'),
  Corner: (t) => t('Corner'),
  Copse: (t) => t('Copse'),
  Circus: (t) => t('Circus'),
  Crossroad: (t) => t('Crossroad'),
  Crescent: (t) => t('Crescent'),
  Court: (t) => t('Court'),
  Crossway: (t) => t('Crossway'),
  Crossing: (t) => t('Crossing'),
  Corso: (t) => t('Corso'),
  Cruiseway: (t) => t('Cruiseway'),
  Centre: (t) => t('Centre'),
  Centreway: (t) => t('Centreway'),
  Cutting: (t) => t('Cutting'),
  Cove: (t) => t('Cove'),
  Causeway: (t) => t('Causeway'),
  Courtyard: (t) => t('Courtyard'),
  Dale: (t) => t('Dale'),
  Dell: (t) => t('Dell'),
  Dip: (t) => t('Dip'),
  Driveway: (t) => t('Driveway'),
  Distributor: (t) => t('Distributor'),
  Deviation: (t) => t('Deviation'),
  Edge: (t) => t('Edge'),
  Elbow: (t) => t('Elbow'),
  End: (t) => t('End'),
  Entrance: (t) => t('Entrance'),
  Esplanade: (t) => t('Esplanade'),
  Estate: (t) => t('Estate'),
  Expressway: (t) => t('Expressway'),
  Extension: (t) => t('Extension'),
  Fairway: (t) => t('Fairway'),
  Flat: (t) => t('Flat'),
  Follow: (t) => t('Follow'),
  Formation: (t) => t('Formation'),
  Frontage: (t) => t('Frontage'),
  Front: (t) => t('Front'),
  Foreshore: (t) => t('Foreshore'),
  'Fire Track': (t) => t('Fire Track'),
  Firetrail: (t) => t('Firetrail'),
  Footway: (t) => t('Footway'),
  Freeway: (t) => t('Freeway'),
  Gap: (t) => t('Gap'),
  Garden: (t) => t('Garden'),
  Gardens: (t) => t('Gardens'),
  Glade: (t) => t('Glade'),
  Glen: (t) => t('Glen'),
  Gully: (t) => t('Gully'),
  Grange: (t) => t('Grange'),
  Ground: (t) => t('Ground'),
  Green: (t) => t('Green'),
  Gate: (t) => t('Gate'),
  Gates: (t) => t('Gates'),
  Grove: (t) => t('Grove'),
  Hill: (t) => t('Hill'),
  Highroad: (t) => t('Highroad'),
  Heights: (t) => t('Heights'),
  Interchange: (t) => t('Interchange'),
  Intersection: (t) => t('Intersection'),
  Junction: (t) => t('Junction'),
  Key: (t) => t('Key'),
  Landing: (t) => t('Landing'),
  Lees: (t) => t('Lees'),
  Line: (t) => t('Line'),
  Link: (t) => t('Link'),
  Lookout: (t) => t('Lookout'),
  Lower: (t) => t('Lower'),
  Loop: (t) => t('Loop'),
  Little: (t) => t('Little'),
  Laneway: (t) => t('Laneway'),
  Mall: (t) => t('Mall'),
  Meander: (t) => t('Meander'),
  Mew: (t) => t('Mew'),
  Mile: (t) => t('Mile'),
  Mount: (t) => t('Mount'),
  Mews: (t) => t('Mews'),
  Motorway: (t) => t('Motorway'),
  Nook: (t) => t('Nook'),
  Outlook: (t) => t('Outlook'),
  Parade: (t) => t('Parade'),
  Parklands: (t) => t('Parklands'),
  Pocket: (t) => t('Pocket'),
  Parkway: (t) => t('Parkway'),
  Place: (t) => t('Place'),
  Plaza: (t) => t('Plaza'),
  Point: (t) => t('Point'),
  Pier: (t) => t('Pier'),
  Park: (t) => t('Park'),
  Promenade: (t) => t('Promenade'),
  Part: (t) => t('Part'),
  Pass: (t) => t('Pass'),
  Port: (t) => t('Port'),
  Path: (t) => t('Path'),
  Plateau: (t) => t('Plateau'),
  Piazza: (t) => t('Piazza'),
  Quadrant: (t) => t('Quadrant'),
  Quadrangle: (t) => t('Quadrangle'),
  Quad: (t) => t('Quad'),
  Quay: (t) => t('Quay'),
  Quays: (t) => t('Quays'),
  Ramble: (t) => t('Ramble'),
  Rosebowl: (t) => t('Rosebowl'),
  Reach: (t) => t('Reach'),
  Ronde: (t) => t('Ronde'),
  Ridge: (t) => t('Ridge'),
  Roads: (t) => t('Roads'),
  Ridgeway: (t) => t('Ridgeway'),
  Roadway: (t) => t('Roadway'),
  Reserve: (t) => t('Reserve'),
  Range: (t) => t('Range'),
  Rise: (t) => t('Rise'),
  Ride: (t) => t('Ride'),
  Ramp: (t) => t('Ramp'),
  Round: (t) => t('Round'),
  Ring: (t) => t('Ring'),
  Row: (t) => t('Row'),
  Roadside: (t) => t('Roadside'),
  Rest: (t) => t('Rest'),
  Retreat: (t) => t('Retreat'),
  Route: (t) => t('Route'),
  'Right of Way': (t) => t('Right of Way'),
  Rotary: (t) => t('Rotary'),
  Rue: (t) => t('Rue'),
  Run: (t) => t('Run'),
  Riviera: (t) => t('Riviera'),
  River: (t) => t('River'),
  Riverway: (t) => t('Riverway'),
  Siding: (t) => t('Siding'),
  'State Highway': (t) => t('State Highway'),
  Slope: (t) => t('Slope'),
  Sound: (t) => t('Sound'),
  Spur: (t) => t('Spur'),
  Square: (t) => t('Square'),
  Stairs: (t) => t('Stairs'),
  Strip: (t) => t('Strip'),
  Steps: (t) => t('Steps'),
  'Service Way': (t) => t('Service Way'),
  Terrace: (t) => t('Terrace'),
  Thoroughfare: (t) => t('Thoroughfare'),
  Trunkway: (t) => t('Trunkway'),
  Top: (t) => t('Top'),
  Tor: (t) => t('Tor'),
  Track: (t) => t('Track'),
  Triangle: (t) => t('Triangle'),
  Trail: (t) => t('Trail'),
  'Tarnice Way': (t) => t('Tarnice Way'),
  Trailer: (t) => t('Trailer'),
  Turn: (t) => t('Turn'),
  Towers: (t) => t('Towers'),
  Tollway: (t) => t('Tollway'),
  Underpass: (t) => t('Underpass'),
  Upper: (t) => t('Upper'),
  Vale: (t) => t('Vale'),
  Viaduct: (t) => t('Viaduct'),
  Villa: (t) => t('Villa'),
  Vista: (t) => t('Vista'),
  View: (t) => t('View'),
  Wade: (t) => t('Wade'),
  Wharf: (t) => t('Wharf'),
  Walk: (t) => t('Walk'),
  Walkway: (t) => t('Walkway'),
  Wynd: (t) => t('Wynd'),
  Yard: (t) => t('Yard')
};

export const CARE_OF_ADDRESS_REGEX = /\s*(c\/o|care of)\s+/i;
export const PO_BOX_REGEX = /([gl])?\.?p(?:ost)?\.?\s*[0o](?:ffice)?\.?\s*b(?:[0o]x)?|locked\s*(mail)?\s*bag|parcel\s*locker|private\s*(box|bag)/i;
export const EMPTY_STRING_REGEX = /^\s*$/;
export const MATCH_ANY_STRING_REGEX = /.*$/;
export const MULTIPLE_WHITEPSACE_REGEX = /\s+/g;

export enum AddressType {
  Residential = 'Residential',
  Postal = 'Postal'
}

export const invalidUnitNumberValidations: Record<string, IFieldValidation> = {
  careOfAddress: { regex: CARE_OF_ADDRESS_REGEX, message: (t) => t('Your residential address cannot be a care of address') },
  postalAddress: {
    regex: PO_BOX_REGEX,
    message: (t) => t('Your residential address cannot be a postal address')
  }
};

export const invalidStreetNumberValidations: Record<string, IFieldValidation> = {
  emptyString: { regex: EMPTY_STRING_REGEX, message: (t) => t('The Street number field is required') },
  careOfAddress: { regex: CARE_OF_ADDRESS_REGEX, message: (t) => t('Your residential address cannot be a care of address') },
  postalAddress: {
    regex: PO_BOX_REGEX,
    message: (t) => t('Your residential address cannot be a postal address')
  }
};

export const invalidStreetNameValidationsForResidentialAddress: Record<string, IFieldValidation> = {
  emptyString: { regex: EMPTY_STRING_REGEX, message: (t) => t('The Street name field is required') },
  careOfAddress: { regex: CARE_OF_ADDRESS_REGEX, message: (t) => t('Your residential address cannot be a care of address') },
  postalAddress: {
    regex: PO_BOX_REGEX,
    message: (t) => t('Your residential address cannot be a postal address')
  }
};

export const invalidStreetNameValidationsForPostalAddress: Record<string, IFieldValidation> = {
  emptyString: { regex: EMPTY_STRING_REGEX, message: (t) => t('The Street name/Post box field is required') }
};

export const invalidPostcodeValidations: Record<string, IFieldValidation> = {
  emptyString: { regex: EMPTY_STRING_REGEX, message: (t) => t('The Postcode field is required') },
  postcode: {
    regex: /^\d{1,3}$|^\d{5,}$/,
    message: (t) => t('The Postcode must be 4 digit')
  }
};

export enum AddressValidator {
  LookUpAddressValidator = 'LookUpAddressValidator',
  UnitNumberValidator = 'UnitNumberValidator',
  StreetNumberValidator = 'StreetNumberValidator',
  StreetNameValidator = 'StreetNameValidator',
  StreetTypeValidator = 'StreetTypeValidator',
  CityValidator = 'CityValidator',
  StateValidator = 'StateValidator',
  PostcodeValidator = 'PostcodeValidator'
}

export enum SimpleAddressRequiredField {
  ResidentialLookup,
  PostalLookup,
  StreetType,
  Suburb,
  State
}

export const SimpleAddressRequiredErrorMessage: KeyValueTranslated<SimpleAddressRequiredField> = {
  [SimpleAddressRequiredField.ResidentialLookup]: (t) => t('Please enter your Residential address'),
  [SimpleAddressRequiredField.PostalLookup]: (t) => t('Please enter your Postal address'),
  [SimpleAddressRequiredField.StreetType]: (t) => t('The Street type is required'),
  [SimpleAddressRequiredField.Suburb]: (t) => t('The Suburb/City is required'),
  [SimpleAddressRequiredField.State]: (t) => t('The State is required')
};

export const ADDRESS_SEARCH_DEBOUNCE_MS = 3000;
export const UNIT_NUMBER_MAX_LENGTH = 5;
export const STREET_NUMBER_MAX_LENGTH = 8;
export const STREET_NAME_MAX_LENGTH = 25;
export const CITY_SUBURB_MAX_LENGTH = 22;
