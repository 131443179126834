import React from 'react';
import {
  Form,
  FormLabel,
  CardLarge,
  CardSeparator,
  Heading1,
  FormGroup,
  CardHeader,
  useTestId,
  useValidator,
  Validators,
  ValidationErrorMessage,
  ISelectOption,
  Select,
  Size
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { EmploymentDetails } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OCCUPATIONS } from '../models/profile';
import { IStore } from '../Store/Store';
import { submitEmploymentDetails } from '../Store/Actions';
import { createKeyValueTranslatedToOptionList } from '../common/mappings';
import { ProfileCardFooter } from '../Components/ProfileCardFooter';
import { GoBackIcon } from '../Components/GoBackIcon';
import { INextPage, IPreviousPage } from '../models/IPageNavigation';
import { FullWidthCardContent } from '../Components/FullWidthCardContent';

export interface IProfileEmploymentProps extends INextPage, Partial<IPreviousPage> {}

export const ProfileEmployment: React.FC<IProfileEmploymentProps> = ({ onNext, onPrevious, ...rest }) => {
  const employmentDetails = useSelector((store: IStore) => store.remote.profile.employmentDetails);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `employment`);

  const occupationsSelectOptions = createKeyValueTranslatedToOptionList(OCCUPATIONS, t);

  const occupationValidator = useValidator(
    occupationsSelectOptions.find((x) => x.value === employmentDetails.occupation),
    Validators.required(t('Required'))
  );

  const onNextClicked = () => {
    const employment: EmploymentDetails = {
      occupation: occupationValidator.value!.value
    };
    dispatch(submitEmploymentDetails(employment));

    onNext();
  };

  return (
    <Form>
      <CardLarge>
        <CardHeader>
          {onPrevious && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Employment')}</Heading1>
        </CardHeader>
        <CardSeparator />
        <FullWidthCardContent>
          <FormGroup>
            <FormLabel>{t('Occupation type')}</FormLabel>
            <Select
              autoFocus
              value={occupationValidator.value}
              options={occupationsSelectOptions}
              onChange={(option) => occupationValidator.handleEvent(option as ISelectOption)}
              placeholder={t('Please select an occupation')}
              invalid={occupationValidator.invalid}
              {...generateTestId(`occupationType`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={occupationValidator} />
          </FormGroup>
        </FullWidthCardContent>
        <ProfileCardFooter
          nextButtonLabel={t('Next')}
          saveButtonLabel={t('Save')}
          onNext={onNextClicked}
          onCancel={onNext}
          validators={[occupationValidator]}
        />
      </CardLarge>
    </Form>
  );
};
