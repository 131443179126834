import { CardContent, OptionRadioButton } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';

// todo the following specific component can be removed when done the web-design PR to make this FC compatible with styled-comp [SDS-4]
export const CardOptionRadioButton = styled(OptionRadioButton)``;

export const CardContentWithoutBottomPadding = styled(CardContent)`
  padding-bottom: 0;
  margin-bottom: 0;
  ${CardOptionRadioButton} {
    margin-left: 0;
    :last-child {
      margin-bottom: 0;
    }
  }
`;
