import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form,
  FormGroup,
  FormControl,
  CardContent,
  CardLarge,
  CardSeparator,
  Label,
  CheckBox,
  Heading3,
  RadioButtonGroup,
  RadioButton,
  RadioButtonLabelSmall,
  CardFooter,
  useTestId,
  useValidator,
  Validators,
  ValidationErrorMessage,
  Size,
  useModalState
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { IStore } from '../Store/Store';
import { consentToIdCheckLabel, defaultDriversLicence, IDriversLicenceDetails, IIdentificationComponentProps } from '../models/identification';
import { AUSTRALIAN_STATES } from '../constants/addressConstants';
import { DRIVERS_LICENCE_CARD_NUMBER_REGEX_RULES, DRIVERS_LICENCE_NUMBER_REGEX_RULES } from '../constants/validationConstants';
import { submitDriversLicenceDetails } from '../Store/Actions';
import { IdentificationCardFooter } from '../Components/IdentificationCardFooter';
import { IdentificationCardHeader } from '../Components/IdentificationCardHeader';
import { EMPTY_STRING, ENABLED_TABINDEX_FOR_NON_INPUT, STRING_SPACE } from '../constants/commonConstants';
import { ifEnterKey } from '../events/onKeyDown';
import { useIdValidationErrorMessageModal } from '../hooks/useIdValidationErrorMessageModal';
import { isCardNumberSameAsLicenceNumber } from '../models/driverLicence';
import { FormGroupSubLabelWithoutTopMargin } from '../Components/TextWithoutTopMargin';
import { EffectiveAustralianState } from '../models/driverLicenceHelpText';
import { DrivingLicenceNumberImage, DrivingLicenceCardNumberImage } from '../Components/IdentificationImageDrivingLicence';

export const IdentificationDriversLicence: React.FC<IIdentificationComponentProps> = ({ onNext, onGoBack, onTryAgain, ...rest }) => {
  const { t } = useTranslation();
  const driversLicence = useSelector((store: IStore) => store.remote.identificationDetails.driversLicence || defaultDriversLicence);

  const [, setModalState] = useModalState();

  const reviewSection = useSelector((store: IStore) => store.local.reviewSection);
  const validatorConfig = {
    debounceMs: 0
  };

  const dispatch = useDispatch();
  const { generateTestId } = useTestId(rest, `driversLicence`);

  const licenceStateValidator = useValidator(driversLicence.licenceState, Validators.required(t('Required')), validatorConfig);
  const licenceNumberValidator = useValidator(
    driversLicence.licenceNumber,
    Validators.required(t('Required')).match(
      DRIVERS_LICENCE_NUMBER_REGEX_RULES[licenceStateValidator.value].pattern,
      DRIVERS_LICENCE_NUMBER_REGEX_RULES[licenceStateValidator.value].message(t)
    ),
    validatorConfig
  );

  const cardNumberValidator = useValidator(
    driversLicence.licenceCardNumber,
    Validators.required('Required')
      .match(
        DRIVERS_LICENCE_CARD_NUMBER_REGEX_RULES[licenceStateValidator.value].pattern,
        DRIVERS_LICENCE_CARD_NUMBER_REGEX_RULES[licenceStateValidator.value].message(t)
      )
      .custom(
        (value) => !isCardNumberSameAsLicenceNumber(licenceNumberValidator.value, value),
        t('Your card number should not be the same as your license number')
      ),
    validatorConfig
  );

  const consentToIdCheckValidator = useValidator(
    driversLicence.consentToIdCheck,
    Validators.custom((value) => value, t('Required')),
    validatorConfig
  );

  const validationStatus = useIdValidationErrorMessageModal(setModalState, onNext, onGoBack, onTryAgain);

  // TODO move to web-design
  const onLicenceStateChanged = (licenceState: string) => {
    licenceStateValidator.handleEvent(licenceState);
  };

  const handleLicenceNumberChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const licenceNumberWithoutSpaces = e.target.value.replaceAll(STRING_SPACE, EMPTY_STRING);
    licenceNumberValidator.handleEvent(licenceNumberWithoutSpaces);
  };

  const handleCardNumberChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cardNumberWithoutSpaces = e.target.value.replaceAll(STRING_SPACE, EMPTY_STRING);
    cardNumberValidator.handleEvent(cardNumberWithoutSpaces);
  };

  const submitDriversLicenceInternal = () => {
    const driversLicenceDetails: IDriversLicenceDetails = {
      consentToIdCheck: consentToIdCheckValidator.value,
      licenceNumber: licenceNumberValidator.value,
      licenceState: licenceStateValidator.value,
      licenceCardNumber: cardNumberValidator.value
    };
    dispatch(submitDriversLicenceDetails(driversLicenceDetails));
  };

  const selectedStateEnum = licenceStateValidator.value as EffectiveAustralianState;

  return (
    <Form>
      <CardLarge>
        <IdentificationCardHeader onGoBack={!reviewSection ? onTryAgain : undefined} />
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <Heading3>{t("Driver's licence")}</Heading3>
          </FormGroup>
          <FormGroup>
            <Label>{t('State of issue')}</Label>
            <RadioButtonGroup>
              {Object.keys(AUSTRALIAN_STATES).map((state) => (
                <React.Fragment key={state}>
                  <RadioButton
                    name='driverLicenceState'
                    id={state}
                    value={state}
                    checked={licenceStateValidator.value === state}
                    onChange={(e) => {
                      licenceStateValidator.handleEvent(e.target.value);
                      licenceNumberValidator.reset();
                      cardNumberValidator.reset();
                    }}
                    invalid={licenceStateValidator.invalid}
                  />
                  <RadioButtonLabelSmall
                    htmlFor={state}
                    tabIndex={ENABLED_TABINDEX_FOR_NON_INPUT}
                    onKeyDown={(e) => ifEnterKey(e, () => onLicenceStateChanged((e.target as HTMLLabelElement).htmlFor))}
                  >
                    {state}
                  </RadioButtonLabelSmall>
                </React.Fragment>
              ))}
            </RadioButtonGroup>
            <ValidationErrorMessage size={Size.Medium} validator={licenceStateValidator} />
          </FormGroup>
          <FormGroupSubLabelWithoutTopMargin>
            <Label>{t('Licence number')}</Label>
            <DrivingLicenceNumberImage state={selectedStateEnum} />
            <FormControl
              type='text'
              placeholder={t('eg. 12345678 or L123456789')}
              value={licenceNumberValidator.value}
              onChange={(e) => handleLicenceNumberChanges(e)}
              onBlur={(e) => handleLicenceNumberChanges(e)}
              invalid={licenceNumberValidator.invalid}
              {...generateTestId(`number`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={licenceNumberValidator} />
          </FormGroupSubLabelWithoutTopMargin>
          <FormGroupSubLabelWithoutTopMargin>
            <Label>{t('Card number')}</Label>
            <DrivingLicenceCardNumberImage state={selectedStateEnum} />
            <FormControl
              type='text'
              placeholder={t('eg. 12345678')}
              value={cardNumberValidator.value}
              onChange={(e) => handleCardNumberChanges(e)}
              onBlur={(e) => handleCardNumberChanges(e)}
              invalid={cardNumberValidator.invalid}
              {...generateTestId(`cardNumber`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={cardNumberValidator} />
          </FormGroupSubLabelWithoutTopMargin>
        </CardContent>
        <CardSeparator />
        <CardFooter>
          <FormGroup>
            <CheckBox
              checked={consentToIdCheckValidator.value}
              label={consentToIdCheckLabel(t)}
              onChange={(e) => consentToIdCheckValidator.handleEvent(e.target.checked)}
              onBlur={(e) => consentToIdCheckValidator.handleEvent(e.target.checked)}
              invalid={consentToIdCheckValidator.invalid}
              {...generateTestId(`confirm`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={consentToIdCheckValidator} />
          </FormGroup>
          <IdentificationCardFooter
            validationStatus={validationStatus}
            onSubmit={submitDriversLicenceInternal}
            onClose={onNext}
            validators={[licenceStateValidator, licenceNumberValidator, cardNumberValidator, consentToIdCheckValidator]}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
