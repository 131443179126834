import { ISelectOption } from '@cmctechnology/phoenix-stockbroking-web-design';
import { STRING_SPACE } from '../constants/commonConstants';
import { IPersonalDetails } from './profile';

export const buildFullName = (firstName: string, lastName: string, middleNameOrInitial?: string | null | undefined) =>
  middleNameOrInitial ? `${firstName}${STRING_SPACE}${middleNameOrInitial}${STRING_SPACE}${lastName}` : `${firstName}${STRING_SPACE}${lastName}`;

const buildSelectOption = (label: string, value: string) => {
  return { label, value };
};

const buildMiddleNamesAndInitials = (middleName: string) => {
  const result = [];
  if (middleName) {
    const middleNameInitial = middleName.charAt(0);
    if (middleNameInitial !== middleName) {
      result.push(middleNameInitial, middleName);
    }
  }
  return result;
};

export const buildFullNamesToMatchNameOnCard = (personalDetails: IPersonalDetails) => {
  const selectOptions: ISelectOption[] = [];
  const middleName = personalDetails.middleName;
  const middleNamesAndInitials = buildMiddleNamesAndInitials(middleName);
  const fullNameWithoutMiddleNameOrInitial = buildFullName(personalDetails.firstName, personalDetails.lastName);
  const selectOptionWithMiddleNameOrInitial = buildSelectOption(fullNameWithoutMiddleNameOrInitial, STRING_SPACE);
  selectOptions.push(selectOptionWithMiddleNameOrInitial);
  middleNamesAndInitials.forEach((item) => {
    const fullName = buildFullName(personalDetails.firstName, personalDetails.lastName, item);
    const selectOption = buildSelectOption(fullName, item);
    selectOptions.push(selectOption);
  });
  return selectOptions;
};
