/* eslint-disable no-magic-numbers */
const tfn8CheckSum = (s: string) => {
  // 8 digit TFN checksum
  const weighted =
    10 * Number.parseInt(s.charAt(0), 10) +
    7 * Number.parseInt(s.charAt(1), 10) +
    8 * Number.parseInt(s.charAt(2), 10) +
    4 * Number.parseInt(s.charAt(3), 10) +
    6 * Number.parseInt(s.charAt(4), 10) +
    3 * Number.parseInt(s.charAt(5), 10) +
    5 * Number.parseInt(s.charAt(6), 10) +
    Number.parseInt(s.charAt(7), 10);
  return weighted;
};

const tfn9CheckSum = (s: string) => {
  // 9 digit TFN checksum
  const weighted =
    10 * Number.parseInt(s.charAt(0), 10) +
    7 * Number.parseInt(s.charAt(1), 10) +
    8 * Number.parseInt(s.charAt(2), 10) +
    4 * Number.parseInt(s.charAt(3), 10) +
    6 * Number.parseInt(s.charAt(4), 10) +
    3 * Number.parseInt(s.charAt(5), 10) +
    5 * Number.parseInt(s.charAt(6), 10) +
    2 * Number.parseInt(s.charAt(7), 10) +
    Number.parseInt(s.charAt(8), 10);
  return weighted;
};

export const taxValidator = {
  isValidTfn: (tfn: string) => {
    if (!tfn) return true;
    // Validate tfn with ATO 8 or 9-digit TFN algorithm
    let s = tfn.replace(/-/g, '').replace(/\s/g, '');

    if (s.length < 8 || s.length > 9) return false;

    let weighted: number;

    if (s.length === 8) {
      // 8 digit TFN checksum
      weighted = tfn8CheckSum(s);
    } else if (s.charAt(0) === '0') {
      s = s.slice(1);
      weighted = tfn8CheckSum(s);
    } else {
      // 9 digit TFN checksum
      weighted = tfn9CheckSum(s);
    }

    return weighted % 11 === 0;
  }
};
