import { Button, CardFooter, CardLarge, useModalState, useTestId } from '@cmctechnology/phoenix-stockbroking-web-design';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CardContentWithoutBottomPadding } from '../../Components/CardContentWithoutBottomPadding';

interface INameExtractCheckModalProps {
  onProceed: () => void;
}

const ModalCardContent = styled(CardContentWithoutBottomPadding)`
  text-align: center;
`;

const ModalCardFooter = styled(CardFooter)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonWithBottomMargin = styled(Button)`
  margin: 1em;
`;

export const NameExtractCheckModal: React.FC<INameExtractCheckModalProps> = ({ onProceed, ...rest }): JSX.Element => {
  const [, setModalState] = useModalState();
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `nameExtractCheckModal`);

  const closeClickHandler = (): void => {
    setModalState({ open: false });
  };

  const onProceedAnyway = () => {
    setModalState({ open: false });
    onProceed();
  };

  return (
    <CardLarge {...generateTestId()}>
      <ModalCardContent>
        <Trans t={t}>
          The name you entered doesn&apos;t seem to match the name that was imported.
          <br />
          <br />
          Please use all of your names that appear in the full name field to avoid delays with your application.
        </Trans>
      </ModalCardContent>
      <ModalCardFooter>
        <ButtonWithBottomMargin label={t('Check Name')} onClick={closeClickHandler} {...generateTestId(`CheckName`)} />
        <Button label={t('Proceed Anyway')} onClick={onProceedAnyway} invertColours {...generateTestId(`ProceedAnyway`)} />
      </ModalCardFooter>
    </CardLarge>
  );
};
