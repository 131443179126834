import { TFunction } from 'i18next';
import { IFieldValidation } from '../models/validation';

export const DATE_FORMAT = 'YYYY/MM/DD';
export const MINIMUM_AGE = 18;
export const MIDDLE_NAME_DOT_REGEX = /\./;
export const MIDDLE_NAME_SPACE_REGEX = /^\s+|\s+$/;
export const MIDDLE_NAME_MINIMUM_CHARACTER_REGEX = /^.$/;
export const FIRST_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX = /^[ 'A-Za-z-]{1,50}$/; // use the same Regex rule as the back end for equifax
export const LAST_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX = /^[ '.A-Za-z-]{1,50}$/; // use the same Regex rule as the back end for equifax
export const MIDDLE_NAME_ALLOWED_CHARACTER_REGEX_FOR_EQUIFAX = /^([ 'A-Za-z-])+$/; // full stop is not allowed for equifax; spaces is not allowed for submit application
export const MIDDLE_NAME_LENGTH_WHEN_NO_VALUE = 0;
export const FORMATTED_MONTH_FIXED_LENGTH = 2;

export const invalidMiddleNameValidations: Record<string, IFieldValidation> = {
  lessThanMinCharacter: { regex: MIDDLE_NAME_MINIMUM_CHARACTER_REGEX, message: (t) => t('Minimum 2 characters') },
  containsSpace: { regex: MIDDLE_NAME_SPACE_REGEX, message: (t) => t('Cannot start or end with spaces') },
  containsDot: { regex: MIDDLE_NAME_DOT_REGEX, message: (t) => t('Cannot contain a dot') }
};

export const errorMessageForFirstLastNameAllowedCharacters = (t: TFunction) => {
  return t('Can only contain alpha numeric characters, hyphens, apostrophe, full stop and spaces but no other characters');
};

export const errorMessageForMiddleNameAllowedCharacters = (t: TFunction) => {
  return t('Can only contain alpha characters, hyphens and apostrophes');
};
