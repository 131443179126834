/* eslint-disable no-console */
/* eslint-disable i18next/no-literal-string */
/* eslint-disable no-underscore-dangle */
import { EventType, Theme } from '@cmctechnology/webinvest-analytics-compat';
import { AccountTypeInput, PaymentMethod } from '../models/application';
import { TrackEventNames, ApplicationFormPaths } from '../constants/analyticsConstants';
import { IApplicationFormPath } from '../models/analyticsTrackEventAttributes';
import { logError } from '../logger/logger';

declare global {
  interface Window {
    fireWhenMparticleReady: undefined | ((callback: () => void) => Promise<void>);
    mParticle: any;
  }
}

export const trackEvent = async (eventName: string, eventType: any, data: any) => {
  if (window.fireWhenMparticleReady) {
    await window.fireWhenMparticleReady(() => {
      const lastInstance = Object.keys(window.mParticle?._instances)?.pop();
      window.mParticle?.getInstance(lastInstance)?.logEvent(eventName, eventType, Object.assign(data));
    });
  }
};

export const identifyUser = async (crc: string, email: string, userAttributes: any) => {
  try {
    if (window.fireWhenMparticleReady) {
      await window.fireWhenMparticleReady(() => {
        const lastInstance = Object.keys(window.mParticle._instances).pop();
        const currentUser = window.mParticle?.getInstance(lastInstance).Identity.getCurrentUser();
        // if same user is already logged in, do nothing
        if (currentUser?.isLoggedIn() && currentUser?.getUserIdentities()?.userIdentities?.customerid === crc) {
          return;
        }

        if (currentUser?.isLoggedIn()) {
          window.mParticle?.endSession();
        }
        window.mParticle?.Identity.login({
          userIdentities: {
            customerid: crc,
            email
          },
          onUserAlias: (alias: any) => {
            alias.setUserAttributes(userAttributes);
          }
        });
      });
    }
  } catch (error) {
    logError(error);
  }
};

export const trackApplication = async (
  status: ApplicationFormPaths,
  accountType?: AccountTypeInput,
  method?: PaymentMethod,
  employment?: string,
  idType?: string
) => {
  const attributes: IApplicationFormPath = {
    Url: window.location.href,
    ApplicationStatus: status,
    Theme: Theme.Cmc
  };

  // mparticle doesn't like undefined propeties so only create them if they exist
  if (accountType) attributes.AccountType = accountType;
  if (method) attributes.PaymentMethod = method;
  if (employment) attributes.Employment = employment;
  if (idType) attributes.Identification = idType;

  try {
    await trackEvent(TrackEventNames.ApplicationForm, EventType.Navigation, attributes);
    return;
  } catch (error) {
    logError(error);
  }
};

export const trackApplicationSubmitted = async (
  status: ApplicationFormPaths,
  accountType?: AccountTypeInput,
  account_id?: string,
  person_id?: string,
  email_address?: string
) => {
  const attributes: IApplicationFormPath = {
    Url: window.location.href,
    ApplicationStatus: status,
    Theme: Theme.Cmc
  };

  // mparticle doesn't like undefined propeties so only create them if they exist
  if (accountType) attributes.AccountType = accountType;
  if (account_id) attributes.AccountId = account_id;
  if (person_id) attributes.PersonId = person_id;
  if (email_address) attributes.EmailAddress = email_address;

  try {
    await trackEvent(TrackEventNames.ApplicationSubmitted, EventType.Navigation, attributes);
    return;
  } catch (error) {
    logError(error);
  }
};
