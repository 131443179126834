import {
  Button,
  CardLarge,
  CardSeparator,
  Form,
  FormGroup,
  Size,
  Text,
  useTestId,
  useValidator,
  Validators,
  Variant
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IdentificationType } from '../models/identification';
import { actions, IStore } from '../Store/Store';
import { IPreviousPage } from '../models/IPageNavigation';
import { IdentificationCardHeader } from '../Components/IdentificationCardHeader';
import { CardFooterWithSmallPaddingTop } from '../Components/CardWithSmallPadding';
import { CardContentWithoutBottomPadding, CardOptionRadioButton } from '../Components/CardContentWithoutBottomPadding';

export interface IIdentificationSelectProps extends IPreviousPage {
  onNext: (idType: IdentificationType) => void;
}

export const IdentificationSelect: React.FC<IIdentificationSelectProps> = ({ onNext, onPrevious, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { generateTestId } = useTestId(rest, `identification`);
  const idType = useSelector((store: IStore) => store.remote.identificationDetails.identificationType);

  const idTypeValidator = useValidator(idType, Validators.required(t('Please choose one identification option')));

  const onNextClicked = () => {
    dispatch(actions.identificationSelected(idTypeValidator.value));
    /* istanbul ignore else */
    if (idTypeValidator.value !== IdentificationType.None) {
      onNext(idTypeValidator.value!);
    }
  };

  return (
    <Form>
      <CardLarge>
        <IdentificationCardHeader onGoBack={onPrevious} />
        <CardSeparator />
        <CardContentWithoutBottomPadding>
          <CardOptionRadioButton
            renderLabel={() => (
              <Text size={Size.Large}>
                <Trans t={t}>
                  <b>Driver&apos;s Licence</b> (recommended)
                </Trans>
              </Text>
            )}
            body={t("A valid Australian Driver's Licence")}
            onClick={() => idTypeValidator.handleEvent(IdentificationType.DriversLicence)}
            selected={idTypeValidator.value === IdentificationType.DriversLicence}
            {...generateTestId(`driversLicence`)}
          />
          <CardOptionRadioButton
            label={t('Passport')}
            body={t('A valid Passport')}
            onClick={() => idTypeValidator.handleEvent(IdentificationType.Passport)}
            selected={idTypeValidator.value === IdentificationType.Passport}
            {...generateTestId(`passport`)}
          />
          <CardOptionRadioButton
            label={t('Medicare')}
            body={t('A valid Medicare card')}
            onClick={() => idTypeValidator.handleEvent(IdentificationType.Medicare)}
            selected={idTypeValidator.value === IdentificationType.Medicare}
            {...generateTestId(`medicare`)}
          />
        </CardContentWithoutBottomPadding>
        <CardFooterWithSmallPaddingTop>
          {idTypeValidator.invalid && (
            <Text size={Size.Large} variant={Variant.Error}>
              {t('Please choose one identification option')}
            </Text>
          )}
          <FormGroup>
            <Button centered label={t('Next')} onClick={onNextClicked} validators={[idTypeValidator]} {...generateTestId(`next`)} />
          </FormGroup>
        </CardFooterWithSmallPaddingTop>
      </CardLarge>
    </Form>
  );
};
