import React, { useEffect, useState } from 'react';
import { CardLarge, Form, FormGroup, LoadingBar, Size, Text, useTestId } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExtraLargeButton } from '../../Components/ExtraLargeButton';
import { IStore } from '../../Store/Store';
import { CardFooterWithSmallPaddingTop } from '../../Components/CardWithSmallPadding';
import { INextPage } from '../../models/IPageNavigation';
import { IdentificationValidationStatus } from '../../models/identification';
import { FullWidthCardContent } from '../../Components/Sgp/FullWidthCardContent';
import { openSingpassOrJumioAuthorisation } from '../../Store/Actions';
import { IdVerifyProvider } from '../../constants/sgp/myinfo';
import { JumioPageHeader } from '../../Components/Sgp/JumioPageHeader';

export interface IJumioProcessingProps extends INextPage {
  onRetryJumio: () => void;
}
export const JumioProcessing: React.FC<IJumioProcessingProps> = ({ onNext, onRetryJumio, ...rest }) => {
  const { generateTestId } = useTestId(rest, `jumio_processing`);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationStatus = useSelector((store: IStore) => store.remote.identificationDetails.status);
  const identityVerifyNeedLongerWaitingTime = useSelector((store: IStore) => store.local.identityVerifyNeedLongerWaitingTime);
  const [showLoadingBar, setShowLoadingBar] = useState(true);
  const finishedIdVerify = validationStatus === IdentificationValidationStatus.Passed || validationStatus === IdentificationValidationStatus.Failed;

  const onRetryJumioClicked = () => {
    dispatch(openSingpassOrJumioAuthorisation({ providerType: IdVerifyProvider.Jumio }));
    onRetryJumio();
  };

  useEffect(() => {
    if (validationStatus === IdentificationValidationStatus.Failed) {
      setShowLoadingBar(false);
    }
    if (validationStatus === IdentificationValidationStatus.Passed) {
      setShowLoadingBar(false);
      onNext();
    }
  }, [validationStatus]);

  // TODO: Verify me later button will appear after we we done the manual verifying process [SBAM-1971]
  return (
    <Form>
      <CardLarge>
        <JumioPageHeader />
        <FullWidthCardContent needSmallPadding>
          {showLoadingBar && !identityVerifyNeedLongerWaitingTime && (
            <FormGroup>
              <Text size={Size.Large} textAlign='left'>
                {t('Please wait while we are verifying your identity from Jumio. Do not close your browser.')}
              </Text>
            </FormGroup>
          )}
          {identityVerifyNeedLongerWaitingTime && !finishedIdVerify && (
            <FormGroup>
              <Text size={Size.Large} textAlign='left'>
                {t('Sorry things are taking longer than expected. Please hold on whilst we try to verify your identity.')}
              </Text>
            </FormGroup>
          )}
          {validationStatus === IdentificationValidationStatus.Failed && (
            <>
              <FormGroup>
                <Text size={Size.Large} textAlign='left'>
                  {t(
                    'Sorry we were unable to verify your identity. You can try again with another ID or we can send you an email with instructions how to send copies of your ID documents.'
                  )}
                </Text>
              </FormGroup>
              <FormGroup>
                <ExtraLargeButton centered label={t('Try Again')} onClick={onRetryJumioClicked} {...generateTestId(`try_jumio_again`)} />
              </FormGroup>
            </>
          )}
        </FullWidthCardContent>
        <CardFooterWithSmallPaddingTop>
          <FormGroup>{showLoadingBar && !finishedIdVerify && <LoadingBar />}</FormGroup>
        </CardFooterWithSmallPaddingTop>
      </CardLarge>
    </Form>
  );
};
