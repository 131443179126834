import React from 'react';
import {
  FormRow,
  CardLarge,
  FormGroup,
  Heading1,
  Text,
  Button,
  FormCol,
  CardFooter,
  CardSeparator,
  Size,
  CardHeader
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReviewProfileIdentificationDetails } from '../Review/ReviewProfileIdentificationDetails';
import { actions, IStore } from '../Store/Store';
import { ReviewProfilePersonalDetails } from '../Review/ReviewProfilePersonalDetails';
import { ReviewProfilePersonalAddressDetails } from '../Review/ReviewProfilePersonalAddressDetails';
import { ReviewProfileTaxDetails } from '../Review/ReviewProfileTaxDetails';
import { ReviewProfileEmploymentDetails } from '../Review/ReviewProfileEmploymentDetails';
import { updateTermsAndConditions } from '../Store/Actions';
import { ProfilePersonalDetails } from './ProfilePersonalDetails';
import { ProfileAddress } from './ProfileAddress';
import { ProfileTaxDetails } from './ProfileTaxDetails';
import { ProfileEmployment } from './ProfileEmployment';
import { IdentificationType } from '../models/identification';
import { ReviewSection } from '../models/reviewAndSubmit';
import { GoBackIcon } from '../Components/GoBackIcon';
import { IPreviousPage } from '../models/IPageNavigation';
import { termsAndConditions, activeAdditionalTermsAndConditions } from '../models/application';

export interface IReviewAndSubmitProps extends IPreviousPage {
  onConfirm: () => void;
  onIdentityEdit: (idType: IdentificationType) => void;
}

export const ReviewAndSubmit: React.FC<IReviewAndSubmitProps> = ({ onConfirm, onIdentityEdit, onPrevious }) => {
  const profile = useSelector((store: IStore) => store.remote.profile);
  const reviewSection = useSelector((store: IStore) => store.local.reviewSection);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onConfirmClicked = () => {
    dispatch(updateTermsAndConditions([...termsAndConditions, ...activeAdditionalTermsAndConditions].map((tc) => tc.label(t))));
    onConfirm();
  };

  const changeReviewSection = (section: ReviewSection) => {
    dispatch(actions.reviewSectionSelected(section));
  };

  const clearReviewSection = () => {
    dispatch(actions.reviewSectionClear());
  };

  if (reviewSection === ReviewSection.PersonalDetails) {
    return <ProfilePersonalDetails onNext={clearReviewSection} />;
  }
  if (reviewSection === ReviewSection.Address) {
    return <ProfileAddress onNext={clearReviewSection} />;
  }
  if (reviewSection === ReviewSection.TaxDetails) {
    return <ProfileTaxDetails onNext={clearReviewSection} />;
  }
  if (reviewSection === ReviewSection.Employment) {
    return <ProfileEmployment onNext={clearReviewSection} />;
  }

  return (
    <CardLarge>
      <CardHeader>
        <GoBackIcon onClick={onPrevious} />
        <Heading1>{t('Review your details')}</Heading1>
        <Text size={Size.Large}>{t('Please confirm if all your details are correct')}</Text>
      </CardHeader>
      <CardSeparator />
      <ReviewProfilePersonalDetails personalDetails={profile.personalDetails} onEdit={() => changeReviewSection(ReviewSection.PersonalDetails)} />
      <CardSeparator />
      <ReviewProfilePersonalAddressDetails addressDetails={profile.addressDetails} onEdit={() => changeReviewSection(ReviewSection.Address)} />
      <CardSeparator />
      <ReviewProfileTaxDetails taxDetails={profile.taxDetails} onEdit={() => changeReviewSection(ReviewSection.TaxDetails)} />
      <CardSeparator />
      <ReviewProfileEmploymentDetails occupation={profile.employmentDetails.occupation} onEdit={() => changeReviewSection(ReviewSection.Employment)} />
      <CardSeparator />
      <ReviewProfileIdentificationDetails onIdentityEdit={onIdentityEdit} />
      <CardSeparator />
      <CardFooter>
        <FormGroup>
          <FormRow>
            <FormCol percentWidth={50}>
              <Button label={t('Confirm')} onClick={onConfirmClicked} />
            </FormCol>
          </FormRow>
        </FormGroup>
      </CardFooter>
    </CardLarge>
  );
};
