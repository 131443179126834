import { OnboardingModel } from '@cmctechnology/phoenix-stockbroking-api-client';
import { IStore } from '../../Store/Store';

export const applicationConverter = {
  convertToOnlineApplicationRequest: (currentState: IStore) => {
    const remoteProfilePart = currentState.remote.sgp.profile;
    const localPart = currentState.local.sgp;

    const onboardingModel: OnboardingModel = {
      user: remoteProfilePart.personalDetails,
      registeredAddress: remoteProfilePart.addressDetails,
      employmentModel: remoteProfilePart.employment,
      taxInformation: remoteProfilePart.taxInformation,
      financialBackground: remoteProfilePart.financialBackground,
      knowledgeModel: remoteProfilePart.knowledgeExperience,
      termsAndConditions: remoteProfilePart.termsAndConditions,
      promoCode: localPart.promoCode
    };

    const request: any = {
      workflowId: currentState.persist?.sgp.workflowId,
      onboardingModel
    };
    return request;
  }
};
