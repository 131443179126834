import { UsersErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { Translated } from '../translation';
import { BaseErrorCode, baseErrorMessages } from '../baseErrorCodes';
import { baseUserErrorCodeMappings } from './baseUserErrorCodeMappings';

enum SignInErrorCode {
  InvalidEmailOrPassword = 'InvalidEmailOrPassword',
  UserNotConfirmedException = 'UserNotConfirmedException'
}

// Map backend error code [UsersErrorCode] to client error code[SignInErrorCode]
const signInErrorCodeMappings: Record<UsersErrorCode, BaseErrorCode | SignInErrorCode> = {
  ...baseUserErrorCodeMappings,
  [UsersErrorCode.NotAuthorizedException]: SignInErrorCode.InvalidEmailOrPassword,
  [UsersErrorCode.UserNotFoundException]: SignInErrorCode.InvalidEmailOrPassword,
  [UsersErrorCode.UserNotConfirmedException]: SignInErrorCode.UserNotConfirmedException
};

const signInErrorMessages: Record<BaseErrorCode | SignInErrorCode, Translated> = {
  ...baseErrorMessages,
  [SignInErrorCode.InvalidEmailOrPassword]: (t) => t('Invalid email / password combination.'),
  [SignInErrorCode.UserNotConfirmedException]: (t) => t("Your email hasn't been confirmed yet. Please create your login again.")
};

export const getSignInErrorMessage = (errorCode: string, t: TFunction) => {
  if (!errorCode) return undefined;
  const errorCodeEnum = errorCode as UsersErrorCode;

  return signInErrorMessages[signInErrorCodeMappings[errorCodeEnum]](t);
};
