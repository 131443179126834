import { ApplicationApi } from '@cmctechnology/phoenix-stockbroking-api-client';
import { createConfig } from '../configuration/configuration';
import { createApiConfig, createAxios, Interceptor } from './axiosFactory';

export const createApplicationApi = (interceptor?: Interceptor) => {
  const { apiUrl } = createConfig();
  const applicationApi = new ApplicationApi(createApiConfig(), apiUrl, createAxios(interceptor));

  return applicationApi;
};
