import { IconNames } from '@cmctechnology/phoenix-stockbroking-web-design';
import { IdentificationValidationStatus } from '../models/identification';
import { KeyValueTranslated } from '../models/translation';

export const submitButtonIcon = {
  [IdentificationValidationStatus.NotStarted]: undefined,
  [IdentificationValidationStatus.InProgress]: undefined,
  [IdentificationValidationStatus.Passed]: IconNames.Check,
  [IdentificationValidationStatus.Failed]: undefined
};

export const submitButtonLabel: KeyValueTranslated<IdentificationValidationStatus> = {
  [IdentificationValidationStatus.NotStarted]: (t) => t('Check my ID'),
  [IdentificationValidationStatus.InProgress]: (t) => t('Checking your ID'),
  [IdentificationValidationStatus.Passed]: (t) => t('Success!'),
  [IdentificationValidationStatus.Failed]: (t) => t('Check my ID')
};

export const IDENTIFICATION_SUCCESS_PAUSE_TIME = 3000;
export const IDENTIFICATION_POLL_STATUS_INTERVAL = 10_000;
export const IDENTIFICATION_POLL_ALERT_SLOW_PROCESS_TIME = 20_000;
export const IDENTIFICATION_POLL_TIMEOUT = 55_000;
export const MAX_DRIVER_LICENCE_CARD_NUMBER_LENGTH = 10;
