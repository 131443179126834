import { CashAccountDetailsErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { BaseErrorCode } from '../baseErrorCodes';

export const baseCashAccountDetailsErrorCodeMappings: Record<CashAccountDetailsErrorCode, BaseErrorCode> = {
  [CashAccountDetailsErrorCode.InvalidParameter]: BaseErrorCode.UnexpectedError,
  [CashAccountDetailsErrorCode.ApplicationNotExists]: BaseErrorCode.UnexpectedError,
  [CashAccountDetailsErrorCode.CashAccountHasNotCreated]: BaseErrorCode.UnexpectedError,
  [CashAccountDetailsErrorCode.CashAccountNotExists]: BaseErrorCode.UnexpectedError,
  [CashAccountDetailsErrorCode.CashAccountNameMissing]: BaseErrorCode.UnexpectedError,
  [CashAccountDetailsErrorCode.CashAccountBsbMissing]: BaseErrorCode.UnexpectedError,
  [CashAccountDetailsErrorCode.CashAccountNumberMissing]: BaseErrorCode.UnexpectedError
};
