import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import {
  Heading1,
  CardHeader,
  CardSeparator,
  CardContent,
  FormGroup,
  Text,
  Size,
  CardFooter,
  Button,
  Notification,
  Variant,
  LoadingBar,
  FormLabel
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useDispatch, useSelector } from 'react-redux';
import { FundInterceptPanel } from './FundInterceptPanel';
import { CardWrapper } from '../Login/LoginCard';
import { IStore } from '../../Store/Store';
import { ApiRequest, ApiRequestStatus } from '../../models/apiRequestResult';
import { getCashAccountDetails } from '../../Store/Actions';
import { getCashAccountErrorMessage } from '../../models/cashAccountErrorCodes/cashAccountErrorCode';
import { INextPage } from '../../models/IPageNavigation';

const FundCardHeader = styled(CardHeader)`
  padding: 2rem 0;
  width: 100%;
  justify-content: center;

  ${Heading1} {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colours.card.header.text};
  }

  ${Text} {
    margin: 1rem 1rem 0;
  }
`;

const FundCardContent = styled(CardContent)`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 2rem 0;
`;

const FundCardFooter = styled(CardFooter)`
  padding-top: 2rem;
  padding-bottom: 3rem;
`;

const FormGroupWithLargeBottomMargin = styled(FormGroup)`
  margin-bottom: 2rem;
`;

interface IFundInterceptCardProps extends INextPage {}

export const FundInterceptCard: React.FC<IFundInterceptCardProps> = ({ onNext }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const getCashAccountDetailsApiResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.GetCashAccount]);
  const cashAccountDetails = useSelector((store: IStore) => store.local.cashAccountDetails);

  useEffect(() => {
    dispatch(getCashAccountDetails());
  }, []);

  return (
    <CardWrapper>
      <FundCardHeader>
        <Heading1>{t('Add funds today')}</Heading1>
        <Text textAlign='left' size={Size.Large}>
          {t('To start investing, log into your online banking and use the below account details to transfer funds today.')}
        </Text>
      </FundCardHeader>
      <CardSeparator />
      <FundCardContent>
        <FormGroupWithLargeBottomMargin>
          <FormLabel>{t('Here are your ANZ Bank details.')}</FormLabel>
        </FormGroupWithLargeBottomMargin>
        <FundInterceptPanel label={t('BSB')} text={cashAccountDetails?.accountBsb} />
        <FundInterceptPanel label={t('Account number')} text={cashAccountDetails?.accountNumber} />
        <FundInterceptPanel label={t('Account name')} text={cashAccountDetails?.accountName} />
        <FormGroup>
          <Text textAlign='left' size={Size.Medium}>
            <Trans t={t}>
              Please allow up to <b>24 hours</b> for transfers to appear in your account.
            </Trans>
          </Text>
        </FormGroup>
      </FundCardContent>
      <FundCardFooter>
        {getCashAccountDetailsApiResult.status === ApiRequestStatus.InProgress && <LoadingBar />}
        {getCashAccountDetailsApiResult.status === ApiRequestStatus.Failed && getCashAccountDetailsApiResult.errorCode && (
          <Notification variant={Variant.Error}>
            <Text size={Size.Large} variant={Variant.Error}>
              {getCashAccountErrorMessage(getCashAccountDetailsApiResult.errorCode, t)}
            </Text>
          </Notification>
        )}
        {getCashAccountDetailsApiResult.status === ApiRequestStatus.Success && <Button label={t('Log into Platform')} onClick={onNext} size={Size.Medium} />}
      </FundCardFooter>
    </CardWrapper>
  );
};
