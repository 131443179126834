import { DayMonthYear, DayMonthYear1 } from '@cmctechnology/phoenix-stockbroking-api-client';
import dayjs from 'dayjs';

export const DATE_REGEX: RegExp = /^\d{4}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01])$/;
export const DATE_FORMAT: string = 'YYYY-MM-DD';
export const DAY_UNIT = 'day';
export const MONTH_UNIT = 'month';
export const YEAR_UNIT = 'year';
export const DATE_SPAN_ZERO = 0;
export const DATE_SPAN_ONE = 1;
export const DATE_SPAN_TWO = 2;

export const getLastDayOfMonth = (year: string, month: string): string | undefined => {
  const firstDateStr = `${year}-${month}-${DATE_SPAN_ONE}`;
  if (!DATE_REGEX.test(firstDateStr)) return undefined;

  const lastDateOfThisMonth = dayjs(firstDateStr, DATE_FORMAT).add(DATE_SPAN_ONE, MONTH_UNIT).subtract(DATE_SPAN_ONE, DAY_UNIT);
  return lastDateOfThisMonth.date().toString();
};

export const isToday = (dateString: string) => {
  return dayjs(dateString, DATE_FORMAT).isSame(dayjs(), DAY_UNIT);
};

export const isTodayBeforeOrEqualTo = (dateString: string) => {
  if (isToday(dateString)) return true;
  return dayjs().isBefore(dayjs(dateString, DATE_FORMAT));
};

export const isTodayAfterOrEqualTo = (dateString: string) => {
  if (isToday(dateString)) return true;
  return dayjs().isAfter(dayjs(dateString, DATE_FORMAT));
};

export const isEqualDayMonthYear = (date1: DayMonthYear1 | DayMonthYear | undefined, date2: DayMonthYear1 | DayMonthYear | undefined): boolean => {
  if (date2 === undefined) return false;
  if (date1 === undefined) return false;
  return date1.day === date2.day && date1.month === date2.month && date1.year === date2.year;
};
