import React from 'react';
import { MedicareCardColour } from '@cmctechnology/phoenix-stockbroking-api-client';
import yellowCardNumberImage from '../Assets/documents/au/mc/medicare-yellow-card-number.jpg';
import greenCardNumberImage from '../Assets/documents/au/mc/medicare-green-card-number.jpg';
import blueCardNumberImage from '../Assets/documents/au/mc/medicare-blue-card-number.jpg';
import yellowReferenceNumberImage from '../Assets/documents/au/mc/medicare-yellow-reference-number.jpg';
import greenReferenceNumberImage from '../Assets/documents/au/mc/medicare-green-reference-number.jpg';
import blueReferenceNumberImage from '../Assets/documents/au/mc/medicare-blue-reference-number.jpg';
import { IdentificationCardImagePod } from './IdentificationCardImagePod';

interface IIdentificationMedicareImageProps {
  cardColour: MedicareCardColour;
}

const medicareCardNumberImages: Record<MedicareCardColour, string> = {
  [MedicareCardColour.Yellow]: yellowCardNumberImage,
  [MedicareCardColour.Green]: greenCardNumberImage,
  [MedicareCardColour.Blue]: blueCardNumberImage
};

const medicareReferenceNumberImages: Record<MedicareCardColour, string> = {
  [MedicareCardColour.Yellow]: yellowReferenceNumberImage,
  [MedicareCardColour.Green]: greenReferenceNumberImage,
  [MedicareCardColour.Blue]: blueReferenceNumberImage
};

export const MedicareCardNumberImage: React.FC<IIdentificationMedicareImageProps> = ({ cardColour }) => {
  const imageUrl = medicareCardNumberImages[cardColour];
  return imageUrl ? <IdentificationCardImagePod src={imageUrl} /> : <></>;
};

export const MedicareReferenceNumberImage: React.FC<IIdentificationMedicareImageProps> = ({ cardColour }) => {
  const imageUrl = medicareReferenceNumberImages[cardColour];
  return imageUrl ? <IdentificationCardImagePod src={imageUrl} /> : <></>;
};
