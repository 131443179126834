import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, CardLarge, CardSeparator, Heading1, CardHeader, CardContent, CardFooter, useTestId } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { IValidator } from '@cmctechnology/phoenix-stockbroking-web-design/dist/js/hooks/useValidator';
import { INextPage, IPreviousPage } from '../models/IPageNavigation';
import { PEPQuestion1 } from '../Components/PEP/PEPQuestion1';
import { PEPQuestion2 } from '../Components/PEP/PEPQuestion2';
import { InlineButton } from '../Components/InlineButton';
import { PEPQuestion3 } from '../Components/PEP/PEPQuestion3';
import { IPEPQuestionsModel } from '../models/pepQuestions';
import { PEPQuestion4 } from '../Components/PEP/PEPQuestion4';
import { PEPQuestion5 } from '../Components/PEP/PEPQuestion5';
import { PEPQuestion6 } from '../Components/PEP/PEPQuestion6';
import { pepQuestionsAnswered } from '../Store/Actions';
import { GoBackIcon } from '../Components/GoBackIcon';

export interface IdentificationPEPProps extends INextPage, Partial<IPreviousPage> {}

export const IdentificationPEP: React.FC<IdentificationPEPProps> = ({ onNext, onPrevious, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `pepQuestions`);

  const [answers, setAnswers] = useState<IPEPQuestionsModel>({
    question1: { answered: false },
    question2: { answered: false },
    question3: { answered: false },
    question4: { answered: false },
    question5: { answered: false },
    question6: { answered: false }
  });

  const [q1Selected, setQ1Selected] = useState<boolean | undefined>(undefined);
  const [q1Validators, setQ1Validators] = useState<IValidator<any>[]>([]);
  const [q2Selected, setQ2Selected] = useState<boolean | undefined>(undefined);
  const [q2Validators, setQ2Validators] = useState<IValidator<any>[]>([]);
  const [q3Selected, setQ3Selected] = useState<boolean | undefined>(undefined);
  const [q3Validators, setQ3Validators] = useState<IValidator<any>[]>([]);
  const [q4Selected, setQ4Selected] = useState<boolean | undefined>(undefined);
  const [q4Validators, setQ4Validators] = useState<IValidator<any>[]>([]);
  const [q5Selected, setQ5Selected] = useState<boolean | undefined>(undefined);
  const [q5Validators, setQ5Validators] = useState<IValidator<any>[]>([]);
  const [q6Selected, setQ6Selected] = useState<boolean | undefined>(undefined);
  const [q6Validators, setQ6Validators] = useState<IValidator<any>[]>([]);

  const onNextClicked = () => {
    dispatch(pepQuestionsAnswered(answers));
    onNext();
  };

  return (
    <Form>
      <CardLarge>
        <CardHeader>
          <GoBackIcon onClick={onPrevious} />
          <Heading1>{t('Additional Questions')}</Heading1>
        </CardHeader>
        <CardSeparator />
        <CardContent>
          <PEPQuestion1
            selected={q1Selected}
            setSelected={setQ1Selected}
            validators={q1Validators}
            setValidators={setQ1Validators}
            answers={answers}
            setAnswers={setAnswers}
          />
          <PEPQuestion2
            selected={q2Selected}
            setSelected={setQ2Selected}
            validators={q2Validators}
            setValidators={setQ2Validators}
            answers={answers}
            setAnswers={setAnswers}
          />
          <PEPQuestion3
            selected={q3Selected}
            setSelected={setQ3Selected}
            validators={q3Validators}
            setValidators={setQ3Validators}
            answers={answers}
            setAnswers={setAnswers}
          />
          <PEPQuestion4
            selected={q4Selected}
            setSelected={setQ4Selected}
            validators={q4Validators}
            setValidators={setQ4Validators}
            answers={answers}
            setAnswers={setAnswers}
          />
          <PEPQuestion5
            selected={q5Selected}
            setSelected={setQ5Selected}
            validators={q5Validators}
            setValidators={setQ5Validators}
            answers={answers}
            setAnswers={setAnswers}
          />
          <PEPQuestion6
            selected={q6Selected}
            setSelected={setQ6Selected}
            validators={q6Validators}
            setValidators={setQ6Validators}
            answers={answers}
            setAnswers={setAnswers}
          />
        </CardContent>
        <CardFooter>
          <InlineButton
            label={t('Next')}
            onClick={() => onNextClicked()}
            validators={[...q1Validators, ...q2Validators, ...q3Validators, ...q4Validators, ...q5Validators, ...q6Validators]}
            disabled={
              q1Selected === undefined ||
              q2Selected === undefined ||
              q3Selected === undefined ||
              q4Selected === undefined ||
              q5Selected === undefined ||
              q6Selected === undefined
            }
            {...generateTestId(`submitButton`)}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
