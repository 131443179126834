import { IModalState } from '@cmctechnology/phoenix-stockbroking-web-design';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IdentificationFail } from '../Components/IdentificationFail';
import { IdentificationTimeout } from '../Components/IdentificationTimeout';
import { IDENTIFICATION_SUCCESS_PAUSE_TIME } from '../constants/identificationValidationConstants';
import { ApiRequest } from '../models/apiRequestResult';
import { hasExceededRequestLimitForVerifyIdStatus, IdentificationValidationStatus } from '../models/identification';
import { resetIdentityVerifyWarnings, resetStoreAndRedirectToLoginPage } from '../Store/Actions';
import { actions, IStore } from '../Store/Store';

const onGoBackClicked = (dispatch: Dispatch<any>, setModalState: (next: IModalState) => void, onGoBack: () => void) => {
  dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.NotStarted));
  setModalState({ open: false });
  dispatch(resetIdentityVerifyWarnings());
  onGoBack();
};

const onTryAgainClicked = (dispatch: Dispatch<any>, setModalState: (next: IModalState) => void, onTryAgain: () => void) => {
  dispatch(actions.identificationValidationStatusUpdated(IdentificationValidationStatus.NotStarted));
  setModalState({ open: false });
  dispatch(resetIdentityVerifyWarnings());
  onTryAgain();
};

const onEmailMeClicked = (dispatch: Dispatch<any>, setModalState: (next: IModalState) => void, onNext: () => void) => {
  setModalState({ open: false });
  onNext();
};

const onExitAppClicked = (dispatch: Dispatch<any>, setModalState: (next: IModalState) => void) => {
  setModalState({ open: false });
  dispatch(resetStoreAndRedirectToLoginPage());
};

export const useIdValidationErrorMessageModal = (
  setModalState: (next: IModalState) => void,
  onNext: () => void,
  onGoBack: () => void,
  onTryAgain: () => void
) => {
  const dispatch = useDispatch();
  const identityVerifyTimeout = useSelector((store: IStore) => store.local.identityVerifyTimeout);
  const verifyIdentityStatusResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.VerifyIdentityStatus]);
  const hasExceededRequestLimitWhenVerifyIdStatus = hasExceededRequestLimitForVerifyIdStatus(verifyIdentityStatusResult.errorCode);
  const identityVerifyInProgressAfterLogin = useSelector((store: IStore) => store.local.identityVerifyInProgressAfterLogin);
  const validationStatus = useSelector((store: IStore) => store.remote.identificationDetails.status);

  useEffect(() => {
    // Timeout from current api call or the status is In_Progress after user re-login
    if (identityVerifyInProgressAfterLogin || identityVerifyTimeout) {
      setModalState({
        open: true,
        dialog: <IdentificationTimeout onExitApp={() => onExitAppClicked(dispatch, setModalState)} />,
        disableBackdropClick: true
      });
    }
    return () => {
      setModalState({
        open: false
      });
    };
  }, [identityVerifyTimeout, identityVerifyInProgressAfterLogin]);

  useEffect(() => {
    if (validationStatus === IdentificationValidationStatus.Passed) {
      setTimeout(() => {
        onNext();
      }, IDENTIFICATION_SUCCESS_PAUSE_TIME);
    } else if (validationStatus === IdentificationValidationStatus.Failed || hasExceededRequestLimitWhenVerifyIdStatus) {
      setModalState({
        open: true,
        dialog: (
          <IdentificationFail
            onGoBack={() => onGoBackClicked(dispatch, setModalState, onGoBack)}
            onTryAgain={() => onTryAgainClicked(dispatch, setModalState, onTryAgain)}
            onEmailMe={() => onEmailMeClicked(dispatch, setModalState, onNext)}
          />
        ),
        disableBackdropClick: true
      });
    }
    return () => {
      setModalState({
        open: false
      });
    };
  }, [validationStatus, hasExceededRequestLimitWhenVerifyIdStatus]);
  return validationStatus;
};
