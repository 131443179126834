import { AddressInput } from '@cmctechnology/phoenix-stockbroking-api-client';
import { invalidPostcodeValidations, PO_BOX_REGEX, CARE_OF_ADDRESS_REGEX } from '../constants/addressConstants';
import { IFieldValidation } from '../models/validation';

const checkFieldValid = (value: string, invalidations: Record<string, IFieldValidation>) => {
  return Object.keys(invalidations).every((key) => !invalidations[key].regex.test(value));
};

export const addressValidator = {
  isValidAddress: (address: AddressInput, isPostal: boolean) => {
    if (address.isUnformatted) {
      return !!address.unformattedAddress;
    }
    const isValidPostcode = checkFieldValid(address.postcode, invalidPostcodeValidations);
    let valid = !!address.streetName && !!address.city && !!address.state && isValidPostcode;

    if (!valid || isPostal) {
      return valid;
    }
    const isPoBoxAddress = PO_BOX_REGEX.test(address.streetName);
    const isCareOfAddress =
      CARE_OF_ADDRESS_REGEX.test(address.unitNumber) || CARE_OF_ADDRESS_REGEX.test(address.streetNumber) || CARE_OF_ADDRESS_REGEX.test(address.streetName);

    valid = !!address.streetType && !isPoBoxAddress && !isCareOfAddress;
    return valid;
  },

  isValidPersonalAddressDetails: (residential: AddressInput, postalSameAsResidential: boolean, postal?: AddressInput) => {
    let valid = addressValidator.isValidAddress(residential, false);

    if (valid && !postalSameAsResidential && !!postal) {
      valid = addressValidator.isValidAddress(postal, true);
    }
    return valid;
  }
};
