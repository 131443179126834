import { ISelectOption } from '@cmctechnology/phoenix-stockbroking-web-design';
import { KeyValueTranslated } from '../models/translation';

const YEARS_TO_INCLUDE = 80;

export const MONTHS: KeyValueTranslated = {
  '01': (t) => t('January'),
  '02': (t) => t('February'),
  '03': (t) => t('March'),
  '04': (t) => t('April'),
  '05': (t) => t('May'),
  '06': (t) => t('June'),
  '07': (t) => t('July'),
  '08': (t) => t('August'),
  '09': (t) => t('September'),
  '10': (t) => t('October'),
  '11': (t) => t('November'),
  '12': (t) => t('December')
};

// Mostly used for Medicare Month which should show number value
export const MONTHS_DISPLAY_WITH_NUMBERS: KeyValueTranslated = {
  '01': (t) => t('01'),
  '02': (t) => t('02'),
  '03': (t) => t('03'),
  '04': (t) => t('04'),
  '05': (t) => t('05'),
  '06': (t) => t('06'),
  '07': (t) => t('07'),
  '08': (t) => t('08'),
  '09': (t) => t('09'),
  '10': (t) => t('10'),
  '11': (t) => t('11'),
  '12': (t) => t('12')
};

export const PERSON_TITLE_OTHER = 'Other';

export const generateYearsDisplayWithNumbers = () => {
  const YEARS_DISPLAY_WITH_NUMBERS: ISelectOption[] = [];
  const currentYear = new Date().getFullYear();

  for (let i = currentYear - YEARS_TO_INCLUDE; i <= currentYear; i += 1) {
    YEARS_DISPLAY_WITH_NUMBERS.push({ label: i.toString(), value: i.toString() });
  }

  return YEARS_DISPLAY_WITH_NUMBERS;
};

export const PERSON_TITLES: KeyValueTranslated = {
  Mr: (t) => t('Mr'),
  Mrs: (t) => t('Mrs'),
  Ms: (t) => t('Ms'),
  Miss: (t) => t('Miss'),
  Dr: (t) => t('Dr'),
  [PERSON_TITLE_OTHER]: (t) => t('Other')
};

export const OTHER_PERSON_TITLES: KeyValueTranslated = {
  Bishop: (t) => t('Bishop'),
  Fr: (t) => t('Fr.'),
  Lady: (t) => t('Lady'),
  Lord: (t) => t('Lord'),
  Professor: (t) => t('Professor'),
  QC: (t) => t('QC'),
  Rabbi: (t) => t('Rabbi'),
  Rev: (t) => t('Rev'),
  Sir: (t) => t('Sir')
};

export const SAVE_PAUSE_TIME = 1000;
export const ENABLED_TABINDEX_FOR_NON_INPUT = 0;
export const ENTER_KEY = 'Enter';
export const EMPTY_STRING = '';
export const STRING_SPACE = ' ';
export const FULL_STOP = '.';

export const COMPARATOR_RESULT_LESS_THAN = -1;
export const COMPARATOR_RESULT_MORE_THAN = 1;

export const MIN_4_CHARACTERS = 4;
