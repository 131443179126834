export const getPromoCode = (): string | undefined => {
  const search = window.location.search;
  const searchList = search?.slice(1).split('&');

  const searchObj = searchList?.reduce<Record<string, string>>((acc, curr) => {
    const [key, val] = curr.split('=');
    acc[key] = val;
    return acc;
  }, {});

  return searchObj?.promo;
};
