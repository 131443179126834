import { breakpoint, CardHeader, Heading1, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled, { css } from 'styled-components';

export const CardHeaderWithText = styled(CardHeader)<{ textWithSmallMargin?: boolean }>`
  width: 100%;
  justify-content: center;
  ${Heading1} {
    margin-bottom: 0;
    color: ${({ theme }) => theme.colours.card.header.text};
  }
  ${Text} {
    margin: 1rem 7.75rem 0;
    ${({ textWithSmallMargin }) =>
      textWithSmallMargin &&
      css`
        margin: 1rem 3rem 0;
      `}
  }

  @media (max-width: ${breakpoint.mobile}) {
    ${Text} {
      margin: 1rem 1rem 0;
    }
  }
`;
