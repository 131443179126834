import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CardLarge,
  FormGroup,
  FormRow,
  Heading1,
  IconNames,
  Label,
  Panel,
  Size,
  Text,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IStore } from '../Store/Store';
import { CenteredButton } from './CenteredButton';
import { ModalCardContent, ModalText, ModalTopIcon } from './IdentificationFailModal';
import { ApiRequest } from '../models/apiRequestResult';
import { hasExceededRequestLimitForVerifyIdStatus } from '../models/identification';

const ModalActionButton = styled(Button)`
  max-width: 7.5rem;
  height: 2.5rem;
`;

const ModalPanel = styled(Panel)`
  margin-bottom: 1em;
  > *:nth-child(1) {
    flex: 1;
  }
`;

export interface IIdentificationFailProps {
  onGoBack: () => void;
  onTryAgain: () => void;
  onEmailMe: () => void;
}

export const IdentificationFail: React.FC<IIdentificationFailProps> = ({ onGoBack, onTryAgain, onEmailMe, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `identificationFail`);
  const verifyIdentityStatusResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.VerifyIdentityStatus]);
  const hasExceededRequestLimitWhenVerifyIdStatus = hasExceededRequestLimitForVerifyIdStatus(verifyIdentityStatusResult.errorCode);

  return (
    <CardLarge {...generateTestId()}>
      <ModalCardContent>
        <FormRow>
          <ModalTopIcon name={IconNames.HelpCircle} size={Size.Large} />
        </FormRow>
        <Heading1>{t('Sorry')}</Heading1>
        {!hasExceededRequestLimitWhenVerifyIdStatus && (
          <>
            <Text size={Size.Large}>{t("We weren't able to verify your identity. You can:")}</Text>
            <FormGroup>
              <ModalPanel onClick={onGoBack}>
                <ModalText size={Size.Large}>
                  <Label>{t('Go back')}</Label> {t('– check if you’ve entered the correct Name, Address and Date of Birth')}
                </ModalText>
                <ModalActionButton label={t('Go back')} />
              </ModalPanel>
              <ModalPanel onClick={onTryAgain}>
                <ModalText size={Size.Large}>
                  <Label>{t('Try another ID')}</Label> {t('– try another acceptable form of ID')}
                </ModalText>
                <ModalActionButton label={t('Try again')} />
              </ModalPanel>
              <ModalPanel onClick={onEmailMe}>
                <ModalText size={Size.Large}>
                  <Label>{t('Send certified ID')}</Label>
                  {t("– we'll email you steps on how to send your certified ID to us")}
                </ModalText>
                <ModalActionButton label={t('Email me')} />
              </ModalPanel>
            </FormGroup>
          </>
        )}
        {hasExceededRequestLimitWhenVerifyIdStatus && (
          <>
            <Text>{t(`You've exceeded the maximum number of attempts to verify your identity. We can send you an email on how to send us your ID.`)}</Text>
            <FormGroup>
              <CenteredButton label={t('Email me')} onClick={onEmailMe} {...generateTestId(`emailMe`)} />
            </FormGroup>
          </>
        )}
      </ModalCardContent>
    </CardLarge>
  );
};
