import {
  CheckBox,
  FormControl,
  FormGroup,
  FormRow,
  ISelectOption,
  NumericInput,
  Size,
  ValidationErrorMessage,
  Validators,
  useValidator,
  Text,
  useTestId,
  Select
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createCountriesOptionList, createKeyValueTranslatedToOptionList } from '../../common/mappings';
import { COUNTRIES_ALL } from '../../constants/countryConstants';
import { IQuestionProps } from '../../models/pepQuestions';
import { preventScrolling } from '../../events/onFocus';

export const PEPQuestion1: React.FC<IQuestionProps> = ({ selected, setSelected, setValidators, answers, setAnswers, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `pepQ1`);

  const countriesSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t).filter((c) => c.label !== 'Australia');

  const q1CityValidator = useValidator<string>('', Validators.required(t('Required')));
  const q1CountryValidator = useValidator<ISelectOption | undefined>(undefined, Validators.required(t('Required')));
  const q1DurationYearValidator = useValidator<string>('', Validators.required(t('Required')));
  const q1DurationMonthsValidator = useValidator<string>('', Validators.required(t('Required')));

  useEffect(() => {
    if (selected) setValidators([q1CityValidator, q1CountryValidator, q1DurationYearValidator, q1DurationMonthsValidator]);
    else setValidators([]);

    setAnswers({
      ...answers,
      question1: {
        answered: selected ?? false,
        city: q1CityValidator.value,
        country: q1CountryValidator.value?.value,
        durationYears: q1DurationYearValidator.value,
        durationMonths: q1DurationMonthsValidator.value
      }
    });
  }, [selected, q1CityValidator.value, q1CountryValidator.value, q1DurationYearValidator.value, q1DurationMonthsValidator.value]);

  return (
    <FormGroup>
      <Text>
        {t('Have you lived in another country (excluding Australia) in the past? If yes, please provide the city, country and duration of residency.')}
      </Text>
      <FormGroup>
        <FormRow>
          <CheckBox
            label={t('Yes')}
            checked={selected}
            onChange={(e) => {
              setSelected(e.target.checked);
            }}
            disabled={selected}
            {...generateTestId(`yes`)}
          />
          <CheckBox
            label={t('No')}
            checked={selected === false}
            onChange={(e) => {
              setSelected(!e.target.checked);
            }}
            disabled={selected === false}
            {...generateTestId(`no`)}
          />
        </FormRow>
      </FormGroup>

      {selected && (
        <FormGroup>
          <Text>{t('Please provide the city, country and duration of your residency.')}</Text>
          <FormControl
            type='text'
            name='cityName'
            placeholder={t('Enter the city')}
            value={q1CityValidator.value}
            onChange={(e) => {
              q1CityValidator.handleEvent(e.target.value);
            }}
            invalid={q1CityValidator.invalid}
            {...generateTestId(`cityInput`)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q1CityValidator} />
          <FormGroup {...generateTestId(`countryInput`)} style={{ marginTop: '0' }}>
            <Select
              value={q1CountryValidator.value}
              options={createCountriesOptionList(countriesSelectOptions, t)}
              placeholder={t('Select country')}
              onChange={(option) => {
                q1CountryValidator.handleEvent(option as ISelectOption);
              }}
              invalid={q1CountryValidator.invalid}
              isClearable
            />
            <ValidationErrorMessage size={Size.Medium} validator={q1CountryValidator} />
          </FormGroup>
          <NumericInput
            value={q1DurationYearValidator.value}
            onChange={(e) => {
              q1DurationYearValidator.handleEvent(e.target.value);
            }}
            placeholder={t('Years')}
            invalid={q1DurationYearValidator.invalid}
            {...generateTestId(`yearInput`)}
            onFocus={(e) => preventScrolling(e)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q1DurationYearValidator} />
          <NumericInput
            value={q1DurationMonthsValidator.value}
            onChange={(e) => {
              q1DurationMonthsValidator.handleEvent(e.target.value);
            }}
            placeholder={t('Months')}
            invalid={q1DurationMonthsValidator.invalid}
            {...generateTestId(`monthInput`)}
            onFocus={(e) => preventScrolling(e)}
          />
          <ValidationErrorMessage size={Size.Medium} validator={q1DurationMonthsValidator} />
        </FormGroup>
      )}
    </FormGroup>
  );
};
