import { Panel, Text } from '@cmctechnology/phoenix-stockbroking-web-design';
import styled from 'styled-components';

export const PanelWithSvgAndWrappedText = styled(Panel)`
  margin: 0;
  background-color: ${({ theme }) => theme.colours.default.background};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colours.default.border};
  margin-bottom: 0;
  width: 100%;
  ${Text} {
    white-space: normal;
    line-break: normal;
  }
`;
