import React, { useEffect } from 'react';
import {
  Form,
  CardLarge,
  CardSeparator,
  Text,
  Size,
  FormRow,
  LoadingBar,
  CardContent,
  FormGroup,
  useTestId
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CompleteProcessRequestModel, StatusTypeEnum } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useHistory } from 'react-router-dom';
import { INextPage } from '../../models/IPageNavigation';
import { CardFooterWithSmallPaddingTop } from '../../Components/CardWithSmallPadding';
import {
  SINGPASS_CONSENT_FORM_CALLBACK_AUTH_CODE,
  SINGPASS_CONSENT_FORM_CALLBACK_ERROR_CODE,
  SINGPASS_CONSENT_FORM_CALLBACK_ERROR_DESCRIPTION,
  SINGPASS_CONSENT_FORM_CALLBACK_STATE
} from '../../constants/singpassConstants';
import { ExtraLargeButton } from '../../Components/ExtraLargeButton';
import { AuthoriseErrorCode, getAuthoriseErrorMessage, getNextStepMessage } from '../../models/sgp/singpassErrorCodes/authoriseErrorCode';
import { getPersonDataFromMyInfo, openSingpassOrJumioAuthorisation } from '../../Store/Actions';
import { IdVerifyProvider } from '../../constants/sgp/myinfo';
import { IStore } from '../../Store/Store';
import { ApiRequest, ApiRequestStatus } from '../../models/apiRequestResult';
import { ILoadJumio } from '../../models/sgp/ILoadJumio';
import { MyInfoPageHeader } from '../../Components/Sgp/MyInfoPageHeader';

export interface ISingpassCallback extends INextPage, ILoadJumio {}
export const SingpassCallback: React.FC<ISingpassCallback> = ({ onNext, onLoadJumio, ...rest }) => {
  const { generateTestId } = useTestId(rest, `singpass_callback`);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const singpassCallbackQueryParams = new URLSearchParams(window.location.search);
  const authCodeFromMyInfoConsentCallback = singpassCallbackQueryParams.get(SINGPASS_CONSENT_FORM_CALLBACK_AUTH_CODE);
  const errorCodeFromMyInfoConsentCallback = singpassCallbackQueryParams.get(SINGPASS_CONSENT_FORM_CALLBACK_ERROR_CODE);
  const workflowId = useSelector((store: IStore) => store.persist!.sgp.workflowId);
  const getPersonDataFromMyInfoResult = useSelector((store: IStore) => store.local.apiResults[ApiRequest.GetPersonDataFromMyInfo]);
  const isLoadingPersonData = getPersonDataFromMyInfoResult.status === ApiRequestStatus.InProgress;
  const jumioAuthoriseUrl = useSelector((store: IStore) => store.local.sgp.jumioAuthoriseUrl);
  const completeMyInfoProcess = useSelector((store: IStore) => store.persist!.sgp.completeMyInfoProcess);

  const cleanupMyinfoCallbackSearchParameters = () => {
    singpassCallbackQueryParams.delete(SINGPASS_CONSENT_FORM_CALLBACK_AUTH_CODE);
    singpassCallbackQueryParams.delete(SINGPASS_CONSENT_FORM_CALLBACK_STATE);
    singpassCallbackQueryParams.delete(SINGPASS_CONSENT_FORM_CALLBACK_ERROR_CODE);
    singpassCallbackQueryParams.delete(SINGPASS_CONSENT_FORM_CALLBACK_ERROR_DESCRIPTION);

    history.replace({
      search: singpassCallbackQueryParams.toString()
    });
  };

  const onTryAgainForMyInfoClicked = () => {
    dispatch(openSingpassOrJumioAuthorisation({ providerType: IdVerifyProvider.MyInfo }));
  };

  const onJumioClicked = () => {
    cleanupMyinfoCallbackSearchParameters();
    dispatch(openSingpassOrJumioAuthorisation({ providerType: IdVerifyProvider.Jumio }));
  };

  useEffect(() => {
    if (getPersonDataFromMyInfoResult.status === ApiRequestStatus.Success) {
      cleanupMyinfoCallbackSearchParameters();
      onNext();
    }
  }, [getPersonDataFromMyInfoResult]);

  useEffect(() => {
    if (jumioAuthoriseUrl) {
      onLoadJumio();
    }
  }, [jumioAuthoriseUrl]);

  useEffect(() => {
    if (workflowId && authCodeFromMyInfoConsentCallback && !completeMyInfoProcess) {
      const personDataRequestDetails: CompleteProcessRequestModel = {
        providerType: IdVerifyProvider.MyInfo,
        workflowId,
        statusType: StatusTypeEnum.Success,
        contextData: { state: workflowId, code: authCodeFromMyInfoConsentCallback! }
      };
      dispatch(getPersonDataFromMyInfo(personDataRequestDetails));
    }
  }, []);

  return (
    <Form>
      <CardLarge>
        <MyInfoPageHeader />
        <CardSeparator />
        <CardContent>
          {isLoadingPersonData && (
            <FormGroup>
              <Text size={Size.Large} textAlign='left'>
                {t('Please wait while we are retrieving your personal information from Myinfo. Do not close your browser.')}
              </Text>
            </FormGroup>
          )}
          {errorCodeFromMyInfoConsentCallback && (
            <>
              <FormRow>
                <Text size={Size.Large} textAlign='left'>
                  <b>{getAuthoriseErrorMessage(errorCodeFromMyInfoConsentCallback!, t)} </b>
                </Text>
              </FormRow>
              <FormGroup>
                <Text size={Size.Large} textAlign='left'>
                  {getNextStepMessage(errorCodeFromMyInfoConsentCallback!, t)}
                </Text>
              </FormGroup>
            </>
          )}
          {(!workflowId || (completeMyInfoProcess && !isLoadingPersonData) || !authCodeFromMyInfoConsentCallback) && !errorCodeFromMyInfoConsentCallback && (
            <>
              <FormRow>
                <Text size={Size.Large} textAlign='left'>
                  <b>{getAuthoriseErrorMessage(AuthoriseErrorCode.Unknown, t)} </b>
                </Text>
              </FormRow>
              <FormGroup>
                <Text size={Size.Large} textAlign='left'>
                  {getNextStepMessage(AuthoriseErrorCode.Unknown, t)}
                </Text>
              </FormGroup>
            </>
          )}
        </CardContent>
        <CardFooterWithSmallPaddingTop>
          {isLoadingPersonData && authCodeFromMyInfoConsentCallback && <LoadingBar />}
          {errorCodeFromMyInfoConsentCallback && (
            <>
              {(errorCodeFromMyInfoConsentCallback === AuthoriseErrorCode.UserNotGiveConsent ||
                errorCodeFromMyInfoConsentCallback === AuthoriseErrorCode.Unknown) && (
                <FormGroup>
                  <ExtraLargeButton centered label={t('Try again with Myinfo')} onClick={onTryAgainForMyInfoClicked} {...generateTestId(`try_myinfo_again`)} />
                </FormGroup>
              )}
              <FormGroup>
                <ExtraLargeButton centered label={t('Apply without using Myinfo')} onClick={onJumioClicked} {...generateTestId(`jumio`)} />
              </FormGroup>
            </>
          )}
          {!errorCodeFromMyInfoConsentCallback && (!workflowId || (completeMyInfoProcess && !isLoadingPersonData) || !authCodeFromMyInfoConsentCallback) && (
            <>
              <FormGroup>
                <ExtraLargeButton centered label={t('Apply without using Myinfo')} onClick={onJumioClicked} {...generateTestId(`jumio`)} />
              </FormGroup>
            </>
          )}
        </CardFooterWithSmallPaddingTop>
      </CardLarge>
    </Form>
  );
};
