import React, { useEffect, useState, useRef } from 'react';
import {
  FormLabel,
  CardContent,
  CardLarge,
  CardSeparator,
  Heading1,
  CheckBox,
  useValidator,
  Validators,
  CardHeader,
  FormRow
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { PersonalAddressDetails } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IStore } from '../Store/Store';
import { AddressForm } from '../Components/AddressForm/AddressForm';
import { submitValidatedAddressDetails } from '../Store/Actions';
import { addressValidator } from '../validators/addressValidator';
import { AddressType, AddressValidator } from '../constants/addressConstants';
import { IAddressInput } from '../models/profile';
import { ProfileCardFooter } from '../Components/ProfileCardFooter';
import { GoBackIcon } from '../Components/GoBackIcon';
import { tooltipLabel, Tooltips, tooltipText } from '../models/tooltip';
import { TooltipLabelAndText } from '../Components/TooltipLableAndText';
import { KeyValueValidator } from '../models/validation';
import { useAddressValidators } from '../hooks/useAddressValidators';
import { INextPage, IPreviousPage } from '../models/IPageNavigation';
import { ENABLED_TABINDEX_FOR_NON_INPUT } from '../constants/commonConstants';

export interface IProfileAddressProps extends INextPage, Partial<IPreviousPage> {}

export const ProfileAddress: React.FC<IProfileAddressProps> = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addressDetails = useSelector((store: IStore) => store.remote.profile.addressDetails);

  const validatorConfig = {
    validated: addressDetails.validated
  };

  const residentialAddressValidator = useValidator<IAddressInput>(
    addressDetails.residentialAddress,
    Validators.custom((value) => addressValidator.isValidAddress(value, false), ''),
    { debounceMs: 30, validated: addressDetails.validated }
  );

  const [postalSameAsResidential, setPostalSameAsResidential] = useState(addressDetails.postalSameAsResidential);

  const postalAddressValidator = useValidator<IAddressInput>(
    addressDetails.postalAddress,
    Validators.custom((value) => addressValidator.isValidPersonalAddressDetails(residentialAddressValidator.value, postalSameAsResidential, value), ''),
    validatorConfig
  );

  const residentialAddressValidators = useAddressValidators(residentialAddressValidator.value, addressDetails.validated, AddressType.Residential);
  const postalAddressValidators = useAddressValidators(postalAddressValidator.value, addressDetails.validated, AddressType.Postal);

  const residentialValidatorsLookup: KeyValueValidator<typeof AddressValidator.LookUpAddressValidator> = {
    [AddressValidator.LookUpAddressValidator]: residentialAddressValidators.lookUpAddressValidator
  };

  const postalValidatorsLookup: KeyValueValidator<typeof AddressValidator.LookUpAddressValidator> = {
    [AddressValidator.LookUpAddressValidator]: postalAddressValidators.lookUpAddressValidator
  };

  const residentialValidatorsManual: KeyValueValidator<Exclude<AddressValidator, AddressValidator.LookUpAddressValidator>> = {
    [AddressValidator.UnitNumberValidator]: residentialAddressValidators.unitNumberValidator,
    [AddressValidator.StreetNumberValidator]: residentialAddressValidators.streetNumberValidator,
    [AddressValidator.StreetNameValidator]: residentialAddressValidators.streetNameValidator,
    [AddressValidator.StreetTypeValidator]: residentialAddressValidators.streetTypeValidator,
    [AddressValidator.CityValidator]: residentialAddressValidators.cityValidator,
    [AddressValidator.StateValidator]: residentialAddressValidators.stateValidator,
    [AddressValidator.PostcodeValidator]: residentialAddressValidators.postcodeValidator
  };

  const postalValidatorsManual: KeyValueValidator<Exclude<AddressValidator, AddressValidator.LookUpAddressValidator>> = {
    [AddressValidator.UnitNumberValidator]: postalAddressValidators.unitNumberValidator,
    [AddressValidator.StreetNumberValidator]: postalAddressValidators.streetNumberValidator,
    [AddressValidator.StreetNameValidator]: postalAddressValidators.streetNameValidator,
    [AddressValidator.StreetTypeValidator]: postalAddressValidators.streetTypeValidator,
    [AddressValidator.CityValidator]: postalAddressValidators.cityValidator,
    [AddressValidator.StateValidator]: postalAddressValidators.stateValidator,
    [AddressValidator.PostcodeValidator]: postalAddressValidators.postcodeValidator
  };

  const residentialValidators = { ...residentialValidatorsLookup, ...residentialValidatorsManual };
  const postalValidators = { ...postalValidatorsLookup, ...postalValidatorsManual };

  useEffect(() => {
    if (residentialAddressValidator.validated) {
      postalAddressValidator.validate();
    }
  }, [residentialAddressValidator.validated, residentialAddressValidator.value, postalSameAsResidential]);

  const [inEffectValidators, setInEffectValidators] = useState([residentialAddressValidator, postalAddressValidator]);
  const [residentialIsUnformatted, setResidentialIsUnformatted] = useState(addressDetails.residentialAddress.isUnformatted);
  const [postalIsUnformatted, setPostalIsUnformatted] = useState(addressDetails.postalAddress.isUnformatted);

  const validatorsOfLookupForBothAddressType = [
    residentialValidators[AddressValidator.LookUpAddressValidator],
    postalValidators[AddressValidator.LookUpAddressValidator]
  ];

  const validatorsOfLookupForResidentialAndManualForPostal = [
    residentialValidators[AddressValidator.LookUpAddressValidator],
    postalValidators[AddressValidator.UnitNumberValidator],
    postalValidators[AddressValidator.StreetNumberValidator],
    postalValidators[AddressValidator.StreetNameValidator],
    postalValidators[AddressValidator.StreetTypeValidator],
    postalValidators[AddressValidator.CityValidator],
    postalValidators[AddressValidator.StateValidator],
    postalValidators[AddressValidator.PostcodeValidator]
  ];

  const validatorsOfManualForBothAddressType = [
    residentialValidators[AddressValidator.UnitNumberValidator],
    residentialValidators[AddressValidator.StreetNumberValidator],
    residentialValidators[AddressValidator.StreetNameValidator],
    residentialValidators[AddressValidator.StreetTypeValidator],
    residentialValidators[AddressValidator.CityValidator],
    residentialValidators[AddressValidator.StateValidator],
    residentialValidators[AddressValidator.PostcodeValidator],
    postalValidators[AddressValidator.UnitNumberValidator],
    postalValidators[AddressValidator.StreetNumberValidator],
    postalValidators[AddressValidator.StreetNameValidator],
    postalValidators[AddressValidator.StreetTypeValidator],
    postalValidators[AddressValidator.CityValidator],
    postalValidators[AddressValidator.StateValidator],
    postalValidators[AddressValidator.PostcodeValidator]
  ];

  const validatorsOfLookupWhenPostalSameAsResidential = [residentialValidators[AddressValidator.LookUpAddressValidator]];

  const validatorsOfManualWhenPostalSameAsResidential = [
    residentialValidators[AddressValidator.UnitNumberValidator],
    residentialValidators[AddressValidator.StreetNumberValidator],
    residentialValidators[AddressValidator.StreetNameValidator],
    residentialValidators[AddressValidator.StreetTypeValidator],
    residentialValidators[AddressValidator.CityValidator],
    residentialValidators[AddressValidator.StateValidator],
    residentialValidators[AddressValidator.PostcodeValidator]
  ];

  const validatorsOfManualForResidentialAndLookupForPostal = [
    residentialValidators[AddressValidator.UnitNumberValidator],
    residentialValidators[AddressValidator.StreetNumberValidator],
    residentialValidators[AddressValidator.StreetNameValidator],
    residentialValidators[AddressValidator.StreetTypeValidator],
    residentialValidators[AddressValidator.CityValidator],
    residentialValidators[AddressValidator.StateValidator],
    residentialValidators[AddressValidator.PostcodeValidator],
    postalValidators[AddressValidator.LookUpAddressValidator]
  ];

  useEffect(() => {
    if (!postalSameAsResidential) {
      if (residentialIsUnformatted && postalIsUnformatted) {
        setInEffectValidators(validatorsOfLookupForBothAddressType);
      } else if (residentialIsUnformatted && !postalIsUnformatted) {
        setInEffectValidators(validatorsOfLookupForResidentialAndManualForPostal);
      } else if (!residentialIsUnformatted && postalIsUnformatted) {
        setInEffectValidators(validatorsOfManualForResidentialAndLookupForPostal);
      } else {
        setInEffectValidators(validatorsOfManualForBothAddressType);
      }
    }

    if (postalSameAsResidential) {
      if (residentialIsUnformatted) {
        setInEffectValidators(validatorsOfLookupWhenPostalSameAsResidential);
      } else {
        setInEffectValidators(validatorsOfManualWhenPostalSameAsResidential);
      }
    }
  }, [postalSameAsResidential, postalAddressValidator.value, residentialAddressValidator.value, residentialIsUnformatted, postalIsUnformatted]);

  const onNextClicked = () => {
    const addresses: PersonalAddressDetails = {
      residentialAddress: residentialAddressValidator.value,
      postalSameAsResidential,
      postalAddress: postalAddressValidator.value
    };

    dispatch(submitValidatedAddressDetails(addresses));
    onNext();
  };

  const postalAddressTooltipParentRef = useRef<HTMLDivElement | null>(null);

  return (
    <CardLarge>
      <CardHeader>
        {onPrevious && <GoBackIcon onClick={onPrevious} />}
        <Heading1>{t('Address')}</Heading1>
      </CardHeader>
      <CardSeparator />
      <CardContent>
        <FormLabel>{t('Residential address')}</FormLabel>
        <AddressForm
          validated={addressDetails.validated}
          addressType={AddressType.Residential}
          address={residentialAddressValidator.value}
          onAddressChanged={async (e) => {
            await residentialAddressValidator.handleEvent(e);
            setResidentialIsUnformatted(e.isUnformatted);
          }}
          removeTopMargin
          validators={residentialValidators}
          autoFocus={postalSameAsResidential}
        />
      </CardContent>
      <CardSeparator />
      <CardContent>
        <TooltipLabelAndText
          label={tooltipLabel[Tooltips.PostalAddress](t)}
          text={tooltipText[Tooltips.PostalAddress](t)}
          parentRef={postalAddressTooltipParentRef}
          includeBottomMargin
        />
        <FormRow ref={postalAddressTooltipParentRef}>
          <CheckBox
            label={t('Same as residential address')}
            checked={postalSameAsResidential}
            onChange={(e) => setPostalSameAsResidential(e.target.checked)}
            tabIndex={ENABLED_TABINDEX_FOR_NON_INPUT}
          />
        </FormRow>
        {!postalSameAsResidential && (
          <AddressForm
            validated={addressDetails.validated}
            addressType={AddressType.Postal}
            address={postalAddressValidator.value}
            onAddressChanged={async (e) => {
              setPostalIsUnformatted(e.isUnformatted);
              await postalAddressValidator.handleEvent(e);
            }}
            validators={postalValidators}
          />
        )}
      </CardContent>
      <ProfileCardFooter
        nextButtonLabel={t('Next')}
        saveButtonLabel={t('Save')}
        onNext={onNextClicked}
        onCancel={onNext}
        validators={[...inEffectValidators]}
      />
    </CardLarge>
  );
};
