import React from 'react';
import { State } from '@cmctechnology/phoenix-stockbroking-api-client';
import { EffectiveAustralianState } from '../models/driverLicenceHelpText';
import ACTLicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-act-licence-number.jpg';
import NSWLicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-nsw-licence-number.jpg';
import NTLicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-nt-licence-number.jpg';
import QLDLicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-qld-licence-number.jpg';
import SALicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-sa-licence-number.jpg';
import TASLicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-tas-licence-number.jpg';
import VICLicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-vic-licence-number.jpg';
import WALicenceNumberImage from '../Assets/documents/au/dl/drivers-licence-wa-licence-number.jpg';
import ACTCardNumberImage from '../Assets/documents/au/dl/drivers-licence-act-card-number.jpg';
import NSWCardNumberImage from '../Assets/documents/au/dl/drivers-licence-nsw-card-number.jpg';
import NTCardNumberImage from '../Assets/documents/au/dl/drivers-licence-nt-card-number.jpg';
import QLDCardNumberImage from '../Assets/documents/au/dl/drivers-licence-qld-card-number.jpg';
import SACardNumberImage from '../Assets/documents/au/dl/drivers-licence-sa-card-number.jpg';
import TASCardNumberImage from '../Assets/documents/au/dl/drivers-licence-tas-card-number.jpg';
import VICCardNumberImage from '../Assets/documents/au/dl/drivers-licence-vic-card-number.jpg';
import WACardNumberImage from '../Assets/documents/au/dl/drivers-licence-wa-card-number.jpg';
import { IdentificationCardImagePod } from './IdentificationCardImagePod';

interface IIdentificationImageProps {
  state: EffectiveAustralianState;
}

const driverLicenceNumberImages: Record<EffectiveAustralianState, string> = {
  [State.ACT]: ACTLicenceNumberImage,
  [State.NSW]: NSWLicenceNumberImage,
  [State.NT]: NTLicenceNumberImage,
  [State.QLD]: QLDLicenceNumberImage,
  [State.SA]: SALicenceNumberImage,
  [State.TAS]: TASLicenceNumberImage,
  [State.VIC]: VICLicenceNumberImage,
  [State.WA]: WALicenceNumberImage
};

const driverLicenceCardNumberImages: Record<EffectiveAustralianState, string> = {
  [State.ACT]: ACTCardNumberImage,
  [State.NSW]: NSWCardNumberImage,
  [State.NT]: NTCardNumberImage,
  [State.QLD]: QLDCardNumberImage,
  [State.SA]: SACardNumberImage,
  [State.TAS]: TASCardNumberImage,
  [State.VIC]: VICCardNumberImage,
  [State.WA]: WACardNumberImage
};

export const DrivingLicenceNumberImage: React.FC<IIdentificationImageProps> = ({ state }) => {
  const imageUrl = driverLicenceNumberImages[state];
  return imageUrl ? <IdentificationCardImagePod src={imageUrl} /> : <></>;
};

export const DrivingLicenceCardNumberImage: React.FC<IIdentificationImageProps> = ({ state }) => {
  const imageUrl = driverLicenceCardNumberImages[state];
  return imageUrl ? <IdentificationCardImagePod src={imageUrl} /> : <></>;
};
