import React from 'react';
import { BaseRoute, IRouteProps } from './BaseRoute';
import { Navigation as AusNavigation } from './Navigation';
import { PageBackground } from './PageBackground';
import { useBusinessRegion } from '../hooks/useBusinessRegion';
import { BusinessRegion } from '../configuration/configuration';
import { Navigation as SpgNavigation } from './Sgp/Navigation';

export const Route: React.FC<IRouteProps> = (props) => {
  const { children } = props;

  const { businessRegion } = useBusinessRegion();

  return (
    <BaseRoute {...props}>
      <div>{businessRegion === BusinessRegion.SGP ? <SpgNavigation /> : <AusNavigation />}</div>
      <PageBackground>{children}</PageBackground>
    </BaseRoute>
  );
};
