import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  FormGroup,
  Text,
  Size,
  FormLabel,
  PasswordFormControl,
  useValidator,
  Validators,
  Icon,
  CircularIcon,
  IconNames,
  useTestId,
  Variant
} from '@cmctechnology/phoenix-stockbroking-web-design';
import { FULL_OPACITY, HALF_OPACITY, passwordValidations } from '../../constants/loginConstants';
import { IStore } from '../../Store/Store';
import { ApiRequest, ApiRequestStatus } from '../../models/apiRequestResult';
import { TooltipLabelAndText } from '../TooltipLableAndText';
import { tooltipText, Tooltips, tooltipLabel } from '../../models/tooltip';
import { getPasswordValidationVariantAndIcon } from '../../common/validations';
import { NoMarginTextSmall, getPasswordValidationsWithoutCheckingStartEndSpaces, startOrEndWithSpaces } from '../PasswordRule';

interface ISetPasswordValidation {
  valid: boolean;
  value: string;
}

interface ICreateNewPasswordSectionProps {
  setPasswordValidation: (validation: ISetPasswordValidation) => void;
}

const StepHeading = styled(Text)`
  margin-bottom: 1rem;
`;

const PasswordFormGroup = styled(FormGroup)`
  label {
    font-weight: normal;
  }
`;

const GroupRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const PasswordInputWrapper = styled.div`
  width: 100%;
`;

const PasswordCheckIcon = styled(CircularIcon)`
  min-width: 1rem;
  min-height: 1rem;
  margin-top: -0.375rem;
`;

export const CreateNewPasswordSection: React.FC<ICreateNewPasswordSectionProps> = ({ setPasswordValidation, ...rest }): JSX.Element => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `CreateNewPasswordSection`);
  const passLabelRef = useRef<HTMLInputElement | null>(null);
  const { status: signInStatus } = useSelector((store: IStore) => store.local.apiResults[ApiRequest.SignIn]);
  const passwordValidator = useValidator<string>(
    ``,
    Validators.required(t(`Required`)).combine(passwordValidations.map((x) => Validators.match(x.regex, x.message(t), x.id)))
  );

  useEffect(() => {
    setPasswordValidation({ valid: passwordValidator.valid, value: passwordValidator.value });
  }, [passwordValidator.valid, passwordValidator.value]);

  return (
    <>
      <PasswordFormGroup ref={passLabelRef}>
        <StepHeading size={Size.Large}>
          <Trans t={t}>
            <b>STEP 2</b>
          </Trans>
        </StepHeading>
        <TooltipLabelAndText
          label={tooltipLabel[Tooltips.CreateSecurePassword](t)}
          text={tooltipText[Tooltips.CreateSecurePassword](t)}
          parentRef={passLabelRef}
        />
      </PasswordFormGroup>
      <FormGroup>
        <FormLabel>
          <Trans t={t}>
            <b>New password</b>
          </Trans>
        </FormLabel>
        <GroupRow>
          <PasswordInputWrapper>
            <PasswordFormControl
              placeholder={t('Enter your password')}
              onChange={(e) => passwordValidator.handleEvent(e.target.value)}
              onBlur={(e) => passwordValidator.handleEvent(e.target.value)}
              invalid={passwordValidator.invalid}
              {...generateTestId(`password`)}
            />
          </PasswordInputWrapper>
          {signInStatus === ApiRequestStatus.Success && <PasswordCheckIcon variant={Variant.Success} name={IconNames.Check} size={Size.Small} />}
        </GroupRow>
        {startOrEndWithSpaces(passwordValidator) && (
          <Text size={Size.ExtraSmall} variant={Variant.Error}>
            {t('Cannot start or end with spaces')}
          </Text>
        )}
        {getPasswordValidationsWithoutCheckingStartEndSpaces(passwordValidations).map((validation) => {
          const validationResult = passwordValidator.results.find((x) => x.id === validation.id);
          return (
            <NoMarginTextSmall key={validation.id} size={Size.Small}>
              <Icon
                {...getPasswordValidationVariantAndIcon(passwordValidator.validated, validationResult)}
                opacity={passwordValidator.validated ? FULL_OPACITY : HALF_OPACITY}
                size={Size.Small}
              />
              {validation.message(t)}
            </NoMarginTextSmall>
          );
        })}
      </FormGroup>
    </>
  );
};
