import {
  CardContent,
  CardFooter,
  CardLarge,
  CardSeparator,
  Form,
  FormGroup,
  FormLabel,
  Heading1,
  ISelectOption,
  Select,
  Size,
  Text,
  useTestId,
  ValidationErrorMessage,
  FormControl
} from '@cmctechnology/phoenix-stockbroking-web-design';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CenteredButton } from '../../Components/CenteredButton';
import { GoBackIcon } from '../../Components/GoBackIcon';
import { INextPage, IPreviousPage } from '../../models/IPageNavigation';
import { createKeyValueTranslatedToOptionList, createOptionListFromNestedList } from '../../common/mappings';
import { IStore } from '../../Store/Store';
import { CardHeaderWithText } from '../../Components/CardHeaderWithText';
import { saveEmployment } from '../../Store/Actions';
import { ISgpEmployment } from '../../models/sgp/profile';
import {
  EMPLOYMENT_STATUS,
  INDUSTRY_NAME,
  OCCUPATION,
  OTHER,
  RETIRED,
  SOURCE_OF_FUNDS,
  SOURCE_OF_FUNDS_PART_TIME_WORK,
  STUDENT
} from '../../constants/sgp/employment';
import { COMPARATOR_RESULT_LESS_THAN, COMPARATOR_RESULT_MORE_THAN, EMPTY_STRING } from '../../constants/commonConstants';
import {
  isEmployed,
  isEmployedOrSelfEmployed,
  isMoreDetailsNeeded,
  isSelfEmployed,
  isStudentOrRetiredOrUnemployed
} from '../../hooks/sgp/validators/employmentConditions';
import { useEmploymentValidators } from '../../hooks/sgp/validators/useEmployment';

interface IProfileEmploymentProps extends INextPage, Partial<IPreviousPage> {}

const sortFn: (a: ISelectOption, b: ISelectOption) => number = (a, b) => {
  if (a.value === OTHER) return COMPARATOR_RESULT_MORE_THAN;
  if (b.value === OTHER) return COMPARATOR_RESULT_LESS_THAN;

  return a.value.localeCompare(b.value);
};

export const ProfileEmployment: React.FC<IProfileEmploymentProps> = ({ onNext, onPrevious, ...rest }) => {
  const { t } = useTranslation();
  const { generateTestId } = useTestId(rest, `sg.employment`);
  const employment = useSelector((store: IStore) => store.remote.sgp.profile.employment);

  const dispatch = useDispatch();

  const {
    employmentStatusValidator,
    companyNameValidator,
    businessNameValidator,
    industryValidator,
    occupationValidator,
    occupationSpecifyValidator,
    sourceOfFundsSpecifyValidator,
    sourceOfFundsValidator
  } = useEmploymentValidators(employment);

  const employmentStatusOptions = createKeyValueTranslatedToOptionList(EMPLOYMENT_STATUS, t);
  const industryOptions = createKeyValueTranslatedToOptionList(INDUSTRY_NAME, t, sortFn);
  const occupationOptions = createOptionListFromNestedList(industryValidator.value?.value, OCCUPATION, t, sortFn);
  const sourceOfFundsOptions = createOptionListFromNestedList(employmentStatusValidator.value?.value, SOURCE_OF_FUNDS, t, sortFn);

  const onNextClicked = () => {
    const updatedEmployment: ISgpEmployment = {
      employmentStatus: employmentStatusValidator.value?.value,
      companyName: companyNameValidator.value,
      businessName: businessNameValidator.value,
      industry: industryValidator.value?.value,
      occupation: occupationValidator.value?.value,
      occupationSpecify: occupationSpecifyValidator.value,
      sourceOfFundsSpecify: sourceOfFundsSpecifyValidator.value,
      sourceOfFunds: sourceOfFundsValidator.value?.value
    };
    dispatch(saveEmployment(updatedEmployment));

    onNext();
  };

  const resetAllFields = () => {
    sourceOfFundsValidator.handleEvent(undefined, true);
    occupationSpecifyValidator.handleEvent(EMPTY_STRING, true);
    sourceOfFundsSpecifyValidator.handleEvent(EMPTY_STRING, true);
    companyNameValidator.handleEvent(EMPTY_STRING, true);
    businessNameValidator.handleEvent(EMPTY_STRING, true);
    industryValidator.handleEvent(undefined, true);
    occupationValidator.handleEvent(undefined, true);
  };

  const onEmploymentStatusChange = (option: any) => {
    resetAllFields();

    employmentStatusValidator.handleEvent(option as ISelectOption);
  };

  const onIndustryChange = (option: any) => {
    occupationValidator.handleEvent(undefined, true);
    occupationSpecifyValidator.handleEvent(EMPTY_STRING, true);
    industryValidator.handleEvent(option as ISelectOption);
  };

  const onSourceOfFundsChange = (option: any) => {
    industryValidator.handleEvent(undefined, true);

    sourceOfFundsValidator.handleEvent(option as ISelectOption);
  };

  return (
    <Form>
      <CardLarge>
        <CardHeaderWithText textWithSmallMargin>
          {onPrevious && <GoBackIcon onClick={onPrevious} />}
          <Heading1>{t('Employment')}</Heading1>
          <Text textAlign='center' size={Size.Large}>
            {t('For regulatory purposes, we require some information on your employment status')}
          </Text>
        </CardHeaderWithText>
        <CardSeparator />
        <CardContent>
          <FormGroup>
            <FormLabel>{t('Employment status')}</FormLabel>
            <Select
              value={employmentStatusValidator.value}
              options={employmentStatusOptions}
              onChange={onEmploymentStatusChange}
              placeholder={t('Please select an employment status')}
              isSearchable
              isClearable
              invalid={employmentStatusValidator.invalid}
              {...generateTestId(`employmentStatus.select`)}
            />
            <ValidationErrorMessage size={Size.Medium} validator={employmentStatusValidator} />
          </FormGroup>

          {isStudentOrRetiredOrUnemployed(employmentStatusValidator.value?.value) && (
            <>
              <FormGroup>
                <FormLabel>{t('Source of Funds')}</FormLabel>
                <Select
                  key={`sourceOfFunds.select.${JSON.stringify(sourceOfFundsValidator.value)}`}
                  value={sourceOfFundsValidator.value}
                  options={sourceOfFundsOptions}
                  onChange={onSourceOfFundsChange}
                  placeholder={t('Please select source of funds')}
                  isSearchable
                  isClearable
                  invalid={sourceOfFundsValidator.invalid}
                  {...generateTestId(`sourceOfFunds.select`)}
                />
                <ValidationErrorMessage size={Size.Medium} validator={sourceOfFundsValidator} />
              </FormGroup>
              {isMoreDetailsNeeded(sourceOfFundsValidator.value?.value) && (
                <FormGroup>
                  <FormLabel>{t('Please specify')}</FormLabel>
                  <FormControl
                    type='text'
                    name='sourceOfFundsOther'
                    placeholder={t('Enter more details')}
                    value={sourceOfFundsSpecifyValidator.value}
                    onChange={(e) => {
                      sourceOfFundsSpecifyValidator.handleEvent(e.target.value, !sourceOfFundsSpecifyValidator.validated);
                    }}
                    onBlur={(e) => {
                      sourceOfFundsSpecifyValidator.handleEvent(e.target.value.trim());
                    }}
                    invalid={sourceOfFundsSpecifyValidator.invalid}
                    {...generateTestId(`sourceOfFundsOther.input`)}
                  />
                  <ValidationErrorMessage size={Size.Medium} validator={sourceOfFundsSpecifyValidator} />
                </FormGroup>
              )}
            </>
          )}

          {isEmployed(employmentStatusValidator.value?.value) && (
            <FormGroup>
              <FormLabel>{t('Company name')}</FormLabel>
              <FormControl
                autoFocus
                type='text'
                name='companyName'
                placeholder={t('Enter your company name')}
                value={companyNameValidator.value}
                onChange={(e) => {
                  companyNameValidator.handleEvent(e.target.value, !companyNameValidator.validated);
                }}
                onBlur={(e) => {
                  companyNameValidator.handleEvent(e.target.value.trim());
                }}
                invalid={companyNameValidator.invalid}
                {...generateTestId(`companyName.input`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={companyNameValidator} />
            </FormGroup>
          )}
          {isSelfEmployed(employmentStatusValidator.value?.value) && (
            <FormGroup>
              <FormLabel>{t('Business name')}</FormLabel>
              <FormControl
                autoFocus
                type='text'
                name='businessName'
                placeholder={t('Enter your business name')}
                value={businessNameValidator.value}
                onChange={(e) => {
                  businessNameValidator.handleEvent(e.target.value, !businessNameValidator.validated);
                }}
                onBlur={(e) => {
                  businessNameValidator.handleEvent(e.target.value.trim());
                }}
                invalid={businessNameValidator.invalid}
                {...generateTestId(`businessName.input`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={businessNameValidator} />
            </FormGroup>
          )}
          {(isEmployedOrSelfEmployed(employmentStatusValidator.value?.value) ||
            (employmentStatusValidator.value?.value === STUDENT && sourceOfFundsValidator.value?.value === SOURCE_OF_FUNDS_PART_TIME_WORK) ||
            (employmentStatusValidator.value?.value === RETIRED && sourceOfFundsValidator.value?.value === SOURCE_OF_FUNDS_PART_TIME_WORK)) && (
            <FormGroup>
              <FormLabel>{t('Industry')}</FormLabel>
              <Select
                key={`industry.select.${JSON.stringify(industryValidator.value)}`}
                value={industryValidator.value}
                options={industryOptions}
                onChange={onIndustryChange}
                placeholder={t('Please select an industry')}
                isSearchable
                isClearable
                invalid={industryValidator.invalid}
                {...generateTestId(`industry.select`)}
              />
              <ValidationErrorMessage size={Size.Medium} validator={industryValidator} />
            </FormGroup>
          )}

          {isEmployedOrSelfEmployed(employmentStatusValidator.value?.value) && occupationOptions.length > 0 && (
            <>
              <FormGroup>
                <FormLabel>{t('Job title/Occupation')}</FormLabel>
                <Select
                  key={`occupation.select.${JSON.stringify(occupationValidator.value)}`}
                  value={occupationValidator.value}
                  options={occupationOptions}
                  onChange={(option) => occupationValidator.handleEvent(option as ISelectOption)}
                  placeholder={t('Please select occupation')}
                  isSearchable
                  isClearable
                  invalid={occupationValidator.invalid}
                  {...generateTestId(`occupation.select`)}
                />
                <ValidationErrorMessage size={Size.Medium} validator={occupationValidator} />
              </FormGroup>
              {isMoreDetailsNeeded(occupationValidator.value?.value) && (
                <FormGroup>
                  <FormLabel>{t('Please specify')}</FormLabel>
                  <FormControl
                    type='text'
                    name='occupationOther'
                    placeholder={t('Enter more details')}
                    value={occupationSpecifyValidator.value}
                    onChange={(e) => {
                      occupationSpecifyValidator.handleEvent(e.target.value, !occupationSpecifyValidator.validated);
                    }}
                    onBlur={(e) => {
                      occupationSpecifyValidator.handleEvent(e.target.value.trim());
                    }}
                    invalid={occupationSpecifyValidator.invalid}
                    {...generateTestId(`occupationOther.input`)}
                  />
                  <ValidationErrorMessage size={Size.Medium} validator={occupationSpecifyValidator} />
                </FormGroup>
              )}
            </>
          )}
        </CardContent>
        <CardSeparator />
        <CardFooter>
          <CenteredButton
            label={t('Next')}
            onClick={onNextClicked}
            validators={[
              employmentStatusValidator,
              companyNameValidator,
              businessNameValidator,
              industryValidator,
              occupationValidator,
              occupationSpecifyValidator,
              sourceOfFundsSpecifyValidator,
              sourceOfFundsValidator
            ]}
            {...generateTestId(`next`)}
          />
        </CardFooter>
      </CardLarge>
    </Form>
  );
};
