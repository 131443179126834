import React, { useEffect, useState } from 'react';
import { CardContent, CardLarge, Form, FormGroup, Size, Text, useTestId } from '@cmctechnology/phoenix-stockbroking-web-design';
import { RetrieveApplicationRequestModel } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../Store/Store';
import { IframeForJumio } from '../../Components/Sgp/IframeForJumio';
import { JUMIO_IMAGE_CHECK_SUCCESS_EVENT, JUMIO_POST_WINDOW_MESSAGE_NAME, JUMIO_IMAGE_CHECK_ERROR_EVENT } from '../../constants/sgp/jumio';
import { INextPage } from '../../models/IPageNavigation';
import { openSingpassOrJumioAuthorisation, verifyIdentityByJumio } from '../../Store/Actions';
import { FullWidthCardContent } from '../../Components/Sgp/FullWidthCardContent';
import { getAuthoriseErrorMessage } from '../../models/sgp/jumioErrorCodes/JumioAuthoriseErrorCode ';
import { CardFooterWithSmallPaddingTop } from '../../Components/CardWithSmallPadding';
import { CenteredButton } from '../../Components/CenteredButton';
import { JumioPageHeader } from '../../Components/Sgp/JumioPageHeader';
import { IdVerifyProvider } from '../../constants/sgp/myinfo';

export interface IJumioConnectorProps extends INextPage {}
export const JumioConnector: React.FC<IJumioConnectorProps> = ({ onNext, ...rest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jumioAuthoriseUrl = useSelector((store: IStore) => store.local.sgp.jumioAuthoriseUrl);
  const workflowId = useSelector((store: IStore) => store.persist!.sgp.workflowId);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const { generateTestId } = useTestId(rest, `sgp_jumio_iframe_host`);

  const startVerifyIdentityByJumio = () => {
    const retrieveApplicationRequest: RetrieveApplicationRequestModel = {
      workflowId
    };
    dispatch(verifyIdentityByJumio(retrieveApplicationRequest));
    onNext();
  };

  const receiveJumioMessage = (event: any) => {
    try {
      const data = JSON.parse(event.data);
      if (data.payload.value === JUMIO_IMAGE_CHECK_SUCCESS_EVENT) {
        startVerifyIdentityByJumio();
      } else if (data.payload.value === JUMIO_IMAGE_CHECK_ERROR_EVENT) {
        setErrorCode(data.payload.metainfo.code);
      } else {
        setErrorCode(undefined);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  };

  useEffect(() => {
    window.addEventListener(JUMIO_POST_WINDOW_MESSAGE_NAME, receiveJumioMessage, false);
    return () => {
      window.removeEventListener(JUMIO_POST_WINDOW_MESSAGE_NAME, receiveJumioMessage, false);
    };
  }, []);

  const onTryAgainClicked = () => {
    setErrorCode(undefined);
    dispatch(openSingpassOrJumioAuthorisation({ providerType: IdVerifyProvider.Jumio }));
  };

  return (
    <Form>
      <CardLarge>
        <JumioPageHeader />
        {!errorCode && (
          <FullWidthCardContent needSmallPadding>
            <IframeForJumio src={jumioAuthoriseUrl!} {...generateTestId(`sgp_jumio_iframe`)} />
          </FullWidthCardContent>
        )}
        {errorCode && (
          <CardContent>
            <FormGroup>
              <Text size={Size.Large} textAlign='left'>
                {getAuthoriseErrorMessage(errorCode, t)}
              </Text>
            </FormGroup>
          </CardContent>
        )}
        {errorCode && (
          <CardFooterWithSmallPaddingTop>
            <FormGroup>
              <CenteredButton label={t('Try again now')} onClick={onTryAgainClicked} {...generateTestId(`jumio_retry`)} />
            </FormGroup>
          </CardFooterWithSmallPaddingTop>
        )}
      </CardLarge>
    </Form>
  );
};
