import { UsersErrorCode } from '@cmctechnology/phoenix-stockbroking-api-client';
import { BaseErrorCode } from '../baseErrorCodes';

// As discussed with backend, MissingInput and InvalidParameterException are unexpected errors for public APIs since frontend needs to validate the input first.
// Backend will not return descriptive MissingInput and InvalidParameterException errors for public APIs since there could be a security breach.
// TooManyRequestsException and LimitExceededException should be retried before displaying the message
// NotAuthorizedException should redirect to login page. Currently not applicable since all APIs are public.

export const baseUserErrorCodeMappings: Record<UsersErrorCode, BaseErrorCode> = {
  [UsersErrorCode.MissingInput]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.InvalidParameterException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.TooManyRequestsException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.LimitExceededException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.NotAuthorizedException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.InvalidPasswordException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.UsernameExistsException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.UserNotFoundException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.SignUpConfirmFail]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.CodeDeliveryFailureException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.CodeMismatchException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.TooManyFailedAttemptsException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.ForgotPasswordException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.ExpiredCodeException]: BaseErrorCode.UnexpectedError,
  [UsersErrorCode.UserNotConfirmedException]: BaseErrorCode.UnexpectedError
};
