import { Brand } from '@cmctechnology/phoenix-stockbroking-api-client';
import { TFunction } from 'i18next';
import { BusinessRegion } from '../configuration/configuration';
import { InternationalDiallingCodes } from '../models/internationalDiallingCodes';
import { Translated } from '../models/translation';

export interface IPasswordValidation {
  id: string;
  regex: RegExp;
  message: Translated;
}

export const passwordValidations: IPasswordValidation[] = [
  { id: 'containsUppercase', regex: /.*[A-Z]/, message: (t) => t('Contain a capital letter') },
  { id: 'containsLowercase', regex: /.*[a-z]/, message: (t) => t('Contain a lowercase') },
  { id: 'containsNumber', regex: /.*\d/, message: (t) => t('Contain a number') },
  { id: 'charactersRange', regex: /^.{8,99}$/, message: (t) => t('Minimum 8 characters') },
  { id: 'cannotStartEndWithSpaces', regex: /^\S+.*\S+$/, message: (t) => t('Cannot start or end with spaces') }
];

export const confirmPasswordValidation = {
  id: 'isMatchedPasswords',
  message: (t: TFunction) => t('Password matches')
};

const singaporeRegionMobileNumber = /^\d{6,13}$/;
// See the following for mobile phone format validation: https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
export const mobilePhoneValidations: Record<InternationalDiallingCodes, RegExp> = {
  [InternationalDiallingCodes.Australia]: /^0?4\s*(\d\s*){8}$/,
  [InternationalDiallingCodes.Singapore]: singaporeRegionMobileNumber,
  [InternationalDiallingCodes.Thailand]: singaporeRegionMobileNumber,
  [InternationalDiallingCodes.Vietnam]: singaporeRegionMobileNumber,
  [InternationalDiallingCodes.Philippines]: singaporeRegionMobileNumber,
  [InternationalDiallingCodes.Taiwan]: singaporeRegionMobileNumber,
  [InternationalDiallingCodes.Malaysia]: singaporeRegionMobileNumber
};

// See the following for email validation: http://emailregex.com/
// Please ensure that this is sync'ed with backend
export const emailRegex =
  /^(?=.{1,60}$)(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/;

export const FULL_OPACITY = 1;
export const HALF_OPACITY = 0.5;

export const BUSINESS_REGION_TO_BRAND_MAPPINGS: Record<BusinessRegion, Brand> = {
  [BusinessRegion.AUS]: Brand.cmc,
  [BusinessRegion.SGP]: Brand.cmcinvestsingapore
};
