import React from 'react';
import { FormCol, FormRow, Label, Text, Heading3, CardContent, FormGroup, Size } from '@cmctechnology/phoenix-stockbroking-web-design';
import { PersonalAddressDetails } from '@cmctechnology/phoenix-stockbroking-api-client';
import { useTranslation } from 'react-i18next';
import { defaultDisplayAddress } from '../models/common';
import { getAddressLines } from '../Store/Actions';
import { ReviewEditButton } from '../Components/ReviewEditButton';

export interface IProfilePersonalAddressDetailsProps {
  addressDetails: PersonalAddressDetails;
  onEdit: () => void;
}

export const ReviewProfilePersonalAddressDetails: React.FC<IProfilePersonalAddressDetailsProps> = ({ addressDetails, onEdit }) => {
  const residentialAddressLines = getAddressLines(addressDetails.residentialAddress);

  let postalAddressLines = defaultDisplayAddress;
  if (!addressDetails.postalSameAsResidential && !!addressDetails.postalAddress) {
    postalAddressLines = getAddressLines(addressDetails.postalAddress);
  }
  const { t } = useTranslation();
  return (
    <CardContent>
      <FormRow>
        <FormCol percentWidth={80}>
          <Heading3>{t('Address')}</Heading3>
        </FormCol>
        <FormCol percentWidth={20}>
          <FormRow>
            <ReviewEditButton label={t('Edit')} onClick={onEdit} />
          </FormRow>
        </FormCol>
      </FormRow>
      <FormGroup>
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('Residential address')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            <Text size={Size.Medium}>
              {residentialAddressLines.line1}
              <br />
              {residentialAddressLines.line2}
            </Text>
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol percentWidth={40}>
            <Label>{t('Postal address')}</Label>
          </FormCol>
          <FormCol percentWidth={60}>
            {addressDetails.postalSameAsResidential && <Text size={Size.Medium}>{t('Same as residential address')}</Text>}
            {!addressDetails.postalSameAsResidential && (
              <Text size={Size.Medium}>
                {postalAddressLines.line1}
                <br />
                {postalAddressLines.line2}
              </Text>
            )}
          </FormCol>
        </FormRow>
      </FormGroup>
    </CardContent>
  );
};
