import { AddressInput, DayMonthYear1 } from '@cmctechnology/phoenix-stockbroking-api-client';
import { EMPTY_STRING } from '../constants/commonConstants';

export interface IDisplayAddress {
  line1: string;
  line2: string;
}

export const defaultAddress: AddressInput = {
  isUnformatted: true,
  unformattedAddress: EMPTY_STRING,
  unitNumber: EMPTY_STRING,
  streetNumber: EMPTY_STRING,
  streetName: EMPTY_STRING,
  streetType: EMPTY_STRING,
  city: EMPTY_STRING,
  state: EMPTY_STRING,
  postcode: EMPTY_STRING
};

export const defaultDateInDayMonthYear: DayMonthYear1 = { day: EMPTY_STRING, month: EMPTY_STRING, year: EMPTY_STRING };

export const defaultDisplayAddress: IDisplayAddress = { line1: EMPTY_STRING, line2: EMPTY_STRING };
