import { useValidator, Validators } from '@cmctechnology/phoenix-stockbroking-web-design';
import { useTranslation } from 'react-i18next';
import { createKeyValueTranslatedToOptionList } from '../../../common/mappings';
import { EMPTY_STRING } from '../../../constants/commonConstants';
import { COUNTRIES_ALL } from '../../../models/sgp/country';
import { ISgpPersonalDetails, ISgpTaxInformation } from '../../../models/sgp/profile';

export const useTaxValidators = (personalDetails: ISgpPersonalDetails, taxInformation?: ISgpTaxInformation) => {
  const { t } = useTranslation();

  const countriesSelectOptions = createKeyValueTranslatedToOptionList(COUNTRIES_ALL, t);

  const validatorConfig = {
    validated: taxInformation?.validated,
    debounceMs: 0
  };

  const tinValidator = useValidator(
    personalDetails.nric || personalDetails.fin || taxInformation?.primaryTaxNumber || EMPTY_STRING,
    Validators.required(t('Tax Identification Number is required'), `Tin`),
    validatorConfig
  );

  const isOtherCountryResidentValidator = useValidator(
    taxInformation?.isTaxResidentOtherCountry,
    Validators.required(t('Required'), `IsOtherCountryResident`),
    validatorConfig
  );

  const otherCountryResidentValidator = useValidator(
    countriesSelectOptions.find((c) => c.value === taxInformation?.otherCountryCode),
    Validators.custom((value) => !isOtherCountryResidentValidator.value || !!value, t('The Country of tax residence is required'), `OtherCountryResident`),
    validatorConfig
  );

  const otherTinValidator = useValidator(
    taxInformation?.otherTaxNumber ?? EMPTY_STRING,
    Validators.custom((value) => !isOtherCountryResidentValidator.value || !!value, t('Tax Identification Number is required'), `OtherCountryTin`),
    validatorConfig
  );

  return {
    tinValidator,
    isOtherCountryResidentValidator,
    otherTinValidator,
    otherCountryResidentValidator
  };
};
